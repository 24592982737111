import { Spinner } from '@fashinza/fashinza-design-system';
import { ObjectType } from '../../@types/defined';

interface IFallBackProps {
  result: ObjectType;
  isLoading?: boolean;
}

export const renderJSON = (data: ObjectType | Array<any>): JSX.Element => {
  if (typeof data === 'object' && data !== null) {
    if (Array.isArray(data)) {
      return (
        <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
          {data.map((item, index) => (
            <li key={index}>{renderJSON(item)}</li>
          ))}
        </ul>
      );
    }
    return (
      <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
        {Object.entries(data).map(([key, value], index) => (
          <li key={index}>
            <b>{key}: </b>
            {renderJSON(value)}
          </li>
        ))}
      </ul>
    );
  }
  return <span>{String(data)}</span>;
};

const FallBack = ({ result, isLoading }: IFallBackProps) => (
  <div
    className="full-dimension v-d-flex gap-24"
    style={{ color: 'var(--white)', padding: '24px' }}
  >
    {isLoading || !result.data ? (
      <div className="full-dimension center">
        <Spinner stroke="var(--white)" height={100} width={100} />
      </div>
    ) : (
      <>
        <h1>Something went wrong</h1>
        <div>{renderJSON(result)}</div>
      </>
    )}
  </div>
);

export default FallBack;
