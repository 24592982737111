import { idiotBoxApi } from '../api';
import { SimpleApiResponse } from '../../../@types/api';
import { MasterHourlyDhuDataType } from '../../../components/IdiotBox/MasterView/HourlyDHU/schemas';
import { MasterHourlyLineEfficiencyDataType } from '../../../components/IdiotBox/MasterView/HourlyLineEfficiency/schemas';
import { MasterLineDhuDataType } from '../../../components/IdiotBox/MasterView/LineDHU/schemas';
import { MasterOverallAnalyticsType } from '../../../components/IdiotBox/MasterView/OverallAnalytics/schemas';
import { NUMBERS_REGEX } from '../../../utils/regex';
import { getFiltersFromUrl } from '../utils';

const masterViewApi = idiotBoxApi.injectEndpoints({
  endpoints: builder => ({
    getMasterBaseAnalytics: builder.query<MasterOverallAnalyticsType, void>({
      query: () => `master-tv/base-analytics/${getFiltersFromUrl()}`,
      transformResponse: (
        response: SimpleApiResponse<MasterOverallAnalyticsType>
      ) => response.data,
    }),
    getMasterHourlyDhu: builder.query<MasterHourlyDhuDataType, void>({
      query: () => `master-tv/hourly-dhu/${getFiltersFromUrl()}`,

      transformResponse: (
        response: SimpleApiResponse<MasterHourlyDhuDataType>
      ) =>
        response.data.map(item => ({
          ...item,
          hour: item.hour.replace(NUMBERS_REGEX, ''),
        })),
    }),
    getMasterLineDhu: builder.query<MasterLineDhuDataType, void>({
      query: () => `master-tv/line-dhu/${getFiltersFromUrl()}`,
      transformResponse: (response: SimpleApiResponse<MasterLineDhuDataType>) =>
        response.data,
    }),
    getMasterHourlyLineEfficiency: builder.query<
      MasterHourlyLineEfficiencyDataType,
      void
    >({
      query: () => `master-tv/hourly-line-efficiency/${getFiltersFromUrl()}`,
      transformResponse: (
        response: SimpleApiResponse<MasterHourlyLineEfficiencyDataType>
      ) =>
        response.data.map(item => ({
          ...item,
          hour: item.hour.replace(NUMBERS_REGEX, ''),
        })),
    }),
  }),
});

export const {
  useGetMasterBaseAnalyticsQuery,
  useGetMasterHourlyDhuQuery,
  useGetMasterHourlyLineEfficiencyQuery,
  useGetMasterLineDhuQuery,
} = masterViewApi;
