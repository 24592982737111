import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  GlobalColors,
  // GlobalFonts,
} from '@fashinza/fashinza-design-system';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import './App.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
//    {/* <ThemeProvider theme={basicTheme}> */}   {/* </ThemeProvider> */}
root.render(
  <>
    <GlobalColors />
    {/* <GlobalFonts /> */}
    <App />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
