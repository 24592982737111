import styled from 'styled-components';
import { IUserInterfaceState } from '../../../../redux/ui';

interface ITotalProps {
  $viewMode: IUserInterfaceState['viewMode'];
}

export const Total = styled.div<ITotalProps>`
  background: ${props =>
    props.$viewMode === 'tv' ? 'var(--rhino)' : 'var(--lavender-blush)'};

  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: var(--subtle-text-color);

  span {
    font-size: 30px;
    font-weight: 600;
    color: var(--text-color);
  }
`;

export const MetricContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2px 12px;
`;

interface IMetricProps {
  $viewMode: IUserInterfaceState['viewMode'];
}

export const Metric = styled.div<IMetricProps>`
  padding: 12px;

  &:nth-child(1),
  &:nth-child(2) {
    border-bottom: 1px solid
      ${props =>
        props.$viewMode === 'tv' ? 'var(--fiord)' : 'var(--athens-gray)'};
  }
`;

export const MetricText = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: var(--subtle-text-color);
`;

export const MetricNumber = styled.p`
  font-size: 20px;
  font-weight: 600;
`;

export const PieChartWrapper = styled.div`
  height: 100%;
`;
