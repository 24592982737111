import { useRef, useState } from 'react';
import { MultiGrid } from 'react-virtualized';
import { TABLE_ATTRIBUTES } from '../constants';
import { ALPHABETS, generateUuid } from '../../../utils';

const LAST_COLUMN_SELECTOR = `[${TABLE_ATTRIBUTES.lastStaticColumnDataAttribute}="true"]`;
const MOVING_FOOTER_SELECTOR = `[data-view-type="${TABLE_ATTRIBUTES.movingFooterViewType}"]`;

export function useMultiGrid() {
  const multiGridRef = useRef<MultiGrid | null>(null);
  const [scrollLeftValue, setScrollLeftValue] = useState(0);
  const classNameHash = useRef(generateUuid(6, ALPHABETS)).current;

  function handleHorizontalScroll(isScrolled: boolean) {
    if (isScrolled) {
      const selector = `.${classNameHash} ${LAST_COLUMN_SELECTOR}:not(.${TABLE_ATTRIBUTES.hasBorderClassName})`;
      document.querySelectorAll(selector).forEach(eachNode => {
        eachNode.classList.add(TABLE_ATTRIBUTES.hasBorderClassName);
      });
    } else {
      const selector = `.${classNameHash} ${LAST_COLUMN_SELECTOR}.${TABLE_ATTRIBUTES.hasBorderClassName}`;
      document.querySelectorAll(selector).forEach(eachNode => {
        eachNode.classList.remove(TABLE_ATTRIBUTES.hasBorderClassName);
      });
    }
  }

  function onScroll(scrollLeft: number) {
    setScrollLeftValue(scrollLeft);
    handleHorizontalScroll(scrollLeft > 0);
    const node = document.querySelector(
      `.${classNameHash} ${MOVING_FOOTER_SELECTOR}`
    ) as HTMLElement;
    if (node) {
      node.scrollLeft = scrollLeft;
    }
  }

  return {
    multiGridRef,
    onScroll,
    classNameHash,
    scrollLeftValue,
  };
}
