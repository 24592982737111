import { CSSProperties, ReactNode } from 'react';
import { WebTarget, isStyledComponent, styled } from 'styled-components';

interface IGridItemProps {
  children: ReactNode;
  gridColumn: CSSProperties['gridColumn'];
  gridRow: CSSProperties['gridRow'];
}

function getStyledChildren(
  children: WebTarget,
  gridColumn: CSSProperties['gridColumn'],
  gridRow: CSSProperties['gridRow']
) {
  const StyledChildren = styled(children)`
    grid-row: ${gridRow};
    grid-column: ${gridColumn};
  `;

  return <StyledChildren />;
}

const GridItem = ({ children, gridColumn, gridRow }: IGridItemProps) =>
  isStyledComponent(children) ? (
    getStyledChildren(children, gridColumn, gridRow)
  ) : (
    <div
      style={{
        gridColumn,
        gridRow,
      }}
    >
      {children}
    </div>
  );

export default GridItem;
