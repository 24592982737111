import { IconPropsType } from './types';

interface IRefreshIconProps extends IconPropsType {}

const RefreshIcon = ({
  stroke = 'var(--rhino)',
  size = 24,
  strokeWidth = 1.25,
  ...props
}: IRefreshIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 4v5h.582m15.356 2A8.001 8.001 0 0 0 4.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 0 1-15.357-2m15.357 2H15"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RefreshIcon;
