import { IconPropsType } from './types';

export enum CalendarIconViewTypeEnum {
  Default = 'Default',
  DatesRevised = 'DatesRevised',
  Error = 'Error',
  Check = 'Check',
  Pencil = 'Pencil',
}

interface ICalendarIconProps extends IconPropsType {}

const CalendarIcon = ({
  stroke = 'var(--rhino)',
  size = 24,
  strokeWidth = 1.25,
  viewType = CalendarIconViewTypeEnum.Default,
  ...props
}: ICalendarIconProps) => {
  if (viewType === CalendarIconViewTypeEnum.DatesRevised) {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M5.5 4.6V1M12.7 4.6V1M4.6 8.2H13.6M17.2 7V4.6C17.2 3.60589 16.3941 2.8 15.4 2.8H2.8C1.80589 2.8 1 3.60589 1 4.6V15.4C1 16.3941 1.80589 17.2 2.8 17.2H7M10.9 10.9V13.4312H11.1944M11.1944 13.4312C11.7952 11.947 13.2503 10.9 14.95 10.9C16.7403 10.9 18.2593 12.0617 18.7944 13.6724M11.1944 13.4312H13.4312M19 19V16.4688H18.7056M18.7056 16.4688C18.1048 17.953 16.6497 19 14.95 19C13.2132 19 11.7317 17.9067 11.1562 16.3708M18.7056 16.4688H16.4688"
          stroke="var(--rhino)"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
