import { IconPropsType } from './types';

interface IFashinzaTextLogoProps extends IconPropsType {}

const FashinzaTextLogo = (props: IFashinzaTextLogoProps) => (
  <svg
    width={97}
    height={16}
    viewBox="0 0 97 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.20065 3.89278V6.598H7.10344V9.61163H3.20065V15.1238H0V0.87915H7.90729V3.89442H3.20065V3.89278Z"
      fill="var(--watermelon)"
    />
    <path
      d="M17.4473 12.6269H11.6661L10.7786 15.1254H7.35156L12.6996 0.87915H16.4778L21.8455 15.1254H18.3348L17.4473 12.6269ZM16.4154 9.67396L14.5567 4.40954L12.698 9.67396H16.4154Z"
      fill="var(--watermelon)"
    />
    <path
      d="M22.2578 10.8496H25.5405C25.5405 11.7781 26.3247 12.5213 27.3368 12.5213C28.308 12.5213 29.0299 11.9225 29.0299 11.158C29.0299 10.1868 27.8536 9.67168 26.5117 9.32061C24.4463 8.80385 22.4645 7.56526 22.4645 4.86168C22.4645 2.44684 24.5299 0.62915 27.2548 0.62915C30.0421 0.62915 32.1075 2.48786 32.1075 4.96504H28.8248C28.8248 4.11853 28.1637 3.47873 27.2565 3.47873C26.3886 3.47873 25.7488 4.01518 25.7488 4.73865C25.7488 5.64749 26.6987 6.14293 28.0816 6.51369C30.2488 7.09115 32.3142 8.14436 32.3142 10.9529C32.3142 13.4711 30.1667 15.3708 27.2975 15.3708C24.4249 15.3725 22.2578 13.4317 22.2578 10.8496Z"
      fill="var(--watermelon)"
    />
    <path
      d="M46.4326 0.87915V15.1254H43.232V9.46726H37.5132V15.1238H34.3125V0.87915H37.5132V6.45363H43.232V0.87915H46.4326Z"
      fill="var(--watermelon)"
    />
    <path
      d="M49.1562 5.44849V15.1243H52.3569V5.44849H49.1562Z"
      fill="var(--watermelon)"
    />
    <path
      d="M67.4325 0.87915V15.1254H64.1908L58.2866 6.41262V15.1254H55.0859V0.87915H58.3063L64.2318 9.59194V0.87915H67.4325Z"
      fill="var(--watermelon)"
    />
    <path
      d="M80.7502 12.1101V15.1254H69.4766V12.8336L76.2076 3.89278H69.5586V0.87915H80.6042V3.17095L73.8731 12.1118H80.7502V12.1101Z"
      fill="var(--watermelon)"
    />
    <path
      d="M91.709 12.6269H85.9278L85.0403 15.1254H81.6133L86.9614 0.87915H90.7395L96.1072 15.1254H92.5965L91.709 12.6269ZM90.6771 9.67396L88.8184 4.40954L86.9597 9.67396H90.6771Z"
      fill="var(--watermelon)"
    />
    <path
      d="M52.3421 1.19653H50.5573C49.7829 1.19653 49.1562 1.82321 49.1562 2.59754V4.38242H50.9411C51.7155 4.38242 52.3421 3.75574 52.3421 2.98142V1.19653Z"
      fill="var(--watermelon)"
    />
  </svg>
);

export default FashinzaTextLogo;
