import Grid from '../../../shared/Grid';
import GridItem from '../../../shared/Grid/GridItem';
import HourlyFttData from './HourlyFttData';
import PlanningMetrics from './PlanningMetrics';

const PageTwo = () => (
  <Grid
    minHeight="auto"
    gridTemplateColumns="2fr 4fr"
    gridTemplateRows="1fr"
    gap={24}
  >
    <GridItem gridColumn="1/2" gridRow="1/2">
      <PlanningMetrics />
    </GridItem>
    <GridItem gridColumn="2/3" gridRow="1/2">
      <HourlyFttData />
    </GridItem>
  </Grid>
);

export default PageTwo;
