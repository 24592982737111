import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithZodValidation, overrideFetchBaseQuery } from '../utils';
import { getFiltersFromUrl } from './utils';
import { RestMethodsEnum, type SimpleApiResponse } from '../../@types/api';
import { NUMBERS_REGEX } from '../../utils/regex';
import type { FactoryTvInfoType } from '../../components/IdiotBox/schemas';
import type { MasterOverallAnalyticsType } from '../../components/IdiotBox/MasterView/OverallAnalytics/schemas';
import type { MasterLineDhuDataType } from '../../components/IdiotBox/MasterView/LineDHU/schemas';
import type { MasterHourlyDhuDataType } from '../../components/IdiotBox/MasterView/HourlyDHU/schemas';
import type { MasterHourlyLineEfficiencyDataType } from '../../components/IdiotBox/MasterView/HourlyLineEfficiency/schemas';
import type { FactoryTvListType } from '../../components/DataViewer/schemas';

export const idiotBoxApi = createApi({
  reducerPath: 'idiotBox',
  baseQuery: baseQueryWithZodValidation(
    overrideFetchBaseQuery({
      urlSuffix: '',
    })
  ),
  endpoints: builder => ({
    getFactoryTvInfo: builder.query<FactoryTvInfoType, void>({
      query: () => `factory-tv-info/`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<FactoryTvInfoType>) =>
        response.data,
    }),
    getFactoryTvList: builder.query<FactoryTvListType, void>({
      query: () => `/factory-tv-info/list/`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<FactoryTvListType>) =>
        response.data,
    }),
    assignTv: builder.mutation<
      FactoryTvListType,
      {
        line_id: number;
        user_m_id: number;
      }
    >({
      query(data) {
        return {
          url: `/factory-tv-info/assign/`,
          method: RestMethodsEnum.PUT,
          body: data,
        };
      },

      transformResponse: (response: SimpleApiResponse<FactoryTvListType>) =>
        response.data,
    }),

    getMasterBaseAnalytics: builder.query<MasterOverallAnalyticsType, void>({
      query: () => `master-tv/base-analytics/${getFiltersFromUrl()}`,

      transformResponse: (
        response: SimpleApiResponse<MasterOverallAnalyticsType>
      ) => response.data,
    }),
    getMasterHourlyDhu: builder.query<MasterHourlyDhuDataType, void>({
      query: () => `master-tv/hourly-dhu/${getFiltersFromUrl()}`,

      transformResponse: (
        response: SimpleApiResponse<MasterHourlyDhuDataType>
      ) =>
        response.data.map(item => ({
          ...item,
          hour: item.hour.replace(NUMBERS_REGEX, ''),
        })),
    }),
    getMasterLineDhu: builder.query<MasterLineDhuDataType, void>({
      query: () => `master-tv/line-dhu/${getFiltersFromUrl()}`,
      transformResponse: (response: SimpleApiResponse<MasterLineDhuDataType>) =>
        response.data,
    }),
    getMasterHourlyLineEfficiency: builder.query<
      MasterHourlyLineEfficiencyDataType,
      void
    >({
      query: () => `master-tv/hourly-line-efficiency/${getFiltersFromUrl()}`,
      transformResponse: (
        response: SimpleApiResponse<MasterHourlyLineEfficiencyDataType>
      ) =>
        response.data.map(item => ({
          ...item,
          hour: item.hour.replace(NUMBERS_REGEX, ''),
        })),
    }),
  }),
});

export const {
  useLazyGetFactoryTvInfoQuery,
  useGetFactoryTvListQuery,
  useAssignTvMutation,
} = idiotBoxApi;

export const { resetApiState: resetIdiotBoxState, invalidateTags } =
  idiotBoxApi.util;

/**
  {
    "detail": "Tv not assigned. Please contact manager."
  }
 */
