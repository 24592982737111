import { IconPropsType } from '../iconography/types';

interface IEmptyBarChartProps extends IconPropsType {}

const EmptyBarChart = (props: IEmptyBarChartProps) => (
  <svg
    width={92}
    height={36}
    viewBox="0 0 92 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={24.5} y={13} width={3} height={23} fill="var(--mischka)" />
    <rect x={29.5} y={29} width={3} height={7} fill="var(--mischka)" />
    <rect x={34.5} y={4} width={3} height={32} fill="var(--mischka)" />
    <rect x={39.5} y={22} width={3} height={14} fill="var(--mischka)" />
    <rect x={44.5} y={18} width={3} height={18} fill="var(--mischka)" />
    <rect x={49.5} y={9} width={3} height={27} fill="var(--mischka)" />
    <rect x={54.5} y={15} width={3} height={21} fill="var(--mischka)" />
    <rect x={59.5} y={23} width={3} height={13} fill="var(--mischka)" />
    <rect x={64.5} y={27} width={3} height={9} fill="var(--mischka)" />
  </svg>
);

export default EmptyBarChart;
