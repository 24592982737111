import { DEFAULT_VALUES } from '../../../../constants';
import { useGetTotalsQuery } from '../../../../redux/dashboard/api';
import useFeatureFlags from '../../../../redux/featureFlags/useFeatureFlags';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import Grid from '../../../../shared/Grid';
import GridItem from '../../../../shared/Grid/GridItem';
import Metric from '../../../../shared/Metric';
import Segment from '../../../../shared/Segment';
import { numberWithCommas } from '../../../../utils/numbers';
import { TotalType } from './schemas';

interface IMetricComponentProps {
  data: TotalType | undefined;
  isFetching: boolean;
}

const EfficiencyMetric = ({ data, isFetching }: IMetricComponentProps) => (
  <Metric
    heading="Total Efficiency"
    description="Total efficiency for the selected date"
    value={
      data?.efficiency
        ? numberWithCommas(data.efficiency)
        : DEFAULT_VALUES.DOUBLE_DASH
    }
    valueSuffix="%"
    valueColor="var(--deep-sea)"
    hasBottomBorder={true}
    isLoading={isFetching}
  />
);

const DhuMetric = ({ data, isFetching }: IMetricComponentProps) => (
  <Metric
    heading="Total DHU"
    description="Overall defects per hundred unit (DHU) for the selected date"
    value={data?.dhu ? numberWithCommas(data.dhu) : DEFAULT_VALUES.DOUBLE_DASH}
    valueSuffix="%"
    hasBottomBorder={true}
    isLoading={isFetching}
  />
);

const ActiveUsersMetric = ({ data, isFetching }: IMetricComponentProps) => (
  <Metric
    heading="Active Users"
    description="Active users of Qapp"
    value={
      data?.active_users
        ? numberWithCommas(data.active_users)
        : DEFAULT_VALUES.DOUBLE_DASH
    }
    isLoading={isFetching}
  />
);

const Totals = () => {
  const { department, start_date, end_date } = useTypedSelector(
    store => store.filters.selected
  );
  const { data, isLoading, isFetching } = useGetTotalsQuery({
    department,
    start_date,
    end_date,
  });

  const { should_show_efficiency } = useFeatureFlags();

  const gridItemConfigurations = [
    {
      key: 'efficiency',
      gridColumn: '1/2',
      gridRow: '1/2',
      MetricComponent: EfficiencyMetric,
      shouldRender: should_show_efficiency,
    },
    {
      key: 'dhu',
      gridColumn: '1/2',
      gridRow: should_show_efficiency ? '2/3' : '1/2',
      MetricComponent: DhuMetric,
      shouldRender: true,
    },
    {
      key: 'activeUsers',
      gridColumn: '1/2',
      gridRow: should_show_efficiency ? '3/4' : '2/3',
      MetricComponent: ActiveUsersMetric,
      shouldRender: true,
    },
  ];

  return (
    <Segment isLoading={isLoading} shouldChildrenHavePadding={false}>
      <Grid
        minHeight="100%"
        gridTemplateRows={
          should_show_efficiency ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)'
        }
        gridTemplateColumns="1fr"
      >
        {gridItemConfigurations.map(
          config =>
            config.shouldRender && (
              <GridItem
                key={config.key}
                gridColumn={config.gridColumn}
                gridRow={config.gridRow}
              >
                <config.MetricComponent data={data} isFetching={isFetching} />
              </GridItem>
            )
        )}
      </Grid>
    </Segment>
  );
};

export default Totals;
