import Grid from '../../../shared/Grid';
import GridItem from '../../../shared/Grid/GridItem';
import Section from '../../../shared/Section';
import AllDefects from './AllDefects';
import AreasOfConcern from './AreasOfConcern';
import Defects from './Defects';
import FactoryPerformance from './FactoryPerformance';
import LineDHU from './LineDHU';
import LineFTT from './LineFTT';
import OverAllTrendAnalysis from './OverAllTrendAnalysis';
import OverallData from './OverallData';
import TabularOverAllTrendAnalysis from './TabularOverAllTrendAnalysis';

const OrdinaryReports = () => (
  <>
    <Grid
      minHeight="20vh"
      gridTemplateColumns="1fr"
      gridTemplateRows="1fr"
      gap={24}
    >
      <GridItem gridColumn="1/2" gridRow="1/2">
        <OverallData />
      </GridItem>
    </Grid>

    <Section heading="Factory Analysis">
      <Grid
        minHeight="40vh"
        gridTemplateColumns="1fr"
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <FactoryPerformance />
        </GridItem>
      </Grid>

      <Grid
        minHeight="40vh"
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <LineDHU />
        </GridItem>
        <GridItem gridColumn="2/3" gridRow="1/2">
          <LineFTT />
        </GridItem>
      </Grid>
    </Section>

    <Section heading="Defect Analysis">
      <Grid
        minHeight="60vh"
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <Defects />
        </GridItem>
        <GridItem gridColumn="2/3" gridRow="1/2">
          <AreasOfConcern />
        </GridItem>
      </Grid>

      <Grid
        minHeight="40vh"
        gridTemplateColumns="1fr"
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <AllDefects />
        </GridItem>
      </Grid>
    </Section>

    <Section heading="Trend Analysis">
      <Grid
        minHeight="50vh"
        gridTemplateColumns="1fr"
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <OverAllTrendAnalysis />
        </GridItem>
      </Grid>

      <Grid
        minHeight="auto"
        gridTemplateColumns="1fr"
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <TabularOverAllTrendAnalysis />
        </GridItem>
      </Grid>
    </Section>
  </>
);

export default OrdinaryReports;
