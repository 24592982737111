import { IconPropsType } from './types';

interface IDownloadInvalidIconProps extends IconPropsType {}

const DownloadInvalidIcon = ({
  size = 18,
  fill = 'var(--rhino)',
  strokeWidth = 1.25,
  ...otherProps
}: IDownloadInvalidIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M1 13v1a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-1m-4-4-4 4m0 0L5 9m4 4V1"
      stroke={fill}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownloadInvalidIcon;
