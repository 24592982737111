/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useMemo, useRef } from 'react';
import { Button } from '@fashinza/fashinza-design-system';
import { HiOutlineDocumentArrowDown } from 'react-icons/hi2';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import Segment from '../../../../shared/Segment';
import {
  useGetAllDefectsQuery,
  useLazyGetDefectAnalyticsSpreadSheetQuery,
} from '../../../../redux/reports/api';
import useFakePagination from '../../../../hooks/useFakePagination';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import useDimensions from '../../../../hooks/useDimensions';
import Table from '../../../../shared/Table';
import { capitalizeFirstLetter, openInNewTab } from '../../../../utils';
import type { AllDefectsDataType } from './schemas';

const columnHelper = createColumnHelper<AllDefectsDataType[number]>();

const fixedColumns = [
  columnHelper.accessor('department', {
    id: 'department',
    header: 'Department',
    cell: info => (
      <div className="truncated-text" style={{ maxWidth: 100 }}>
        {capitalizeFirstLetter(info.getValue())}
      </div>
    ),
    maxSize: 15,
  }),
  columnHelper.accessor('defect_type', {
    id: 'defect_type',
    header: 'Defect Type',
    cell: info => info.getValue(),
    maxSize: 15,
  }),
  columnHelper.accessor('count', {
    id: 'count',
    header: 'Count',
    cell: info => info.getValue(),
    maxSize: 15,
  }),
];

const FIXED_COLUMNS_NAMES = ['department', 'defect_type', 'count'];

const AllDefects = () => {
  const tableContentRef = useRef<null>(null);
  const { department, brand, order, style, start_date, end_date, line } =
    useTypedSelector(store => store.filters.selected);
  const lineOptions = useTypedSelector(store =>
    store.filters.line_options
      .filter(item => item.department === department)
      .map(item => item.id)
      .join(',')
  );

  const filters = {
    department,
    brand,
    order,
    line: line || lineOptions,
    style,
    start_date,
    end_date,
  };

  const { data, isLoading, isFetching } = useGetAllDefectsQuery(filters);
  const tableContentDimensions = useDimensions(tableContentRef, [isLoading]);

  const [spreadSheetDownloadTrigger, spreadSheetResult] =
    useLazyGetDefectAnalyticsSpreadSheetQuery();

  useEffect(() => {
    if (spreadSheetResult.isFetching) {
      return;
    }

    if (spreadSheetResult.isSuccess) {
      openInNewTab(spreadSheetResult.data.link);
    }
  }, [spreadSheetResult]);

  const constructedColumns = useMemo(() => {
    if (!data || data?.length === 0) {
      return [];
    }

    const firstItem = data[0];
    const extraColumns = Object.keys(firstItem).filter(
      column => !FIXED_COLUMNS_NAMES.includes(column)
    );

    const columns: Array<ColumnDef<AllDefectsDataType[number]>> = [];

    extraColumns.forEach(item => {
      const columnItem = columnHelper.accessor(item, {
        id: item,
        header: item,
        cell: info => info.getValue(),
        maxSize: 10,
      });

      columns.push(columnItem);
    });

    return columns;
  }, [data]);

  const { page, action } = useFakePagination(data, 5);

  const columns = [...fixedColumns, ...constructedColumns];

  return (
    <Segment
      heading="All Defects"
      description="Tabular representation of hourly trend of DHU, FTT, Reject & Efficiency"
      descriptionMaxWidth={180}
      shouldChildrenHavePadding={false}
      isLoading={isLoading || isFetching}
      className="pos-r"
      childrenWrapperRef={tableContentRef}
      headerContent={
        <Button
          onClick={() => spreadSheetDownloadTrigger(filters)}
          colorScheme="secondary"
          variant="tertiary"
          isLoading={spreadSheetResult.isFetching}
          leftIcon={<HiOutlineDocumentArrowDown size={16} />}
        >
          Download
        </Button>
      }
    >
      <Table
        data={page.data ?? []}
        columns={columns}
        currentPage={page.current ?? 0}
        handlePagination={c =>
          c === 'next' ? action.next() : action.previous()
        }
        pageLength={5}
        totalCount={data?.length ?? 0}
        width={tableContentDimensions.width}
      />
    </Segment>
  );
};

export default AllDefects;
