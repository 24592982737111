import { PORT_GORE_BG_IMAGE } from '../../../../constants';
import { ImageWrapper } from '../../../../shared/ImageWrapper';
import { OverAllDataType } from '../OverallData/schemas';
import * as Styles from './styles';

interface IStylesData {
  data: OverAllDataType['sku_m'] | undefined;
}

const StylesData = ({ data }: IStylesData) => (
  <div className="full-height v-d-flex gap-12">
    <ImageWrapper>
      <img
        src={data?.style_image ?? PORT_GORE_BG_IMAGE}
        alt={data?.style_name}
      />
    </ImageWrapper>
    <Styles.Name>{data?.style_name}</Styles.Name>
    {data?.quantity && <Styles.Quantity>{data.quantity} pcs</Styles.Quantity>}
  </div>
);

export default StylesData;
