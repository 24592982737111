import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Tr = styled.tr`
  /* border: 1px solid black; */
`;

export const Th = styled.th`
  color: var(--lynch);
  padding: 0 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  width: 25%;
  text-align: end;
`;

export const Td = styled.td`
  padding: 8px 24px 8px 0;
  border-left: 1px solid var(--athens-gray);
  width: 75%;
`;
