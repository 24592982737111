import { idiotBoxApi } from '../api';
import { SimpleApiResponse } from '../../../@types/api';
import { HourlyDhuItemsType } from '../../../components/Dashboard/Ordinary/HourlyDHU/schemas';
import { HourlyLineEfficienciesType } from '../../../components/Dashboard/Ordinary/HourlyLineEfficiency/schemas';
import { AreaOfConcernDataType } from '../../../components/IdiotBox/LineView/AreaOfConcern/schemas';
import {
  DefectsGraphDataSchema,
  DefectsGraphDataType,
} from '../../../components/IdiotBox/LineView/Defects/schemas';
import { PlanningGraphDataType } from '../../../components/IdiotBox/LineView/HourlyFttData/schemas';
import { OverAllDataType } from '../../../components/IdiotBox/LineView/OverallData/schemas';
import { PlanningMetricsDataType } from '../../../components/IdiotBox/LineView/PlanningMetrics/schemas';
import {
  LineViewLayoutType,
  LineViewLayoutSchema,
} from '../../../components/IdiotBox/schemas';
import { NUMBERS_REGEX } from '../../../utils/regex';
import { getFiltersFromUrl } from '../utils';
import {
  MidlinesSchema,
  MidlinesType,
} from '../../../components/IdiotBox/LineView/Midlines/schemas';

const lineViewApi = idiotBoxApi.injectEndpoints({
  endpoints: builder => ({
    getOverAllData: builder.query<OverAllDataType, void>({
      query: () => `line-tv/base-analytics/${getFiltersFromUrl()}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<OverAllDataType>) => ({
        ...response.data,
        ftt: Number(response.data.ftt.toFixed(2)),
        ftt_percentage: Math.floor(response.data.ftt_percentage),
        dhu: Number(response.data.dhu.toFixed(2)),
        efficiency: Number(response.data.efficiency.toFixed(2)),
      }),
    }),
    getHourlyDhu: builder.query({
      query: () => `line-tv/hourly-dhu/${getFiltersFromUrl()}`,
      transformResponse: (response: SimpleApiResponse<HourlyDhuItemsType>) =>
        response.data.map(item => ({
          ...item,
          dhu: Number(item.dhu.toFixed(2)),
          hour: item.hour.replace(NUMBERS_REGEX, ''),
        })),
    }),
    getHourlyLineEfficiency: builder.query({
      query: () => `line-tv/line-efficiency/${getFiltersFromUrl()}`,

      transformResponse: (
        response: SimpleApiResponse<HourlyLineEfficienciesType>
      ) =>
        response.data.map(item => ({
          ...item,
          efficiency: Number(item.efficiency.toFixed(2)),
          hour: item.hour.replace(NUMBERS_REGEX, ''),
        })),
    }),
    getPlanningMetrics: builder.query<PlanningMetricsDataType, void>({
      query: () => `line-tv/planning-metric/${getFiltersFromUrl()}`,

      transformResponse: (
        response: SimpleApiResponse<PlanningMetricsDataType>
      ) => response.data,
    }),
    getHourlyFttData: builder.query<PlanningGraphDataType, void>({
      query: () => `line-tv/hourly-ftt/${getFiltersFromUrl()}`,

      transformResponse: (response: SimpleApiResponse<PlanningGraphDataType>) =>
        response.data.map(item => ({
          ...item,
          ftt: Number(item.ftt.toFixed(2)),
          ftt_percentage: Math.floor(item.ftt_percentage),
          inspected_count: Number(item.inspected_count.toFixed(2)),
        })),
    }),
    getTopDefects: builder.query<DefectsGraphDataType, void>({
      query: () => `line-tv/top-defect/${getFiltersFromUrl()}`,
      extraOptions: {
        zodSchema: DefectsGraphDataSchema,
      },
      transformResponse: (response: SimpleApiResponse<DefectsGraphDataType>) =>
        response.data,
    }),
    getTopDefectSections: builder.query<AreaOfConcernDataType, void>({
      query: () => `line-tv/top-defect-section/${getFiltersFromUrl()}`,
      transformResponse: (response: SimpleApiResponse<AreaOfConcernDataType>) =>
        response.data,
    }),

    getLineViewLayout: builder.query<LineViewLayoutType, void>({
      query: () => `/line-tv/layout-web/${getFiltersFromUrl()}`,
      extraOptions: {
        zodSchema: LineViewLayoutSchema,
      },
      transformResponse: (response: SimpleApiResponse<LineViewLayoutType>) =>
        response.data,
    }),

    getMidlines: builder.query<MidlinesType, void>({
      query: () => `/line-tv/midline-data/${getFiltersFromUrl()}`,
      extraOptions: {
        zodSchema: MidlinesSchema,
      },
      transformResponse: (response: SimpleApiResponse<MidlinesType>) =>
        response.data,
    }),
  }),
});

export const {
  useGetOverAllDataQuery,
  useGetHourlyDhuQuery,
  useGetHourlyLineEfficiencyQuery,
  useGetHourlyFttDataQuery,
  useGetPlanningMetricsQuery,
  useGetTopDefectsQuery,
  useGetTopDefectSectionsQuery,
  useGetLineViewLayoutQuery,
  useGetMidlinesQuery,
} = lineViewApi;
