import { z } from 'zod';

export const AuditOverallAnalyticsSchema = z.object({
  audit_count: z.number(),
  inspection_count: z.number(),
  inspection_pass_count: z.number(),
  defective_piece_count: z.number(),
  audit_pass_count: z.number(),
  audit_fail_count: z.number(),
});

export type AuditOverallAnalyticsType = z.infer<
  typeof AuditOverallAnalyticsSchema
>;
