import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithZodValidation, overrideFetchBaseQuery } from '../utils';
import { getFormattedFilters } from './utils';
import type { SimpleApiResponse } from '../../@types/api';
import type { OverAllTrendAnalysisTableItemsType } from '../../components/Reports/Ordinary/TabularOverAllTrendAnalysis/schemas';
import type { LineDhuItemsType } from '../../components/Reports/Ordinary/LineDHU/schemas';
import type { LineFttItemsType } from '../../components/Reports/Ordinary/LineFTT/schemas';
import type { OverallDataType } from '../../components/Reports/Ordinary/OverallData/schemas';
import type { DefectsType } from '../../components/Reports/Ordinary/Defects/schemas';
import { AreasOfConcernSchema } from '../../components/Reports/Ordinary/AreasOfConcern/schemas';
import type { AreasOfConcernType } from '../../components/Reports/Ordinary/AreasOfConcern/schemas';
import type { FactoryPerformanceDataType } from '../../components/Reports/Ordinary/FactoryPerformance/schemas';
import type { AllDefectsDataType } from '../../components/Reports/Ordinary/AllDefects/schemas';
import type { IFiltersState } from '../filters';
import type {
  SpreadSheetDataType,
  UrlType,
} from '../../components/Reports/schemas';

export const reportsApi = createApi({
  reducerPath: 'reports',
  baseQuery: baseQueryWithZodValidation(
    overrideFetchBaseQuery({
      urlSuffix: 'report-section',
    })
  ),
  endpoints: builder => ({
    getOverAllData: builder.query<
      OverallDataType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `overall-data/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<OverallDataType>) => ({
        ...response.data,
        dhu: Number(response.data.dhu.toFixed(2)),
        ftt_percentage: Math.floor(response.data.ftt_percentage),
        efficiency: Number(response.data.efficiency.toFixed(2)),
      }),
    }),
    getOverAllTrend: builder.query<
      OverAllTrendAnalysisTableItemsType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `overall-trend/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (
        response: SimpleApiResponse<OverAllTrendAnalysisTableItemsType>
      ) =>
        response.data.map((item, index) => ({
          ...item,
          id: index + 1,
          dhu: Number(item.dhu.toFixed(2)),
          efficiency: Number(item.efficiency.toFixed(2)),
          ftt_count: Number(item.ftt_count.toFixed(2)),
          ftt_percentage: Math.floor(item.ftt_percentage),
        })),
    }),
    getAllDefects: builder.query<
      AllDefectsDataType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `defect/analytics/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<AllDefectsDataType>) =>
        response.data,
    }),

    getDefects: builder.query<DefectsType, Partial<IFiltersState['selected']>>({
      query: filters => `top-defect/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<DefectsType>) =>
        response.data,
    }),
    getAreaOfConcern: builder.query<
      AreasOfConcernType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `top-defect-section/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: AreasOfConcernSchema,
        // mock: true,
      },
      transformResponse: (response: SimpleApiResponse<AreasOfConcernType>) =>
        response.data,
    }),
    getFactoryAnalytics: builder.query<
      FactoryPerformanceDataType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `factory-analytics/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (
        response: SimpleApiResponse<FactoryPerformanceDataType>
      ) =>
        response.data.map((item, index) => ({
          ...item,
          id: index + 1,
          dhu: Number(item.dhu.toFixed(2)),
          efficiency: Number(item.efficiency.toFixed(2)),
          ftt: Number(item.ftt.toFixed(2)),
          ftt_percentage: Math.floor(item.ftt_percentage),
        })),
    }),
    getLineDhu: builder.query<
      LineDhuItemsType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `line-dhu/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<LineDhuItemsType>) =>
        response.data.map(item => ({
          ...item,
          value: Number(item.value.toFixed(2)),
        })),
    }),
    getLineFtt: builder.query<
      LineFttItemsType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `line-ftt/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<LineFttItemsType>) =>
        response.data.map(item => ({
          ...item,
          value: Number(item.value.toFixed(2)),
        })),
    }),

    // *─── Spreadsheet Apis ────────────────────────────────────────

    getOverAllTrendSpreadSheet: builder.query<
      SpreadSheetDataType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `overall-trend/csv/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<UrlType>) => ({
        name: response.data.split('/').at(-1) ?? '',
        link: response.data,
      }),
    }),

    getDefectAnalyticsSpreadSheet: builder.query<
      SpreadSheetDataType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `defect/analytics/csv/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<UrlType>) => ({
        name: response.data.split('/').at(-1) ?? '',
        link: response.data,
      }),
    }),

    getFactoryAnalyticsSpreadSheet: builder.query<
      SpreadSheetDataType,
      Partial<IFiltersState['selected']>
    >({
      query: filters =>
        `factory-analytics/csv/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<UrlType>) => ({
        name: response.data.split('/').at(-1) ?? '',
        link: response.data,
      }),
    }),
  }),
});

export const {
  useGetOverAllDataQuery,
  useGetOverAllTrendQuery,
  useGetAllDefectsQuery,
  useGetDefectsQuery,
  useGetAreaOfConcernQuery,
  useGetFactoryAnalyticsQuery,
  useGetLineDhuQuery,
  useGetLineFttQuery,
  useLazyGetOverAllTrendSpreadSheetQuery,
  useLazyGetDefectAnalyticsSpreadSheetQuery,
  useLazyGetFactoryAnalyticsSpreadSheetQuery,
} = reportsApi;

export const { resetApiState: resetReportsState } = reportsApi.util;
