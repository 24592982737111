import { IconPropsType } from './types';

interface IPoInvoiceReceiptIconProps extends IconPropsType {}

const PATH_DRAWN = Object.freeze({
  'document-po':
    'M8.57143 9H15.4286M8.57143 13H13.1429M20 21V5C20 3.89543 18.9767 3 17.7143 3H6.28571C5.02335 3 4 3.89543 4 5V21L8 19L12 21L16 19L20 21Z',
  invoice:
    'M9 14L15 8M9.50003 8.5H9.51003M14.5 13.5H14.51M20 21V5C20 3.89543 19.1046 3 18 3H6C4.89543 3 4 3.89543 4 5V21L8 19L12 21L16 19L20 21ZM10 8.5C10 8.77614 9.77614 9 9.5 9C9.22386 9 9 8.77614 9 8.5C9 8.22386 9.22386 8 9.5 8C9.77614 8 10 8.22386 10 8.5ZM15 13.5C15 13.7761 14.7761 14 14.5 14C14.2239 14 14 13.7761 14 13.5C14 13.2239 14.2239 13 14.5 13C14.7761 13 15 13.2239 15 13.5Z',
  dollar:
    'M12 11.5C10.3431 11.5 9 10.6046 9 9.5C9 8.39543 10.3431 7.5 12 7.5M12 11.5C13.6569 11.5 15 12.3954 15 13.5C15 14.6046 13.6569 15.5 12 15.5M12 11.5V7.5M12 11.5L12 15.5M12 7.5C13.1104 7.5 14.0799 7.9022 14.5987 8.5M12 7.5V6.5M12 15.5L12 16.5M12 15.5C10.8896 15.5 9.92008 15.0978 9.40137 14.5M20 21V5C20 3.89543 19.1046 3 18 3H6C4.89543 3 4 3.89543 4 5V21L8 19L12 21L16 19L20 21Z',
});

const PoInvoiceReceiptIcon = ({
  stroke = 'var(--rhino)',
  size = 24,
  strokeWidth = 1.25,
  viewType = '',
  ...props
}: IPoInvoiceReceiptIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={PATH_DRAWN[viewType as keyof typeof PATH_DRAWN]}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PoInvoiceReceiptIcon;
