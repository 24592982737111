import { Fragment, useRef } from 'react';
import PieChart from '../../../../shared/PieChart';
import { IPieChartData } from '../../../../shared/PieChart/types';
import { Label, Dot } from '../../../../shared/PieChart/styles';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import useDimensions from '../../../../hooks/useDimensions';
import type { MasterOverallAnalyticsType } from './schemas';
import Segment from '../../../../shared/Segment';
import { DEFAULT_VALUES } from '../../../../constants';
import * as Styles from './styles';

interface IOverallAnalyticsProps {
  data: MasterOverallAnalyticsType | undefined;
  isLoading: boolean;
}

const OverallAnalytics = ({ data, isLoading }: IOverallAnalyticsProps) => {
  const pieChartWrapperRef = useRef<HTMLDivElement>(null);
  const viewMode = useTypedSelector(store => store.ui.viewMode);
  const pieChartWrapperDimensions = useDimensions(pieChartWrapperRef, [
    isLoading,
  ]);

  const chartData: Array<IPieChartData> = [
    {
      color: 'var(--deep-sea)',
      name: 'Passed',
      subTexts: [`${data?.pass_count} Passed`],
      value: data?.pass_count ?? 0,
    },
    {
      color: 'var(--milano-red)',
      name: 'Rejected',
      subTexts: [`${data?.reject_count} Defects`],
      value: data?.reject_count ?? 0,
    },
    {
      color: 'var(--web-orange)',
      name: 'Repair',
      subTexts: [`${data?.alter_count} Defects`],
      value: data?.alter_count ?? 0,
    },
  ];

  return (
    <Segment
      heading="Overall Analytics"
      isLoading={isLoading}
      headerContent={
        <div className="center">
          {chartData.map(eachEntry => (
            <Fragment key={eachEntry.name}>
              <Dot fill={eachEntry.color} />
              <Label>{eachEntry.name}</Label>
            </Fragment>
          ))}
        </div>
      }
    >
      <div className="d-flex full-flex">
        <div className="v-d-flex gap-12 full-flex">
          <Styles.Total $viewMode={viewMode} className="align-center gap-8">
            <span>{data?.inspection_count ?? DEFAULT_VALUES.DOUBLE_DASH}</span>
            Total Inspected Quantity
          </Styles.Total>
          <Styles.MetricContainer className="full-flex">
            <Styles.Metric className="v-space-around" $viewMode={viewMode}>
              <Styles.MetricText>Passed</Styles.MetricText>
              <Styles.MetricNumber style={{ color: 'var(--deep-sea)' }}>
                {data?.pass_count}
              </Styles.MetricNumber>
            </Styles.Metric>
            <Styles.Metric className="v-space-around" $viewMode={viewMode}>
              <Styles.MetricText>Rejected</Styles.MetricText>
              <Styles.MetricNumber style={{ color: 'var(--milano-red)' }}>
                {data?.reject_count}
              </Styles.MetricNumber>
            </Styles.Metric>
            <Styles.Metric className="v-space-around" $viewMode={viewMode}>
              <Styles.MetricText>Alter</Styles.MetricText>
              <Styles.MetricNumber style={{ color: 'var(--web-orange)' }}>
                {data?.alter_count}
              </Styles.MetricNumber>
            </Styles.Metric>
            <Styles.Metric className="v-space-around" $viewMode={viewMode}>
              <Styles.MetricText>Repair</Styles.MetricText>
              <Styles.MetricNumber
                style={{
                  color: 'var(--text-color)',
                }}
              >
                {data?.repair_count}
              </Styles.MetricNumber>
            </Styles.Metric>
          </Styles.MetricContainer>
        </div>
        <Styles.PieChartWrapper className="full-flex" ref={pieChartWrapperRef}>
          <PieChart
            config={{
              chartWidth: pieChartWrapperDimensions.width,
              chartHeight: pieChartWrapperDimensions.height,
              chartOuterRadius: 90,
            }}
            shouldShowLabels={false}
            data={chartData.map(eachData => ({
              ...eachData,
            }))}
          />
        </Styles.PieChartWrapper>
      </div>
    </Segment>
  );
};

export default OverallAnalytics;
