import { z } from 'zod';

export const DefectsGraphDataSchema = z.array(
  z.object({
    defect_type: z.string(),
    defect_count_in_all_hour: z.number(),
    defect_count_in_current_hour: z.number(),
    defect_count_in_previous_hour: z.number(),
  })
);

export type DefectsGraphDataType = z.infer<typeof DefectsGraphDataSchema>;
