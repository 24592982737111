import { useRef } from 'react';
import useDimensions from '../../../../hooks/useDimensions';
import BarChart from '../../../../shared/BarChart';
import Segment from '../../../../shared/Segment';
import ChartWrapper from '../../../../shared/ChartWrapper';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import { useGetMasterLineDhuQuery } from '../../../../redux/idiotBox/apis/masterView';
import { POLLING_INTERVAL_IN_MILLISECONDS } from '../../../../constants/polling';

const LineDHU = () => {
  const areaChartWrapperRef = useRef<HTMLDivElement>(null);
  const { data, isLoading } = useGetMasterLineDhuQuery(undefined, {
    pollingInterval: POLLING_INTERVAL_IN_MILLISECONDS,
  });
  const { height, width } = useDimensions(areaChartWrapperRef, [isLoading]);
  const viewMode = useTypedSelector(store => store.ui.viewMode);

  return (
    <Segment heading="Line DHU" descriptionMaxWidth={180} isLoading={isLoading}>
      <ChartWrapper wrapperRef={areaChartWrapperRef}>
        <BarChart
          data={data ?? []}
          axisStroke={viewMode === 'tv' ? 'var(--white)' : 'var(--fiord)'}
          height={height}
          width={width}
          yLabel="Defects"
        />
      </ChartWrapper>
    </Segment>
  );
};

export default LineDHU;
