import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithZodValidation, overrideFetchBaseQuery } from '../utils';
import { FeatureFlagsSchema } from './schemas';
import type { SimpleApiResponse } from '../../@types/api';
import type { FeatureFlagsType } from './schemas';

export const featureFlagsApi = createApi({
  reducerPath: 'featureFlags',
  baseQuery: baseQueryWithZodValidation(
    overrideFetchBaseQuery({
      urlSuffix: '',
      base: 'general/',
    })
  ),
  endpoints: builder => ({
    getFeatureFlags: builder.query<FeatureFlagsType, void>({
      query: () => `/feature-controls/`,
      extraOptions: {
        zodSchema: FeatureFlagsSchema,
      },
      transformResponse: (response: SimpleApiResponse<FeatureFlagsType>) =>
        response.data,
    }),
  }),
});

export const { useGetFeatureFlagsQuery } = featureFlagsApi;
