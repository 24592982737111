import { z } from 'zod';

export const MidLineItemsSchema = z.array(
  z.object({
    checker_m_id: z.number(),
    user_m_id: z.number(),
    checker_m_name: z.string(),
    total_checked: z.number(),
    pass_count: z.number(),
    alter_count: z.number(),
    reject_count: z.number(),
    dhu: z.number(),
    ftt: z.number(),
    ftt_percentage: z.number(),
    efficiency: z.number().nullable(),
  })
);

export const LinePerformanceItemsSchema = z.array(
  z.object({
    line_id: z.number(),
    name: z.string(),
    total_checked: z.number(),
    hourly: z.array(
      z.object({
        key: z.string(),
        value: z.number(),
      })
    ),
    pass_count: z.number(),
    alter_count: z.number(),
    reject_count: z.number(),
    ftt: z.number(),
    ftt_percentage: z.number(),
    efficiency: z.number(),
    dhu: z.number(),
    midline_count: z.number(),
  })
);

export type LinePerformanceItemsType = z.infer<
  typeof LinePerformanceItemsSchema
>;

export type MidLineItemsType = z.infer<typeof MidLineItemsSchema>;
