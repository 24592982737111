import styled, { css } from 'styled-components';
import Tooltip, { TooltipProps } from 'react-tooltip-lite';
import { ReactNode } from 'react';

export const Heading = styled.p`
  font-weight: 600;
`;

export const SubHeading = styled.p`
  margin-top: 4px;
`;

interface IStyledTooltipProps extends TooltipProps {
  maxWidth?: number;
  children: ReactNode;
}

export const StyledTooltip = styled(Tooltip)<IStyledTooltipProps>`
  margin-left: 8px;

  .react-tooltip-lite {
    ${props =>
      props.maxWidth &&
      css`
        max-width: ${props.maxWidth}px !important;
      `}
    color: var(--white);
    background-color: var(--ebony);
    border-color: var(--ebony);
    padding: 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    white-space: pre-wrap;
  }

  .react-tooltip-lite-arrow {
    border-color: var(--ebony);
  }
`;
