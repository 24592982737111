import { z } from 'zod';

export const HourlyDhuItemSchema = z.object({
  hour: z.string(),
  dhu: z.number(),
});

export type HourlyDhuItemType = z.infer<typeof HourlyDhuItemSchema>;

export const HourlyDhuItemsSchema = z.array(HourlyDhuItemSchema);

export type HourlyDhuItemsType = z.infer<typeof HourlyDhuItemsSchema>;
