import { ReactNode, RefObject } from 'react';
import { Spinner } from '@fashinza/fashinza-design-system';
import InfoTooltip from '../InfoTooltip';
import { useTypedSelector } from '../../redux/useTypedSelector';
import * as Styles from './styles';

interface ISegmentProps {
  children: ReactNode;
  heading?: string;
  isLoading?: boolean;
  className?: string;
  icon?: ReactNode;
  description?: string;
  headerContent?: ReactNode;
  descriptionMaxWidth?: number;
  shouldChildrenHavePadding?: boolean;
  isHeadingLarge?: boolean;
  shouldHeadingHaveBorder?: boolean;
  childrenWrapperRef?: RefObject<HTMLDivElement>;
}

const Segment = ({
  heading,
  children,
  description,
  headerContent,
  descriptionMaxWidth,
  icon,
  isLoading,
  childrenWrapperRef,
  className = '',
  shouldChildrenHavePadding = true,
  isHeadingLarge = false,
  shouldHeadingHaveBorder = false,
}: ISegmentProps) => {
  const Icon = icon ?? null;

  const viewMode = useTypedSelector(store => store.ui.viewMode);

  return (
    <Styles.Wrapper className="v-d-flex" $viewMode={viewMode}>
      {heading && (
        <Styles.Header
          className="d-flex gap-16"
          $shouldHeadingHaveBorder={shouldHeadingHaveBorder}
          $isHeadingLarge={isHeadingLarge}
        >
          {Icon}
          <Styles.Heading
            $viewMode={viewMode}
            $isHeadingLarge={isHeadingLarge}
            className={`align-center ${isHeadingLarge ? 'spread' : ''}`}
          >
            {heading}
            {description && (
              <InfoTooltip
                heading={description}
                maxWidth={descriptionMaxWidth}
                iconSize={15}
              />
            )}
          </Styles.Heading>
          {headerContent}
        </Styles.Header>
      )}
      <Styles.ChildrenWrapper
        ref={childrenWrapperRef}
        className={`v-d-flex ${className} ${isLoading ? 'center' : ''}`}
        $shouldChildrenHavePadding={shouldChildrenHavePadding}
      >
        {isLoading ? <Spinner /> : children}
      </Styles.ChildrenWrapper>
    </Styles.Wrapper>
  );
};

export default Segment;
