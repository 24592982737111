import { createColumnHelper } from '@tanstack/react-table';
import type { AuditSummaryByCheckerDataType } from './schemas';
import { capitalizeFirstLetter } from '../../../../utils';

export function getColumns({
  shouldShowEfficiency,
}: {
  shouldShowEfficiency: boolean;
}) {
  const columnHelper =
    createColumnHelper<AuditSummaryByCheckerDataType[number]>();

  const columns = [
    columnHelper.accessor('auditor_name', {
      id: 'auditor_name',
      header: 'Inspector Name',
      cell: info => info.getValue(),
      maxSize: 10,
    }),

    columnHelper.accessor('department', {
      id: 'department',
      header: 'Audit type',
      cell: info => `${capitalizeFirstLetter(info.getValue())}`,
      maxSize: 10,
    }),
    columnHelper.accessor('audit_count', {
      id: 'audit_count',
      header: 'Total audits',
      cell: info => info.getValue(),
      maxSize: 10,
    }),
    columnHelper.accessor('inspection_count', {
      id: 'inspection_count',
      header: 'Total Inspected qty',
      cell: info => info.getValue(),
      maxSize: 10,
    }),
    columnHelper.accessor('inspection_pass_count', {
      id: 'inspection_pass_count',
      header: 'Pass',
      cell: info => info.getValue(),
      maxSize: 5,
      meta: {
        headerStyles: {
          color: 'var(--green-haze)',
          background: 'var(--scandal)',
        },
        cellStyles: {
          color: 'var(--green-haze)',
        },
      },
    }),
    columnHelper.accessor('inspection_pass_percentage', {
      id: 'inspection_pass_percentage',
      header: 'Pass %',
      cell: info => `${info.getValue()}%`,
      maxSize: 5,
      meta: {
        headerStyles: {
          color: 'var(--green-haze)',
          background: 'var(--scandal)',
        },
        cellStyles: {
          color: 'var(--green-haze)',
        },
      },
    }),
    columnHelper.accessor('inspection_fail_count', {
      id: 'inspection_fail_count',
      header: 'Fail',
      cell: info => info.getValue(),
      maxSize: 5,
      meta: {
        headerStyles: {
          color: 'var(--red-dirt)',
          background: 'var(--fair-pink)',
        },
        cellStyles: {
          color: 'var(--red-dirt)',
        },
      },
    }),
    columnHelper.accessor('inspection_fail_percentage', {
      id: 'inspection_fail_percentage',
      header: 'Fail %',
      cell: info => `${info.getValue()} %`,
      maxSize: 5,
      meta: {
        headerStyles: {
          color: 'var(--red-dirt)',
          background: 'var(--fair-pink)',
        },
        cellStyles: {
          color: 'var(--red-dirt)',
        },
      },
    }),
    columnHelper.accessor('line_names', {
      id: 'line_names',
      header: 'Lines audited',
      cell: info => info.getValue(),
      maxSize: 20,
    }),
  ];

  return columns;
}
