import { IconPropsType } from './types';

interface IApproveIconProps extends IconPropsType {}

const ApproveTickWithWavyCircleIcon = ({
  size = 24,
  fill = 'var(--deep-sea)',
  ...props
}: IApproveIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M47.7656 25.6018L44.9365 21.109L46.6146 16.0668C46.8418 15.3786 46.5587 14.623 45.9348 14.2629L41.3567 11.6059L40.5149 6.35559C40.3975 5.6393 39.7978 5.10489 39.0789 5.07489L33.7987 4.86675L30.6305 0.61024C30.1984 0.0289549 29.4162 -0.164182 28.7699 0.148962L24 2.43285L19.2283 0.147087C18.5801 -0.162307 17.8016 0.03083 17.3676 0.61024L14.1995 4.86675L8.91926 5.07489C8.20219 5.10301 7.6006 5.63742 7.48512 6.35371L6.64327 11.604L2.06522 14.2611C1.44128 14.6211 1.15631 15.3768 1.38354 16.0649L3.06166 21.1071L0.234371 25.6018C-0.151169 26.2149 -0.0524557 27.0156 0.467185 27.5181L4.27974 31.199L3.85137 36.4981C3.79363 37.2237 4.24808 37.8856 4.93907 38.0882L10.0144 39.5601L12.0799 44.456C12.363 45.1217 13.0727 45.508 13.7767 45.3542L18.9545 44.2854L23.0408 47.655C23.3183 47.8819 23.6592 48 24 48C24.3408 48 24.6798 47.8819 24.9592 47.655L29.0455 44.2854L34.2233 45.3542C34.9255 45.5042 35.6388 45.1236 35.9201 44.456L37.9856 39.5601L43.0609 38.0882C43.7519 37.8875 44.2064 37.2237 44.1486 36.4981L43.7203 31.199L47.5328 27.5181C48.0525 27.0156 48.1512 26.2149 47.7656 25.6018ZM34.946 18.2682L24.3464 34.2554C23.946 34.8517 23.3258 35.2511 22.7093 35.2511C22.0947 35.2511 21.4074 34.9042 20.9697 34.4617L13.1881 26.5018C12.6573 25.958 12.6573 25.0767 13.1881 24.5329L15.1084 22.5641C15.6392 22.0278 16.5015 22.0278 17.0286 22.5641L22.0928 27.7431L30.4443 15.1461C30.8634 14.5105 31.7145 14.3492 32.3329 14.7786L34.5846 16.3368C35.2049 16.7681 35.365 17.6363 34.946 18.2682Z"
      fill={fill}
    />
  </svg>
);

export default ApproveTickWithWavyCircleIcon;
