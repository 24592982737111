import { useRef } from 'react';
import useDimensions from '../../hooks/useDimensions';
import * as Styles from './styles';
import { useTypedSelector } from '../../redux/useTypedSelector';

interface IProgressBarProps {
  value: number;
  color: string;
  shouldShowValue?: boolean;
  valueSuffix?: string;
  labelPosition?: 'start' | 'end';
  gap?: number;
  referencePoint?: number;
  barSize?: number;
  hasBackground?: boolean;
}

const ProgressBar = ({
  value,
  color,
  shouldShowValue,
  labelPosition,
  valueSuffix,
  referencePoint,
  gap = 32,
  barSize = 8,
  hasBackground = true,
}: IProgressBarProps) => {
  const chartWrapperRef = useRef<HTMLDivElement>(null);

  const { width } = useDimensions(chartWrapperRef);

  const viewMode = useTypedSelector(store => store.ui.viewMode);

  function getBarWidth() {
    if (referencePoint) {
      return (width / referencePoint) * value;
    }

    return (width / 100) * value;
  }

  return (
    <Styles.Wrapper
      className={`align-center gap-${gap} ${
        labelPosition === 'end' ? 'flex-row-reverse' : ''
      }`}
    >
      {shouldShowValue && (
        <Styles.LabelContainer>
          {value} {valueSuffix}
        </Styles.LabelContainer>
      )}
      <Styles.ProgressContainer
        ref={chartWrapperRef}
        $height={barSize}
        $hasBackground={hasBackground}
        $borderRadius={viewMode === 'web' ? [8, 8, 8, 8] : [0, 4, 4, 0]}
      >
        <Styles.Progress
          $borderRadius={viewMode === 'web' ? [8, 8, 8, 8] : [0, 4, 4, 0]}
          $width={getBarWidth()}
          $background={color}
        />
      </Styles.ProgressContainer>
    </Styles.Wrapper>
  );
};

export default ProgressBar;
