import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid var(--athens-gray);
  height: auto;
  width: 350px;
  font-family: Montserrat;
  padding: 24px;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0px 32px 64px -12px rgba(12, 16, 33, 0.14);

  .rdp {
    padding: 0;
    z-index: 1;
  }

  .rdp-button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .rdp-caption {
    display: flex;

    .rdp-caption_label {
      color: var(--rhino);
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      display: flex;
      align-items: center;
    }

    .rdp-nav {
      display: flex;

      .rdp-nav_button {
        height: 32px;
        width: 32px;

        svg {
          height: 8px;
          width: 8px;
        }

        &:hover {
          background: var(--athens-gray);
        }
      }
    }
  }

  .rdp-table {
    border-spacing: 4px;

    .rdp-head_cell {
      font-size: 14px;
      font-weight: 600;
      color: var(--rhino);
      height: 40px;
      width: 40px;
    }
  }

  .rdp-day {
    width: 40px;
    height: 40px;
    color: var(--rhino);
    border-radius: 0;

    &:hover {
      background: var(--bubbles);
    }
  }

  .rdp-day_selected {
    background: var(--all-ports);
    color: var(--white);
    border-radius: 8px;
    font-weight: 600;

    &:hover {
      background: var(--blue-lagoon);
    }
  }

  .rdp-day_disabled {
    color: var(--manatee);
    &:hover {
      background-color: inherit;
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    background-color: var(--all-ports);
    color: var(--white);
    font-weight: 600;
  }
`;
