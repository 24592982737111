import { DEFAULT_VALUES } from '../../../../constants';
import useFeatureFlags from '../../../../redux/featureFlags/useFeatureFlags';
import { useGetOverAllDataQuery } from '../../../../redux/reports/api';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import Grid from '../../../../shared/Grid';
import GridItem from '../../../../shared/Grid/GridItem';
import Metric from '../../../../shared/Metric';
import Segment from '../../../../shared/Segment';
import { numberWithCommas } from '../../../../utils/numbers';

const OverallData = () => {
  const { department, brand, order, style, start_date, end_date, line } =
    useTypedSelector(store => store.filters.selected);
  const lineOptions = useTypedSelector(store =>
    store.filters.line_options
      .filter(item => item.department === department)
      .map(item => item.id)
      .join(',')
  );
  const { data, isLoading, isFetching } = useGetOverAllDataQuery({
    department,
    brand,
    order,
    line: line || lineOptions,
    style,
    start_date,
    end_date,
  });

  const { should_show_efficiency } = useFeatureFlags();

  return (
    <Segment
      heading="Overall Data"
      isLoading={isLoading}
      shouldChildrenHavePadding={false}
    >
      <Grid
        minHeight="100%"
        gridTemplateRows="1fr 1fr"
        gridTemplateColumns={
          should_show_efficiency ? 'repeat(5, 1fr)' : 'repeat(4, 1fr)'
        }
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <Metric
            heading="Total Inspected"
            description="Total checked quantity"
            value={
              data?.total_inspected
                ? numberWithCommas(data.total_inspected)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueColor="var(--deep-sea)"
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>
        <GridItem gridColumn="2/3" gridRow="1/2">
          <Metric
            heading="Total passed"
            description="Total passed quantity (first time + after alteration)"
            value={
              data?.pass_count
                ? numberWithCommas(data.pass_count)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueColor="var(--deep-sea)"
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>
        <GridItem gridColumn="3/4" gridRow="1/2">
          <Metric
            heading="Passed in first time"
            description="Pieces approved in first check"
            value={
              data?.ftt_count
                ? numberWithCommas(data.ftt_count)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueSuffix={` (${
              data?.ftt_percentage ?? DEFAULT_VALUES.DOUBLE_DASH
            } %)`}
            valueColor="var(--deep-sea)"
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>
        <GridItem gridColumn="4/6" gridRow="1/2">
          <Metric
            heading="Passed after alter"
            description="Pieces approved after alteration"
            value={
              data?.pass_with_alter_count
                ? numberWithCommas(data.pass_with_alter_count)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueColor="var(--chilean-fire)"
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>

        <GridItem gridColumn="1/2" gridRow="2/3">
          <Metric
            heading="Defective Pieces"
            description="Garment pieces with defects"
            value={
              data?.alter_count
                ? numberWithCommas(data.alter_count)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueColor="var(--red-dirt)"
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>
        <GridItem gridColumn="2/3" gridRow="2/3">
          <Metric
            heading="Defect Count"
            description="Total defect count in all defective pieces"
            value={
              data?.defect_kind_count
                ? numberWithCommas(data.defect_kind_count)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueColor="var(--red-dirt)"
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>
        <GridItem gridColumn="3/4" gridRow="2/3">
          <Metric
            heading="Rejected"
            description="Rejected or failed pieces"
            value={
              data?.reject_count
                ? numberWithCommas(data.reject_count)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueColor="var(--red-dirt)"
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>
        <GridItem gridColumn="4/5" gridRow="2/3">
          <Metric
            heading="DHU"
            description="Defects per hundred units"
            value={
              data?.dhu
                ? numberWithCommas(data.dhu)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueSuffix="%"
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>
        <GridItem gridColumn="5/6" gridRow="2/3">
          {should_show_efficiency && (
            <Metric
              heading="Efficiency"
              description="Efficiency of the factory"
              value={
                data?.efficiency
                  ? numberWithCommas(data.efficiency)
                  : DEFAULT_VALUES.DOUBLE_DASH
              }
              valueSuffix="%"
              hasTopBorder
              isLoading={isFetching}
            />
          )}
        </GridItem>
      </Grid>
    </Segment>
  );
};

export default OverallData;
