import styled from 'styled-components';

export const Wrapper = styled.div``;

interface IButtonProps {
  $isActive?: boolean;
}

export const Button = styled.div<IButtonProps>`
  height: 40px;
  padding: 8px 16px;
  border: none;
  border-radius: 0;
  gap: 8px;
  color: var(--rhino);
  font-weight: 500;
  font-size: 14px;
  border-right: 1px solid var(--loblolly);
  border-top: 1px solid var(--loblolly);
  border-bottom: 1px solid var(--loblolly);
  background: ${({ $isActive }) =>
    $isActive ? 'var(--wash-me)' : 'var(--white)'};

  &:hover {
    text-decoration: none;
    color: var(--rhino);
    font-weight: 500;
    font-size: 14px;
  }

  &:first-child {
    border-left: 1px solid var(--loblolly);
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
  }
`;
