import styled, { css, keyframes } from 'styled-components';

const slideAnimation = keyframes`
 from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const Looper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const InnerList = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
`;

interface IListInstanceProps {
  $shouldAnimate: boolean;
}

export const ListInstance = styled.div<IListInstanceProps>`
  display: flex;
  width: max-content;

  ${props =>
    props.$shouldAnimate
      ? css`
          animation: ${slideAnimation} linear infinite;
        `
      : css`
          animation: none;
        `}
`;
