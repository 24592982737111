/* eslint-disable no-nested-ternary */
import { Skeleton } from '@fashinza/fashinza-design-system';
import { Fragment, useRef } from 'react';
import PieChart from '../../../../shared/PieChart';
import { IPieChartData } from '../../../../shared/PieChart/types';
import Segment from '../../../../shared/Segment';
import { Label, Dot } from '../../../../shared/PieChart/styles';
import useDimensions from '../../../../hooks/useDimensions';
import { useGetOverallAnalyticsQuery } from '../../../../redux/dashboard/api';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import { DEFAULT_VALUES } from '../../../../constants';
import { numberWithCommas } from '../../../../utils/numbers';
import TinyMetric from '../../../../shared/TinyMetric';
import * as Styles from './styles';

const OverallAnalytics = () => {
  const pieChartWrapperRef = useRef<HTMLDivElement>(null);

  const { department, start_date, end_date } = useTypedSelector(
    store => store.filters.selected
  );
  const { data, isLoading, isFetching } = useGetOverallAnalyticsQuery({
    department,
    start_date,
    end_date,
  });

  const viewMode = useTypedSelector(store => store.ui.viewMode);

  const pieChartWrapperDimensions = useDimensions(pieChartWrapperRef, [
    isLoading,
  ]);

  const chartData: Array<IPieChartData> = [
    {
      color: 'var(--deep-sea)',
      name: 'Pass',
      subTexts: [`${data?.pass_count} Passed`],
      value: data?.pass_count ?? 0,
    },
    {
      color: 'var(--milano-red)',
      name: 'Reject',
      subTexts: [`${data?.reject_count} Rejected`],
      value: data?.reject_count ?? 0,
    },
    {
      color: 'var(--web-orange)',
      name: 'Alter',
      subTexts: [`${data?.alter_count} Altered`],
      value: data?.alter_count ?? 0,
    },
  ];

  return (
    <Segment
      heading="Overall Analytics"
      description="Summary of the production floor"
      descriptionMaxWidth={180}
      isLoading={isLoading}
      headerContent={
        <div className="center">
          {chartData.map(eachEntry => (
            <Fragment key={eachEntry.name}>
              <Dot fill={eachEntry.color} />
              <Label>{eachEntry.name}</Label>
            </Fragment>
          ))}
        </div>
      }
    >
      <div className="d-flex full-flex">
        <div className="v-d-flex gap-12 full-flex">
          {isFetching ? (
            <Skeleton height={50} width="100%" />
          ) : (
            <Styles.Total $viewMode={viewMode} className="align-center gap-8">
              <span>
                {data?.inspection_count
                  ? numberWithCommas(data.inspection_count)
                  : DEFAULT_VALUES.DOUBLE_DASH}
              </span>
              Total Quantity
            </Styles.Total>
          )}
          <Styles.MetricContainer className="full-flex">
            <TinyMetric
              color="var(--deep-sea)"
              heading="Passed"
              isLoading={isFetching}
              value={data?.pass_count}
              viewMode={viewMode}
            />
            <TinyMetric
              color="var(--milano-red)"
              heading="Rejected"
              isLoading={isFetching}
              value={data?.reject_count}
              viewMode={viewMode}
            />
            <TinyMetric
              color="var(--text-color)"
              heading="Alter"
              isLoading={isFetching}
              value={data?.alter_count}
              viewMode={viewMode}
            />
            <TinyMetric
              color="var(--text-color)"
              heading="Repair"
              isLoading={isFetching}
              value={data?.repair_count}
              viewMode={viewMode}
            />
          </Styles.MetricContainer>
        </div>
        <Styles.PieChartWrapper className="full-flex" ref={pieChartWrapperRef}>
          <PieChart
            config={{
              chartWidth: pieChartWrapperDimensions.width,
              chartHeight: pieChartWrapperDimensions.height,
              chartOuterRadius: 90,
            }}
            shouldShowLabels={false}
            data={chartData.map(eachData => ({
              ...eachData,
            }))}
          />
        </Styles.PieChartWrapper>
      </div>
    </Segment>
  );
};

export default OverallAnalytics;
