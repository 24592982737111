import { createColumnHelper } from '@tanstack/react-table';
import { HourlyCountsItemsType } from './schemas';

export function getColumns({
  shouldShowEfficiency,
}: {
  shouldShowEfficiency: boolean;
}) {
  const columnHelper = createColumnHelper<HourlyCountsItemsType[number]>();

  const size = shouldShowEfficiency ? 100 / 6 : 100 / 5;

  const columns = [
    columnHelper.accessor('hour', {
      id: 'hour',
      header: 'Hour',
      cell: info => info.getValue(),
      //   size: 100,
    }),
    columnHelper.accessor('alter_count', {
      id: 'alter_count',
      header: 'Alter',
      cell: info => info.getValue(),
      //   size: 100,
    }),
    columnHelper.accessor('pass_count', {
      id: 'pass_count',
      header: 'Pass',
      cell: info => info.getValue(),
      //   size: 100,
    }),
    columnHelper.accessor('reject_count', {
      id: 'reject_count',
      header: 'Reject',
      cell: info => info.getValue(),
      //   size: 100,
    }),
    columnHelper.accessor('dhu', {
      id: 'dhu',
      header: 'DHU',
      cell: info => info.getValue(),
      //   size: 100,
    }),
  ];

  if (shouldShowEfficiency) {
    return [
      ...columns,
      columnHelper.accessor('efficiency', {
        id: 'efficiency',
        header: 'Efficiency',
        cell: info => info.getValue(),
        // size,
      }),
    ];
  }

  return columns;
}
