import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import SessionsTable from '../Table';
import OrdinaryDetails from './OrdinaryDetails';
import { useTypedSelector } from '../../../redux/useTypedSelector';
import { useGetSessionsQuery } from '../../../redux/sessions/api';
import ActivityInfo from './ActivityInfo';
import Sidebar from '../../../shared/Sidebar';
import { capitalizeFirstLetter } from '../../../utils';

const OrdinarySessions = () => {
  const { session, department, start_date, end_date } = useTypedSelector(
    store => store.filters.selected
  );

  const {
    data: sessions,
    isLoading,
    isFetching,
  } = useGetSessionsQuery({
    session,
    department,
    start_date,
    end_date,
  });

  const [selectedSessionId, setSelectedSessionId] = useState(-1);

  function openDetailsView(sessionId: number) {
    setSelectedSessionId(() => sessionId);
  }

  function closeDetailsView() {
    setSelectedSessionId(-1);
  }

  const detailsData = sessions?.data[selectedSessionId];

  return (
    <AnimatePresence>
      <ActivityInfo />
      <SessionsTable
        mode="ordinary"
        height={720}
        onClickViewDetails={openDetailsView}
        data={sessions?.data ?? {}}
        ids={sessions?.ids ?? []}
        isLoading={isLoading}
        isFetching={isFetching}
      />
      <Sidebar
        isOpen={selectedSessionId > -1 && Boolean(detailsData)}
        heading={detailsData ? `${detailsData.collection_name} Details` : ''}
        subHeadings={
          detailsData
            ? [
                detailsData.line_name,
                capitalizeFirstLetter(detailsData.department),
              ]
            : []
        }
        onClose={closeDetailsView}
      >
        <OrdinaryDetails sessionId={selectedSessionId} />
      </Sidebar>
    </AnimatePresence>
  );
};

export default OrdinarySessions;
