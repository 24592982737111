import { Fragment } from 'react';
import styled from 'styled-components';
import { TABLE_ATTRIBUTES } from './constants';
import { IGridSettings, IGridCellNode } from './types';
import { createArrayOfSize } from '../../utils';

const Wrapper = styled.div`
  background-color: var(--white);
  box-shadow: rgb(44 53 86 / 15%) 0 -1px 1px;
`;

interface IFooterRowProps {
  positioning: {
    top?: number;
    height: IGridSettings['footerHeight'];
    width: number;
  };
  getGridCellNode(gridProps: IGridCellNode): JSX.Element;
  totalColumnCount: number;
  fixedColumnCount: number;
  rowIndex: number;
  getColumnWidth(index: number): number;
  onScroll(scrollLeft: number): void;
}

const FooterRow = ({
  positioning: { top = 0, height, width },
  getGridCellNode,
  totalColumnCount,
  fixedColumnCount,
  rowIndex,
  getColumnWidth,
  onScroll,
}: IFooterRowProps) => (
  <Wrapper
    className="full-width d-flex pos-a no-x-scroll"
    style={{
      top,
      height,
      maxWidth: width,
    }}
  >
    <div className="d-flex">
      {createArrayOfSize(fixedColumnCount).map((_, index) => (
        <Fragment key={index}>
          {getGridCellNode({
            columnIndex: index,
            rowIndex,
            viewMode: 'footer',
            style: {
              minWidth: getColumnWidth(index),
            },
          })}
        </Fragment>
      ))}
    </div>

    <div
      className="d-flex"
      style={{ overflowX: 'scroll' }}
      onScroll={event => {
        const target = event.target as HTMLElement;
        onScroll(target.scrollLeft);
      }}
      data-view-type={TABLE_ATTRIBUTES.movingFooterViewType}
    >
      {createArrayOfSize(totalColumnCount - fixedColumnCount).map(
        (_, index) => (
          <Fragment key={index}>
            {getGridCellNode({
              columnIndex: fixedColumnCount + index,
              rowIndex,
              viewMode: 'footer',
              style: {
                minWidth: getColumnWidth(fixedColumnCount + index),
              },
            })}
          </Fragment>
        )
      )}
    </div>
  </Wrapper>
);

export default FooterRow;
