import { useRef } from 'react';
import useDimensions from '../../../../../../hooks/useDimensions';
import AreaChart from '../../../../../../shared/AreaChart';
import Segment from '../../../../../../shared/Segment';
import ChartWrapper from '../../../../../../shared/ChartWrapper';
import { useGetHourlyEfficiencyByLineIdQuery } from '../../../../../../redux/dashboard/api';

interface IHourlyLineEfficiencyProps {
  lineId: number;
}

const HourlyLineEfficiency = ({ lineId }: IHourlyLineEfficiencyProps) => {
  const areaChartWrapperRef = useRef<HTMLDivElement>(null);

  const { data, isLoading, isFetching } =
    useGetHourlyEfficiencyByLineIdQuery(lineId);
  const { height, width } = useDimensions(areaChartWrapperRef, [isLoading]);

  return (
    <Segment
      heading="Hourly Line Efficiency"
      description="Hourly line efficiency for all lines"
      descriptionMaxWidth={180}
      isLoading={isLoading || isFetching}
    >
      <ChartWrapper wrapperRef={areaChartWrapperRef}>
        <AreaChart
          data={data ?? []}
          height={height}
          width={width}
          xAxisDataKey="hour"
          xAxisLabel="Hours"
          yAxisLabel="Efficiency"
          axisStroke="var(--fiord)"
          areaData={[
            {
              dataKey: 'efficiency',
              stroke: 'var(--cerise)',
              strokeWidth: 2,
              gradientOpacity: 0.25,
            },
          ]}
        />
      </ChartWrapper>
    </Segment>
  );
};

export default HourlyLineEfficiency;
