import { z } from 'zod';

export const FilterItemSchema = z.object({
  id: z.union([z.number(), z.string()]),
  name: z.string(),
  department: z.string().optional(),
});

export const FilterListSchema = z.array(FilterItemSchema);

export const OrdersListSchema = z.array(
  z.object({
    id: z.union([z.number(), z.string()]),
    collection_name: z.string().nullable(),
    brand_name: z.string(),
    image: z.string().nullable(),
    total_quantity: z.number().nullable(),
    completed_styles: z.string().nullable(),
  })
);

export const ProductsSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    image: z.string().nullable(),
    quantity: z.number(),
    tna_id: z.number(),
    status: z.string().nullable(),
    colors: z.string().nullable(),
    sku_size: z.array(
      z.object({
        po_sku_id: z.number(),
        quantity: z.number(),
        size: z.string(),
      })
    ),
  })
);

export const StylesSchema = z.object({
  order_id: z.number(),
  collection_name: z.string(),
  buyer: z.object({
    id: z.number(),
    name: z.string(),
  }),
  products: ProductsSchema,
});

export type StylesType = z.infer<typeof StylesSchema>;

export const ApiDrivenFiltersSchema = z.object({
  line_options: z.array(FilterItemSchema),
  department: z.array(FilterItemSchema),
  session_options: z.array(FilterItemSchema),
  brands: z.array(FilterItemSchema),
  orders: OrdersListSchema,
  styles: ProductsSchema,
});

export type ApiDrivenFiltersType = z.infer<typeof ApiDrivenFiltersSchema>;
