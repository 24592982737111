import { useRef } from 'react';
import BarChart from '../../../../shared/BarChart';
import ChartWrapper from '../../../../shared/ChartWrapper';
import useDimensions from '../../../../hooks/useDimensions';

interface IHourlyChartProps {
  data: Array<{ key: string; value: number }>;
}

const HourlyChart = ({ data }: IHourlyChartProps) => {
  const areaChartWrapperRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDimensions(areaChartWrapperRef, [data]);

  return (
    <div
      className="full-dimension"
      style={{
        borderLeft: '1px dashed var(--athens-gray)',
        borderRight: '1px dashed var(--athens-gray)',
      }}
    >
      <ChartWrapper wrapperRef={areaChartWrapperRef}>
        <BarChart
          data={
            data.map(item => ({
              name: item.key,
              value: item.value,
            })) ?? []
          }
          height={height}
          width={width}
          barRadius={0}
          barCellWidth={1}
          shouldShowXAxis={false}
          shouldShowTooltip={false}
          shouldShowCartesianGrid={false}
          fill="var(--moon-raker)"
          margin={{
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
          }}
        />
      </ChartWrapper>
    </div>
  );
};

export default HourlyChart;
