import Grid from '../../../shared/Grid';
import GridItem from '../../../shared/Grid/GridItem';
import AreasOfConcern from './AreasOfConcern';
import Defects from './Defects';
import DefectsAnalytics from './DefectsAnalytics';
import LotWiseSummary from './LotWiseSummary';
import OverallAnalytics from './OverallAnalytics';
import StyleWiseSummary from './StyleWiseSummary';
import Totals from './Totals';

const AuditDashboard = () => (
  <>
    <Grid
      minHeight="40vh"
      gridTemplateColumns="repeat(5, 1fr)"
      gridTemplateRows="1fr"
      gap={24}
    >
      <GridItem gridColumn="1/3" gridRow="1/2">
        <OverallAnalytics />
      </GridItem>
      <GridItem gridColumn="3/4" gridRow="1/2">
        <Totals />
      </GridItem>
      <GridItem gridColumn="4/6" gridRow="1/2">
        <DefectsAnalytics />
      </GridItem>
    </Grid>

    <Grid
      minHeight="40vh"
      gridTemplateColumns="1fr"
      gridTemplateRows="1fr"
      gap={24}
    >
      <GridItem gridColumn="1/2" gridRow="1/2">
        <StyleWiseSummary />
      </GridItem>
    </Grid>

    <Grid
      minHeight="40vh"
      gridTemplateColumns="1fr"
      gridTemplateRows="1fr"
      gap={24}
    >
      <GridItem gridColumn="1/2" gridRow="1/2">
        <LotWiseSummary />
      </GridItem>
    </Grid>

    <Grid
      minHeight="60vh"
      gridTemplateColumns="1fr 1fr"
      gridTemplateRows="1fr"
      gap={24}
    >
      <GridItem gridColumn="1/2" gridRow="1/2">
        <Defects />
      </GridItem>
      <GridItem gridColumn="2/3" gridRow="1/2">
        <AreasOfConcern />
      </GridItem>
    </Grid>
  </>
);

export default AuditDashboard;
