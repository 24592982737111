import LineDHU from './LineDHU';
import HourlyDHU from './HourlyDHU';
import HourlyCounts from './HourlyCounts';
import TopDefects from './TopDefects';
import LinePerformance from './LinePerformance';
import HourlyLineEfficiency from './HourlyLineEfficiency';
import OverallAnalytics from './OverallAnalytics';
import Totals from './Totals';
import Grid from '../../../shared/Grid';
import GridItem from '../../../shared/Grid/GridItem';
import useFeatureFlags from '../../../redux/featureFlags/useFeatureFlags';

const OrdinaryDashboard = () => {
  const { should_show_efficiency } = useFeatureFlags();

  return (
    <>
      <Grid
        minHeight="40vh"
        gridTemplateColumns="repeat(5, 1fr)"
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem gridColumn="1/3" gridRow="1/2">
          <OverallAnalytics />
        </GridItem>
        <GridItem gridColumn="3/4" gridRow="1/2">
          <Totals />
        </GridItem>
        <GridItem gridColumn="4/6" gridRow="1/2">
          <LineDHU />
        </GridItem>
      </Grid>

      <Grid
        minHeight="40vh"
        gridTemplateColumns="1fr"
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <LinePerformance />
        </GridItem>
      </Grid>

      <Grid
        minHeight="40vh"
        gridTemplateColumns={should_show_efficiency ? '1fr 1fr' : '1fr'}
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem gridColumn="'1/2'" gridRow="'1/2'">
          <HourlyDHU />
        </GridItem>
        {should_show_efficiency && (
          <GridItem gridColumn="2/3" gridRow="1/2">
            <HourlyLineEfficiency />
          </GridItem>
        )}
      </Grid>

      <Grid
        minHeight="30vh"
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <HourlyCounts />
        </GridItem>
        <GridItem gridColumn="2/3" gridRow="1/2">
          <TopDefects />
        </GridItem>
      </Grid>
    </>
  );
};

export default OrdinaryDashboard;
