import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Table = styled.table``;

export const Th = styled.th`
  color: var(--manatee);
  padding: 0 20px;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  line-height: 24px;
`;

export const ChartsHeader = styled(Th)`
  width: 100%;
  text-align: left;
`;

export const Td = styled.td`
  color: var(--white);
  padding: 0 20px;
  font-size: 14px;
  font-size: 18px;
  font-weight: 700;
  text-align: right;
  white-space: nowrap;
`;
