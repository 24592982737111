import { ReactNode } from 'react';
import SelectSearch, {
  DomProps,
  OptionSnapshot,
  SelectSearchOption,
  SelectSearchProps,
  SelectedOption,
  SelectedOptionValue,
  Snapshot,
  ValueProps,
} from 'react-select-search';
import 'react-select-search/style.css';
import * as Styles from './styles';

interface IDropdownProps {
  options: Array<SelectSearchOption>;
  value?: string | number;
  placeholder: string;
  onChange?: (
    selectedValue: SelectedOptionValue | Array<SelectedOptionValue>,
    selectedOption: SelectedOption | Array<SelectedOption>,
    optionSnapshot: SelectSearchProps
  ) => void;
  label?: string;
  multiple?: boolean;
  showAllOption?: boolean;
  isDisabled?: boolean;
  renderOption?: (
    domProps: DomProps,
    option: SelectedOption,
    snapshot: OptionSnapshot,
    className: string
  ) => ReactNode;
  renderValue?: (
    valueProps: ValueProps,
    snapshot: Snapshot,
    className: string
  ) => ReactNode;
  shouldEnableSearch?: boolean;
}

const Dropdown = ({
  options,
  value,
  placeholder,
  label,
  multiple,
  onChange,
  renderOption,
  renderValue,
  isDisabled = false,
  showAllOption = false,
  shouldEnableSearch = true,
}: IDropdownProps) => (
  <Styles.Wrapper className="v-d-flex gap-4">
    {label && <Styles.DropdownLabel>{label}</Styles.DropdownLabel>}
    <SelectSearch
      search={shouldEnableSearch}
      disabled={isDisabled}
      onChange={onChange}
      options={
        showAllOption
          ? [
              {
                name: `All`,
                value: '',
              },
              ...options,
            ]
          : options
      }
      value={String(value)}
      placeholder={placeholder}
      renderOption={renderOption}
      renderValue={renderValue}
    />
  </Styles.Wrapper>
);

export default Dropdown;
