import { IconPropsType } from './types';

interface IShipmentIconProps extends IconPropsType {}

const ShipmentIcon = ({
  size = 24,
  stroke = 'var(--rhino)',
  strokeWidth = 1.25,
  ...props
}: IShipmentIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 12H15M10 12C8.89543 12 8 11.1046 8 10V5C8 3.89543 8.89543 3 10 3H11M10 12H5C3.89543 12 3 12.8954 3 14V19C3 20.1046 3.89543 21 5 21H10C11.1046 21 12 20.1046 12 19M10 12C11.1046 12 12 12.8954 12 14M15 12C16.1046 12 17 11.1046 17 10V5C17 3.89543 16.1046 3 15 3H14M15 12H19C20.1046 12 21 12.8954 21 14V19C21 20.1046 20.1046 21 19 21H14C12.8954 21 12 20.1046 12 19M15 12V15.5L16.5 15L18 15.5V12M15 12C14.3284 12 15.5 12 14 12C12.5 12 12 13.2664 12 14M12 19V14M6 12V15.5L7.5 15L9 15.5V12M14 3V6.5L12.5 6L11 6.5V3M14 3H11"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </svg>
);

export default ShipmentIcon;
