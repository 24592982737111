import Segment from '../../../../shared/Segment';
import TableWithVerticalBarChart from '../../../../shared/TableWithVerticalBarChart';
import { useGetTopDefectSectionsQuery } from '../../../../redux/idiotBox/apis/lineView';
import { POLLING_INTERVAL_IN_MILLISECONDS } from '../../../../constants/polling';
import FakeTablePagination, {
  useFakeTablePagination,
} from '../../../../shared/FakeTablePagination';

const AreaOfConcern = () => {
  const { data, isLoading } = useGetTopDefectSectionsQuery(undefined, {
    pollingInterval: POLLING_INTERVAL_IN_MILLISECONDS,
  });

  const { page, total } = useFakeTablePagination({ data: data ?? [], size: 5 });

  return (
    <Segment
      heading="Area of concern"
      isLoading={isLoading}
      headerContent={
        <FakeTablePagination total={total} current={page.current ?? 0} />
      }
    >
      <TableWithVerticalBarChart
        barFill="var(--web-orange)"
        barSize={16}
        data={page.data ?? []}
        chartHeader="Hours"
        headers={[
          {
            accessor: 'defect_section_count_in_current_hour',
            text: 'Current Hour',
          },
          {
            accessor: 'defect_section_count_in_previous_hour',
            text: 'Last Hour',
          },
          { accessor: 'defect_section_count_in_all_hour', text: 'Total' },
        ]}
        axisDataKey="defect_section_type"
        barDataKey="defect_section_count_in_current_hour"
        yAxisWidth={300}
      />
    </Segment>
  );
};

export default AreaOfConcern;
