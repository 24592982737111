import { Skeleton, Spinner } from '@fashinza/fashinza-design-system';
import useFeatureFlags from '../../../redux/featureFlags/useFeatureFlags';
import Grid from '../../../shared/Grid';
import GridItem from '../../../shared/Grid/GridItem';
import HourlyDHU from './HourlyDHU';
import HourlyLineEfficiency from './HourlyLineEfficiency';

const PageOne = () => {
  const { should_show_efficiency, isFetching } = useFeatureFlags();

  return (
    <Grid
      minHeight="auto"
      gridTemplateColumns="1fr 1fr"
      gridTemplateRows="1fr"
      gap={24}
      className="full-flex"
    >
      {isFetching ? (
        <GridItem gridColumn="1/3" gridRow="1/2">
          <div className="full-dimension center">
            <Spinner height={50} width={50} />
          </div>
        </GridItem>
      ) : (
        <>
          <GridItem
            gridColumn={should_show_efficiency ? '1/2' : '1/3'}
            gridRow="1/2"
          >
            <HourlyDHU />
          </GridItem>
          {should_show_efficiency && (
            <GridItem gridColumn="2/3" gridRow="1/2">
              <HourlyLineEfficiency />
            </GridItem>
          )}
        </>
      )}
    </Grid>
  );
};

export default PageOne;
