import { useEffect } from 'react';
import { format } from 'date-fns';
import {
  getBrands,
  getOrders,
  getQappFilters,
  getStyles,
} from '../../../redux/filters/thunks';
import useAppDispatch from '../../../redux/useAppDispatch';
import { useTypedSelector } from '../../../redux/useTypedSelector';
import {
  setBrand,
  setDepartment,
  setLine,
  setOrder,
  setDateRange,
  setStyle,
  resetFilter,
  IFiltersState,
} from '../../../redux/filters';
import Dropdown from '../../../shared/Dropdown';
import FilterContainer from '../../../shared/FilterContainer';
import DateRangePicker from '../../../shared/DateRangePicker';
import StylesFilter from './StylesFilter';
import FilterPills from '../../../shared/FilterPills';
import {
  capitalizeFirstLetter,
  removeUnderscoresAndCapitalize,
} from '../../../utils';
import { FILTERS_WITHOUT_CLOSE, NON_REPORT_FILTERS } from './constants';
import QappTracker from '../../../analytics/tracker';
import DatePickerField from '../../../shared/DatePickerField';

const Filters = () => {
  const dispatch = useAppDispatch();

  const selectedFilters = useTypedSelector(store => store.filters.selected);
  const assemblyLineFilterOptions = useTypedSelector(store =>
    store.filters.line_options
      .map(option => ({
        name: option.name,
        value: option.id,
        department: option.department,
      }))
      .filter(option => option.department === selectedFilters.department)
  );
  const departmentFilterOptions = useTypedSelector(store =>
    store.filters.department.map(option => ({
      name: option.name,
      value: option.id,
    }))
  );
  const brandsFilterOptions = useTypedSelector(store =>
    store.filters.brands.map(option => ({
      name: option.name,
      value: option.id,
    }))
  );
  const ordersFilterOptions = useTypedSelector(store =>
    store.filters.orders.map(option => ({
      name: `${option.id} - ${option.brand_name}`,
      value: option.id,
    }))
  );
  const stylesFilterOptions =
    useTypedSelector(store =>
      store.filters.styles.map(option => ({
        ...option,
        name: option.name,
        value: option.id,
      }))
    ) ?? [];

  useEffect(() => {
    dispatch(getQappFilters());
    dispatch(getBrands());
    dispatch(getOrders());
  }, []);

  useEffect(() => {
    if (selectedFilters.order) {
      dispatch(
        getStyles({
          orderId: selectedFilters.order,
        })
      );
    }
  }, [selectedFilters.order]);

  function getSelectedFilterValue(
    idOrValue: string | number | null,
    filterKey: keyof IFiltersState['selected']
  ): string | null | undefined {
    if (!idOrValue) {
      return null;
    }

    switch (filterKey) {
      case 'department': {
        return capitalizeFirstLetter(String(idOrValue));
      }

      case 'brand': {
        return brandsFilterOptions.find(item => item.value === idOrValue)?.name;
      }

      case 'style': {
        return stylesFilterOptions.find(item => item.value === idOrValue)?.name;
      }

      case 'order': {
        return ordersFilterOptions.find(item => item.value === idOrValue)?.name;
      }

      case 'line': {
        return assemblyLineFilterOptions.find(item => item.value === idOrValue)
          ?.name;
      }

      default: {
        return String(idOrValue);
      }
    }
  }

  const filterPills = Object.keys(selectedFilters)
    .filter(filter => !NON_REPORT_FILTERS.includes(filter))
    .map(filter => ({
      key: removeUnderscoresAndCapitalize(filter),
      value: getSelectedFilterValue(
        selectedFilters[filter as keyof IFiltersState['selected']],
        filter as keyof IFiltersState['selected']
      ),
      ...(FILTERS_WITHOUT_CLOSE.includes(filter)
        ? {}
        : {
            onClick: () =>
              dispatch(resetFilter(filter as keyof IFiltersState['selected'])),
          }),
    }));
  return (
    <div className="v-d-flex gap-12">
      <FilterContainer itemWidth={200} className="align-flex-end gap-16">
        <DatePickerField
          label="Start Date"
          value={new Date(selectedFilters.start_date)}
          setValue={newDate => {
            dispatch(
              setDateRange({
                start_date: format(newDate ?? new Date(), 'yyyy-M-d'),
                end_date: format(
                  selectedFilters.end_date
                    ? new Date(selectedFilters.end_date)
                    : new Date(),
                  'yyyy-M-d'
                ),
              })
            );
            QappTracker.track('ApplyFilter', {
              filter_name: 'Date Range',
              current_page: 'reports',
              filter_value: `start_date=${format(
                newDate ?? new Date(),
                'yyyy-M-d'
              )},end_date=${format(
                selectedFilters.end_date
                  ? new Date(selectedFilters.end_date)
                  : new Date(),
                'yyyy-M-d'
              )}`,
            });
          }}
        />
        <DatePickerField
          label="End Date"
          value={new Date(selectedFilters.end_date)}
          setValue={newDate => {
            dispatch(
              setDateRange({
                start_date: format(
                  selectedFilters.start_date
                    ? new Date(selectedFilters.start_date)
                    : new Date(),
                  'yyyy-M-d'
                ),
                end_date: format(newDate ?? new Date(), 'yyyy-M-d'),
              })
            );
            QappTracker.track('ApplyFilter', {
              filter_name: 'Date Range',
              current_page: 'reports',
              filter_value: `start_date=${format(
                selectedFilters.start_date
                  ? new Date(selectedFilters.start_date)
                  : new Date(),
                'yyyy-M-d'
              )},end_date=${format(newDate ?? new Date(), 'yyyy-M-d')}`,
            });
          }}
        />
        <Dropdown
          label="Departments"
          onChange={selectedValue => {
            dispatch(setDepartment(selectedValue));
            QappTracker.track('ApplyFilter', {
              filter_name: 'Department',
              current_page: 'reports',
              filter_value: selectedValue,
            });
          }}
          options={departmentFilterOptions}
          value={selectedFilters.department}
          placeholder="Select Department"
        />
        <Dropdown
          label="Buyers"
          showAllOption
          onChange={selectedValue => {
            dispatch(setBrand(selectedValue));
            QappTracker.track('ApplyFilter', {
              filter_name: 'Buyer',
              current_page: 'reports',
              filter_value: selectedValue,
            });
          }}
          options={brandsFilterOptions}
          value={selectedFilters.brand}
          placeholder="Select Buyer"
        />
        <Dropdown
          label="Orders"
          showAllOption
          onChange={selectedValue => {
            dispatch(setOrder(selectedValue));
            QappTracker.track('ApplyFilter', {
              filter_name: 'Order',
              current_page: 'reports',
              filter_value: selectedValue,
            });
          }}
          options={ordersFilterOptions}
          value={selectedFilters.order}
          placeholder="Select Orders"
        />

        <Dropdown
          label="Assembly Line"
          showAllOption
          onChange={selectedValue => {
            dispatch(setLine(selectedValue));
            QappTracker.track('ApplyFilter', {
              filter_name: 'Assembly Line',
              current_page: 'reports',
              filter_value: selectedValue,
            });
          }}
          options={assemblyLineFilterOptions}
          value={selectedFilters.line}
          placeholder="Select Line"
        />
        <Dropdown
          label="Styles"
          showAllOption
          isDisabled={!selectedFilters.order}
          onChange={selectedValue => {
            dispatch(setStyle(selectedValue));
            QappTracker.track('ApplyFilter', {
              filter_name: 'Style',
              current_page: 'reports',
              filter_value: selectedValue,
            });
          }}
          options={stylesFilterOptions}
          value={selectedFilters.style}
          placeholder="Select Line"
        />
        {/* <StylesFilter /> */}
        {/* <DateRangePicker
          endDate={new Date(selectedFilters.end_date)}
          startDate={new Date(selectedFilters.start_date)}
          onChangeDays={(newStartDate, newEndDate) => {
            dispatch(
              setDateRange({
                start_date: format(newStartDate ?? new Date(), 'yyyy-M-d'),
                end_date: format(newEndDate ?? new Date(), 'yyyy-M-d'),
              })
            );
            QappTracker.track('ApplyFilter', {
              filter_name: 'Date Range',
              current_page: 'reports',
              filter_value: `start_date=${format(
                newStartDate ?? new Date(),
                'yyyy-M-d'
              )},end_date=${format(newEndDate ?? new Date(), 'yyyy-M-d')}`,
            });
          }}
        /> */}
      </FilterContainer>

      <FilterPills items={filterPills} />
    </div>
  );
};

export default Filters;
