/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { LineOverallDataType } from './schemas';
import Grid from '../../../../../shared/Grid';
import GridItem from '../../../../../shared/Grid/GridItem';
import Defects from './Defects';
import AreasOfConcern from './AreasOfConcern';
import { LineHeading } from './styles';

interface IMidlinesProps {
  endlineId: number;
  department: string;
  data: LineOverallDataType['midline'] | undefined;
  name: string | undefined;
}

const Midlines = ({ name, endlineId, department, data }: IMidlinesProps) => (
  <>
    {data?.map(midline => (
      <div className="v-d-flex gap-16">
        <LineHeading>{midline.checker_m_name}</LineHeading>
        <Grid
          minHeight="50vh"
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="1fr"
          gap={24}
        >
          <GridItem gridColumn="1/2" gridRow="1/2">
            <Defects
              department={department}
              endlineId={endlineId}
              midlineId={midline.checker_m_id}
            />
          </GridItem>
          <GridItem gridColumn="2/3" gridRow="1/2">
            <AreasOfConcern
              department={department}
              endlineId={endlineId}
              midlineId={midline.checker_m_id}
            />
          </GridItem>
        </Grid>
      </div>
    ))}
  </>
);

export default Midlines;
