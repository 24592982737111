/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  ReactNode,
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BsFilter } from 'react-icons/bs';
import { Button } from '@fashinza/fashinza-design-system';
import useBoolean from '../../hooks/useBoolean';
import ClickOutside from '../ClickOutside';
import { useCustomPopper } from '../../hooks/useCustomPopper';
import { swallowEvent } from '../../utils';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import * as Styles from './styles';

interface IFilterContainerProps {
  children: Array<ReactNode>;
  /** To calculate how many filters to keep and how many to truncate */
  itemWidth: number;
  className?: string;
}

const FilterContainer = ({
  children,
  itemWidth,
  className = '',
}: IFilterContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const outsideClickRef = useRef<HTMLDivElement>(null);
  const [elementsToFit, setElementsToFit] = useState(0);
  const [areMoreFiltersOpen, moreFiltersActions] = useBoolean();
  const [isClickOutsideEnabled, clickOutsideActions] = useBoolean();

  useLayoutEffect(() => {
    function calculateElementsToFit() {
      const container = containerRef.current;
      if (!container) {
        return;
      }
      const containerWidth = container.offsetWidth;
      const containerStyles = window.getComputedStyle(container);
      const gap = parseFloat(containerStyles.gap);
      let totalWidthConsumed = 0;
      let totalElements = 0;
      // let totalElements = -1;

      for (let i = 0; i < children.length; i += 1) {
        if (totalWidthConsumed + itemWidth + gap > containerWidth) {
          break;
        }
        totalWidthConsumed += itemWidth + gap;
        totalElements += 1;
      }

      setElementsToFit(totalElements);
    }

    calculateElementsToFit();
    window.addEventListener('resize', calculateElementsToFit);

    return () => {
      window.removeEventListener('resize', calculateElementsToFit);
    };
  }, [children]);

  useOnClickOutside(outsideClickRef, () => {
    if (isClickOutsideEnabled) {
      moreFiltersActions.off();
      // clickOutsideActions.off();
    }
  });

  const popperAttributes = useCustomPopper({
    strategy: 'fixed',
    placement: 'bottom-end',
  });

  const outsideFilters = children.slice(0, elementsToFit);
  const moreFiltersList = children.slice(elementsToFit, children.length);

  return (
    <div ref={containerRef} className={className}>
      {moreFiltersList.length > 0 ? (
        <>
          {outsideFilters}
          <div
            onMouseEnter={() => {
              clickOutsideActions.off();
            }}
            onMouseLeave={() => {
              clickOutsideActions.on();
            }}
            onClick={swallowEvent}
          >
            <Button
              onClick={moreFiltersActions.on}
              variant="outline"
              colorScheme="neutral"
              leftIcon={<BsFilter size={20} />}
              {...popperAttributes.reference}
            >
              More
            </Button>

            {areMoreFiltersOpen && (
              <div ref={outsideClickRef}>
                <Styles.MoreFiltersContainer
                  {...popperAttributes.fixed}
                  style={{
                    ...popperAttributes.fixed.style,
                  }}
                  className="v-d-flex gap-16"
                >
                  {moreFiltersList}
                </Styles.MoreFiltersContainer>
              </div>
            )}
          </div>
        </>
      ) : (
        children
      )}
    </div>
  );
};

export default memo(FilterContainer);
