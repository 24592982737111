import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import * as Styles from './styles';

interface IInfiniteLooperProps {
  speed: number;
  direction: 'right' | 'left';
  children: ReactNode;
}

const InfiniteLooper = ({
  speed,
  direction,
  children,
}: IInfiniteLooperProps) => {
  const [looperInstances, setLooperInstances] = useState(1);
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const outerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  function resetAnimation() {
    if (innerRef?.current) {
      setShouldAnimate(false);

      setTimeout(() => {
        if (innerRef?.current) {
          setShouldAnimate(true);
        }
      }, 50);
    }
  }

  const setupInstances = useCallback(() => {
    if (!innerRef?.current || !outerRef?.current) return;

    const { width } = innerRef.current.getBoundingClientRect();

    const { width: parentWidth } = outerRef.current.getBoundingClientRect();

    const instanceWidth = width / innerRef.current.children.length;

    if (width < parentWidth + instanceWidth) {
      setLooperInstances(looperInstances + Math.ceil(parentWidth / width));
    }

    resetAnimation();
  }, [looperInstances]);

  useEffect(() => {
    setupInstances();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setupInstances);

    return () => {
      window.removeEventListener('resize', setupInstances);
    };
  }, []);

  return (
    <Styles.Looper ref={outerRef}>
      <Styles.InnerList ref={innerRef}>
        {[...Array(looperInstances)].map((_, index) => (
          <Styles.ListInstance
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            $shouldAnimate={shouldAnimate}
            style={{
              animationDuration: `${speed}s`,
              animationDirection: direction === 'right' ? 'reverse' : 'normal',
            }}
          >
            {children}
          </Styles.ListInstance>
        ))}
      </Styles.InnerList>
    </Styles.Looper>
  );
};

export default InfiniteLooper;
