import { z } from 'zod';

const AuditStatusSchema = z.union([
  z.literal('active'),
  z.literal('completed'),
  z.literal('paused'),
  z.literal('in_progress'),
]);

export const AuditSessionSchema = z.object({
  id: z.number(),
  created_on: z.string(),
  line_name: z.string(),
  department: z.string(),
  status: AuditStatusSchema,
  order_id: z.number(),
  collection_name: z.string(),
  style_name: z.string(),
  position: z.string(),
  auditor_name: z.string(),
});

export type AuditSessionType = z.infer<typeof AuditSessionSchema>;

export const AuditSessionsSchema = z.array(AuditSessionSchema);

export type AuditSessionsType = z.infer<typeof AuditSessionsSchema>;
