import { ReactNode } from 'react';
import * as Styles from './styles';

interface IContainerProps {
  children: ReactNode;
}

const Container = ({ children }: IContainerProps) => (
  <Styles.Wrapper className="v-d-flex">{children}</Styles.Wrapper>
);

export default Container;
