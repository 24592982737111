import { IconPropsType } from './types';

interface ICrossIconProps extends IconPropsType {}

const CrossWithCircleIcon = ({
  size = 24,
  fill = 'var(--red-dirt)',
  ...props
}: ICrossIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM16.1336 13.4813C15.4013 12.7491 14.2141 12.7491 13.4819 13.4813C12.7497 14.2135 12.7497 15.4007 13.4819 16.133L21.3485 23.9995L13.4819 31.8661C12.7497 32.5983 12.7497 33.7855 13.4819 34.5177C14.2141 35.25 15.4013 35.25 16.1336 34.5177L24.0001 26.6512L31.8667 34.5177C32.5989 35.25 33.7861 35.25 34.5183 34.5177C35.2506 33.7855 35.2506 32.5983 34.5183 31.8661L26.6518 23.9995L34.5183 16.133C35.2506 15.4007 35.2506 14.2135 34.5183 13.4813C33.7861 12.7491 32.5989 12.7491 31.8667 13.4813L24.0001 21.3479L16.1336 13.4813Z"
      fill={fill}
    />
  </svg>
);

export default CrossWithCircleIcon;
