import { useMemo, useState } from 'react';
import { ExpandedState, Row, ColumnDef } from '@tanstack/react-table';
import Segment from '../../../../shared/Segment';
import Table from '../../../../shared/Table';
import { useGetLinePerformanceQuery } from '../../../../redux/dashboard/api';
import useFakePagination from '../../../../hooks/useFakePagination';
import { LinePerformanceItemsType } from './schemas';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import Details from './Details';
import { getColumns } from './columns';
import Sidebar from '../../../../shared/Sidebar';
import { capitalizeFirstLetter } from '../../../../utils';
import ExpandedRow from './ExpandedRow';
import useFeatureFlags from '../../../../redux/featureFlags/useFeatureFlags';

function getExpandedRowComponent(
  columns: Array<ColumnDef<LinePerformanceItemsType[number], any>>
) {
  return ({ original }: Row<LinePerformanceItemsType[number]>) => (
    <ExpandedRow lineId={original.line_id} columns={columns} />
  );
}

const LinePerformance = () => {
  const { department, start_date, end_date } = useTypedSelector(
    store => store.filters.selected
  );
  const { data, isLoading, isFetching } = useGetLinePerformanceQuery({
    department,
    start_date,
    end_date,
  });

  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [selectedLineId, setSelectedLineId] = useState<number>(-1);

  function closeDetailsView() {
    setSelectedLineId(-1);
  }

  function openDetailsView(lineId: number) {
    setSelectedLineId(lineId);
  }

  const { page, action } = useFakePagination(data, 10);

  const totalCheckedReferencePoint = data
    ? Math.max(...data.map(item => Number(item.total_checked)))
    : 0;

  const { should_show_efficiency } = useFeatureFlags();

  const columns = useMemo(
    () =>
      getColumns({
        openDetailsView,
        totalCheckedReferencePoint,
        shouldShowEfficiency: should_show_efficiency,
      }),
    [totalCheckedReferencePoint, should_show_efficiency]
  );

  const selectedLineData = data?.find(item => item.line_id === selectedLineId);

  const ExpandedRowComponent = getExpandedRowComponent(columns);

  return (
    <>
      <Segment
        heading="Line performance"
        description="This compares the performance of all your lines"
        descriptionMaxWidth={220}
        shouldChildrenHavePadding={false}
        isLoading={isLoading || isFetching}
      >
        <Table
          data={page.data ?? []}
          columns={columns}
          currentPage={page.current ?? 0}
          handlePagination={c =>
            c === 'next' ? action.next() : action.previous()
          }
          pageLength={5}
          totalCount={data?.length ?? 0}
          cellsWithNoPadding={['hourly']}
          tableOptions={{
            state: {
              expanded,
            },
            onExpandedChange: setExpanded,
          }}
          expandedRow={ExpandedRowComponent}
        />
      </Segment>
      <Sidebar
        isOpen={selectedLineId > -1}
        heading={selectedLineData ? `${selectedLineData.name} Details` : ''}
        subHeadings={
          selectedLineData
            ? [
                capitalizeFirstLetter(department),
                'Endline',
                `Midlines: ${selectedLineData.midline_count}`,
              ]
            : []
        }
        onClose={closeDetailsView}
      >
        <Details
          name={selectedLineData?.name}
          department={department}
          lineId={selectedLineId}
        />
      </Sidebar>
    </>
  );
};

export default LinePerformance;
