import Filters from './Filters'; 
import OrdinaryDashboard from './Ordinary';
import { useTypedSelector } from '../../redux/useTypedSelector';
import AuditDashboard from './Audit';

const Dashboard = () => {
  const department = useTypedSelector(
    store => store.filters.selected.department
  );

  const isAuditDepartment = department.includes('audit');

  return (
    <>
      <Filters />
      {isAuditDepartment ? <AuditDashboard /> : <OrdinaryDashboard />}
    </>
  );
};

export default Dashboard;
