import { DEFAULT_VALUES } from '../../../../constants';
import { useGetMidlinesQuery } from '../../../../redux/idiotBox/apis/lineView';
import * as Styles from './styles';

const Midlines = () => {
  const { data } = useGetMidlinesQuery();

  return (
    <div className="d-flex gap-24 full-flex">
      {data?.map(midline => (
        <Styles.Midline
          key={midline.user_m_name}
          className="v-d-flex full-flex"
        >
          <Styles.Heading>
            {midline.user_m_name ?? DEFAULT_VALUES.NOT_APPLICABLE}
          </Styles.Heading>
          <table className="d-flex full-flex">
            <tbody className="v-d-flex spread full-width">
              <Styles.TableRow className="d-flex ">
                <Styles.Key className="align-center">Total</Styles.Key>
                <Styles.Value
                  className="full-flex flex-justify-end"
                  $color="var(--white)"
                >
                  {midline.total_checked}
                </Styles.Value>
              </Styles.TableRow>
              <Styles.TableRow className="d-flex">
                <Styles.Key className="align-center">Passed</Styles.Key>
                <Styles.Value
                  className="full-flex flex-justify-end"
                  $color="var(--shamrock)"
                >
                  {midline.pass_count}
                </Styles.Value>
              </Styles.TableRow>
              <Styles.TableRow className="d-flex">
                <Styles.Key className="align-center">Altered</Styles.Key>
                <Styles.Value
                  className="full-flex flex-justify-end"
                  $color="var(--white)"
                >
                  {midline.alter_count}
                </Styles.Value>
              </Styles.TableRow>
              <Styles.TableRow className="d-flex">
                <Styles.Key className="align-center">Rejected</Styles.Key>
                <Styles.Value
                  className="full-flex flex-justify-end"
                  $color="var(--red-dirt)"
                >
                  {midline.reject_count}
                </Styles.Value>
              </Styles.TableRow>
              <Styles.TableRow className="d-flex">
                <Styles.Key className="align-center">Total Defects</Styles.Key>
                <Styles.Value
                  className="full-flex flex-justify-end"
                  $color="var(--amber)"
                >
                  {midline.defect_count}
                </Styles.Value>
              </Styles.TableRow>
            </tbody>
          </table>
        </Styles.Midline>
      ))}
    </div>
  );
};

export default Midlines;
