import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { SortingState, createColumnHelper } from '@tanstack/react-table';
import { AuditInspectionsType } from './schemas';
import useFakePagination from '../../../../../hooks/useFakePagination';
import Segment from '../../../../../shared/Segment';
import Table from '../../../../../shared/Table';
import { capitalizeFirstLetter } from '../../../../../utils';
import Attachments from './Attachments';
import { useGetInspectionByAuditSessionIdQuery } from '../../../../../redux/auditSessions/api';
import useAppDispatch from '../../../../../redux/useAppDispatch';
import { DEFAULT_VALUES } from '../../../../../constants';

function getColumns({ handleSort }: { handleSort: (id: string) => void }) {
  const columnHelper = createColumnHelper<
    AuditInspectionsType[number] & { item_number: number }
  >();

  return [
    columnHelper.accessor('item_number', {
      id: 'item_number',
      header: 'Item Number',
      cell: info => info.getValue(),
      size: 5,
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Result',
      cell: info => capitalizeFirstLetter(info.getValue()),
      size: 10,
    }),
    columnHelper.accessor('images', {
      id: 'images',
      header: 'Attachment',
      cell: info => <Attachments files={info.getValue() ?? []} />,
      size: 10,
    }),

    columnHelper.accessor('defect', {
      id: 'defect',
      header: 'Defects',
      cell: info => info.getValue(),
      size: 20,
    }),
    columnHelper.accessor('reject_reason', {
      id: 'reject_reason',
      header: 'Reason for rejection',
      cell: info => info.getValue(),
      size: 15,
    }),
    columnHelper.accessor('defect_section', {
      id: 'defect_section',
      header: 'Sections',
      cell: info => info.getValue(),
      size: 15,
    }),
    columnHelper.accessor('created_on', {
      id: 'created_on',
      header: 'Inspection Time',
      cell: info => format(new Date(info.getValue()), 'pp'),
      size: 25,
    }),
  ];
}

interface IInspectionsProps {
  sessionId: number;
}

const Inspections = ({ sessionId }: IInspectionsProps) => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetInspectionByAuditSessionIdQuery(sessionId);

  const formattedData = data?.map((item, index) => ({
    ...item,
    item_number: index + 1,
  }));

  const { page, action } = useFakePagination(formattedData, 5);

  function handleSort(id: string) {
    // dispatch(sortInspections(sessionId));
  }

  const columns = useMemo(() => getColumns({ handleSort }), []);

  return (
    <Segment
      heading="Inspection details"
      shouldChildrenHavePadding={false}
      isLoading={isLoading}
    >
      <Table
        data={page.data ?? []}
        columns={columns}
        currentPage={page.current ?? 0}
        handlePagination={c =>
          c === 'next' ? action.next() : action.previous()
        }
        pageLength={5}
        totalCount={data?.length ?? 0}
        cellsWithNoPadding={['images']}
      />
    </Segment>
  );
};

export default Inspections;
