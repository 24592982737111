import { useRef } from 'react';
import useDimensions from '../../../../hooks/useDimensions';
import Segment from '../../../../shared/Segment';
import ChartWrapper from '../../../../shared/ChartWrapper';
import LineChart from '../../../../shared/LineChart';
import { useGetOverAllTrendQuery } from '../../../../redux/reports/api';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import useFeatureFlags from '../../../../redux/featureFlags/useFeatureFlags';

const OverAllTrendAnalysis = () => {
  const lineChartWrapperRef = useRef<HTMLDivElement>(null);
  const { department, brand, order, style, start_date, end_date, line } =
    useTypedSelector(store => store.filters.selected);
  const lineOptions = useTypedSelector(store =>
    store.filters.line_options
      .filter(item => item.department === department)
      .map(item => item.id)
      .join(',')
  );
  const { data, isLoading, isFetching } = useGetOverAllTrendQuery({
    department,
    brand,
    order,
    line: line || lineOptions,
    style,
    start_date,
    end_date,
  });
  const { height, width } = useDimensions(lineChartWrapperRef, [isLoading]);

  const { should_show_efficiency } = useFeatureFlags();

  const LineChartComponent = should_show_efficiency ? (
    <LineChart
      data={data ?? []}
      height={height}
      width={width}
      xAxisDataKey="hour"
      xAxisLabel="Hours"
      lineData={[
        {
          dataKey: 'dhu',
          stroke: 'var(--astro-purple)',
        },
        {
          dataKey: 'ftt_count',
          stroke: 'var(--cerise)',
        },
        {
          dataKey: 'ftt_percentage',
          stroke: 'var(--cerise)',
        },
        {
          dataKey: 'reject_count',
          stroke: 'var(--web-orange)',
        },
        {
          dataKey: 'efficiency',
          stroke: 'var(--ocean-green)',
        },
      ]}
    />
  ) : (
    <LineChart
      data={
        data?.map(item => ({
          reject_count: item.reject_count,
          id: item.id,
          dhu: item.dhu,
          hour: item.hour,
          ftt_percentage: item.ftt_percentage,
          ftt_count: item.ftt_count,
        })) ?? []
      }
      height={height}
      width={width}
      xAxisDataKey="hour"
      xAxisLabel="Hours"
      lineData={[
        {
          dataKey: 'dhu',
          stroke: 'var(--astro-purple)',
        },
        {
          dataKey: 'ftt_count',
          stroke: 'var(--cerise)',
        },
        {
          dataKey: 'ftt_percentage',
          stroke: 'var(--cerise)',
        },
        {
          dataKey: 'reject_count',
          stroke: 'var(--web-orange)',
        },
      ]}
    />
  );

  return (
    <Segment
      heading="Overall Trend Analysis"
      description="Graphical representation of hourly trend of DHU, FTT, Reject & Efficiency"
      descriptionMaxWidth={300}
      isLoading={isLoading || isFetching}
    >
      <ChartWrapper wrapperRef={lineChartWrapperRef}>
        {LineChartComponent}
      </ChartWrapper>
    </Segment>
  );
};

export default OverAllTrendAnalysis;
