import Filters from './Filters';
import OrdinaryReports from './Ordinary';
import { useTypedSelector } from '../../redux/useTypedSelector';
import AuditReports from './Audit';

const Reports = () => {
  const department = useTypedSelector(
    store => store.filters.selected.department
  );

  const isAuditDepartment = department.includes('audit');

  return (
    <>
      <Filters />
      {isAuditDepartment ? <AuditReports /> : <OrdinaryReports />}
    </>
  );
};

export default Reports;
