export const DEVICE_ID = '3';

export const DEFAULT_IMAGE_PLACEHOLDER =
  'https://fashinza-media.s3.ap-south-1.amazonaws.com/default-img-rfq.svg';

export const PORT_GORE_BG_IMAGE =
  'https://tna-app-media-development.s3.amazonaws.com/public/0f02e0913b324b9bd42d57a8badf924bad0d17b3.png';

export const TT_WHITE_CLASS = 'tooltip-white tooltip-wrapper';

export const defaultValue = 'N/A';

export const DEFAULT_VALUES = Object.freeze({
  NOT_APPLICABLE: 'N/A',
  DOUBLE_DASH: '--',
});

export const FILE_SIZE_NAMES = ['bytes', 'kbs', 'mbs', 'gbs'] as const;
