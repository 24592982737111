import styled from 'styled-components';

export const Midline = styled.div`
  border-radius: 12px;
  border: 1px solid var(--fiord);
  background: var(--port-gore);
  padding: 24px;
  height: 100%;
  overflow: hidden;
  max-width: 30vw;
`;

export const TableRow = styled.tr`
  width: 100%;
`;

export const Heading = styled.h4`
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: var(--white);
  padding: 4px 0;
`;

export const Key = styled.th`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--manatee);
`;

interface IValueProps {
  $color:
    | 'var(--white)'
    | 'var(--shamrock)'
    | 'var(--red-dirt)'
    | 'var(--amber)';
}

export const Value = styled.td<IValueProps>`
  font-size: 36px;
  font-weight: 700;
  line-height: 54px;
  color: ${props => props.$color};
`;
