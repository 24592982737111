import Grid from '../../../shared/Grid';
import GridItem from '../../../shared/Grid/GridItem';
import StylesData from './StylesData';
import AreaOfConcern from './AreaOfConcern';
import Defects from './Defects';
import { OverAllDataType } from './OverallData/schemas';

interface IPageThreeAndFourProps {
  page: number;
  styleData: OverAllDataType['sku_m'] | undefined;
}

const PageThreeAndFour = ({ page, styleData }: IPageThreeAndFourProps) => (
  <Grid
    minHeight="auto"
    gridTemplateColumns="1fr 5fr"
    gridTemplateRows="1fr"
    gap={24}
  >
    <GridItem gridColumn="1/2" gridRow="1/2">
      <StylesData data={styleData} />
    </GridItem>
    <GridItem gridColumn="2/3" gridRow="1/2">
      {page === 3 && <Defects />}
      {page === 4 && <AreaOfConcern />}
    </GridItem>
  </Grid>
);

export default PageThreeAndFour;
