import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithZodValidation, overrideFetchBaseQuery } from '../utils';
import { getFormattedFilters } from './utils';
import { AuditReportsOverallDataSchema } from '../../components/Reports/Audit/OverallData/schemas';
import type { SimpleApiResponse } from '../../@types/api';
import type { IFiltersState } from '../filters';
import type { AuditReportsOverallDataType } from '../../components/Reports/Audit/OverallData/schemas';
import {
  DefectsSchema,
  DefectsType,
} from '../../components/Reports/Audit/Defects/schemas';
import {
  AreasOfConcernSchema,
  AreasOfConcernType,
} from '../../components/Reports/Audit/AreasOfConcern/schemas';
import {
  DefectsSummaryDataSchema,
  DefectsSummaryDataType,
} from '../../components/Reports/Audit/DefectsSummary/schemas';
import {
  AuditSummaryByCheckerDataSchema,
  AuditSummaryByCheckerDataType,
} from '../../components/Reports/Audit/AuditSummaryByChecker/schemas';
import {
  AuditSummaryByStyleDataSchema,
  AuditSummaryByStyleDataType,
} from '../../components/Reports/Audit/AuditSummaryByStyle/schemas';

export const auditReportsApi = createApi({
  reducerPath: 'auditReports',
  baseQuery: baseQueryWithZodValidation(
    overrideFetchBaseQuery({
      urlSuffix: '/reports',
      base: 'audit',
    })
  ),
  endpoints: builder => ({
    getAuditReportsOverAllData: builder.query<
      AuditReportsOverallDataType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `overall/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: AuditReportsOverallDataSchema,
        // mock: true,
      },
      transformResponse: (
        response: SimpleApiResponse<AuditReportsOverallDataType>
      ) => ({
        ...response.data,
        dhu: Number(response.data.dhu.toFixed(2)),
      }),
    }),
    getAuditReportsDefects: builder.query<
      DefectsType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `top-defect/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: DefectsSchema,
        // mock: true,
      },
      transformResponse: (response: SimpleApiResponse<DefectsType>) =>
        response.data,
    }),
    getAuditReportsAreaOfConcern: builder.query<
      AreasOfConcernType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `top-defect-section/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: AreasOfConcernSchema,
        // mock: true,
      },
      transformResponse: (response: SimpleApiResponse<AreasOfConcernType>) =>
        response.data,
    }),
    getDefectSummary: builder.query<
      DefectsSummaryDataType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `defect-analytics/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: DefectsSummaryDataSchema,
        // mock: true,
      },
      transformResponse: (
        response: SimpleApiResponse<DefectsSummaryDataType>
      ) =>
        response.data.map(item => ({
          ...item,
          defect_percentage: Number(item.defect_percentage.toFixed(2)),
        })),
    }),
    getAuditSummaryByChecker: builder.query<
      AuditSummaryByCheckerDataType,
      Partial<IFiltersState['selected']>
    >({
      query: filters =>
        `audit-summary-by-checker/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: AuditSummaryByCheckerDataSchema,
        // mock: true,
      },
      transformResponse: (
        response: SimpleApiResponse<AuditSummaryByCheckerDataType>
      ) =>
        response.data.map(item => ({
          ...item,
          inspection_fail_percentage: Number(
            item.inspection_fail_percentage.toFixed(2)
          ),
          inspection_pass_percentage: Number(
            item.inspection_pass_percentage.toFixed(2)
          ),
        })),
    }),
    getAuditSummaryByStyle: builder.query<
      AuditSummaryByStyleDataType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `audit-summary/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: AuditSummaryByStyleDataSchema,
        // mock: true,
      },
      transformResponse: (
        response: SimpleApiResponse<AuditSummaryByStyleDataType>
      ) =>
        response.data.map(item => ({
          ...item,
          inspection_fail_percentage: Number(
            item.inspection_fail_percentage.toFixed(2)
          ),
          inspection_pass_percentage: Number(
            item.inspection_pass_percentage.toFixed(2)
          ),
        })),
    }),
  }),
});

export const {
  useGetAuditReportsOverAllDataQuery,
  useGetAuditReportsAreaOfConcernQuery,
  useGetAuditReportsDefectsQuery,
  useGetDefectSummaryQuery,
  useGetAuditSummaryByCheckerQuery,
  useGetAuditSummaryByStyleQuery,
} = auditReportsApi;

export const { resetApiState: resetAuditDashboardState } = auditReportsApi.util;
