import { ReactNode } from 'react';
import { DayPicker } from 'react-day-picker';
import { addDays } from 'date-fns';
import { swallowEvent } from '../../utils';
import { useCurrentDate } from '../../hooks/useCurrentDate';
import * as Styles from './styles';

export interface IDatePickerProps {
  value: Date | null;
  setValue(newValue: Date | null): void;
  footer?: ReactNode;
}

const DatePicker = ({ value, setValue, footer }: IDatePickerProps) => {
  const currentDate = useCurrentDate();

  return (
    <Styles.Wrapper onClick={swallowEvent}>
      <DayPicker
        onDayClick={(day, modifiers, event) => {
          if (modifiers.disabled) {
            return;
          }
          setValue(day);
          event.stopPropagation();
        }}
        selected={value === null ? [] : [new Date(value)]}
        footer={footer}
        disabled={{ after: addDays(new Date(), 1) }}
      />
    </Styles.Wrapper>
  );
};

export default DatePicker;
