import { Key, CSSProperties } from 'react';
import {
  AreaChart as ReChartsAreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  YAxis,
  Label,
  ResponsiveContainer,
} from 'recharts';
import EmptyChartView from '../EmptyChartView';
import renderTooltip from './renderTooltip';

interface IAreaChartProps<T extends Record<string, unknown>> {
  data: Array<T>;
  areaData: Array<{
    dataKey: keyof T & Key;
    stroke: CSSProperties['stroke'];
    strokeWidth?: CSSProperties['strokeWidth'];
    gradientColor?: CSSProperties['stopColor'];
    gradientOpacity?: CSSProperties['stopOpacity'];
  }>;
  xAxisDataKey: keyof T & Key;
  height?: number;
  width?: number;
  className?: string;
  showTick?: boolean;
  xAxisLabel?: string;
  yAxisLabel?: string;
  axisStroke?: string;
}

const AreaChart = <T extends Record<string, unknown>>({
  data,
  xAxisDataKey,
  areaData,
  height,
  width,
  className,
  xAxisLabel,
  yAxisLabel,
  axisStroke,
  showTick = true,
}: IAreaChartProps<T>) => {
  if (data.length === 0) {
    return <EmptyChartView />;
  }

  return (
    <ResponsiveContainer width={width} height="100%">
      <ReChartsAreaChart
        className={className}
        // width={width}
        // height={height}
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 24,
        }}
      >
        <defs>
          {areaData.map(eachData => (
            <linearGradient
              key={eachData.dataKey}
              id={`color${eachData.dataKey}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="5%"
                stopColor={eachData.gradientColor ?? eachData.stroke}
                stopOpacity={eachData.gradientOpacity ?? 0.25}
              />
              <stop
                offset="95%"
                stopColor={eachData.gradientColor ?? eachData.stroke}
                stopOpacity={0}
              />
            </linearGradient>
          ))}
        </defs>
        <CartesianGrid vertical={false} />
        <XAxis
          axisLine={false}
          tickLine={false}
          dataKey={xAxisDataKey}
          tickMargin={8}
          scale="point"
          interval="preserveStartEnd"
          tick={showTick}
          stroke={axisStroke}
        >
          <Label
            value={xAxisLabel}
            width={40}
            position="insideBottom"
            offset={-24}
            stroke={axisStroke}
            style={{
              fontSize: '12px',
              fontWeight: 500,
            }}
          />
        </XAxis>

        <YAxis
          axisLine={false}
          tickLine={false}
          tick={showTick}
          dx={-10}
          stroke={axisStroke}
        >
          <Label
            value={yAxisLabel}
            position="insideLeft"
            angle={-90}
            stroke={axisStroke}
            style={{
              fontSize: '12px',
              fontWeight: 500,
            }}
          />
        </YAxis>

        <Tooltip content={renderTooltip} />

        {areaData.map(eachData => (
          <Area
            key={eachData.dataKey}
            type="monotone"
            dataKey={eachData.dataKey}
            stroke={eachData.stroke}
            fill={`url(#color${eachData.dataKey})`}
            strokeWidth={eachData.strokeWidth ?? 1}
          />
        ))}
      </ReChartsAreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChart;
