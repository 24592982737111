const Icon = () => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.22 35.784c-1.25 0-2-1.537-1.262-2.642 2.524-3.762 8.26-10.234 17.695-10.234h21.995l2.368 9.883.268 1.119c.25 1.065-.487 2.103-1.512 2.09l-39.564-.23.012.014Z"
      fill="#fff"
    />
    <path
      d="M20.029 12.014h18.27c.675 0 1.274.471 1.474 1.159l2.874 9.735h-3.711l-1.7-5.906c-.2-.7-.8-1.173-1.474-1.173H20.029c-.975 0-1.775-.85-1.775-1.914 0-1.052.787-1.915 1.774-1.915v.014Z"
      fill="#fff"
    />
    <path
      d="M3.208 32.79h41.808m-2.368-9.882-2.875-9.735c-.2-.688-.8-1.16-1.474-1.16h-18.27V12c-.988 0-1.775.863-1.775 1.915 0 1.065.8 1.914 1.774 1.914h15.734c.675 0 1.274.472 1.474 1.173l1.7 5.906h3.712Zm0 0H30.675m11.972 0 2.368 9.883m-14.34-9.883v-.647c0-.971-.725-1.753-1.625-1.753h-4.399c-.9 0-1.624.782-1.624 1.753v.647m7.648 0h-7.648m0 0h-2.375c-9.435 0-15.17 6.472-17.695 10.233-.737 1.106.013 2.643 1.262 2.643l-.012-.013L43.772 36c1.025.013 1.762-1.025 1.512-2.09l-.268-1.12"
      stroke="#2C3556"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.224 29.514c.849 0 1.537-.742 1.537-1.658 0-.916-.688-1.659-1.537-1.659-.85 0-1.537.743-1.537 1.659s.688 1.658 1.537 1.658Z"
      fill="#fff"
      stroke="#AF2660"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
