import { createColumnHelper } from '@tanstack/react-table';
import type { DefectsSummaryDataType } from './schemas';
import { DEFAULT_VALUES } from '../../../../constants';
import OrderItem from '../../../../shared/OrderItem';
import {
  capitalizeFirstLetter,
  removeUnderscoresAndCapitalize,
} from '../../../../utils';

export function getColumns() {
  const columnHelper = createColumnHelper<DefectsSummaryDataType[number]>();

  const columns = [
    columnHelper.accessor('style', {
      id: 'style',
      header: 'Style',
      cell: info => (
        <OrderItem
          image={info.getValue().style_image}
          name={info.getValue().style_name}
          subText={info.getValue().style_color}
        />
      ),
      maxSize: 15,
    }),
    columnHelper.accessor('order', {
      id: 'order',
      header: 'Order',
      cell: info => (
        <OrderItem
          name={info.getValue().collection_name}
          subText={info.getValue().buyer_name ?? DEFAULT_VALUES.DOUBLE_DASH}
          isBold={false}
        />
      ),
      maxSize: 15,
    }),
    columnHelper.accessor('auditor_name', {
      id: 'auditor_name',
      header: 'Inspector Name',
      cell: info => info.getValue(),
      maxSize: 10,
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Status',
      cell: info => `${removeUnderscoresAndCapitalize(info.getValue())}`,
      maxSize: 10,
    }),
    columnHelper.accessor('offered_quantity', {
      id: 'offered_quantity',
      header: 'Offered Quantity',
      cell: info => info.getValue(),
      maxSize: 10,
    }),
    columnHelper.accessor('audit_quantity', {
      id: 'audit_quantity',
      header: 'Audit Quantity',
      cell: info => info.getValue(),
      maxSize: 10,
    }),
    columnHelper.accessor('defect_count', {
      id: 'defect_count',
      header: 'Total Defects',
      cell: info => info.getValue(),
      maxSize: 10,
    }),
    columnHelper.accessor('defect_percentage', {
      id: 'defect_percentage',
      header: 'Defect %',
      cell: info => `${info.getValue()} %`,
      maxSize: 5,
    }),
  ];

  return columns;
}
