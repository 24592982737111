import styled from 'styled-components';

interface ICollectionNameProps {
  $isBold: boolean;
}

export const CollectionName = styled.p<ICollectionNameProps>`
  font-weight: ${props => (props.$isBold ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  color: var(--all-ports);
  &:hover {
    text-decoration: underline;
  }
`;
