import React, { CSSProperties, ReactNode, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { format } from 'date-fns';
import ClickOutside from './ClickOutside';
import ICONOGRAPHY from './iconography';
import useBoolean from '../hooks/useBoolean';
import { useCustomPopper } from '../hooks/useCustomPopper';
import DatePicker, { IDatePickerProps } from './DatePicker';
import { FOCUS_STYLES } from '../constants/commonStyles';

const Wrapper = styled.div<{ hasError: boolean; isOpen: boolean }>`
  height: 40px;
  padding: 8px 16px;
  border: 1px solid var(--athens-gray);
  border-radius: 8px;
  background: var(--white);

  ${props =>
    props.hasError &&
    css`
      border-color: var(--red-dirt);
    `}

  ${props => props.isOpen && FOCUS_STYLES}

  &:focus {
    ${FOCUS_STYLES}
  }

  .DayPicker-wrapper {
    border-radius: 4px;
    border: 1px solid var(--athens-gray);
  }
`;

const Value = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--rhino);
  margin-left: 8px;
  white-space: nowrap;
`;

const Placeholder = styled(Value)`
  color: var(--manatee);
`;

export const Label = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--rhino);
`;

const POPPER_OPTIONS = Object.freeze({
  strategy: 'fixed',
  placement: 'bottom-start',
});

const { calendar: CalendarIcon, 'chevron-down': ChevronDownIcon } = ICONOGRAPHY;

interface IDatePickerFieldProps extends IDatePickerProps {
  className?: string;
  hasError?: boolean;
  dateFieldFormat?: string;
  fixedPopperStyles?: CSSProperties;
  footer?: ReactNode;
  label?: string;
}

const DatePickerField = ({
  value,
  setValue,
  className,
  hasError = false,
  fixedPopperStyles,
  footer,
  label,
  dateFieldFormat = 'd MMM yyyy',
}: IDatePickerFieldProps) => {
  const [isOpen, openActions] = useBoolean();
  const popperAttributes = useCustomPopper(POPPER_OPTIONS);

  return (
    <div className="v-d-flex gap-4">
      <ClickOutside onClose={openActions.off}>
        {label && <Label>{label}</Label>}
        <Wrapper
          className={`align-center cursor-pointer ${className}`}
          onClick={() => {
            openActions.toggle();
          }}
          tabIndex={0}
          hasError={hasError}
          isOpen={isOpen}
          {...popperAttributes.reference}
        >
          <CalendarIcon size={18} />
          {value === null ? (
            <Placeholder>Select</Placeholder>
          ) : (
            <Value>{format(value, dateFieldFormat)}</Value>
          )}
          {isOpen && (
            <div
              {...popperAttributes.fixed}
              style={{
                ...popperAttributes.fixed.style,
                ...fixedPopperStyles,
              }}
            >
              <DatePicker
                value={value}
                setValue={newValue => {
                  setValue(newValue);
                  openActions.off();
                }}
                footer={footer}
              />
            </div>
          )}
          <ChevronDownIcon size={18} />
        </Wrapper>
      </ClickOutside>
    </div>
  );
};

export default DatePickerField;
