import { ModeOfOperationType } from '../../../@types';

interface IBaseFieldData {
  label: string;
  value: string;
  isSortable?: boolean;
  showFilter?: boolean;
  width: number;
  isStatic?: boolean;
  style?: Record<string, string>;
  isCellFullWidth?: boolean;
  prefix?: string;
  suffix?: string;
}

interface IColumnStyle {
  minWidth: string;
  maxWidth: string;
  flexGrow: number;
  flexShrink: number;
  width: number;
  marginRight: number;
  paddingLeft: number;
  paddingRight: number;
}

const DEFAULT_COLUMN_STYLE: IColumnStyle = Object.freeze({
  minWidth: '50px',
  maxWidth: '200px',
  flexGrow: 1,
  flexShrink: 1,
  width: 100,
  marginRight: 1,
  paddingLeft: 12,
  paddingRight: 12,
});

export const SESSION_FIELDS: Record<
  IBaseFieldData['value'],
  IBaseFieldData & IColumnStyle
> = {
  id: {
    label: 'Session ID',
    value: 'id',
    ...DEFAULT_COLUMN_STYLE,
    width: 300,
    isStatic: true,
  },
  line_name: {
    label: 'Line',
    value: 'line_name',
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 150,
    // isStatic: true,
  },
  department: {
    label: 'Department',
    value: 'department',
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 150,
  },
  checker_name: {
    label: 'Started By',
    value: 'checker_name',
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 300,
  },
  duration: {
    label: 'Total Time',
    value: 'duration',
    ...DEFAULT_COLUMN_STYLE,
    width: 250,
  },
  order_id: {
    label: 'Order',
    value: 'order_id',
    ...DEFAULT_COLUMN_STYLE,
    width: 250,
  },
  style_name: {
    label: 'Style',
    value: 'style_name',
    ...DEFAULT_COLUMN_STYLE,
    width: 250,
  },
};

export const AUDIT_SESSION_FIELDS: Record<
  IBaseFieldData['value'],
  IBaseFieldData & IColumnStyle
> = {
  id: {
    label: 'Session ID',
    value: 'id',
    ...DEFAULT_COLUMN_STYLE,
    width: 300,
    isStatic: true,
  },
  line_name: {
    label: 'Line',
    value: 'line_name',
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 150,
    // isStatic: true,
  },
  department: {
    label: 'Department',
    value: 'department',
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 150,
  },
  auditor_name: {
    label: 'Started By',
    value: 'auditor_name',
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 300,
  },
  status: {
    label: 'Status',
    value: 'status',
    ...DEFAULT_COLUMN_STYLE,
    width: 100,
  },
  order_id: {
    label: 'Order',
    value: 'order_id',
    ...DEFAULT_COLUMN_STYLE,
    width: 250,
  },
  style_name: {
    label: 'Style',
    value: 'style_name',
    ...DEFAULT_COLUMN_STYLE,
    width: 250,
  },
};

export const SESSION_FIELDS_MAPPING = {
  ordinary: SESSION_FIELDS,
  audit: AUDIT_SESSION_FIELDS,
};

export function getMultiGridSettings(mode: ModeOfOperationType) {
  const sessionFields = SESSION_FIELDS_MAPPING[mode];

  return Object.freeze({
    fixedColumnCount: Object.values(sessionFields).filter(
      eachField => eachField.isStatic
    ).length,
  });
}
