import { ReactNode, useMemo, CSSProperties } from 'react';
import { TABLE_ATTRIBUTES } from './constants';
import * as Styles from './styles';

interface IGridCellProps {
  gridProps: {
    columnIndex: number;
    rowIndex: number;
    style?: CSSProperties;
  };
  totalRowsCount: number;
  fixedColumnCount: number;
  totalColumnsCount: number;
  cellNode: ReactNode;
  className?: string;
  paddingLayer?: 'self' | 'first_child' | 'horizontal-child' | 'none';
  showScrollingBorder?: boolean;
}

const GridCell = ({
  gridProps: { columnIndex, rowIndex, style },
  totalRowsCount,
  totalColumnsCount,
  fixedColumnCount,
  cellNode,
  className = '',
  paddingLayer = 'self',
  showScrollingBorder = true,
}: IGridCellProps) => {
  const rowIndexType = useMemo(() => {
    if (rowIndex === 0) {
      return 'first';
    }
    return rowIndex === totalRowsCount ? 'last' : null;
  }, [rowIndex, totalRowsCount]);

  const columnIndexType = useMemo(() => {
    if (columnIndex === 0) {
      return 'first';
    }
    return columnIndex === totalColumnsCount - 1 ? 'last' : null;
  }, [columnIndex, totalColumnsCount]);

  const { isFirstColumn, isLastStaticColumn } = useMemo(
    () => ({
      isFirstColumn: columnIndex === 0,
      isLastStaticColumn: columnIndex === fixedColumnCount - 1,
    }),
    [columnIndex]
  );

  const cellAttributes = {
    [TABLE_ATTRIBUTES.firstColumnDataAttribute]: isFirstColumn,
    [TABLE_ATTRIBUTES.lastStaticColumnDataAttribute]: isLastStaticColumn,
  };

  if (rowIndex === 0) {
    return (
      <Styles.HeaderCell
        className={`center full-height ${className}`}
        columnIndexType={columnIndexType}
        style={style}
        data-view-type="header-cell"
        {...cellAttributes}
      >
        {cellNode}
        {isLastStaticColumn && showScrollingBorder && (
          <Styles.AbsoluteBorder
            data-view-type={TABLE_ATTRIBUTES.borderViewType}
          />
        )}
      </Styles.HeaderCell>
    );
  }

  return (
    <Styles.BodyCell
      className={`full-height ${className}`}
      rowIndexType={rowIndexType}
      columnIndexType={columnIndexType}
      paddingLayer={paddingLayer}
      style={style}
      data-view-type="body-cell"
      {...cellAttributes}
    >
      {cellNode}
      {isLastStaticColumn && showScrollingBorder && (
        <Styles.AbsoluteBorder
          data-view-type={TABLE_ATTRIBUTES.borderViewType}
        />
      )}
    </Styles.BodyCell>
  );
};

export default GridCell;
