/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ObjectType } from '../../@types/defined';

export interface IUserState extends ObjectType {}

const initialState: IUserState = {} as const;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserState(state, action) {
      state = action.payload;
    },
  },
});

export const { setUserState } = userSlice.actions;
export default userSlice.reducer;
