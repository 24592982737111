const NoInventoryIcon = () => {
  return (
    <svg
      width="247"
      height="209"
      viewBox="0 0 247 209"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5894_56755)">
        <path
          d="M8.12 105.43C23.01 93.71 46.43 103.67 62.96 94.4C72.98 88.78 78.15 77.28 80.95 66.13C83.75 54.98 84.91 43.27 89.94 32.93C94.97 22.6 105.36 13.65 116.78 14.91C125.32 15.85 132.19 22.08 139.96 25.74C160.94 35.63 186.56 26.22 208.56 33.55C224.74 38.95 237.08 53.24 242.43 69.44C247.78 85.64 246.72 103.46 241.94 119.84C233.13 150.03 211.51 176.26 183.55 190.67C153.39 206.22 116.89 207.75 84.58 197.39C65.99 191.43 48.73 181.68 33.58 169.41C21.44 159.57 5.31 146.55 0.990003 130.82C-1.48 121.84 0.500002 111.42 8.11 105.43H8.12Z"
          fill="var(--wash-me)"
        />
        <path
          d="M27.36 86.63C32.14 87.65 37.35 86.5 41.19 83.48C46.31 79.47 48.62 72.21 46.75 65.98C43.16 53.97 25.52 50.13 16.94 59.26C8.33998 68.41 16.19 84.26 27.36 86.63Z"
          fill="var(--wash-me)"
        />
        <path
          d="M64.42 77.31C68.52 78.03 72.89 75.36 74.32 71.45C76.98 64.15 68.62 54.96 61.13 58.73C53.43 62.61 56.31 75.88 64.42 77.31Z"
          fill="var(--wash-me)"
        />
        <path
          d="M36.23 193.63H196.23"
          stroke="var(--manatee)"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M27.23 193.63H19.23"
          stroke="var(--manatee)"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M212.23 193.63H204.23"
          stroke="var(--manatee)"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M231.01 16.2C233.219 16.2 235.01 14.4092 235.01 12.2C235.01 9.99087 233.219 8.20001 231.01 8.20001C228.801 8.20001 227.01 9.99087 227.01 12.2C227.01 14.4092 228.801 16.2 231.01 16.2Z"
          fill="#E8EAEE"
        />
        <path
          d="M234.82 35.36L227.19 30.96V39.77L234.82 35.36Z"
          stroke="#E8EAEE"
          strokeWidth="0.66"
          strokeMiterlimit="10"
        />
        <path d="M234.98 27.58V19.58H226.98V27.58H234.98Z" fill="#E8EAEE" />
        <path
          d="M215.08 198.15C216.185 198.15 217.08 197.255 217.08 196.15C217.08 195.045 216.185 194.15 215.08 194.15C213.975 194.15 213.08 195.045 213.08 196.15C213.08 197.255 213.975 198.15 215.08 198.15Z"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M227.07 156.4V164.4"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M223.07 160.4H231.07"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.96 184.25C25.17 184.25 26.96 182.46 26.96 180.25C26.96 178.04 25.17 176.25 22.96 176.25C20.75 176.25 18.96 178.04 18.96 180.25C18.96 182.46 20.75 184.25 22.96 184.25Z"
          fill="#E8EAEE"
        />
        <path
          d="M19.15 203.41L26.78 199V207.81L19.15 203.41Z"
          stroke="#E8EAEE"
          strokeWidth="0.66"
          strokeMiterlimit="10"
        />
        <path d="M26.99 195.62V187.62H18.99V195.62H26.99Z" fill="#E8EAEE" />
        <path
          d="M39.08 8.5C41.2892 8.5 43.08 6.70914 43.08 4.5C43.08 2.29086 41.2892 0.5 39.08 0.5C36.8709 0.5 35.08 2.29086 35.08 4.5C35.08 6.70914 36.8709 8.5 39.08 8.5Z"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.97 22.17C24.0746 22.17 24.97 21.2746 24.97 20.17C24.97 19.0654 24.0746 18.17 22.97 18.17C21.8655 18.17 20.97 19.0654 20.97 20.17C20.97 21.2746 21.8655 22.17 22.97 22.17Z"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.39 65.48L56.53 70.6"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.45 68.3L57.82 61.26"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.61 72.91L47.57 73.55"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M161.53 53.87C157.97 55.46 157.11 60.24 160.01 62.84C166.46 68.63 174.87 64.65 185.63 65.84C196.85 67.09 207.21 70.89 213.74 83.56C217.17 90.2 216.73 101.53 214.69 108.72C213.85 111.67 212.43 114.42 210.43 116.71C206.76 120.91 200.42 123.09 195.46 120.62C190.5 118.15 188.65 110.31 192.83 106.63C197.26 102.72 204.95 106.14 206.9 111.76C208.86 117.37 206.54 123.66 203.09 128.47C194.1 141.03 178.1 146.78 162.79 147.31C147.48 147.84 132.42 143.9 117.43 140.66C102.44 137.42 86.87 134.87 71.9 138.14C64.33 139.79 56.84 143.09 51.7 148.95C46.56 154.81 44.22 163.6 47.3 170.79C50.22 177.62 58.07 181.62 65.27 180.71"
          stroke="#F1A43A"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M166.15 18.79C158.51 24.37 156.84 35.08 162.42 42.72C163.93 44.78 165.81 46.41 167.9 47.57C167.43 49.45 166.14 51.61 165.01 52.62C168.26 54.3 172.67 51.48 174.97 49.69C178.89 49.99 182.93 48.94 186.35 46.44C193.99 40.86 195.66 30.15 190.08 22.51C184.5 14.87 173.79 13.21 166.15 18.79Z"
          fill="white"
          stroke="#939AA8"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M178.27 22.32L178.14 36.14L174.31 36.22L173.58 22.42L178.27 22.32ZM176.36 42.55C175.7 42.56 175.12 42.33 174.63 41.85C174.15 41.37 173.9 40.78 173.88 40.08C173.87 39.39 174.09 38.8 174.55 38.31C175.02 37.81 175.58 37.55 176.25 37.53C176.89 37.52 177.46 37.75 177.95 38.23C178.45 38.71 178.71 39.29 178.72 39.97C178.72 40.44 178.62 40.86 178.4 41.25C178.18 41.64 177.9 41.95 177.54 42.18C177.19 42.41 176.79 42.54 176.36 42.55Z"
          fill="#F1A43A"
        />
        <path
          d="M118.89 119.71C118.89 119.71 122.77 116.68 126.9 119.71"
          stroke="#939AA8"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M174.58 74.62V80.77H67.19V74.62C67.19 73.95 66.65 73.4 65.97 73.4H60.89C60.22 73.4 59.67 73.94 59.67 74.62V192.41C59.67 193.08 60.21 193.63 60.89 193.63H65.97C66.64 193.63 67.19 193.09 67.19 192.41V185.66H174.59V192.41C174.59 193.08 175.13 193.63 175.81 193.63H180.89C181.56 193.63 182.11 193.09 182.11 192.41V74.62C182.11 73.95 181.57 73.4 180.89 73.4H175.81C175.14 73.4 174.59 73.94 174.59 74.62H174.58ZM174.58 178.15H67.19V153.2H174.59V178.15H174.58ZM174.58 145.69H67.19V120.74H174.59V145.69H174.58ZM174.58 113.23H67.19V88.28H174.59V113.23H174.58Z"
          fill="white"
          stroke="var(--manatee)"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M79.45 165.33H104.67C105.41 165.33 106 165.93 106 166.66V178.07H78.11V166.66C78.11 165.92 78.71 165.33 79.44 165.33H79.45Z"
          fill="white"
          stroke="var(--manatee)"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5894_56755">
          <rect width="246.03" height="208.38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoInventoryIcon;
