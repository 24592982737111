import { Button } from '@fashinza/fashinza-design-system';
import { HiOutlineComputerDesktop } from 'react-icons/hi2';
import PurpleText from '../PurpleText';
import { FactoryTvLineListItemsType } from '../schemas';
import { openLineTvView } from '../utils';
import * as Styles from './styles';

interface ILineViewProps {
  lineName: string;
  lines: FactoryTvLineListItemsType;
}

const LineView = ({ lineName, lines }: ILineViewProps) => (
  <Styles.Wrapper className="v-d-flex gap-8">
    <PurpleText>Line View</PurpleText>
    <div>
      {lines.map(line => (
        <Styles.LineViewItem key={line.id} className="align-center">
          <h5 className="full-flex">
            {lineName} {line.name}
          </h5>
          <Button
            onClick={() => openLineTvView(line)}
            leftIcon={<HiOutlineComputerDesktop size={16} />}
            colorScheme="secondary"
            variant="tertiary"
            sizeVariant="small"
          >
            Open Line View
          </Button>
        </Styles.LineViewItem>
      ))}
    </div>
  </Styles.Wrapper>
);
export default LineView;
