import { IconPropsType } from './types';

interface IArrowOutcomingIconProps extends IconPropsType {}

const ArrowOutcomingIcon = ({
  stroke = 'var(--rhino)',
  size = 24,
  strokeWidth = 1.25,
  ...props
}: IArrowOutcomingIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 18L18 6M18 6H7.2M18 6V16.8"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowOutcomingIcon;
