import { useRef } from 'react';
import ChartWrapper from '../ChartWrapper';
import VerticalBarChart from '../VerticalBarChart';
import useDimensions from '../../hooks/useDimensions';
import { ObjectType } from '../../@types/defined';
import * as Styles from './styles';
import EmptyChartView from '../EmptyChartView';

interface ITableWithVerticalBarChartProps<T extends Record<string, unknown>> {
  data: Array<T>;
  chartHeader: string;
  headers: Array<{
    accessor: string & keyof T;
    text: string;
  }>;
  barFill: 'var(--astro-purple)' | 'var(--cerise)' | 'var(--web-orange)';
  barDataKey: string;
  axisDataKey: string;
  yAxisWidth: number;
  barSize: number;
  referenceLine?: number;
}

const TableWithVerticalBarChart = <T extends Record<string, unknown>>({
  data,
  chartHeader,
  headers,
  barFill,
  barDataKey,
  axisDataKey,
  barSize,
  referenceLine,
  yAxisWidth = 75,
}: ITableWithVerticalBarChartProps<T>) => {
  const chartWrapperRef = useRef<HTMLDivElement>(null);
  const { height, width } = useDimensions(chartWrapperRef);

  if (data.length === 0) {
    return <EmptyChartView />;
  }

  return (
    <Styles.Wrapper className="full-height d-flex">
      <ChartWrapper wrapperRef={chartWrapperRef}>
        {chartHeader && (
          <Styles.ChartsHeader>{chartHeader}</Styles.ChartsHeader>
        )}
        <VerticalBarChart
          barSize={barSize}
          barFill={barFill}
          data={data}
          barDataKey={barDataKey}
          axisDataKey={axisDataKey}
        />
      </ChartWrapper>
      <Styles.Table>
        <thead>
          {headers.map(header => (
            <Styles.Th key={header.accessor}>{header.text}</Styles.Th>
          ))}
        </thead>
        <tbody>
          {data.map((item: ObjectType, index) => (
            <tr key={index}>
              {headers.map(header => (
                <Styles.Td>{item?.[header.accessor]}</Styles.Td>
              ))}
            </tr>
          ))}
        </tbody>
      </Styles.Table>
    </Styles.Wrapper>
  );
};

export default TableWithVerticalBarChart;
