import { ENV } from '../env';
import { getAuthToken } from './auth';
import { DEVICE_ID } from '../constants';
import HttpClient from './httpClient';

interface ICallApiArgs<T> {
  method: RequestInit['method'];
  endPoint: string;
  data?: T;
  tokenToUse?: string;
}

class BaseApiService {
  private backendUrl: string;
  private token: string | null;
  private httpClient: HttpClient;

  constructor(backendUrl: string, token?: string | null) {
    this.backendUrl = backendUrl;
    this.token = token ?? null;
    this.httpClient = new HttpClient();
  }

  // eslint-disable-next-line class-methods-use-this
  getBaseApiHeaders(token?: string | null): Record<string, string> {
    const headers: Record<string, string> = {
      device: DEVICE_ID,
      'abs-referer': window.location.href,
      'page-referer': document.referrer,
    };
    const authToken = token || getAuthToken();
    if (authToken) {
      headers.Authorization = `Token ${authToken}`;
    }
    return headers;
  }

  private constructOptions<T>({
    method,
    data,
  }: ICallApiArgs<T>): Record<string, unknown> {
    const token = this.token || getAuthToken();
    const options: Record<string, unknown> = {
      method,
      headers: {
        ...this.getBaseApiHeaders(token),
        'Content-Type': 'application/json',
      },
    };
    if (method !== 'get') {
      options.body = JSON.stringify(data);
    }
    return options;
  }

  async callApi<T>({ method, endPoint, data }: ICallApiArgs<T>) {
    const options = this.constructOptions({ method, endPoint, data });
    const json = await this.httpClient.makeJsonRequest(
      this.backendUrl + endPoint,
      options
    );
    return json;
  }

  async callFinanceApi<T>({ method, endPoint, data }: ICallApiArgs<T>) {
    const options = this.constructOptions({ method, endPoint, data });
    const json = await this.httpClient.makeJsonRequest(
      this.backendUrl + endPoint,
      options
    );
    return json;
  }

  async callRawApi<T>({ method, endPoint, data }: ICallApiArgs<T>) {
    const options = this.constructOptions({ method, endPoint, data });
    const json = await this.httpClient.makeRawJsonRequest(
      this.backendUrl + endPoint,
      options
    );
    return json;
  }

  async uploadFile(
    method: RequestInit['method'],
    endPoint: string,
    file: File
  ) {
    const formData = new FormData();
    formData.append('file', file);
    const options = {
      method,
      body: formData,
      headers: this.getBaseApiHeaders(),
    };
    const json = await this.httpClient.makeJsonRequest(
      this.backendUrl + endPoint,
      options
    );
    return json;
  }

  async downloadFileApi(
    method: RequestInit['method'],
    endPoint: string,
    contentType: string
  ) {
    const options = {
      method,
      headers: {
        ...this.getBaseApiHeaders(),
        'Content-Type': contentType,
      },
    };
    const blob = await this.httpClient.makeBlobRequest(
      this.backendUrl + endPoint,
      options
    );
    return blob;
  }

  setBackendUrl(backendUrl: string) {
    this.backendUrl = backendUrl;
  }
}

export class CoreApiService extends BaseApiService {
  constructor() {
    super(`${ENV.PMB_SERVER_URL}`);
  }
}

class QappApiService extends BaseApiService {
  constructor() {
    super(`${ENV.QAPP_SERVER_URL}/rest-q/qapp`);
  }
}

const coreApiService = new CoreApiService();
const qappApiService = new QappApiService();

const SERVICE_MAPPING = Object.freeze({
  core: coreApiService,
  qapp: qappApiService,
});

interface IApiConfig<T> {
  method: RequestInit['method'];
  endPoint: string;
  data?: T;
  file?: File;
}

async function callApi<T>(
  service: keyof typeof SERVICE_MAPPING,
  config: IApiConfig<T>,
  variant: 'raw' | 'uploadFile' | 'vanilla' = 'vanilla'
) {
  switch (variant) {
    case 'raw': {
      return SERVICE_MAPPING[service].callRawApi<T>(config);
    }

    case 'uploadFile': {
      if (!config.file) {
        return SERVICE_MAPPING[service].callApi<T>(config);
      }

      return SERVICE_MAPPING[service].uploadFile(
        config.method,
        config.endPoint,
        config.file
      );
    }

    case 'vanilla':
    default: {
      return SERVICE_MAPPING[service].callApi<T>(config);
    }
  }
}

export async function callAdminApi<T>(
  method: RequestInit['method'],
  endPoint: string,
  data?: T
) {
  return callApi<T>('core', { method, endPoint, data });
}

export async function callQappApi<T>(
  method: RequestInit['method'],
  endPoint: string,
  data?: T
) {
  return callApi<T>('qapp', { method, endPoint, data });
}

// export async function callAdminFileUploadApi(
//   method: RequestInit['method'],
//   endPoint: string,
//   file: File
// ) {
//   return coreApiService.uploadFile(method, endPoint, file);
// }

// export async function callAdminFileDownloadApi(
//   method: RequestInit['method'],
//   endPoint: string,
//   contentType: string
// ) {
//   return coreApiService.downloadFileApi(method, endPoint, contentType);
// }

// export function setCoreApiBackendUrl(backendUrl: string) {
//   coreApiService.setBackendUrl(backendUrl);
// }
