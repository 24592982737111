/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Link } from 'react-router-dom';
import ProductImage from '../ProductImage';
import TooltipWrapper from '../TooltipWrapper';
import { DEFAULT_IMAGE_PLACEHOLDER } from '../../constants';
import { formatNumberToLocale, pluralize } from '../../utils';
import * as Styles from './styles';

interface IOrderItemProps {
  image?: string;
  name?: string;
  numProducts?: number;
  totalQuantity?: number;
  subText?: string;
  isBold?: boolean;
  className?: string;
  onClick?: VoidFunction;
}

const OrderItem = ({
  name,
  image,
  numProducts,
  totalQuantity,
  subText,
  className = '',
  isBold = true,
  onClick,
  ...others
}: IOrderItemProps) => (
  <div
    {...others}
    className={`d-flex full-height align-center ${className}`}
    onClick={onClick}
  >
    {image && (
      <ProductImage
        imageUrl={image}
        width={32}
        height={48}
        borderRadius={0}
        customMargin="0 8px 0 0"
      />
    )}
    <div className="v-d-flex">
      <TooltipWrapper
        content={name ?? 'N/A'}
        valueToShow={
          <Styles.CollectionName $isBold={isBold} className="truncated">
            {name ?? 'N/A'}
          </Styles.CollectionName>
        }
        textType="main-text"
      />

      <TooltipWrapper
        valueToShow={
          <>
            {subText}
            {numProducts && formatNumberToLocale(numProducts)}
            {numProducts && `${pluralize(' style', numProducts)} , &nbsp;`}
            {totalQuantity && formatNumberToLocale(totalQuantity)}
            {totalQuantity && pluralize(' pc', totalQuantity || 0)}
          </>
        }
        textType="sub-text"
      />
    </div>
  </div>
);

export default OrderItem;
