// import { useReactToPrint } from 'react-to-print';
import Grid from '../../../../../shared/Grid';
import GridItem from '../../../../../shared/Grid/GridItem';
import HourlyDHU from './HourlyDHU';
import HourlyLineEfficiency from './HourlyLineEfficiency';
import { useGetLineOverallDataQuery } from '../../../../../redux/dashboard/api';
import OverallDataCards from './OverallDataCards';
import Midlines from './Midlines';
import Endline from './Endline';
import LinesList from './LinesList';
import * as Styles from './styles';
import useFeatureFlags from '../../../../../redux/featureFlags/useFeatureFlags';

interface IDetailsProps {
  name: string | undefined;
  lineId: number;
  department: string;
}

const Details = ({ name, department, lineId }: IDetailsProps) => {
  // const printRef = useRef<HTMLDivElement>(null);

  // const onPrint = useReactToPrint({
  //   content: () => printRef.current,
  //   documentTitle: `Line ${lineId} Report`,
  //   pageStyle: 'padding: 24px',
  // });

  const { data: overallData, isFetching: isFetchingOverallData } =
    useGetLineOverallDataQuery(lineId);

  const { should_show_efficiency } = useFeatureFlags();

  return (
    <Styles.Wrapper className="v-d-flex gap-24">
      <OverallDataCards data={overallData} isLoading={isFetchingOverallData} />

      <LinesList isLoading={isFetchingOverallData} data={overallData} />

      <Grid
        minHeight="40vh"
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem
          gridColumn={should_show_efficiency ? '1/2' : '1/3'}
          gridRow="1/2"
        >
          <HourlyDHU lineId={lineId} />
        </GridItem>
        {should_show_efficiency && (
          <GridItem gridColumn="2/3" gridRow="1/2">
            <HourlyLineEfficiency lineId={lineId} />
          </GridItem>
        )}
      </Grid>

      <Endline name={name} department={department} endlineId={lineId} />

      <Midlines
        name={name}
        department={department}
        endlineId={lineId}
        data={overallData?.midline}
      />
    </Styles.Wrapper>
  );
};

export default Details;
