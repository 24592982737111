import { useMemo } from 'react';

export function useCurrentDate(): Date {
  const currentDate = useMemo(() => {
    const date = new Date();
    const disabledYear = date.getFullYear();
    const disabledDate = date.getDate();
    const disabledMonth = date.getMonth();
    return new Date(disabledYear, disabledMonth, disabledDate);
  }, []);

  return currentDate;
}
