import { styled } from 'styled-components';

export const TableWrapper = styled.div`
  height: 100%;
`;

export const LabelContainer = styled.aside``;

export const Label = styled.h5`
  font-weight: 600;
  font-size: 12px;
  color: var(--rhino);
  background: var(--wash-me);
  padding: 12px 24px;
  border-bottom: 1px solid var(--athens-gray);

  &:first-child {
    border-top: 1px solid var(--athens-gray);
  }

  &:last-child {
    border-radius: 0 0 0 8px;
    border-bottom: none;
  }
`;

export const ContentContainer = styled.main`
  flex-grow: 1;
`;

export const Column = styled.div``;

export const Cell = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: var(--rhino);
  padding: 12px 24px;
  border-bottom: 1px solid var(--athens-gray);

  &:first-child {
    border-top: 1px solid var(--athens-gray);
  }

  &:last-child {
    border-bottom: none;
  }
`;
