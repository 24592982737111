import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import {
  DesignSystemProvider,
  basicTheme,
} from '@fashinza/fashinza-design-system';
import { history, store } from './redux/store';
import WorkStation from './components/WorkStation';
import IdiotBox from './components/IdiotBox';
import './App.css';

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div className="App">
        <DesignSystemProvider themeTokens={basicTheme}>
          <ThemeProvider theme={basicTheme}>
            <Switch>
              {/* For Actual TV / Android Box */}
              <Route path="/tv" component={IdiotBox} />
              {/* For TV Dashboards on web */}
              <Route path="/factory-qapp/tv" component={IdiotBox} />
              {/* For web dashboards */}
              <Route path="/factory-qapp" component={WorkStation} />
              <Redirect to="/factory-qapp" />
            </Switch>
          </ThemeProvider>
        </DesignSystemProvider>
      </div>
    </ConnectedRouter>
  </Provider>
);

export default App;
