import { styled } from 'styled-components';
import { useTypedSelector } from '../../redux/useTypedSelector';

const Text = styled.p`
  border-radius: 8px;
  box-shadow: 0px 12px 16px -4px rgba(12, 16, 33, 0.08),
    0px 4px 6px -2px rgba(12, 16, 33, 0.03);
  padding: 8px 12px;
  font-weight: 600;
`;

const EmptyChartView = () => {
  const viewMode = useTypedSelector(store => store.ui.viewMode);

  return (
    <div className="full-dimension center">
      <Text
        style={{
          background: viewMode === 'tv' ? 'var(--port-gore)' : 'var(--white)',
          color: viewMode === 'tv' ? 'var(--white)' : 'var(--rhino)',
          fontSize: viewMode === 'tv' ? '22px' : '12px',
        }}
      >
        No Data Found
      </Text>
    </div>
  );
};

export default EmptyChartView;
