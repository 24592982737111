import { styled } from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    padding: 8px 16px;
    background: var(--wash-me);
    border-bottom: 1px solid var(--athens-gray);
    border-top: 1px solid var(--athens-gray);
  }

  td {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    padding: 12px 16px;
  }

  td,
  th {
    &:nth-child(1) {
      color: var(--rhino);
    }

    &:nth-child(2) {
      color: var(--deep-sea);
    }

    &:nth-child(3) {
      color: var(--chilean-fire);
    }

    &:nth-child(4) {
      color: var(--red-dirt);
    }
  }
`;

export const TotalInfoWrapper = styled.div`
  p {
    padding: 8px 24px;
    font-size: 14px;
    font-weight: 400;
    color: var(--rhino);
  }
`;
