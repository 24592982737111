import { css, styled } from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  /* 
  @media print {
    .progress-bar {
      display: block !important;
    }

   
  } */
`;

export const LabelContainer = styled.h5`
  color: var(--rhino);
  font-weight: 600;
  font-size: 12px;
`;

interface IProgressContainerProps {
  $height: number;
  $hasBackground: boolean;
  $borderRadius: [number, number, number, number];
}

export const ProgressContainer = styled.div<IProgressContainerProps>`
  position: relative;
  border-radius: ${props =>
    props.$borderRadius.map(i => `${i}${i > 0 ? 'px' : ''}`).join(' ')};
  flex: 1;
  height: ${props => props.$height}px;

  ${props =>
    props.$hasBackground &&
    css`
      background: var(--athens-gray);
    `}
`;

interface IProgressProps {
  $width: number;
  $background: string;
  $borderRadius: [number, number, number, number];
}

export const Progress = styled.div<IProgressProps>`
  position: absolute;
  left: 0;
  height: 100%;
  border-radius: ${props =>
    props.$borderRadius.map(i => `${i}${i > 0 ? 'px' : ''}`).join(' ')};
  width: ${props => props.$width}px;
  background: ${props => props.$background};
  transition: width 1s ease-in-out;
`;
