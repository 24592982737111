import { createColumnHelper } from '@tanstack/react-table';
import type { StyleWiseSummaryDataType } from './schemas';
import { DEFAULT_VALUES } from '../../../../constants';
import OrderItem from '../../../../shared/OrderItem';
import { capitalizeFirstLetter } from '../../../../utils';

export function getColumns({
  shouldShowEfficiency,
}: {
  shouldShowEfficiency: boolean;
}) {
  const columnHelper = createColumnHelper<StyleWiseSummaryDataType[number]>();

  const columns = [
    columnHelper.accessor('style', {
      id: 'style',
      header: 'Style',
      cell: info => (
        <OrderItem
          image={info.getValue().style_image}
          name={info.getValue().style_name}
          subText={info.getValue().style_color}
        />
      ),
      maxSize: 20,
    }),
    columnHelper.accessor('order', {
      id: 'order',
      header: 'Order',
      cell: info => (
        <OrderItem
          name={info.getValue().collection_name}
          subText={info.getValue().buyer_name ?? DEFAULT_VALUES.DOUBLE_DASH}
          isBold={false}
        />
      ),
      maxSize: 20,
    }),
    columnHelper.accessor('department', {
      id: 'department',
      header: 'Audit Type',
      cell: info => `${capitalizeFirstLetter(info.getValue())}`,
      maxSize: 10,
    }),
    columnHelper.accessor('audit_count', {
      id: 'audit_count',
      header: 'Total Audits',
      cell: info => info.getValue(),
      maxSize: 10,
    }),
    columnHelper.accessor('inspection_count', {
      id: 'inspection_count',
      header: 'Total Insp.',
      cell: info => info.getValue(),
      maxSize: 10,
    }),

    columnHelper.accessor('inspection_pass_count', {
      id: 'inspection_pass_count',
      header: 'Pass',
      cell: info => info.getValue(),
      maxSize: 10,
      meta: {
        headerStyles: {
          color: 'var(--green-haze)',
          background: 'var(--scandal)',
        },
        cellStyles: {
          color: 'var(--green-haze)',
        },
      },
    }),
    columnHelper.accessor('defect_count', {
      id: 'defect_count',
      header: 'Total Defects',
      cell: info => info.getValue(),
      maxSize: 10,
      meta: {
        headerStyles: {
          color: 'var(--chilean-fire)',
          background: 'var(--early-dawn)',
        },
        cellStyles: {
          color: 'var(--chilean-fire)',
        },
      },
    }),
    columnHelper.accessor('critical_defect_count', {
      id: 'critical_defect_count',
      header: 'Critical',
      cell: info => info.getValue(),
      maxSize: 5,
    }),
    columnHelper.accessor('major_defect_count', {
      id: 'major_defect_count',
      header: 'Major',
      cell: info => info.getValue(),
      maxSize: 5,
    }),
    columnHelper.accessor('minor_defect_count', {
      id: 'minor_defect_count',
      header: 'Minor',
      cell: info => info.getValue(),
      maxSize: 5,
    }),
  ];

  // if (shouldShowEfficiency) {
  //   return [
  //     ...columns,
  //     columnHelper.accessor('efficiency', {
  //       id: 'efficiency',
  //       header: 'Efficiency',
  //       cell: info => `${info.getValue()} %`,
  //       maxSize: 10,
  //     }),
  //   ];
  // }

  return columns;
}
