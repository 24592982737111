import { useRef } from 'react';
import useDimensions from '../../../../hooks/useDimensions';
import AreaChart from '../../../../shared/AreaChart';
import Segment from '../../../../shared/Segment';
import ChartWrapper from '../../../../shared/ChartWrapper';
import { useGetHourlyDhuQuery } from '../../../../redux/dashboard/api';
import { useTypedSelector } from '../../../../redux/useTypedSelector';

const HourlyDHU = () => {
  const areaChartWrapperRef = useRef<HTMLDivElement>(null);
  const viewMode = useTypedSelector(store => store.ui.viewMode);
  const { department, start_date, end_date } = useTypedSelector(
    store => store.filters.selected
  );
  const { data, isLoading, isFetching } = useGetHourlyDhuQuery({
    department,
    start_date,
    end_date,
  });
  const { height, width } = useDimensions(areaChartWrapperRef, [isLoading]);

  return (
    <Segment
      heading="Hourly DHU"
      description="Defects per hundred unit(DHU) for each line"
      descriptionMaxWidth={180}
      isLoading={isLoading || isFetching}
    >
      <ChartWrapper wrapperRef={areaChartWrapperRef}>
        <AreaChart
          data={data ?? []}
          height={height}
          width={width}
          xAxisDataKey="hour"
          xAxisLabel="Hours"
          yAxisLabel="DHU"
          axisStroke={viewMode === 'tv' ? 'var(--white)' : 'var(--fiord)'}
          areaData={[
            {
              dataKey: 'dhu',
              stroke: viewMode === 'tv' ? 'var(--white)' : 'var(--seance)',
              strokeWidth: 2,
              gradientOpacity: 0.25,
            },
          ]}
        />
      </ChartWrapper>
    </Segment>
  );
};

export default HourlyDHU;
