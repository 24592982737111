import { z } from 'zod';
import { MidLineItemsSchema } from '../schemas';

export const LineOverallDataSchema = z.object({
  line_id: z.number(),
  name: z.string(),
  total_checked: z.number(),
  efficiency: z.number(),
  pass_count: z.number(),
  alter_count: z.number(),
  reject_count: z.number(),
  dhu: z.number(),
  ftt: z.number(),
  ftt_percentage: z.number(),
  midline: MidLineItemsSchema,
});

export type LineOverallDataType = z.infer<typeof LineOverallDataSchema>;
