/* eslint-disable react/no-unstable-nested-components */
import { CSSProperties } from 'react';
import { Spinner } from '@fashinza/fashinza-design-system';
import { usePaginatedTable } from '../../../hooks/usePaginatedTable';
import VirtualizedGrid from '../../../shared/VirtualizedTable/VirtualizedGrid';
import TableCell from './TableCell';
import { SESSION_FIELDS_MAPPING, getMultiGridSettings } from './constants';
import { SessionType } from '../Ordinary/schemas';
import EmptyView from '../../../shared/Table/EmptyView';
import * as Styles from './styles';
import { AuditSessionType } from '../Audit/schemas';
import { ModeOfOperationType } from '../../../@types';

interface ITableProps {
  mode: ModeOfOperationType;
  ids: Array<number>;
  data: Record<string, SessionType | AuditSessionType>;
  isFetching: boolean;
  isLoading: boolean;
  height: CSSProperties['height'];
  onClickViewDetails: (sessionId: number) => void;
}

const SessionsTable = ({
  mode,
  ids,
  data,
  height,
  onClickViewDetails,
  isFetching,
  isLoading,
}: ITableProps) => {
  function fetchNext() {
    // if (isLoading) {
    //   return;
    // }
    // dispatch(fetchTableData('append'));
  }

  const lastItemRef = usePaginatedTable(fetchNext);

  const listFields = Object.keys(SESSION_FIELDS_MAPPING[mode]);

  return (
    <Styles.TableWrapper className="pos-r " style={{ height }}>
      <VirtualizedGrid
        className="quality-table"
        key={ids[0] ?? ids.length}
        getGridCellNode={gridProps => (
          <TableCell
            mode={mode}
            onClickViewDetails={onClickViewDetails}
            data={data[ids[gridProps.rowIndex - 1]]}
            listIds={ids}
            gridProps={gridProps}
            lastItemRef={lastItemRef}
          />
        )}
        columnCount={listFields.length}
        recomputeDependency={[]}
        listLength={ids.length}
        getColumnWidth={index =>
          SESSION_FIELDS_MAPPING[mode][listFields[index]]?.width
        }
        heightDependencies={[]}
        fixedColumnCount={getMultiGridSettings(mode).fixedColumnCount}
        commonGridClassName="remove-scrollbar"
      />
      {!isLoading && ids.length === 0 && <EmptyView />}
      {(isLoading || isFetching) && (
        <Styles.LoaderWrapper className="pos-a center">
          <Spinner height={42} width={42} stroke="var(--wild-watermelon)" />
        </Styles.LoaderWrapper>
      )}
    </Styles.TableWrapper>
  );
};

export default SessionsTable;
