import { CSSProperties, ReactNode } from 'react';

interface IGridProps {
  children: ReactNode;
  gridTemplateColumns?: CSSProperties['gridTemplateColumns'];
  gridTemplateRows?: CSSProperties['gridTemplateRows'];
  gap?: CSSProperties['gap'];
  minHeight?: CSSProperties['minHeight'];
  minWidth?: CSSProperties['minWidth'];
  className?: string;
}

const Grid = ({
  children,
  gridTemplateColumns,
  gridTemplateRows,
  gap,
  minHeight,
  minWidth,
  className,
}: IGridProps) => (
  <div
    className={className}
    style={{
      display: 'grid',
      gridTemplateColumns,
      gridTemplateRows,
      gap,
      minHeight,
      minWidth,
    }}
  >
    {children}
  </div>
);

export default Grid;
