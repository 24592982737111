import { z } from 'zod';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getBrandsListApi,
  getDashboardFiltersApi,
  getOrdersListApi,
  getStylesListApi,
} from './api';
import apiResponseHandler from '../../services/apiResponseHandler';
import { ApiResponseEnum } from '../../@types/api';
import {
  ApiDrivenFiltersType,
  FilterListSchema,
  OrdersListSchema,
  StylesSchema,
  StylesType,
} from './schemas';

export const getQappFilters = createAsyncThunk<ApiDrivenFiltersType, void>(
  'filter',
  async () => {
    const promise = getDashboardFiltersApi();
    const response = await apiResponseHandler<ApiDrivenFiltersType>(promise);

    if (response.type === ApiResponseEnum.Failure) {
      throw new Error(response.error);
    }

    return response.data;
  }
);

export const getOrders = createAsyncThunk<ApiDrivenFiltersType['orders'], void>(
  'orders',
  async () => {
    const promise = getOrdersListApi();
    const response = await apiResponseHandler<ApiDrivenFiltersType['orders']>(
      promise,
      OrdersListSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      throw new Error(response.error);
    }

    return response.data;
  }
);

export const getStyles = createAsyncThunk<
  StylesType,
  { orderId: number | string }
>('styles', async ({ orderId }) => {
  const promise = getStylesListApi(orderId);
  const response = await apiResponseHandler<StylesType>(promise, StylesSchema);

  if (response.type === ApiResponseEnum.Failure) {
    throw new Error(response.error);
  }

  return response.data;
});

export const getBrands = createAsyncThunk<ApiDrivenFiltersType['brands'], void>(
  'brands',
  async () => {
    const searchParams = new URLSearchParams({
      pagination: 'false',
    }).toString();

    const promise = getBrandsListApi(searchParams);
    const response = await apiResponseHandler<ApiDrivenFiltersType['brands']>(
      promise,
      FilterListSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      throw new Error(response.error);
    }

    return response.data;
  }
);
