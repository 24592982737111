import Cookies from 'js-cookie';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import MasterView from './MasterView';
import LineView from './LineView';
import { setViewMode } from '../../redux/ui';
import { useTypedSelector } from '../../redux/useTypedSelector';
import { useLazyGetFactoryTvInfoQuery } from '../../redux/idiotBox/api';
import useDebounce from '../../hooks/useDebounce';
import { openInSameTab } from '../../utils';
import FallBack from './FallBack';
import { getTokenCookieKey } from '../../utils/cookies';
import * as Styles from './styles';

const IdiotBox = () => {
  const { path } = useRouteMatch();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const viewMode = useTypedSelector(store => store.ui.viewMode);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const urlRef = useRef<string>();

  const [trigger, result] = useLazyGetFactoryTvInfoQuery();

  useEffect(() => {
    const params = new URLSearchParams(search);

    const department = params.get('department');
    const lineId = params.get('line_id');
    const view = params.get('view');

    switch (view) {
      case 'line': {
        dispatch(
          push(`${path}/line-view?department=${department}&line_id=${lineId}`)
        );
        return;
      }
      case 'master': {
        dispatch(push(`${path}/master-view?department=${department}`));
        return;
      }

      default: {
        // do some error handling
      }
    }

    const tokenCookieKey = getTokenCookieKey();

    const token = params.get('Token') || Cookies.get(tokenCookieKey ?? '');

    if (token) {
      Cookies.set(tokenCookieKey ?? 'xxx-dev', token);
      trigger();
    }
  }, [search]);

  useEffect(() => {
    if (!result?.isSuccess) {
      return;
    }

    switch (result.data.worker_department) {
      case 'line_finishing_tv': {
        dispatch(push(`${path}/line-view?department=finishing`));
        break;
      }

      case 'line_stitching_tv': {
        dispatch(push(`${path}/line-view?department=stitching`));
        break;
      }

      case 'master_finishing_tv': {
        dispatch(push(`${path}/master-view?department=finishing`));
        break;
      }

      case 'master_stitching_tv': {
        dispatch(push(`${path}/master-view?department=stitching`));
        break;
      }

      default: {
        // do some error handling
      }
    }
  }, [result]);

  function refreshPage() {
    openInSameTab(`${path}${urlRef.current}`);
  }

  const debouncedResize = useDebounce(refreshPage, 300, []);

  useLayoutEffect(() => {
    urlRef.current = search;
  }, []);

  useEffect(() => {
    dispatch(setViewMode('tv'));

    window.addEventListener('resize', debouncedResize.implementation);

    return () => {
      dispatch(setViewMode('web'));
      window.removeEventListener('resize', debouncedResize.implementation);
    };
  }, []);

  return (
    <Styles.Wrapper
      ref={wrapperRef}
      $viewMode={viewMode}
      className="full-view-dimensions v-d-flex"
    >
      <Switch>
        <Route path={`${path}/master-view`} component={MasterView} />
        <Route path={`${path}/line-view`} component={LineView} />
        <Route
          path={`${path}/404`}
          render={props => (
            <FallBack
              isLoading={result?.isFetching}
              {...props}
              result={result}
            />
          )}
        />
        <Redirect to={`${path}/404`} />
      </Switch>
    </Styles.Wrapper>
  );
};

export default IdiotBox;
