import styled, { css } from 'styled-components';
import { IUserInterfaceState } from '../../redux/ui';

interface IWrapperProps {
  $borderColor: 'var(--athens-gray)' | 'var(--fiord)';
  $hasTopBorder?: boolean;
  $hasBottomBorder?: boolean;
  $hasLeftBorder?: boolean;
  $hasRightBorder?: boolean;
  $hasBackground?: boolean;
  $hasBorderRadius?: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  padding: ${props =>
    `${props.theme.spacing[4]}px ${props.theme.spacing[6]}px`};

  ${props =>
    props.$hasBackground &&
    css`
      background: var(--white);
    `}

  ${props =>
    props.$hasBorderRadius &&
    css`
      border-radius: 8px;
    `}

  ${props =>
    props.$hasTopBorder &&
    css`
      border-top: 1px solid ${props.$borderColor};
    `}

  ${props =>
    props.$hasBottomBorder &&
    css`
      border-bottom: 1px solid ${props.$borderColor};
    `}

  ${props =>
    props.$hasLeftBorder &&
    css`
      border-left: 1px solid ${props.$borderColor};
    `}

  ${props =>
    props.$hasRightBorder &&
    css`
      border-right: 1px solid ${props.$borderColor};
    `}
`;

export const Heading = styled.h5`
  color: var(--manatee-or-rhino);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
`;

interface IValueProps {
  $color: string;
  $viewMode: IUserInterfaceState['viewMode'];
}

export const Value = styled.p<IValueProps>`
  color: ${props => props.$color};
  font-size: ${props => (props.$viewMode === 'tv' ? '36px' : '24px')};
  font-weight: ${props => (props.$viewMode === 'tv' ? 700 : 600)};
  line-height: 46px;
  white-space: nowrap;
`;
