import { ImageType } from './schemas';
import { DEFAULT_VALUES } from '../../../../../constants';
import ProductImage from '../../../../../shared/ProductImage';

interface IAttachmentsProps {
  files: Array<ImageType>;
}

const Attachments = ({ files }: IAttachmentsProps) => {
  if (!Array.isArray(files)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{DEFAULT_VALUES.DOUBLE_DASH}</>;
  }

  return (
    <>
      {files.map(file => (
        <ProductImage
          imageUrl={file.link}
          borderRadius={0}
          width={28}
          height={40}
          customMargin="2px"
        />
      ))}
    </>
  );
};

export default Attachments;
