import { z } from 'zod';

export const AuditSummaryByStyleDataSchema = z.array(
  z.object({
    id: z.number(),
    department: z.string(),
    audit_count: z.number(),
    inspection_count: z.number(),
    inspection_pass_count: z.number(),
    inspection_pass_percentage: z.number(),
    inspection_fail_count: z.number(),
    inspection_fail_percentage: z.number(),
    style: z.object({
      id: z.number(),
      style_name: z.string(),
      style_color: z.string(),
      style_image: z.string(),
    }),
    order: z.object({
      order_id: z.number(),
      collection_name: z.string(),
      buyer_name: z.string(),
    }),
  })
);

export type AuditSummaryByStyleDataType = z.infer<
  typeof AuditSummaryByStyleDataSchema
>;
