/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useMemo, useRef } from 'react';
import { Button } from '@fashinza/fashinza-design-system';
import { HiOutlineDocumentArrowDown } from 'react-icons/hi2';
import Segment from '../../../../shared/Segment';
import Table from '../../../../shared/Table';
import {
  useGetFactoryAnalyticsQuery,
  useLazyGetFactoryAnalyticsSpreadSheetQuery,
} from '../../../../redux/reports/api';
import useDimensions from '../../../../hooks/useDimensions';
import useFakePagination from '../../../../hooks/useFakePagination';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import { openInNewTab } from '../../../../utils';
import useFeatureFlags from '../../../../redux/featureFlags/useFeatureFlags';
import { getColumns } from './columns';

const FactoryPerformance = () => {
  const tableContentRef = useRef<null>(null);
  const { department, brand, order, style, start_date, end_date, line } =
    useTypedSelector(store => store.filters.selected);
  const lineOptions = useTypedSelector(store =>
    store.filters.line_options
      .filter(item => item.department === department)
      .map(item => item.id)
      .join(',')
  );

  const filters = {
    department,
    brand,
    order,
    line: line || lineOptions,
    style,
    start_date,
    end_date,
  };

  const { data, isLoading, isFetching } = useGetFactoryAnalyticsQuery(filters);
  const tableContentDimensions = useDimensions(tableContentRef, [isLoading]);

  const [spreadSheetDownloadTrigger, spreadSheetResult] =
    useLazyGetFactoryAnalyticsSpreadSheetQuery();

  useEffect(() => {
    if (spreadSheetResult.isFetching) {
      return;
    }

    if (spreadSheetResult.isSuccess) {
      openInNewTab(spreadSheetResult.data.link);
    }
  }, [spreadSheetResult]);

  const { page, action } = useFakePagination(data, 5);

  const { should_show_efficiency } = useFeatureFlags();

  const columns = useMemo(
    () =>
      getColumns({
        shouldShowEfficiency: should_show_efficiency,
      }),
    [should_show_efficiency]
  );

  return (
    <Segment
      heading="Factory Performance"
      description="Tabular representation of hourly trend of DHU, FTT, Reject & Efficiency"
      descriptionMaxWidth={180}
      shouldChildrenHavePadding={false}
      isLoading={isLoading || isFetching}
      childrenWrapperRef={tableContentRef}
      headerContent={
        <Button
          onClick={() => spreadSheetDownloadTrigger(filters)}
          colorScheme="secondary"
          variant="tertiary"
          isLoading={spreadSheetResult.isFetching}
          leftIcon={<HiOutlineDocumentArrowDown size={16} />}
        >
          Download
        </Button>
      }
    >
      <Table
        data={page.data ?? []}
        columns={columns}
        currentPage={page.current ?? 0}
        handlePagination={c =>
          c === 'next' ? action.next() : action.previous()
        }
        pageLength={5}
        totalCount={data?.length ?? 0}
        width={tableContentDimensions.width}
      />
    </Segment>
  );
};

export default FactoryPerformance;
