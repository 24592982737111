import Segment from '../../../../shared/Segment';
import TableWithVerticalBarChart from '../../../../shared/TableWithVerticalBarChart';
import { useGetTopDefectsQuery } from '../../../../redux/idiotBox/apis/lineView';
import FakeTablePagination, {
  useFakeTablePagination,
} from '../../../../shared/FakeTablePagination';

const Defects = () => {
  const { data, isLoading } = useGetTopDefectsQuery();

  const { page, total } = useFakeTablePagination({ data: data ?? [], size: 5 });

  return (
    <Segment
      heading="Defects"
      isLoading={isLoading}
      headerContent={
        <FakeTablePagination total={total} current={page.current ?? 0} />
      }
    >
      <TableWithVerticalBarChart
        barFill="var(--cerise)"
        barSize={16}
        data={page.data ?? []}
        chartHeader="Defects"
        axisDataKey="defect_type"
        barDataKey="defect_count_in_current_hour"
        yAxisWidth={300}
        headers={[
          {
            accessor: 'defect_count_in_current_hour',
            text: 'Current Hour',
          },
          {
            accessor: 'defect_count_in_previous_hour',
            text: 'Last Hour',
          },
          {
            accessor: 'defect_count_in_all_hour',
            text: 'Total',
          },
        ]}
      />
    </Segment>
  );
};

export default Defects;
