import { z } from 'zod';

const ImageSchema = z.object({
  id: z.string().uuid(),
  ext: z.string(),
  link: z.string(),
  name: z.string(),
  type: z.string(),
  user_id: z.string(),
});

export type ImageType = z.infer<typeof ImageSchema>;

export const AuditInspectionsSchema = z.array(
  z.object({
    id: z.number(),
    status: z.string(),
    images: z.array(ImageSchema),
    created_on: z.string(),
    remarks: z.string().nullable(),
    reject_reason: z.string().nullable(),
    defect: z.string().nullable(),
    defect_section: z.string(),
    defect_level: z.array(z.string()),
  })
);

export type AuditInspectionsType = z.infer<typeof AuditInspectionsSchema>;
