const Illustration = () => (
  <svg
    width={236}
    height={200}
    viewBox="0 0 236 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2590_367662)">
      <path
        d="M8.25597 101.191C22.4784 89.9423 44.8485 99.5018 60.6374 90.6046C70.2082 85.2106 75.1464 74.1731 77.8209 63.4715C80.4953 52.7699 81.6033 41.5308 86.4078 31.6066C91.2123 21.692 101.137 13.1019 112.045 14.3113C120.202 15.2135 126.764 21.1929 134.185 24.7057C154.225 34.198 178.696 25.1664 199.71 32.2017C215.164 37.3845 226.951 51.0998 232.061 66.6484C237.172 82.1969 236.159 99.3002 231.593 115.022C223.178 143.997 202.528 169.173 175.821 183.003C147.013 197.928 112.15 199.396 81.2881 189.453C63.5315 183.733 47.0453 174.375 32.5745 162.598C20.9788 153.154 5.57195 140.657 1.44562 125.56C-0.913646 116.941 0.977586 106.94 8.24641 101.191H8.25597Z"
        fill="#2C3556"
      />
      <path
        d="M26.6335 83.1465C31.1992 84.1255 36.1756 83.0217 39.8434 80.1232C44.7339 76.2745 46.9403 69.3064 45.1542 63.327C41.7251 51.7999 24.8759 48.1144 16.6806 56.8772C8.46616 65.6592 15.9642 80.8718 26.6335 83.1465Z"
        fill="#1A1E3E"
      />
      <path
        d="M62.032 74.202C65.9482 74.893 70.1222 72.3304 71.4881 68.5777C74.0289 61.5712 66.0437 52.7508 58.8895 56.3692C51.5347 60.0932 54.2856 72.8295 62.032 74.202Z"
        fill="#1A1E3E"
      />
      <path
        d="M129.787 170.985C143.051 170.526 156.249 173.432 169.513 172.897C182.777 172.362 197.293 167.055 201.7 156.154C205.348 147.118 200.579 136.59 192.052 130.537C183.524 124.484 172.018 122.39 161.029 122.792C150.04 123.193 139.348 125.861 128.743 128.424C118.139 130.986 107.37 133.473 96.3694 133.492C84.4682 133.52 72.589 130.575 62.49 125.106C56.2152 121.702 50.5009 117.303 43.4899 115.247C36.4788 113.191 27.3908 114.358 23.8084 119.99C20.0501 125.899 24.3798 133.606 30.7974 137.412C39.259 142.423 51.1822 142.528 59.7647 137.661C71.7868 130.843 75.2923 116.93 75.633 104.452C75.9737 91.9732 74.3253 78.921 80.0396 67.4656C84.5122 58.4868 93.4793 51.2866 104.128 48.1215C114.776 44.9565 126.93 45.8936 136.776 50.6172"
        stroke="#FF991F"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M180.969 18.0723L175.363 23.2935"
        stroke="#FA5762"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.311 25.4043L184.055 25.6634"
        stroke="#FA5762"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.414 21.1822L173.672 14.3965"
        stroke="#FA5762"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M174.503 173.72H115.063H107.421H48.4207C46.3098 173.72 44.6 172.002 44.6 169.881L42.6897 82.5403C42.6897 80.4192 44.3994 78.7012 46.5104 78.7012H176.413C178.524 78.7012 180.234 80.4192 180.234 82.5403L178.324 169.881C178.324 172.002 176.614 173.72 174.503 173.72Z"
        fill="#1A1E3E"
      />
      <path
        d="M107.421 173.72H48.4207C46.3098 173.72 44.6 172.002 44.6 169.881L42.6897 82.5403C42.6897 80.4192 44.3994 78.7012 46.5104 78.7012H176.413C178.524 78.7012 180.234 80.4192 180.234 82.5403L178.324 169.881C178.324 172.002 176.614 173.72 174.503 173.72H115.063M107.421 173.72V183.519M107.421 173.72H115.063M107.421 183.519H97.8698M107.421 183.519H115.063M124.614 183.519H115.063M115.063 173.72V183.519"
        stroke="#BABFCB"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M158.988 159.389C154.219 159.389 150.337 155.487 150.337 150.679C150.337 146.728 153.002 143.413 156.603 142.361L155.983 139.584C151.128 140.917 147.538 145.37 147.538 150.679C147.538 157.053 152.661 162.203 158.988 162.203C165.316 162.203 170.45 157.053 170.45 150.679L167.603 150.973C167.445 155.646 163.673 159.389 158.988 159.389Z"
        fill="#4C5578"
      />
      <path
        d="M158.988 139.168C157.942 139.168 156.944 139.315 155.983 139.584L156.603 142.361C157.37 142.141 158.149 141.982 158.988 141.982C163.77 141.982 167.64 145.884 167.64 150.679C167.64 150.777 167.615 150.875 167.615 150.973L170.463 150.679C170.463 144.318 165.34 139.168 159.001 139.168H158.988Z"
        fill="#BABFCB"
      />
      <path
        d="M104.499 105.777C105.554 105.777 106.409 104.917 106.409 103.857C106.409 102.797 105.554 101.938 104.499 101.938C103.444 101.938 102.588 102.797 102.588 103.857C102.588 104.917 103.444 105.777 104.499 105.777Z"
        fill="#BABFCB"
      />
      <path
        d="M115.98 105.777C117.035 105.777 117.89 104.917 117.89 103.857C117.89 102.797 117.035 101.938 115.98 101.938C114.925 101.938 114.069 102.797 114.069 103.857C114.069 104.917 114.925 105.777 115.98 105.777Z"
        fill="#BABFCB"
      />
      <path
        d="M106.409 110.019C106.409 110.019 110.115 107.111 114.06 110.019"
        stroke="#BABFCB"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M129.393 32.1027C130.676 29.9059 133.835 29.9059 135.119 32.1027L153.261 63.1515C154.564 65.3811 152.961 68.1822 150.398 68.1822H114.113C111.55 68.1822 109.948 65.3811 111.25 63.1515L129.393 32.1027Z"
        fill="#1A1E3E"
        stroke="#BABFCB"
      />
      <path
        d="M130.022 55.6269V55.1575C130.022 53.433 130.71 52.1588 132.61 50.4631C133.68 49.5051 133.995 48.7291 133.995 47.8189C133.995 46.8322 133.431 46.037 132.19 46.037C130.862 46.037 130.337 46.8801 130.156 47.9818C130.127 48.1543 129.984 48.2884 129.812 48.2884H126.88C126.67 48.2884 126.507 48.1159 126.526 47.9052C126.784 45.7879 128.236 43.1629 132.257 43.1916C136.478 43.2108 137.71 45.9508 137.71 47.9531C137.71 49.5722 137.185 50.6931 135.322 52.4175C134.224 53.4522 133.852 54.2953 133.852 55.3587V55.6269H130.003H130.022ZM130.022 59.3058V58.8842C130.022 57.8208 130.881 56.9586 131.942 56.9586C133.002 56.9586 133.861 57.8208 133.861 58.8842V59.3058C133.861 60.3692 133.002 61.2314 131.942 61.2314C130.881 61.2314 130.022 60.3692 130.022 59.3058Z"
        fill="#FF991F"
      />
      <path
        d="M61.8918 139.639H56.7976L56.8149 162.203H61.9091L61.8918 139.639Z"
        fill="#4C5578"
      />
      <path
        d="M70.3995 131.49H65.3053L65.3288 162.203H70.423L70.3995 131.49Z"
        fill="#4C5578"
      />
      <path
        d="M78.9133 135.877H73.8191L73.8392 162.202H78.9335L78.9133 135.877Z"
        fill="#4C5578"
      />
      <path
        d="M87.4238 142.146H82.3296L82.3449 162.204H87.4392L87.4238 142.146Z"
        fill="#4C5578"
      />
      <path
        d="M221.153 15.5494C223.264 15.5494 224.974 13.8305 224.974 11.7102C224.974 9.58994 223.264 7.87109 221.153 7.87109C219.043 7.87109 217.333 9.58994 217.333 11.7102C217.333 13.8305 219.043 15.5494 221.153 15.5494Z"
        fill="#2C3556"
      />
      <path
        d="M224.793 33.9379L217.505 29.7148V38.1705L224.793 33.9379Z"
        stroke="#2C3556"
        strokeWidth={0.66}
        strokeMiterlimit={10}
      />
      <path
        d="M224.945 26.4712V18.793H217.304V26.4712H224.945Z"
        fill="#2C3556"
      />
      <path
        d="M205.938 190.183C206.993 190.183 207.848 189.323 207.848 188.263C207.848 187.203 206.993 186.344 205.938 186.344C204.883 186.344 204.027 187.203 204.027 188.263C204.027 189.323 204.883 190.183 205.938 190.183Z"
        stroke="#FA5762"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M217.39 150.111V157.79"
        stroke="#FA5762"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M213.569 153.951H221.211"
        stroke="#FA5762"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4307 176.84C24.5416 176.84 26.2513 175.122 26.2513 173.001C26.2513 170.88 24.5416 169.162 22.4307 169.162C20.3197 169.162 18.61 170.88 18.61 173.001C18.61 175.122 20.3197 176.84 22.4307 176.84Z"
        fill="#2C3556"
      />
      <path
        d="M18.7915 195.231L26.0794 190.998V199.454L18.7915 195.231Z"
        stroke="#2C3556"
        strokeWidth={0.66}
        strokeMiterlimit={10}
      />
      <path d="M26.28 187.752V180.074H18.6387V187.752H26.28Z" fill="#2C3556" />
      <path
        d="M37.828 8.15875C39.9381 8.15875 41.6487 6.43991 41.6487 4.31961C41.6487 2.19931 39.9381 0.480469 37.828 0.480469C35.7179 0.480469 34.0073 2.19931 34.0073 4.31961C34.0073 6.43991 35.7179 8.15875 37.828 8.15875Z"
        stroke="#FA5762"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4402 21.2786C23.4953 21.2786 24.3506 20.4192 24.3506 19.359C24.3506 18.2989 23.4953 17.4395 22.4402 17.4395C21.3852 17.4395 20.5299 18.2989 20.5299 19.359C20.5299 20.4192 21.3852 21.2786 22.4402 21.2786Z"
        stroke="#FA5762"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.44 164L115.124 152.667L124.861 162.111L140.44 147"
        stroke="#4C5578"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={115.44} cy={153} r={2} fill="#BABFCB" />
      <circle cx={124.44} cy={161} r={2} fill="#BABFCB" />
    </g>
    <defs>
      <clipPath id="clip0_2590_367662">
        <rect
          width={235}
          height={200}
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Illustration;
