import { css, styled } from 'styled-components';

interface ITableProps {
  $shouldHavePadding?: boolean;
}

export const Table = styled.table<ITableProps>`
  width: 100%;
  border-spacing: 0 8px;

  ${props =>
    props.$shouldHavePadding &&
    css`
      padding: 0 24px;
    `}
`;

export const Th = styled.th`
  font-size: 14px;
  font-weight: 600;
  width: 50%;
  text-align: left;
  vertical-align: top;
`;

export const Td = styled.td`
  font-size: 14px;
  font-weight: 400;
  width: 50%;
  text-align: left;
  vertical-align: top;
`;
