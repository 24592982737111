import { useRef } from 'react';
import useDimensions from '../../../../hooks/useDimensions';
import AreaChart from '../../../../shared/AreaChart';
import Segment from '../../../../shared/Segment';
import ChartWrapper from '../../../../shared/ChartWrapper';
import { useGetHourlyDhuQuery } from '../../../../redux/idiotBox/apis/lineView';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import { POLLING_INTERVAL_IN_MILLISECONDS } from '../../../../constants/polling';

const HourlyDHU = () => {
  const areaChartWrapperRef = useRef<HTMLDivElement>(null);
  const viewMode = useTypedSelector(store => store.ui.viewMode);
  const { data, isLoading } = useGetHourlyDhuQuery(undefined, {
    pollingInterval: POLLING_INTERVAL_IN_MILLISECONDS,
  });
  const { height, width } = useDimensions(areaChartWrapperRef, [isLoading]);

  return (
    <Segment heading="Hourly DHU" isLoading={isLoading}>
      <ChartWrapper wrapperRef={areaChartWrapperRef}>
        <AreaChart
          data={data ?? []}
          height={height}
          width={width}
          xAxisDataKey="hour"
          xAxisLabel="Hours"
          yAxisLabel="DHU"
          axisStroke={viewMode === 'tv' ? 'var(--white)' : 'var(--fiord)'}
          areaData={[
            {
              dataKey: 'dhu',
              stroke: viewMode === 'tv' ? 'var(--white)' : 'var(--seance)',
              strokeWidth: 2,
              gradientOpacity: 0.25,
            },
          ]}
        />
      </ChartWrapper>
    </Segment>
  );
};

export default HourlyDHU;
