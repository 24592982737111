import { z } from 'zod';

export const MidlinesSchema = z.array(
  z.object({
    user_m_id: z.number(),
    user_m_name: z.string(),
    pass_count: z.number(),
    alter_count: z.number(),
    reject_count: z.number(),
    dhu: z.number(),
    ftt: z.number(),
    total_checked: z.number(),
    ftt_percentage: z.number(),
    defect_count: z.number(),
    efficiency: z.number().nullable(),
  })
);

export type MidlinesType = z.infer<typeof MidlinesSchema>;
