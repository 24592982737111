import { format } from 'date-fns';
import Segment from '../../../../../shared/Segment';
import KeyValueTable from '../../../../../shared/KeyValueTable';
import type { SessionDetailsType } from '../schemas';
import { capitalizeFirstLetter } from '../../../../../utils';
import { DEFAULT_VALUES } from '../../../../../constants';
import * as Styles from './styles';

interface ISessionDetailsProps {
  isLoading: boolean;
  data: SessionDetailsType | undefined;
}

const SessionDetails = ({ data, isLoading }: ISessionDetailsProps) => (
  <Segment
    heading="Session Details"
    isLoading={isLoading}
    shouldHeadingHaveBorder
    shouldChildrenHavePadding={false}
  >
    <div className="v-d-flex full-flex">
      <KeyValueTable
        shouldHavePadding
        data={[
          {
            key: 'Department',
            value: data?.department
              ? capitalizeFirstLetter(data.department)
              : DEFAULT_VALUES.DOUBLE_DASH,
          },
          {
            key: 'Assembly Line',
            value: data?.line_name
              ? capitalizeFirstLetter(data.line_name)
              : DEFAULT_VALUES.DOUBLE_DASH,
          },
          {
            key: 'Inspection',
            value: data?.position
              ? capitalizeFirstLetter(data.position)
              : DEFAULT_VALUES.DOUBLE_DASH,
          },
          {
            key: 'Started By',
            value: data?.checker_name,
          },
          {
            key: 'Status',
            value: data?.status
              ? capitalizeFirstLetter(data.status)
              : DEFAULT_VALUES.DOUBLE_DASH,
          },
          {
            key: 'Started At',
            value: data?.created_on
              ? format(new Date(data?.created_on), 'dd MMM yyyy, K:m:s a')
              : DEFAULT_VALUES.DOUBLE_DASH,
          },
        ]}
      />
      <Styles.TotalInfoWrapper>
        <p>Total inspected (till now):</p>
        <Styles.Table>
          <thead>
            <th>Total Inspected</th>
            <th>Pass</th>
            <th>Alter</th>
            <th>Reject</th>
          </thead>
          <tbody>
            <td>{data?.inspection_count ?? DEFAULT_VALUES.DOUBLE_DASH}</td>
            <td>{data?.pass_count ?? DEFAULT_VALUES.DOUBLE_DASH}</td>
            <td>{data?.alter_count ?? DEFAULT_VALUES.DOUBLE_DASH}</td>
            <td>{data?.reject_count ?? DEFAULT_VALUES.DOUBLE_DASH}</td>
          </tbody>
        </Styles.Table>
      </Styles.TotalInfoWrapper>
    </div>
  </Segment>
);

export default SessionDetails;
