import { createColumnHelper } from '@tanstack/react-table';
import { Button, IconButton } from '@fashinza/fashinza-design-system';
import { LinePerformanceItemsType } from './schemas';
import ProgressBar from '../../../../shared/ProgressBar';
import HourlyChart from './HourlyChart';
import * as Styles from './styles';

export function getColumns({
  openDetailsView,
  totalCheckedReferencePoint,
  shouldShowEfficiency,
}: {
  openDetailsView: (lineId: number) => void;
  totalCheckedReferencePoint: number;
  shouldShowEfficiency: boolean;
}) {
  const columnHelper = createColumnHelper<LinePerformanceItemsType[number]>();

  const columns = [
    columnHelper.accessor('line_id', {
      id: 'line_id',
      header: '',
      cell: ({ row }) =>
        row.original.midline_count > 0 ? (
          <IconButton
            icon={row.getIsExpanded() ? 'SubtractIcon' : 'AddIcon'}
            onClick={() => row.toggleExpanded()}
            sizeVariant="small"
          />
        ) : null,
      maxSize: 5,
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Lines',
      cell: info => (
        <div className="align-center gap-24 full-flex">
          <div>
            <Styles.Text className="full-flex">{info.getValue()}</Styles.Text>
            {info.row.original.midline_count > 0 && (
              <Styles.SubText className="full-flex">
                Midline Insp: {info.row.original.midline_count}
              </Styles.SubText>
            )}
          </div>
          {info.row.original.midline_count > 0 && (
            <Button
              onClick={() => openDetailsView(info.row.original.line_id)}
              variant="tertiary"
              sizeVariant="small"
              colorScheme="secondary"
            >
              Details
            </Button>
          )}
        </div>
      ),
      maxSize: 7,
    }),
    columnHelper.accessor('total_checked', {
      id: 'total_checked',
      header: 'Total Checked',
      cell: info => (
        <ProgressBar
          value={info.getValue()}
          color="var(--astro-purple)"
          labelPosition="start"
          shouldShowValue
          referencePoint={totalCheckedReferencePoint}
        />
      ),
      maxSize: 30,
    }),
    columnHelper.accessor('hourly', {
      id: 'hourly',
      header: 'Hourly',
      cell: info => <HourlyChart data={info.getValue()} />,
      maxSize: 10,
    }),
    columnHelper.accessor('pass_count', {
      id: 'passed',
      header: 'Passed',
      cell: info => info.getValue(),
      maxSize: 6,
    }),
    columnHelper.accessor('alter_count', {
      id: 'altered',
      header: 'Altered',
      cell: info => info.getValue(),
      maxSize: 6,
    }),
    columnHelper.accessor('reject_count', {
      id: 'rejected',
      header: 'Rejected',
      cell: info => info.getValue(),
      maxSize: 6,
    }),
    columnHelper.accessor('ftt', {
      id: 'ftt',
      header: 'FTT',
      cell: info =>
        `${info.getValue()} (${info.row.original.ftt_percentage} %)`,
      maxSize: 6,
    }),
    columnHelper.accessor('dhu', {
      id: 'dhu',
      header: 'DHU %',
      cell: info => `${info.getValue()} %`,

      maxSize: 6,
    }),
  ];

  if (shouldShowEfficiency) {
    return [
      ...columns,
      columnHelper.accessor('efficiency', {
        id: 'efficiency',
        header: 'Efficiency %',
        cell: info => `${info.getValue()} %`,
        maxSize: 6,
      }),
    ];
  }

  return columns;
}
