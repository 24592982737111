import { styled } from 'styled-components';

export const Wrapper = styled.div`
  /* max-width: 180px; */
  /* max-height: 40px; */

  /* background: var(--white); */

  .select-search-container {
    max-width: 200px;
    /* background: var(--white); */
    /* border: 1px dashed blue; */

    &.select-search-has-focus {
      box-shadow: 0px 0px 0px 4px var(--french-pass);
      border-radius: 8px;

      input {
        border: 1px solid var(--all-ports);
      }
    }

    .select-search-input {
      background: var(--white);
      border: 1px solid var(--athens-gray);
      border-radius: 8px;
      padding: 8px 16px;
      max-height: 40px;
      max-width: 200px;
      font-family: Montserrat;
      font-size: 14px;
      color: var(--rhino);

      &:hover {
        border: 1px solid var(--all-ports);
      }
    }

    .select-search-select {
      max-width: 200px;
      background: var(--white);
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
        0px 12px 16px -4px rgba(16, 24, 40, 0.08);
      border: 1px solid var(--athens-gray);
      top: 40px;
      border-radius: 8px;

      ul.select-search-options {
        border-radius: 8px;

        li.select-search-row {
          margin: 0;
          padding: 2px 6px;

          button {
            border-radius: 6px;
          }

          button.select-search-is-selected {
            background: var(--wash-me) !important;
          }

          button.select-search-option {
            background: var(--white);
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 500;
            color: var(--ebony);
            padding: 10px 8px;
            height: unset;

            &:hover {
              background: var(--wash-me);
            }
          }
        }
      }
    }
  }
`;

export const DropdownLabel = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--rhino);
`;
