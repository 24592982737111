import { CSSProperties, Key } from 'react';
import {
  LineChart as RechartsLineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  Label,
} from 'recharts';

import { ITooltipPosition } from './types';
import renderTooltip from './renderTooltip';
import EmptyChartView from '../EmptyChartView';

interface ILineChartProps<T> {
  data: Array<T>;
  lineData: Array<{
    dataKey: keyof T & Key;
    stroke: CSSProperties['stroke'];
  }>;
  height: number;
  width: number;
  xAxisDataKey: keyof T & Key;
  xAxisLabel?: string;
}

const LineChart = <T extends Record<string, unknown>>({
  data,
  height,
  width,
  lineData,
  xAxisDataKey,
  xAxisLabel,
}: ILineChartProps<T>) => {
  if (data.length === 0) {
    return <EmptyChartView />;
  }

  return (
    <RechartsLineChart
      width={width}
      height={height}
      data={data}
      // onMouseLeave={() => {
      //   setActiveIndex(null);
      //   setActiveTooltipPosition(null);
      // }}
    >
      <CartesianGrid vertical={false} stroke="var(--athens-gray)" />
      <XAxis
        dataKey={xAxisDataKey}
        axisLine={false}
        tickLine={false}
        style={{
          fontSize: '12px',
          fontWeight: 500,
        }}
      >
        <Label
          value={xAxisLabel ?? xAxisDataKey}
          offset={0}
          position="insideBottom"
        />
      </XAxis>

      <YAxis
        axisLine={false}
        tickLine={false}
        style={{
          fontSize: '12px',
          fontWeight: 500,
        }}
      />

      {lineData.map((line, index) => (
        <Line
          type="linear"
          dot={false}
          key={line.dataKey}
          dataKey={line.dataKey}
          stroke={line.stroke}
          strokeWidth={1.25}
          strokeLinejoin="round"
          // onMouseMove={event => {
          //   setActiveIndex(index);
          //   console.log({ event });
          //   // setActiveTooltipPosition({
          //   //   x: event.clientX,
          //   //   y: event.clientY,
          //   // });
          // }}
          // onMouseLeave={() => {
          //   setActiveIndex(null);
          //   setActiveTooltipPosition(null);
          // }}
        />
      ))}

      <Tooltip content={renderTooltip} />

      {/* {activeIndex !== null && activeTooltipPosition !== null && (
        <TooltipDisplay
          payload={data[activeIndex]}
          position={activeTooltipPosition}
        />
      )} */}
    </RechartsLineChart>
  );
};

export default LineChart;
