import styled from 'styled-components';

export const Wrapper = styled.div`
  border-top: 1px solid var(--fiord);
  border-bottom: 1px solid var(--fiord);
  background: var(--port-gore);
  padding: 0 24px;
  height: 36px;
`;

export const CommentsContainer = styled.div`
  min-width: 100%;
  padding-right: 20vw;
`;

export const Comment = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: var(--white);
  white-space: nowrap;
`;
