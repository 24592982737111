import { Button } from '@fashinza/fashinza-design-system';
import { createColumnHelper } from '@tanstack/react-table';
import type { AuditSummaryByStyleDataType } from './schemas';
import OrderItem from '../../../../shared/OrderItem';
import { DEFAULT_VALUES } from '../../../../constants';
import { capitalizeFirstLetter } from '../../../../utils';

export function getColumns() {
  const columnHelper =
    createColumnHelper<AuditSummaryByStyleDataType[number]>();

  const columns = [
    columnHelper.accessor('style', {
      id: 'style',
      header: 'Style',
      cell: info => (
        <OrderItem
          image={info.getValue().style_image}
          name={info.getValue().style_name}
          subText={info.getValue().style_color}
        />
      ),
      maxSize: 25,
    }),

    columnHelper.accessor('department', {
      id: 'department',
      header: 'Audit Type',
      cell: info => `${capitalizeFirstLetter(info.getValue())}`,
      maxSize: 10,
    }),
    columnHelper.accessor('audit_count', {
      id: 'audit_count',
      header: 'Total audits',
      cell: info => info.getValue(),
      maxSize: 10,
    }),
    columnHelper.accessor('inspection_count', {
      id: 'inspection_count',
      header: 'Total Inspected qty',
      cell: info => info.getValue(),
      maxSize: 10,
    }),

    columnHelper.accessor('inspection_pass_count', {
      id: 'inspection_pass_count',
      header: 'Pass',
      cell: info => info.getValue(),
      maxSize: 5,
      meta: {
        headerStyles: {
          color: 'var(--green-haze)',
          background: 'var(--scandal)',
        },
        cellStyles: {
          color: 'var(--green-haze)',
        },
      },
    }),
    columnHelper.accessor('inspection_pass_percentage', {
      id: 'inspection_pass_percentage',
      header: 'Pass %',
      cell: info => `${info.getValue()}%`,
      maxSize: 5,
      meta: {
        headerStyles: {
          color: 'var(--green-haze)',
          background: 'var(--scandal)',
        },
        cellStyles: {
          color: 'var(--green-haze)',
        },
      },
    }),
    columnHelper.accessor('inspection_fail_count', {
      id: 'inspection_fail_count',
      header: 'Fail',
      cell: info => info.getValue(),
      maxSize: 5,
      meta: {
        headerStyles: {
          color: 'var(--red-dirt)',
          background: 'var(--fair-pink)',
        },
        cellStyles: {
          color: 'var(--red-dirt)',
        },
      },
    }),
    columnHelper.accessor('inspection_fail_percentage', {
      id: 'inspection_fail_percentage',
      header: 'Fail %',
      cell: info => `${info.getValue()} %`,
      maxSize: 5,
      meta: {
        headerStyles: {
          color: 'var(--red-dirt)',
          background: 'var(--fair-pink)',
        },
        cellStyles: {
          color: 'var(--red-dirt)',
        },
      },
    }),
    columnHelper.accessor('order', {
      id: 'order',
      header: 'Order',
      cell: info => (
        <OrderItem
          name={info.getValue().collection_name}
          subText={info.getValue().buyer_name ?? DEFAULT_VALUES.DOUBLE_DASH}
          isBold={false}
        />
      ),
      maxSize: 15,
    }),
  ];

  return columns;
}
