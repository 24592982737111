import { styled } from 'styled-components';

export const MoreFiltersContainer = styled.div`
  background: var(--white);
  border-radius: 8px;
  position: absolute;
  top: 0;

  padding: 12px;
  box-shadow: 0 12px 30px 0 rgba(9, 12, 11, 0.2);
`;
