import { useRef } from 'react';
import { useGetLineAreaOfConcernDataByIdQuery } from '../../../../../../redux/dashboard/api';
import ChartWrapper from '../../../../../../shared/ChartWrapper';
import VerticalBarChart from '../../../../../../shared/VerticalBarChart';
import Segment from '../../../../../../shared/Segment';
import useFakePagination from '../../../../../../hooks/useFakePagination';
import { PaginationWrapper } from '../../../../../../shared/Table/styles';
import ManualPagination from '../../../../../../shared/ManualPagination';

interface IAreasOfConcernProps {
  department: string;
  endlineId: number;
  midlineId?: number;
}

const AreasOfConcern = ({
  department,
  endlineId,
  midlineId,
}: IAreasOfConcernProps) => {
  const chartWrapperRef = useRef<HTMLDivElement>(null);

  const { data, isFetching } = useGetLineAreaOfConcernDataByIdQuery({
    department,
    endlineId,
    midlineId,
  });

  const { page, action } = useFakePagination(data, 10);

  return (
    <Segment
      heading="Area of concern"
      description="Area where maximum defects are found"
      descriptionMaxWidth={300}
      shouldChildrenHavePadding={false}
      isLoading={isFetching}
    >
      <ChartWrapper wrapperRef={chartWrapperRef}>
        <VerticalBarChart
          barFill="var(--cerise)"
          data={
            page.data?.map(item => ({
              name: item.defect_section_type,
              value: item.count,
            })) ?? []
          }
        />
      </ChartWrapper>
      <PaginationWrapper className="flex-justify-end">
        <ManualPagination
          kind="manual"
          totalCount={data?.length ?? 0}
          pageLength={10}
          currentPage={page.current ?? 0}
          onChangePage={c => (c === 'next' ? action.next() : action.previous())}
        />
      </PaginationWrapper>
    </Segment>
  );
};

export default AreasOfConcern;
