import { LineHeading } from './styles';
import Grid from '../../../../../shared/Grid';
import GridItem from '../../../../../shared/Grid/GridItem';
import AreasOfConcern from './AreasOfConcern';
import Defects from './Defects';

interface IEndlineProps {
  name: string | undefined;
  endlineId: number;
  department: string;
}

const Endline = ({ name, department, endlineId }: IEndlineProps) => (
  <div className="v-d-flex gap-16">
    {name && <LineHeading>Endline Inspection {name}</LineHeading>}
    <Grid
      minHeight="50vh"
      gridTemplateColumns="1fr 1fr"
      gridTemplateRows="1fr"
      gap={24}
    >
      <GridItem gridColumn="1/2" gridRow="1/2">
        <Defects department={department} endlineId={endlineId} />
      </GridItem>
      <GridItem gridColumn="2/3" gridRow="1/2">
        <AreasOfConcern department={department} endlineId={endlineId} />
      </GridItem>
    </Grid>
  </div>
);

export default Endline;
