import CellContent from './CellContent';
import HeaderCell from './HeaderCell';
import GridCell from '../../../../shared/VirtualizedTable/GridCell';
import { IGridCellNode } from '../../../../shared/VirtualizedTable/types';
import { LOT_WISE_SUMMARY_FIELDS, getMultiGridSettings } from './constants';
import { LotWiseSummaryDataType } from './schemas';

interface ITableCellProps {
  listIds: Array<number>;
  gridProps: IGridCellNode;
  lastItemRef: (node: any) => void;
  data: LotWiseSummaryDataType;
  onClickViewDetails: (sessionId: number) => void;
}

const TableCell = ({
  listIds,
  gridProps,
  data,
  lastItemRef,
  onClickViewDetails,
}: ITableCellProps) => {
  const { columnIndex, rowIndex } = gridProps;

  const listFields = Object.keys(LOT_WISE_SUMMARY_FIELDS);

  const field = LOT_WISE_SUMMARY_FIELDS[listFields[columnIndex]];

  if (rowIndex === 0) {
    return (
      <GridCell
        gridProps={gridProps}
        totalRowsCount={listIds.length}
        totalColumnsCount={listFields.length}
        fixedColumnCount={getMultiGridSettings().fixedColumnCount}
        cellNode={<HeaderCell columnIndex={columnIndex} />}
      />
    );
  }

  if (data) {
    let cellNode = (
      <CellContent
        data={data}
        identifier={field.value as keyof typeof data}
        onClickViewDetails={onClickViewDetails}
      />
    );

    if (columnIndex === 0 && rowIndex === listIds.length - 3) {
      cellNode = (
        <div ref={lastItemRef}>
          <CellContent
            data={data}
            identifier={field.value as keyof typeof data}
            onClickViewDetails={onClickViewDetails}
          />
        </div>
      );
    }

    return (
      <GridCell
        gridProps={gridProps}
        totalRowsCount={listIds.length}
        totalColumnsCount={listFields.length}
        fixedColumnCount={getMultiGridSettings().fixedColumnCount}
        cellNode={cellNode}
      />
    );
  }

  return null;
};

export default TableCell;
