import { IconPropsType } from './types';

interface IArrowIncomingOutgoingIconProps extends IconPropsType {}

const ArrowIncomingOutgoingIcon = ({
  size = 24,
  stroke = 'var(--rhino)',
  strokeWidth = 1.25,
  ...props
}: IArrowIncomingOutgoingIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6704 3L3 15.896M3 15.896L7.9176 15.098M3 15.896L3.78418 10.8909M8.32956 21L21 8.10396M21 8.10396L16.0824 8.90202M21 8.10396L20.2158 13.1091"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowIncomingOutgoingIcon;
