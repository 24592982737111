import styled from 'styled-components';

export const H1 = styled.h1`
  font-family: Lora;
  font-weight: 600;
  font-size: 24px;
  color: var(--rhino);
  padding: 14px 24px;
  flex: 1;
  border-bottom: 1px solid var(--athens-gray);
`;
