import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import TrakTezLogo from '../TrakTezLogo';
import { Indicator } from './styles';
import * as Styles from './styles';
import { DEFAULT_VALUES } from '../../constants';

interface ITvHeaderProps {
  indicators?: Array<{
    attribute?: string;
    value?: string | number;
    shouldStretch?: boolean;
  }>;
  currentHour: number;
}

const TvHeader = ({ currentHour, indicators = [] }: ITvHeaderProps) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const allIndicators = useMemo(
    () => [
      ...indicators,
      ...[
        {
          attribute: 'hour',
          value: currentHour,
        },
      ],
    ],
    [indicators, currentHour]
  );

  return (
    <Styles.Wrapper className="align-center gap-12">
      <TrakTezLogo />
      <div className="full-flex end-align-center gap-12">
        {allIndicators.map(indicator => (
          <Indicator.Wrapper
            key={indicator.value}
            className="gap-12 center"
            $shouldStretch={indicator.shouldStretch}
          >
            {indicator.attribute && (
              <Indicator.Attribute>{indicator.attribute}</Indicator.Attribute>
            )}
            <Indicator.Value>
              {indicator.value ?? DEFAULT_VALUES.NOT_APPLICABLE}
            </Indicator.Value>
          </Indicator.Wrapper>
        ))}
        <Indicator.Wrapper className="gap-12 center">
          <Indicator.Value>{format(time, 'p')}</Indicator.Value>
        </Indicator.Wrapper>
      </div>
    </Styles.Wrapper>
  );
};

export default TvHeader;
