import { createColumnHelper } from '@tanstack/react-table';
import Table from '../../../../../shared/Table';
import { LineOverallDataType } from './schemas';
import Segment from '../../../../../shared/Segment';
import useFakePagination from '../../../../../hooks/useFakePagination';
import ProgressBar from '../../../../../shared/ProgressBar';
import useFeatureFlags from '../../../../../redux/featureFlags/useFeatureFlags';
import { useMemo } from 'react';

interface ILinesListItem {
  id: number;
  name: string;
  total_checked: number;
  pass_count: number;
  alter_count: number;
  reject_count: number;
  ftt: number;
  ftt_percentage: number;
  efficiency: number | null;
  dhu: number;
}

interface ILinesListProps {
  data: LineOverallDataType | undefined;
  isLoading: boolean;
}

const columnHelper = createColumnHelper<ILinesListItem>();

function getColumns({
  shouldShowEfficiency,
}: {
  shouldShowEfficiency: boolean;
}) {
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Lines',
      cell: info => info.getValue(),
      maxSize: 7,
    }),
    columnHelper.accessor('total_checked', {
      id: 'total_checked',
      header: 'Total Checked',
      cell: info => (
        <ProgressBar
          value={info.getValue()}
          color="var(--astro-purple)"
          labelPosition="start"
          shouldShowValue
        />
      ),
      maxSize: 30,
    }),
    columnHelper.accessor('pass_count', {
      id: 'passed',
      header: 'Passed',
      cell: info => info.getValue(),
      maxSize: 6,
    }),
    columnHelper.accessor('alter_count', {
      id: 'altered',
      header: 'Altered',
      cell: info => info.getValue(),
      maxSize: 6,
    }),
    columnHelper.accessor('reject_count', {
      id: 'rejected',
      header: 'Rejected',
      cell: info => info.getValue(),
      maxSize: 6,
    }),
    columnHelper.accessor('ftt', {
      id: 'ftt',
      header: 'FTT',
      cell: info =>
        `${info.getValue()} (${info.row.original.ftt_percentage} %)`,
      maxSize: 6,
    }),

    columnHelper.accessor('dhu', {
      id: 'dhu',
      header: 'DHU %',
      cell: info => (
        <ProgressBar
          value={info.getValue()}
          color="var(--red-dirt)"
          labelPosition="end"
          valueSuffix="%"
          shouldShowValue
          gap={12}
        />
      ),
      maxSize: 15,
    }),
  ];

  if (shouldShowEfficiency) {
    return [
      ...columns,
      columnHelper.accessor('efficiency', {
        id: 'efficiency',
        header: 'Efficiency %',
        cell: info => `${info.getValue()} %`,
        maxSize: 6,
      }),
    ];
  }

  return columns;
}

function flattenAndTransformOverallData(
  rawData: LineOverallDataType | undefined
) {
  if (!rawData) {
    return [];
  }

  const flattenedArray: Array<ILinesListItem> = [];

  const {
    line_id: id,
    name,
    total_checked,
    pass_count,
    alter_count,
    reject_count,
    dhu,
    ftt,
    ftt_percentage,
    efficiency,
    midline,
  } = rawData;

  flattenedArray.push({
    id,
    name,
    total_checked,
    pass_count,
    alter_count,
    reject_count,
    dhu,
    ftt,
    ftt_percentage,
    efficiency,
  });

  midline.forEach(midlineObj => {
    flattenedArray.push({
      id: midlineObj.checker_m_id,
      name: midlineObj.checker_m_name,
      total_checked: midlineObj.total_checked,
      pass_count: midlineObj.pass_count,
      alter_count: midlineObj.alter_count,
      reject_count: midlineObj.reject_count,
      dhu: midlineObj.dhu,
      ftt: midlineObj.ftt,
      ftt_percentage: midlineObj.ftt_percentage,
      efficiency: midlineObj.efficiency ?? 0,
    });
  });

  return flattenedArray;
}

const LinesList = ({ data, isLoading }: ILinesListProps) => {
  const formattedData = flattenAndTransformOverallData(data);

  const { page, action } = useFakePagination(formattedData, 5);

  const { should_show_efficiency } = useFeatureFlags();

  const columns = useMemo(
    () => getColumns({ shouldShowEfficiency: should_show_efficiency }),
    [should_show_efficiency]
  );

  return (
    <Segment
      heading="Lines"
      shouldChildrenHavePadding={isLoading}
      isLoading={isLoading}
    >
      <Table
        data={page.data ?? []}
        columns={columns}
        currentPage={page.current ?? 0}
        handlePagination={c =>
          c === 'next' ? action.next() : action.previous()
        }
        pageLength={5}
        totalCount={formattedData?.length ?? 0}
        cellsWithNoPadding={['images']}
      />
    </Segment>
  );
};

export default LinesList;
