/* eslint-disable react/no-unstable-nested-components */
import { Spinner } from '@fashinza/fashinza-design-system';
import { usePaginatedTable } from '../../../../hooks/usePaginatedTable';
import VirtualizedGrid from '../../../../shared/VirtualizedTable/VirtualizedGrid';
import TableCell from './TableCell';
import { LOT_WISE_SUMMARY_FIELDS, getMultiGridSettings } from './constants';
import EmptyView from '../../../../shared/Table/EmptyView';
import { LotWiseSummaryDataType } from './schemas';
import * as Styles from './styles';

interface ITableProps {
  ids: Array<number>;
  data: Record<string, LotWiseSummaryDataType>;
  isFetching: boolean;
  isLoading: boolean;
  onClickViewDetails: (sessionId: number) => void;
}

const Table = ({
  ids,
  data,
  onClickViewDetails,
  isFetching,
  isLoading,
}: ITableProps) => {
  function fetchNext() {
    // if (isLoading) {
    //   return;
    // }
    // dispatch(fetchTableData('append'));
  }

  const lastItemRef = usePaginatedTable(fetchNext);

  const listFields = Object.keys(LOT_WISE_SUMMARY_FIELDS);

  return (
    <Styles.TableWrapper className="pos-r">
      <VirtualizedGrid
        key={ids[0] ?? ids.length}
        getGridCellNode={gridProps => (
          <TableCell
            onClickViewDetails={onClickViewDetails}
            data={data[ids[gridProps.rowIndex - 1]]}
            listIds={ids}
            gridProps={gridProps}
            lastItemRef={lastItemRef}
          />
        )}
        columnCount={listFields.length}
        recomputeDependency={[]}
        listLength={ids.length}
        getColumnWidth={index =>
          LOT_WISE_SUMMARY_FIELDS[
            listFields[index] as keyof LotWiseSummaryDataType
          ]?.width
        }
        heightDependencies={[]}
        fixedColumnCount={getMultiGridSettings().fixedColumnCount}
        commonGridClassName="remove-scrollbar"
      />
      {!isLoading && ids.length === 0 && <EmptyView />}
      {(isLoading || isFetching) && (
        <Styles.LoaderWrapper className="pos-a center">
          <Spinner height={42} width={42} stroke="var(--wild-watermelon)" />
        </Styles.LoaderWrapper>
      )}
    </Styles.TableWrapper>
  );
};

export default Table;
