import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const LineHeading = styled.div`
  font-family: Lora;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
`;
