import { z } from 'zod';

export const DefectsSchema = z.array(
  z.object({
    defect_type: z.string(),
    count: z.number(),
  })
);

export type DefectsType = z.infer<typeof DefectsSchema>;
