import { Button } from '@fashinza/fashinza-design-system';
import PurpleText from '../PurpleText';
import { FactoryTvListItemsType } from '../schemas';
import * as Styles from './styles';
import { openMasterTvView } from '../utils';

interface IMasterViewProps {
  department: string;
  data: FactoryTvListItemsType[number];
}

const MasterView = ({ data, department }: IMasterViewProps) => (
  <Styles.Wrapper className="d-flex">
    <div className="v-d-flex gap-4 full-flex">
      <PurpleText>Master View</PurpleText>
      <Styles.SubText>
        Open the master view of {department} department
      </Styles.SubText>
    </div>
    <div>
      <Button
        onClick={() => openMasterTvView(data)}
        colorScheme="neutral"
        variant="outline"
      >
        Open
      </Button>
    </div>
  </Styles.Wrapper>
);

export default MasterView;
