import Segment from '../../../shared/Segment';
import Icon from './Icon';
import MasterView from '../MasterView';
import LineView from '../LineView';
import { FactoryTvLineListItemsType, FactoryTvListItemsType } from '../schemas';

interface IFinishingProps {
  masterLines: FactoryTvListItemsType | undefined;
  lines: FactoryTvLineListItemsType;

  isLoading: boolean;
}

const Finishing = ({ lines, masterLines, isLoading }: IFinishingProps) => (
  <Segment
    isHeadingLarge
    heading="Finishing"
    icon={<Icon />}
    shouldChildrenHavePadding={false}
    isLoading={isLoading}
    className="gap-32"
  >
    {masterLines?.map(masterData => (
      <MasterView
        key={masterData.id}
        department="Finishing"
        data={masterData}
      />
    ))}
    {lines.length > 0 && <LineView lineName="Finishing" lines={lines} />}
  </Segment>
);

export default Finishing;
