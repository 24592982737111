import { z } from 'zod';

export const StyleWiseSummaryDataSchema = z.array(
  z.object({
    style: z.object({
      id: z.number(),
      style_name: z.string(),
      style_color: z.string(),
      style_image: z.string(),
    }),
    order: z.object({
      order_id: z.number(),
      collection_name: z.string(),
      buyer_name: z.string(),
    }),
    inspection_count: z.number(),
    audit_count: z.number(),
    defect_count: z.number(),
    department: z.string(),
    inspection_pass_count: z.number(),
    minor_defect_count: z.number(),
    major_defect_count: z.number(),
    critical_defect_count: z.number(),
  })
);

export type StyleWiseSummaryDataType = z.infer<
  typeof StyleWiseSummaryDataSchema
>;
