import { z } from 'zod';

export const AreasOfConcernSchema = z.array(
  z.object({
    defect_section_type: z.string(),
    count: z.number(),
  })
);

export type AreasOfConcernType = z.infer<typeof AreasOfConcernSchema>;
