import Grid from '../../../../shared/Grid';
import GridItem from '../../../../shared/Grid/GridItem';
import Inspections from './Inspections';
import ProductDetails from './ProductDetails';
import SessionDetails from './SessionDetails';
import {
  useGetDetailsBySessionIdQuery,
  useGetInspectionBySessionIdQuery,
} from '../../../../redux/sessions/api';

interface IOrdinaryDetailsProps {
  sessionId: number;
}

const OrdinaryDetails = ({ sessionId }: IOrdinaryDetailsProps) => {
  const { data, isLoading } = useGetDetailsBySessionIdQuery(sessionId);

  const { data: inspectionData, isLoading: isInspectionDataLoading } =
    useGetInspectionBySessionIdQuery(sessionId);

  return (
    <Grid gridTemplateColumns="1fr 1fr" gridTemplateRows="1fr 1fr" gap={24}>
      <GridItem gridColumn="1/2" gridRow="1/2">
        <SessionDetails data={data} isLoading={isLoading} />
      </GridItem>
      <GridItem gridColumn="2/3" gridRow="1/2">
        <ProductDetails data={data} isLoading={isLoading} />
      </GridItem>
      <GridItem gridColumn="1/3" gridRow="2/3">
        <Inspections
          data={inspectionData}
          isLoading={isInspectionDataLoading}
        />
      </GridItem>
    </Grid>
  );
};

export default OrdinaryDetails;
