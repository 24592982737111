import styled, { css } from 'styled-components';
import { IUserInterfaceState } from '../../redux/ui';

interface IWrapperProps {
  $viewMode: IUserInterfaceState['viewMode'];
}

export const Wrapper = styled.div<IWrapperProps>`
  border-radius: 8px;
  height: 100%;
  width: 100%;
  border: 1px solid
    ${props =>
      props.$viewMode === 'tv' ? 'var(--fiord)' : 'var(--athens-gray)'};
  background: ${props =>
    props.$viewMode === 'tv' ? 'var(--port-gore)' : 'var(--white)'};
`;

interface IHeaderProps {
  $isHeadingLarge: boolean;
  $shouldHeadingHaveBorder: boolean;
}

export const Header = styled.header<IHeaderProps>`
  padding: ${({ $isHeadingLarge }) => ($isHeadingLarge ? '24px' : '16px 24px')};
  ${props =>
    props.$shouldHeadingHaveBorder &&
    css`
      border-bottom: 1px solid var(--athens-gray);
    `}
`;

interface IHeadingProps {
  $isHeadingLarge: boolean;
  $viewMode: IUserInterfaceState['viewMode'];
}

export const Heading = styled.h3<IHeadingProps>`
  font-weight: ${({ $isHeadingLarge, $viewMode }) =>
    // eslint-disable-next-line no-nested-ternary
    $isHeadingLarge ? 600 : $viewMode === 'tv' ? '700' : '500'};
  font-size: ${({ $isHeadingLarge }) => ($isHeadingLarge ? 20 : 16)}px;
  gap: 8px;
  color: ${props =>
    props.$viewMode === 'tv' ? 'var(--manatee)' : 'var(--rhino)'};
  flex: 1;
`;

interface IChildrenWrapperProps {
  $shouldChildrenHavePadding: boolean;
}

export const ChildrenWrapper = styled.div<IChildrenWrapperProps>`
  flex: 1;

  ${props =>
    props.$shouldChildrenHavePadding &&
    css`
      padding: ${props.theme.spacing[4]}px ${props.theme.spacing[6]}px;
    `}
`;
