import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  gap: 24px;
`;

const StyledHeading = styled.h3`
  font-family: Lora;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--rhino);
`;

interface ISectionProps {
  heading: string;
  children: ReactNode;
  className?: string;
}

const Section = ({
  heading,
  children,
  className = 'v-d-flex',
}: ISectionProps) => (
  <StyledSection className={className}>
    <StyledHeading>{heading}</StyledHeading>
    {children}
  </StyledSection>
);

export default Section;
