import { Fragment, ReactNode } from 'react';
import { AnimatePresence } from 'framer-motion';
import { IconButton } from '@fashinza/fashinza-design-system';

import * as Styles from './styles';

interface ISidebarProps {
  isOpen: boolean;
  children: ReactNode;
  onClose: VoidFunction;
  heading: string;
  subHeadings?: Array<string | undefined>;
  subHeadingDelimiter?: string;
  shouldChildrenHavePadding?: boolean;
}

const Sidebar = ({
  isOpen,
  children,
  heading,
  subHeadings,
  onClose,
  subHeadingDelimiter = '●',
  shouldChildrenHavePadding = true,
}: ISidebarProps) => (
  <AnimatePresence mode="wait">
    {isOpen && (
      <Styles.Wrapper
        className="flex-end"
        initial={{
          x: '100%',
          //   opacity: 0,
        }}
        animate={{
          x: 0,
          //   opacity: 1,
        }}
        exit={{
          x: '100%',
          //   opacity: 0,
        }}
        transition={{ duration: 0.3, type: 'tween' }}
      >
        <Styles.BodyWrapper>
          <Styles.Header className="d-flex">
            <div className="v-d-flex gap-4 full-flex">
              <Styles.Heading>{heading}</Styles.Heading>
              {subHeadings && (
                <Styles.SubHeading className="align-center gap-4">
                  {subHeadings.filter(Boolean).map((subHeading, index) => (
                    <Fragment key={`${subHeading}-${index}`}>
                      {subHeading}
                      {index !== Number(subHeadings?.length) - 1 && (
                        <Styles.SubHeadingSeparator>
                          {subHeadingDelimiter}
                        </Styles.SubHeadingSeparator>
                      )}
                    </Fragment>
                  ))}
                </Styles.SubHeading>
              )}
            </div>
            <div className="end-align-center">
              <IconButton
                onClick={onClose}
                icon="CloseIcon"
                colorVariant="neutral"
                sizeVariant="medium"
                shapeVariant="circle"
              />
            </div>
          </Styles.Header>
          <Styles.Body
            className="v-d-flex gap-24"
            $shouldHavePadding={shouldChildrenHavePadding}
          >
            {children}
          </Styles.Body>
        </Styles.BodyWrapper>
      </Styles.Wrapper>
    )}
  </AnimatePresence>
);

export default Sidebar;
