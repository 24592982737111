import Filters from './Filters';
import { useTypedSelector } from '../../redux/useTypedSelector';
import OrdinarySessions from './Ordinary';
import AuditSessions from './Audit';

const Sessions = () => {
  const department = useTypedSelector(
    store => store.filters.selected.department
  );

  const isAuditDepartment = department.includes('audit');

  return (
    <div className="v-d-flex gap-24">
      <Filters />
      {isAuditDepartment ? <AuditSessions /> : <OrdinarySessions />}
    </div>
  );
};

export default Sessions;
