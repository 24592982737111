import { z } from 'zod';

export const AuditSessionDetailsSchema = z.object({
  id: z.number(),
  created_on: z.string(),
  line_name: z.string(),
  department: z.string(),
  auditor_name: z.string(),
  status: z.string(),
  inspection_count: z.number(),
  pass_count: z.number(),
  defect_count: z.number(),
  defective_pieces_count: z.number(),
  brand: z.string(),
  order_id: z.number(),
  collection_name: z.string(),
  style_name: z.string(),
  style_image: z.string().nullable(),
  color: z.string(),
  quantity: z.number(),
  position: z.string(),
  aql_level: z.number(),
  sample_size: z.number(),
  sample_offered_quantity: z.number(),
});

export type AuditSessionDetailsType = z.infer<typeof AuditSessionDetailsSchema>;
