import { IconPropsType } from './types';

interface IChatIconProps extends IconPropsType {}

const PATH_DRAWN = Object.freeze({
  default:
    'M20 8C21.1046 8 22 8.89543 22 10V16C22 17.1046 21.1046 18 20 18H18V22L14 18H12.75H12C11.5 18 11 17.5 11 17.5M7 14H5C3.89543 14 3 13.1046 3 12V6C3 4.89543 3.89543 4 5 4H15C16.1046 4 17 4.89543 17 6V12C17 13.1046 16.1046 14 15 14H11L7.58579 17.4142L7 18V14Z',
  'alternate-1':
    'M8 12H8.01M12 12H12.01M16 12H16.01M21 12C21 16.4183 16.9706 20 12 20C10.4607 20 9.01172 19.6565 7.74467 19.0511L3 20L4.39499 16.28C3.51156 15.0423 3 13.5743 3 12C3 7.58172 7.02944 4 12 4C16.9706 4 21 7.58172 21 12Z',
  'alternate-2':
    'M8 10H8.01M12 10H12.01M16 10H16.01M9 16H5C3.89543 16 3 15.1046 3 14V6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V14C21 15.1046 20.1046 16 19 16H14L9 21V16Z',
});

const ChatIcon = ({
  size = 24,
  stroke = 'var(--rhino)',
  strokeWidth = 1.25,
  viewType = 'default',
  ...props
}: IChatIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={PATH_DRAWN[viewType as keyof typeof PATH_DRAWN]}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChatIcon;
