interface IBaseFieldData {
  label: string;
  value: string;
  isSortable?: boolean;
  showFilter?: boolean;
  width: number;
  isStatic?: boolean;
  style?: Record<string, string>;
  isCellFullWidth?: boolean;
  prefix?: string;
  suffix?: string;
}

interface IColumnStyle {
  minWidth: string;
  maxWidth: string;
  flexGrow: number;
  flexShrink: number;
  width: number;
  marginRight: number;
  paddingLeft: number;
  paddingRight: number;
}

const DEFAULT_COLUMN_STYLE: IColumnStyle = Object.freeze({
  minWidth: '50px',
  maxWidth: '200px',
  flexGrow: 1,
  flexShrink: 1,
  width: 100,
  marginRight: 1,
  paddingLeft: 12,
  paddingRight: 12,
});

export const LOT_WISE_SUMMARY_FIELDS: Record<
  IBaseFieldData['value'],
  IBaseFieldData & IColumnStyle
> = {
  id: {
    label: 'Audits',
    value: 'id',
    ...DEFAULT_COLUMN_STYLE,
    width: 300,
    isStatic: true,
  },
  status: {
    label: 'Status / Result',
    value: 'status',
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 150,
    // isStatic: true,
  },
  line_id: {
    label: 'Line & Audit',
    value: 'line_id',
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 150,
  },
  inspection_count: {
    label: 'Total Insp.',
    value: 'inspection_count',
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 150,
  },
  inspection_pass_count: {
    label: 'Pass',
    value: 'inspection_pass_count',
    ...DEFAULT_COLUMN_STYLE,
    width: 150,
  },
  defect_count: {
    label: 'Total Defects',
    value: 'defect_count',
    ...DEFAULT_COLUMN_STYLE,
    width: 150,
  },
  order: {
    label: 'Order',
    value: 'order',
    ...DEFAULT_COLUMN_STYLE,
    width: 200,
  },
  style: {
    label: 'Style',
    value: 'style',
    ...DEFAULT_COLUMN_STYLE,
    width: 200,
  },
  inspector_name: {
    label: 'Started By',
    value: 'auditor_name',
    ...DEFAULT_COLUMN_STYLE,
    width: 175,
  },
};

export function getMultiGridSettings() {
  return Object.freeze({
    fixedColumnCount: Object.values(LOT_WISE_SUMMARY_FIELDS).filter(
      eachField => eachField.isStatic
    ).length,
  });
}
