import { IconPropsType } from './types';

interface ICheckUnderCircleIconProps extends IconPropsType {}

const CheckUnderCircleIcon = ({
  size = 24,
  stroke = 'var(--rhino)',
  strokeWidth = 1.25,
  ...props
}: ICheckUnderCircleIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.47526 12L11.4918 14.0165L15.5247 9.98351M8.30039 4.63683C9.02384 4.57909 9.71064 4.29461 10.263 3.82388C11.5521 2.72537 13.4479 2.72537 14.737 3.82388C15.2894 4.29461 15.9762 4.57909 16.6996 4.63683C18.3879 4.77155 19.7285 6.11215 19.8632 7.80039C19.9209 8.52384 20.2054 9.21064 20.6761 9.76302C21.7746 11.0521 21.7746 12.9479 20.6761 14.237C20.2054 14.7894 19.9209 15.4762 19.8632 16.1996C19.7285 17.8879 18.3879 19.2285 16.6996 19.3632C15.9762 19.4209 15.2894 19.7054 14.737 20.1761C13.4479 21.2746 11.5521 21.2746 10.263 20.1761C9.71064 19.7054 9.02384 19.4209 8.30039 19.3632C6.61215 19.2285 5.27155 17.8879 5.13683 16.1996C5.07909 15.4762 4.79461 14.7894 4.32388 14.237C3.22537 12.9479 3.22537 11.0521 4.32388 9.76302C4.79461 9.21064 5.07909 8.52384 5.13683 7.80039C5.27155 6.11215 6.61215 4.77155 8.30039 4.63683Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckUnderCircleIcon;
