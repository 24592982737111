import { createColumnHelper } from '@tanstack/react-table';
import type { FactoryPerformanceDataType } from './schemas';
import { DEFAULT_VALUES } from '../../../../constants';
import OrderItem from '../../../../shared/OrderItem';

export function getColumns({
  shouldShowEfficiency,
}: {
  shouldShowEfficiency: boolean;
}) {
  const columnHelper = createColumnHelper<FactoryPerformanceDataType[number]>();

  const columns = [
    columnHelper.accessor('style', {
      id: 'style',
      header: 'Style',
      cell: info => (
        <OrderItem
          image={info.getValue().style_image}
          name={info.getValue().style_name}
          subText={info.getValue().style_color}
        />
      ),
      maxSize: 20,
    }),
    columnHelper.accessor('order', {
      id: 'order',
      header: 'Order',
      cell: info => (
        <OrderItem
          name={info.getValue().collection_name}
          subText={info.getValue().buyer_name ?? DEFAULT_VALUES.DOUBLE_DASH}
          isBold={false}
        />
      ),
      maxSize: 20,
    }),
    columnHelper.accessor('line', {
      id: 'line',
      header: 'Line',
      cell: info => info.getValue(),
      maxSize: 10,
    }),
    columnHelper.accessor('inspection_count', {
      id: 'inspection_count',
      header: 'Total Insp.',
      cell: info => info.getValue(),
      maxSize: 10,
    }),
    columnHelper.accessor('ftt', {
      id: 'ftt',
      header: 'FTT',
      cell: info =>
        `${info.getValue()} (${info.row.original.ftt_percentage} %)`,
      maxSize: 10,
    }),
    columnHelper.accessor('defect_count', {
      id: 'defect_count',
      header: 'Total Defects',
      cell: info => info.getValue(),
      maxSize: 10,
    }),
    columnHelper.accessor('reject_count', {
      id: 'reject_count',
      header: 'Rejected',
      cell: info => info.getValue(),
      maxSize: 10,
      meta: {
        headerStyles: {
          color: 'var(--red-dirt)',
          background: 'var(--fair-pink)',
        },
        cellStyles: {
          color: 'var(--red-dirt)',
        },
      },
    }),
    columnHelper.accessor('dhu', {
      id: 'dhu',
      header: 'DHU',
      cell: info => `${info.getValue()} %`,
      maxSize: 10,
    }),
  ];

  if (shouldShowEfficiency) {
    return [
      ...columns,
      columnHelper.accessor('efficiency', {
        id: 'efficiency',
        header: 'Efficiency',
        cell: info => `${info.getValue()} %`,
        maxSize: 10,
      }),
    ];
  }

  return columns;
}
