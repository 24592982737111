import { IconPropsType } from './types';

interface IEyeIconProps extends IconPropsType {}

const PATH_DRAWN = Object.freeze({
  'slashed-no':
    'M21.5 12.0256C18 21.5 6 21.5 2.5 12.0257C2.5 12.0257 4.5 5 12 5C19.5 5 21.5 12.0256 21.5 12.0256ZM11.9998 15.0366C10.3329 15.0365 8.98988 13.6669 9 12C9.01006 10.3432 10.343 9.0001 11.9998 9C13.6567 8.9999 14.9899 10.3431 15 12C15.0101 13.667 13.6669 15.0367 11.9998 15.0366Z',
  'slashed-yes':
    'M3.00171 3L6.59087 6.58916M21.0017 21L17.4129 17.4112M13.8766 18.8246C13.2694 18.9398 12.6428 19 12.0022 19C7.52452 19 3.73421 16.0571 2.45996 12C2.80686 10.8955 3.34022 9.87361 4.02314 8.97118M9.88039 9.87868C10.4233 9.33579 11.1733 9 12.0017 9C13.6586 9 15.0017 10.3431 15.0017 12C15.0017 12.8284 14.6659 13.5784 14.123 14.1213M9.88039 9.87868L14.123 14.1213M9.88039 9.87868L6.59087 6.58916M14.123 14.1213L6.59087 6.58916M14.123 14.1213L17.4129 17.4112M6.59087 6.58916C8.15069 5.58354 10.0083 5 12.0021 5C16.4798 5 20.2701 7.94291 21.5444 12C20.8374 14.2507 19.3562 16.1585 17.4129 17.4112',
});

const EyeIcon = ({
  stroke = 'var(--rhino)',
  size = 24,
  strokeWidth = 1.25,
  viewType = 'slashed-no',
  ...props
}: IEyeIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={PATH_DRAWN[viewType as keyof typeof PATH_DRAWN]}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EyeIcon;
