import   { ReactNode } from 'react';
import Tooltip from 'react-tooltip-lite';
import { TT_WHITE_CLASS } from '../constants';

interface ITooltipWrapperProps {
  valueToShow: ReactNode;
  content?: ReactNode;
  canShowTooltip?: boolean;
  textType?: 'main-text' | 'main-text-light' | 'sub-text';
  className?: string;
}

const TooltipWrapper = ({
  valueToShow,
  content,
  canShowTooltip = true,
  textType = 'main-text',
  className = '',
}: ITooltipWrapperProps) => {
  if (canShowTooltip) {
    return (
      <Tooltip
        content={content ?? valueToShow}
        direction="bottom-left"
        arrowSize={8}
        className={`max-width-tooltip ${TT_WHITE_CLASS} ${className}`}
      >
        <div data-text-type={textType} className="truncated-text">
          {valueToShow}
        </div>
      </Tooltip>
    );
  }

  return (
    <div data-text-type={textType} className="truncated-text">
      {valueToShow}
    </div>
  );
};

export default TooltipWrapper;
