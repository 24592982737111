import { IconPropsType } from './types';

interface ITechpackIconProps extends IconPropsType {}

const TechpackIcon = ({
  stroke = 'var(--rhino)',
  ...props
}: ITechpackIconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.3487 10.231H14.9237M14.9237 13.3268H9.3487M16.0441 18.8919C16.6497 18.8447 17.2283 18.8799 17.6849 18.9336C18.1357 18.9866 18.5848 18.7706 18.7857 18.3636L19.2899 17.3418C19.4976 16.9211 19.3806 16.4165 19.0365 16.0976C17.5087 14.6815 16.7111 13.0804 16.9244 11.3514C17.0044 10.1351 17.6446 9.16216 18.2048 8.43243C18.3874 8.24742 18.5585 8.07414 18.7179 7.91704C19.0902 7.5503 19.1864 6.95823 18.9031 6.51916L18.5333 5.94612C18.2556 5.51591 17.7019 5.37953 17.2146 5.53664C16.4061 5.7973 15.6425 5.79656 14.9237 5.43243C14.2034 5.02703 13.7233 4.37838 13.4832 3.81081C13.4451 3.73372 13.4116 3.65663 13.3815 3.58172C13.2397 3.22847 12.9221 3 12.5414 3H11.486C11.0541 3 10.6861 3.28377 10.5061 3.67635C10.4863 3.71967 10.465 3.76441 10.4421 3.81081C10.202 4.37838 9.80189 5.02703 9.00161 5.43243C8.60147 5.59459 8.20133 5.67568 7.80119 5.67568C7.41587 5.67568 7.03055 5.61694 6.62778 5.49946C6.14304 5.35807 5.60359 5.51336 5.34146 5.94493L4.76628 6.89189C4.63346 7.11057 4.7013 7.4006 4.88137 7.58235V7.58235C4.90178 7.60294 4.93231 7.62958 4.95585 7.64649C5.16968 7.80008 5.4374 8.07174 5.65583 8.4092C5.70011 8.47761 5.74976 8.5423 5.8051 8.60213C6.40972 9.25592 6.93153 10.2701 7.16097 11.4324C7.30315 13.161 6.49811 14.6976 4.97024 16.0992C4.62454 16.4163 4.50778 16.9211 4.71539 17.3418L5.21967 18.3636C5.42051 18.7706 5.86971 18.9866 6.32051 18.9343C6.78613 18.8802 7.38636 18.8445 8.04128 18.8919C9.0169 18.95 10.1571 19.2167 11.1079 19.931C11.6743 20.3566 12.5687 20.3554 13.1302 19.9233C14.0508 19.2147 15.1292 18.9498 16.0441 18.8919Z"
      stroke={stroke}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TechpackIcon;
