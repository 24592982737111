import { IconPropsType } from './types';

export enum SettingIconViewTypeEnum {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

// interface ISettingsProps {
//   viewType?: SettingIconViewTypeEnum;
// }

const SettingsIcon = ({
  viewType = SettingIconViewTypeEnum.Horizontal,
  size = 24,
}: IconPropsType) => {
  if (viewType === SettingIconViewTypeEnum.Horizontal) {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 9H1M3 9C3 10.1046 3.89543 11 5 11C6.10457 11 7 10.1046 7 9M3 9C3 7.89543 3.89543 7 5 7C6.10457 7 7 7.89543 7 9M15 15C15 13.8954 14.1046 13 13 13C11.8954 13 11 13.8954 11 15M15 15C15 16.1046 14.1046 17 13 17C11.8954 17 11 16.1046 11 15M15 15H17M11 15H1M7 9H17M15 3C15 1.89543 14.1046 1 13 1C11.8954 1 11 1.89543 11 3M15 3C15 4.10457 14.1046 5 13 5C11.8954 5 11 4.10457 11 3M15 3H17M11 3H1"
          stroke="var(--rhino)"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 3V1M9 3C7.89543 3 7 3.89543 7 5C7 6.10457 7.89543 7 9 7M9 3C10.1046 3 11 3.89543 11 5C11 6.10457 10.1046 7 9 7M3 15C4.10457 15 5 14.1046 5 13C5 11.8954 4.10457 11 3 11M3 15C1.89543 15 1 14.1046 1 13C1 11.8954 1.89543 11 3 11M3 15V17M3 11V1M9 7V17M15 15C16.1046 15 17 14.1046 17 13C17 11.8954 16.1046 11 15 11M15 15C13.8954 15 13 14.1046 13 13C13 11.8954 13.8954 11 15 11M15 15V17M15 11V1"
        stroke="var(--rhino)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsIcon;
