/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import useFakePagination from '../../hooks/useFakePagination';
import { TABLE_SECTION_CHANGE_DURATION_IN_MILLISECONDS } from '../../constants/polling';

const fillAnimation = keyframes`
  from {
    width:  0%;
  }
  to {
    width:  100%;
  }
`;

const Text = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  color: var(--lynch);
`;

const Peg = styled.div`
  height: 6px;
  width: 40px;
  border-radius: 4px;
  background: var(--fiord);

  &.filled {
    background: var(--watermelon);
  }
`;

const PegFill = styled.div`
  width: 0%;
  height: 100%;
  background: var(--watermelon);
  border-radius: 4px;
  animation: ${fillAnimation} ${TABLE_SECTION_CHANGE_DURATION_IN_MILLISECONDS}ms
    linear;
`;

interface IFakeTablePaginationProps {
  total: number;
  current: number;
}

const FakeTablePagination = ({ total, current }: IFakeTablePaginationProps) => (
  <div className="align-center gap-16">
    {total > 1 && (
      <>
        <Text>
          {current} of {total}
        </Text>
        {Array.from(Array(total).keys())
          .map(n => n + 1)
          .map(num => (
            <Peg key={num} className={num < current ? 'filled' : ''}>
              {num === current && <PegFill />}
            </Peg>
          ))}
      </>
    )}
  </div>
);

export default FakeTablePagination;

export const useFakeTablePagination = ({
  data,
  size,
}: {
  data: Array<any>;
  size: number;
}) => {
  const { page, action } = useFakePagination(data, size);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPegs = Math.ceil(data.length / size);

  useEffect(() => {
    const interval = setInterval(() => {
      if ((currentPage % size) + 1 > totalPegs) {
        setCurrentPage(1);
      } else {
        setCurrentPage(prevPage => (prevPage % size) + 1);
      }
    }, TABLE_SECTION_CHANGE_DURATION_IN_MILLISECONDS);

    return () => {
      clearInterval(interval);
    };
  }, [page]);

  useEffect(() => {
    action.goTo(currentPage);
  }, [currentPage]);

  return { page, total: totalPegs };
};
