import { z } from 'zod';

export const WorkerDepartmentsSchema = z.union([
  z.literal('master_stitching_tv'),
  z.literal('master_finishing_tv'),
  z.literal('line_stitching_tv'),
  z.literal('line_finishing_tv'),
]);

export type WorkerDepartmentType = z.infer<typeof WorkerDepartmentsSchema>;

export const FactoryTvInfoSchema = z.object({
  worker_department: WorkerDepartmentsSchema,
  line_id: z.number().optional(),
});

export type FactoryTvInfoType = z.infer<typeof FactoryTvInfoSchema>;

export const LineViewLayoutSchema = z.object({
  layout: z.union([z.literal('extended'), z.literal('standard')]),
});

export type LineViewLayoutType = z.infer<typeof LineViewLayoutSchema>;
