import { useEffect } from 'react';
import { format } from 'date-fns';
import { getQappFilters } from '../../../redux/filters/thunks';
import useAppDispatch from '../../../redux/useAppDispatch';
import { useTypedSelector } from '../../../redux/useTypedSelector';
import {
  setDateRange,
  setDepartment,
  setSession,
} from '../../../redux/filters';
import Dropdown from '../../../shared/Dropdown';
import DateRangePicker from '../../../shared/DateRangePicker';
import FilterContainer from '../../../shared/FilterContainer';
import QappTracker from '../../../analytics/tracker';
import DatePickerField from '../../../shared/DatePickerField';

const Filters = () => {
  const dispatch = useAppDispatch();

  const selectedFilters = useTypedSelector(store => store.filters.selected);

  const sessionStatusFilterOptions = useTypedSelector(store =>
    store.filters.session_options.map(option => ({
      name: option.name,
      value: option.id,
    }))
  );
  const departmentFilterOptions = useTypedSelector(store =>
    store.filters.department.map(option => ({
      name: option.name,
      value: option.id,
    }))
  );
  const { session, department } = useTypedSelector(
    store => store.filters.selected
  );

  useEffect(() => {
    dispatch(getQappFilters());
  }, []);

  return (
    <FilterContainer itemWidth={200} className="align-flex-end gap-16">
      <Dropdown
        label="Session Status"
        showAllOption
        onChange={selectedValue => {
          dispatch(setSession(selectedValue));
          QappTracker.track('ApplyFilter', {
            filter_name: 'Session Status',
            current_page: 'sessions',
            filter_value: selectedValue,
          });
        }}
        options={sessionStatusFilterOptions}
        value={session}
        placeholder="Select Status"
      />
      <Dropdown
        label="Departments"
        onChange={selectedValue => {
          dispatch(setDepartment(selectedValue));
          QappTracker.track('ApplyFilter', {
            filter_name: 'Department',
            current_page: 'sessions',
            filter_value: selectedValue,
          });
        }}
        options={departmentFilterOptions}
        value={department}
        placeholder="Select Department"
      />
      {/* <DateRangePicker
        shouldShowLabel={false}
        className="full-flex center-flex-end"
        endDate={new Date(selectedFilters.end_date)}
        startDate={new Date(selectedFilters.start_date)}
        onChangeDays={(newStartDate, newEndDate) => {
          dispatch(
            setDateRange({
              start_date: format(newStartDate ?? new Date(), 'yyyy-M-d'),
              end_date: format(newEndDate ?? new Date(), 'yyyy-M-d'),
            })
          );
          QappTracker.track('ApplyFilter', {
            filter_name: 'Date Range',
            current_page: 'sessions',
            filter_value: `start_date=${format(
              newStartDate ?? new Date(),
              'yyyy-M-d'
            )},end_date=${format(newEndDate ?? new Date(), 'yyyy-M-d')}`,
          });
        }}
      /> */}
      <DatePickerField
        label="Start Date"
        value={new Date(selectedFilters.start_date)}
        setValue={newDate => {
          dispatch(
            setDateRange({
              start_date: format(newDate ?? new Date(), 'yyyy-M-d'),
              end_date: format(
                selectedFilters.end_date
                  ? new Date(selectedFilters.end_date)
                  : new Date(),
                'yyyy-M-d'
              ),
            })
          );
          QappTracker.track('ApplyFilter', {
            filter_name: 'Date Range',
            current_page: 'sessions',
            filter_value: `start_date=${format(
              newDate ?? new Date(),
              'yyyy-M-d'
            )},end_date=${format(
              selectedFilters.end_date
                ? new Date(selectedFilters.end_date)
                : new Date(),
              'yyyy-M-d'
            )}`,
          });
        }}
      />
      <DatePickerField
        label="End Date"
        value={new Date(selectedFilters.end_date)}
        setValue={newDate => {
          dispatch(
            setDateRange({
              start_date: format(
                selectedFilters.start_date
                  ? new Date(selectedFilters.start_date)
                  : new Date(),
                'yyyy-M-d'
              ),
              end_date: format(newDate ?? new Date(), 'yyyy-M-d'),
            })
          );
          QappTracker.track('ApplyFilter', {
            filter_name: 'Date Range',
            current_page: 'sessions',
            filter_value: `start_date=${format(
              selectedFilters.start_date
                ? new Date(selectedFilters.start_date)
                : new Date(),
              'yyyy-M-d'
            )},end_date=${format(newDate ?? new Date(), 'yyyy-M-d')}`,
          });
        }}
      />
    </FilterContainer>
  );
};

export default Filters;
