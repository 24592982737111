import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid var(--athens-gray);
  border-radius: 8px;
  padding: 24px 0 0 0;
  margin: 0 24px 24px 24px;
  background: var(--white);

  h3 {
    padding: 0 24px;
  }
`;

export const InActivePill = styled.span`
  border-radius: 12px;
  padding: 2px 8px;
  background: var(--athens-gray);
  font-size: 12px;
  font-weight: 600;
  margin: 0 8px;
  color: var(--rhino);
`;

export const LineViewItem = styled.div`
  padding: 16px 24px;

  .select-search-container {
    max-width: 150px;
    height: 36px;
    font-size: 12px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--athens-gray, #e8eaef);
  }

  h5 {
    font-size: 14px;
    font-weight: 600;
    color: var(--rhino);
  }
`;
