import styled from 'styled-components';

export const Name = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: var(--white);
`;

export const Quantity = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
`;
