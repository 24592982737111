import { styled } from 'styled-components';
import EmptyBarChart from '../Illustrations/EmptyBarChart';

const Wrapper = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  p {
    font-size: 18px;
    font-weight: 400;
    color: var(--manatee);
  }
`;

const EmptyView = () => (
  <Wrapper className="gap-8 v-center pos-a">
    <EmptyBarChart />
    <p>No Data Found</p>
  </Wrapper>
);

export default EmptyView;
