/* eslint-disable no-nested-ternary */
import { Spinner } from '@fashinza/fashinza-design-system';
import TvHeader from '../../../shared/TvHeader';
import {
  useGetLineViewLayoutQuery,
  useGetOverAllDataQuery,
} from '../../../redux/idiotBox/apis/lineView';
import { POLLING_INTERVAL_IN_MILLISECONDS } from '../../../constants/polling';
import Content from './Content';
import NoStyles from './NoStyles';

const LineView = () => {
  const {
    data: overallData,
    isLoading,
    isFetching,
  } = useGetOverAllDataQuery(undefined, {
    pollingInterval: POLLING_INTERVAL_IN_MILLISECONDS,
  });

  const { data: layoutInfo } = useGetLineViewLayoutQuery(undefined, {
    pollingInterval: 4 * POLLING_INTERVAL_IN_MILLISECONDS,
  });

  const pages = layoutInfo?.layout === 'extended' ? 5 : 4;

  return (
    <>
      <TvHeader
        currentHour={overallData?.current_hour ?? 0}
        indicators={[
          {
            value: overallData?.line_name,
            shouldStretch: true,
          },
          {
            attribute: 'Buyer',
            value: overallData?.sku_m?.buyer_name,
            shouldStretch: true,
          },
          {
            attribute: 'Styles',
            value: overallData?.sku_m?.style_name,
            shouldStretch: true,
          },
        ]}
      />

      {isLoading ? (
        <div className="full-dimension center">
          <Spinner stroke="var(--white)" height={100} width={100} />
        </div>
      ) : overallData?.sku_m ? (
        <Content pages={pages} isLoading={isFetching} data={overallData} />
      ) : (
        <NoStyles />
      )}
    </>
  );
};

export default LineView;
