import { DependencyList, RefObject, useEffect, useState } from 'react';

const useDimensions = (
  ref: RefObject<HTMLElement>,
  deps: DependencyList = []
) => {
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    if (ref.current) {
      setDimensions({
        height: ref.current.offsetHeight,
        width: ref.current.offsetWidth,
      });
    }
  }, [ref, ...deps]);

  return dimensions;
};

export default useDimensions;
