import { IconPropsType } from './types';

interface IHalfFilledCircleIconProps extends IconPropsType {}

const HalfFilledCircleIcon = ({
  size = 24,
  ...props
}: IHalfFilledCircleIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="6.33328" cy="6" r="6" fill="var(--athens-gray)" />
    <path
      d="M6.32996 6C6.32996 9.31371 6.32996 12 6.32996 12C3.01625 12 0.329956 9.31371 0.329956 6C0.329956 2.68629 3.01625 0 6.32996 0C6.32996 0 6.32996 2.68629 6.32996 6Z"
      fill="var(--all-ports)"
    />
  </svg>
);

export default HalfFilledCircleIcon;
