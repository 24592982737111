import { createApi } from '@reduxjs/toolkit/query/react';
import { overrideFetchBaseQuery } from '../utils';
import { convertArrayToObject } from '../../utils/arrays';
import { getFormattedFilters } from '../dashboard/utils';
import type { SimpleApiResponse } from '../../@types/api';
import type { InspectionsType } from '../../components/Sessions/Ordinary/OrdinaryDetails/Inspections/schemas';
import type { IFiltersState } from '../filters';
import type {
  SessionType,
  SessionsType,
} from '../../components/Sessions/Ordinary/schemas';
import type { ActivityInfoType } from '../../components/Sessions/Ordinary/ActivityInfo/schemas';
import type { SessionDetailsType } from '../../components/Sessions/Ordinary/OrdinaryDetails/schemas';

type PaginatedResponse<T> = {
  count: number;
  next: string;
  previous: string | null;
  results: Array<T>;
  total_pages: number;
};

export const sessionsApi = createApi({
  reducerPath: 'sessions',
  baseQuery: overrideFetchBaseQuery({
    urlSuffix: 'checker-session',
  }),
  endpoints: builder => ({
    getSessions: builder.query<
      {
        ids: Array<SessionType['id']>;
        data: Record<string, SessionType>;
      },
      Partial<IFiltersState['selected']>
    >({
      query: filters => `/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<SessionsType>) => {
        const ids = response.data.map(item => item.id);
        const data = convertArrayToObject(response.data, 'id');
        return {
          ids,
          data,
        };
      },
    }),
    getActivityInfo: builder.query<ActivityInfoType, void>({
      query: () => `basic/`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<ActivityInfoType>) =>
        response.data,
    }),
    getInspectionBySessionId: builder.query<InspectionsType, number>({
      query: sessionId => `${sessionId}/inspection/`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<InspectionsType>) =>
        response.data,
    }),
    getDetailsBySessionId: builder.query<SessionDetailsType, number>({
      query: sessionId => `${sessionId}/`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<SessionDetailsType>) =>
        response.data,
    }),
  }),
});

export const {
  useGetSessionsQuery,
  useGetInspectionBySessionIdQuery,
  useGetActivityInfoQuery,
  useGetDetailsBySessionIdQuery,
} = sessionsApi;

export const { resetApiState: resetSessionsState } = sessionsApi.util;
