import Finishing from './Finishing';
import Stitching from './Stitching';
import Grid from '../../shared/Grid';
import GridItem from '../../shared/Grid/GridItem';
import { useGetFactoryTvListQuery } from '../../redux/idiotBox/api';

const DataViewer = () => {
  const { data, isLoading } = useGetFactoryTvListQuery();

  const stitchingLines =
    data?.line_list.filter(item => item.worker_department === 'stitching') ??
    [];
  const finishingLines =
    data?.line_list.filter(item => item.worker_department === 'finishing') ??
    [];

  return (
    <Grid
      minHeight="40vh"
      gridTemplateColumns="1fr 1fr"
      gridTemplateRows="1fr"
      gap={24}
    >
      <GridItem gridColumn="1/2" gridRow="1/2">
        <Stitching
          isLoading={isLoading}
          lines={stitchingLines}
          masterLines={data?.master_tv_list.filter(
            item => item.worker_department === 'master_stitching_tv'
          )}
        />
      </GridItem>
      <GridItem gridColumn="2/3" gridRow="1/2">
        <Finishing
          isLoading={isLoading}
          lines={finishingLines}
          masterLines={data?.master_tv_list.filter(
            item => item.worker_department === 'master_finishing_tv'
          )}
        />
      </GridItem>
    </Grid>
  );
};

export default DataViewer;
