import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithZodValidation, overrideFetchBaseQuery } from '../utils';
import { convertArrayToObject } from '../../utils/arrays';
import { AuditOverallAnalyticsSchema } from '../../components/Dashboard/Audit/OverallAnalytics/schemas';
import { StyleWiseSummaryDataSchema } from '../../components/Dashboard/Audit/StyleWiseSummary/schemas';
import { LotWiseSummariesSchema } from '../../components/Dashboard/Audit/LotWiseSummary/schemas';
import { AreasOfConcernSchema } from '../../components/Dashboard/Audit/AreasOfConcern/schemas';
import { DefectsSchema } from '../../components/Dashboard/Audit/Defects/schemas';
import type { AuditOverallAnalyticsType } from '../../components/Dashboard/Audit/OverallAnalytics/schemas';
import type { SimpleApiResponse } from '../../@types/api';
import type { IFiltersState } from '../filters';
import type { StyleWiseSummaryDataType } from '../../components/Dashboard/Audit/StyleWiseSummary/schemas';
import type {
  LotWiseSummariesDataType,
  LotWiseSummaryDataType,
} from '../../components/Dashboard/Audit/LotWiseSummary/schemas';
import type { AreasOfConcernType } from '../../components/Dashboard/Audit/AreasOfConcern/schemas';
import type { DefectsType } from '../../components/Dashboard/Audit/Defects/schemas';
import { getFormattedFilters } from './utils';
import {
  AuditDefectsAnalyticsSchema,
  AuditDefectsAnalyticsType,
} from '../../components/Dashboard/Audit/DefectsAnalytics/schemas';

export const auditDashboardApi = createApi({
  reducerPath: 'auditDashboard',
  baseQuery: baseQueryWithZodValidation(
    overrideFetchBaseQuery({
      urlSuffix: '/dashboard',
      base: 'audit',
    })
  ),
  endpoints: builder => ({
    getAuditOverallAnalytics: builder.query<
      AuditOverallAnalyticsType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => ({
        url: `basic-analytics/?${getFormattedFilters(filters)}`,
      }),
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: AuditOverallAnalyticsSchema,
        // mock: true,
      },
      transformResponse: (
        response: SimpleApiResponse<AuditOverallAnalyticsType>
      ) => response.data,
    }),
    getAuditDefectsAnalytics: builder.query<
      AuditDefectsAnalyticsType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => ({
        url: `defects-data/?${getFormattedFilters(filters)}`,
      }),
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: AuditDefectsAnalyticsSchema,
        // mock: true,
      },
      transformResponse: (
        response: SimpleApiResponse<AuditDefectsAnalyticsType>
      ) => response.data,
    }),
    getStyleWiseAnalytics: builder.query<
      StyleWiseSummaryDataType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `style-wise-analytics/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: StyleWiseSummaryDataSchema,
        // mock: true,
      },
      transformResponse: (
        response: SimpleApiResponse<StyleWiseSummaryDataType>
      ) => response.data,
    }),
    getLotWiseAnalytics: builder.query<
      {
        ids: Array<LotWiseSummaryDataType['audit_sku_id']>;
        data: Record<string, LotWiseSummaryDataType>;
      },
      Partial<IFiltersState['selected']>
    >({
      query: filters => `lot-wise-analytics/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: LotWiseSummariesSchema,
        // mock: true,
      },
      transformResponse: (
        response: SimpleApiResponse<LotWiseSummariesDataType>
      ) => {
        const ids = response.data.map(item => item.id);
        const data = convertArrayToObject(response.data, 'id');
        return {
          ids,
          data,
        };
      },
    }),
    getDashboardDefects: builder.query<
      DefectsType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `top-defect/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: DefectsSchema,
        // mock: true,
      },
      transformResponse: (response: SimpleApiResponse<DefectsType>) =>
        response.data,
    }),
    getDashboardAreaOfConcern: builder.query<
      AreasOfConcernType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `top-defect-section/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: AreasOfConcernSchema,
        // mock: true,
      },
      transformResponse: (response: SimpleApiResponse<AreasOfConcernType>) =>
        response.data,
    }),
  }),
});

export const {
  useGetAuditOverallAnalyticsQuery,
  useGetAuditDefectsAnalyticsQuery,
  useGetStyleWiseAnalyticsQuery,
  useGetLotWiseAnalyticsQuery,
  useGetDashboardAreaOfConcernQuery,
  useGetDashboardDefectsQuery,
} = auditDashboardApi;

export const { resetApiState: resetAuditDashboardState } =
  auditDashboardApi.util;
