import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: 10vh;
  padding: 24px 24px 12px 24px;
  /* border: 1px solid white; */
`;

interface IIndicatorWrapperProps {
  $shouldStretch?: boolean;
}

const IndicatorWrapper = styled.div<IIndicatorWrapperProps>`
  border-radius: 8px;
  border: 1px solid var(--fiord);
  background: var(--port-gore);
  padding: 4px 24px;

  ${props =>
    props.$shouldStretch &&
    css`
      flex: 1;
    `}
`;

const IndicatorAttribute = styled.p`
  font-size: 14px;
  color: var(--manatee);
  font-weight: 700;
`;

const IndicatorValue = styled.p`
  font-size: 20px;
  color: var(--white);
  font-weight: 700;
`;

export const Indicator = {
  Wrapper: IndicatorWrapper,
  Attribute: IndicatorAttribute,
  Value: IndicatorValue,
};
