import { useMemo } from 'react';
import Segment from '../../../../shared/Segment';
import Table from '../../../../shared/Table';
import { useGetHourlyCountsQuery } from '../../../../redux/dashboard/api';
import useFakePagination from '../../../../hooks/useFakePagination';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import useFeatureFlags from '../../../../redux/featureFlags/useFeatureFlags';
import { getColumns } from './columns';

const HourlyCounts = () => {
  const { department, start_date, end_date } = useTypedSelector(
    store => store.filters.selected
  );
  const { data, isLoading, isFetching } = useGetHourlyCountsQuery({
    department,
    start_date,
    end_date,
  });
  const { page, action } = useFakePagination(data, 5);

  const { should_show_efficiency } = useFeatureFlags();

  const columns = useMemo(
    () =>
      getColumns({
        shouldShowEfficiency: should_show_efficiency,
      }),
    [should_show_efficiency]
  );

  return (
    <Segment
      heading="Hourly Counts"
      description="This compares the performance of all your lines"
      descriptionMaxWidth={180}
      shouldChildrenHavePadding={false}
      isLoading={isLoading || isFetching}
    >
      <Table
        data={page.data ?? []}
        columns={columns}
        currentPage={page.current ?? 0}
        handlePagination={c =>
          c === 'next' ? action.next() : action.previous()
        }
        pageLength={5}
        totalCount={data?.length ?? 0}
      />
    </Segment>
  );
};

export default HourlyCounts;
