import { RestMethodsEnum } from '../../@types/api';
import { callAdminApi, callQappApi } from '../../services/api';

export const getDashboardFiltersApi = () =>
  callQappApi(RestMethodsEnum.GET, `/dashboard/filter/`);

export const getBrandsListApi = (search = '') =>
  callAdminApi(RestMethodsEnum.GET, `/brand/supplier-specific/?${search}`);

export const getOrdersListApi = () =>
  callAdminApi(RestMethodsEnum.GET, `/oms/purchase_order/list-qapp/?type=all`);

export const getStylesListApi = (orderId: number | string) =>
  callAdminApi(
    RestMethodsEnum.GET,
    `/oms/purchase_order/${orderId}/products-qapp/`
  );
