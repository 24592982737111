/* eslint-disable no-nested-ternary */
import { Fragment, useRef } from 'react';
import PieChart from '../../../../shared/PieChart';
import { IPieChartData } from '../../../../shared/PieChart/types';
import Segment from '../../../../shared/Segment';
import { Label, Dot } from '../../../../shared/PieChart/styles';
import useDimensions from '../../../../hooks/useDimensions';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import TinyMetric from '../../../../shared/TinyMetric';
import { useGetAuditDefectsAnalyticsQuery } from '../../../../redux/auditDashboard/api';
import * as Styles from '../../Ordinary/OverallAnalytics/styles';
import Grid from '../../../../shared/Grid';
import GridItem from '../../../../shared/Grid/GridItem';

const DefectsAnalytics = () => {
  const pieChartWrapperRef = useRef<HTMLDivElement>(null);

  const { department, start_date, end_date } = useTypedSelector(
    store => store.filters.selected
  );
  const { data, isLoading, isFetching, error } =
    useGetAuditDefectsAnalyticsQuery({
      department,
      start_date,
      end_date,
    });

  const viewMode = useTypedSelector(store => store.ui.viewMode);

  const pieChartWrapperDimensions = useDimensions(pieChartWrapperRef, [
    isLoading,
  ]);

  const chartData: Array<IPieChartData> = [
    {
      color: 'var(--milano-red)',
      name: 'Critical',
      subTexts: [`${data?.critical_defect_count} Critical`],
      value: data?.critical_defect_count ?? 0,
    },
    {
      color: 'var(--clementine)',
      name: 'Major',
      subTexts: [`${data?.major_defect_count} Major`],
      value: data?.major_defect_count ?? 0,
    },
    {
      color: 'var(--web-orange)',
      name: 'Minor',
      subTexts: [`${data?.minor_defect_count} Minor`],
      value: data?.minor_defect_count ?? 0,
    },
  ];

  return (
    <Segment
      heading="Defects"
      // description="Summary of the production floor"
      descriptionMaxWidth={180}
      isLoading={isLoading}
      headerContent={
        <div className="center">
          {chartData.map(eachEntry => (
            <Fragment key={eachEntry.name}>
              <Dot fill={eachEntry.color} />
              <Label>{eachEntry.name}</Label>
            </Fragment>
          ))}
        </div>
      }
    >
      <div className="d-flex full-flex">
        <Styles.PieChartWrapper className="full-flex" ref={pieChartWrapperRef}>
          <PieChart
            config={{
              chartWidth: pieChartWrapperDimensions.width,
              chartHeight: pieChartWrapperDimensions.height,
              chartOuterRadius: 90,
            }}
            shouldShowLabels={false}
            data={chartData.map(eachData => ({
              ...eachData,
            }))}
          />
        </Styles.PieChartWrapper>

        <Grid
          minHeight="auto"
          gridTemplateColumns="repeat(3, 1fr)"
          gridTemplateRows="1fr 1fr"
        >
          <GridItem gridColumn="1/4" gridRow="1/2">
            <TinyMetric
              color="var(--text-color)"
              heading="Total defects found"
              isLoading={isFetching}
              value={data?.defect_count}
              viewMode={viewMode}
              className="v-space-around-center"
            />
          </GridItem>
          <GridItem gridColumn="1/2" gridRow="2/3">
            <TinyMetric
              color="var(--milano-red)"
              heading="Critical"
              isLoading={isFetching}
              value={data?.critical_defect_count}
              viewMode={viewMode}
              className="v-space-around-center"
            />
          </GridItem>
          <GridItem gridColumn="2/3" gridRow="2/3">
            <TinyMetric
              color="var(--clementine)"
              heading="Major"
              isLoading={isFetching}
              value={data?.major_defect_count}
              viewMode={viewMode}
              className="v-space-around-center"
            />
          </GridItem>
          <GridItem gridColumn="3/4" gridRow="2/3">
            <TinyMetric
              color="var(--web-orange)"
              heading="Minor"
              isLoading={isFetching}
              value={data?.minor_defect_count}
              viewMode={viewMode}
              className="v-space-around-center"
            />
          </GridItem>
        </Grid>
      </div>
    </Segment>
  );
};

export default DefectsAnalytics;
