import { Button } from '@fashinza/fashinza-design-system';
import { format } from 'date-fns';
import { removeUnderscoresAndCapitalize } from '../../../../utils';
import { DEFAULT_VALUES } from '../../../../constants';
import TooltipWrapper from '../../../../shared/VirtualizedTable/EditableCell/TooltipWrapper';
import { LotWiseSummaryDataType } from './schemas';
import OrderItem from '../../../../shared/OrderItem';
import * as Styles from './styles';

interface ICellContentProps {
  data: LotWiseSummaryDataType;
  identifier: keyof LotWiseSummaryDataType;
  onClickViewDetails?: (sessionId: number) => void;
}

const CellContent = ({
  data,
  identifier,
  onClickViewDetails,
}: ICellContentProps) => {
  switch (identifier) {
    case 'id': {
      return (
        <Styles.CellData className="d-flex full-height">
          <div className="v-d-flex">
            <Styles.BoldBlueText className="full-width">
              {data.id}
            </Styles.BoldBlueText>
            <Styles.SubText>
              {format(new Date(data.created_on), 'dd MMM yyyy')}
            </Styles.SubText>
          </div>
          <div className="end-align-center full-flex">
            <Button
              onClick={() => onClickViewDetails?.(data.id)}
              variant="outline"
              colorScheme="neutral"
              sizeVariant="small"
            >
              View Details
            </Button>
          </div>
        </Styles.CellData>
      );
    }

    case 'auditor_name': {
      return (
        <Styles.CellData className="full-height align-center">
          <TooltipWrapper
            valueToShow={data.auditor_name ?? DEFAULT_VALUES.NOT_APPLICABLE}
            textType="main-text"
          />
        </Styles.CellData>
      );
    }

    case 'inspection_pass_count': {
      return (
        <Styles.CellData className="full-height v-justify-center">
          <Styles.Text>{data.inspection_pass_count}</Styles.Text>
        </Styles.CellData>
      );
    }

    case 'status': {
      return (
        <Styles.CellData className="full-height align-center">
          <Styles.Text>
            {removeUnderscoresAndCapitalize(data.status)}
          </Styles.Text>
        </Styles.CellData>
      );
    }

    case 'inspection_count': {
      return (
        <Styles.CellData className="full-height align-center">
          <TooltipWrapper
            valueToShow={<Styles.Text>{data.inspection_count}</Styles.Text>}
            textType="main-text"
          />
        </Styles.CellData>
      );
    }

    case 'defect_count': {
      return (
        <Styles.CellData className="full-height align-center">
          <TooltipWrapper
            valueToShow={<Styles.Text>{data.defect_count}</Styles.Text>}
            textType="main-text"
          />
        </Styles.CellData>
      );
    }

    case 'order': {
      return (
        <Styles.CellData className=" full-height align-center">
          <OrderItem
            name={data.order.collection_name}
            subText={data.order.buyer_name ?? DEFAULT_VALUES.DOUBLE_DASH}
            isBold={false}
          />
        </Styles.CellData>
      );
    }

    case 'style': {
      return (
        <Styles.CellData className="gap-8 full-height align-center">
          <OrderItem
            image={data.style.style_image}
            name={data.style.style_name}
            subText={data.style.style_color}
          />
        </Styles.CellData>
      );
    }

    case 'line_id': {
      return (
        <Styles.CellData className="v-justify-center gap-8 full-height">
          <Styles.Text>{data.line_id}</Styles.Text>
          <Styles.SubText>{data.line_name}</Styles.SubText>
        </Styles.CellData>
      );
    }

    default: {
      if (
        data[identifier as keyof typeof data] &&
        typeof data[identifier as keyof typeof data] === 'object'
      ) {
        throw new Error(`[Sessions Table]: Unknown column ${identifier}`);
      }

      return (
        <Styles.CellData className="full-height align-center">
          <TooltipWrapper
            valueToShow={data[identifier] ?? DEFAULT_VALUES.DOUBLE_DASH}
            textType="main-text"
          />
        </Styles.CellData>
      );
    }
  }
};

export default CellContent;
