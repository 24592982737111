import { DEFAULT_VALUES } from '../../../../constants';
import { useGetActivityInfoQuery } from '../../../../redux/sessions/api';
import Grid from '../../../../shared/Grid';
import GridItem from '../../../../shared/Grid/GridItem';
import Metric from '../../../../shared/Metric';

const ActivityInfo = () => {
  const { data, isFetching } = useGetActivityInfoQuery();

  return (
    <Grid
      minHeight="10vh"
      gridTemplateColumns="1fr 1fr"
      gridTemplateRows="1fr"
      gap={24}
    >
      <GridItem gridColumn="1/2" gridRow="1/2">
        <Metric
          heading="Active Stitching Sessions"
          value={
            data?.active_stitching_session_count ?? DEFAULT_VALUES.DOUBLE_DASH
          }
          isLoading={isFetching}
          valueColor="var(--rhino)"
          hasBottomBorder
          hasLeftBorder
          hasRightBorder
          hasTopBorder
          hasBorderRadius
          hasBackground
        />
      </GridItem>
      <GridItem gridColumn="2/3" gridRow="1/2">
        <Metric
          heading="Active Finishing Sessions"
          value={
            data?.active_finishing_session_count ?? DEFAULT_VALUES.DOUBLE_DASH
          }
          isLoading={isFetching}
          hasBottomBorder
          hasLeftBorder
          hasRightBorder
          hasTopBorder
          hasBorderRadius
          hasBackground
          valueColor="var(--rhino)"
        />
      </GridItem>
    </Grid>
  );
};

export default ActivityInfo;
