import { useRef } from 'react';
import useDimensions from '../../../../hooks/useDimensions';
import BarChart from '../../../../shared/BarChart';
import Segment from '../../../../shared/Segment';
import ChartWrapper from '../../../../shared/ChartWrapper';
import { useGetLineDhuQuery } from '../../../../redux/reports/api';
import { useTypedSelector } from '../../../../redux/useTypedSelector';

const LineDHU = () => {
  const areaChartWrapperRef = useRef<HTMLDivElement>(null);
  const { department, brand, order, line, style, start_date, end_date } =
    useTypedSelector(store => store.filters.selected);
  const lineOptions = useTypedSelector(store =>
    store.filters.line_options
      .filter(item => item.department === department)
      .map(item => item.id)
      .join(',')
  );

  const { data, isLoading, isFetching } = useGetLineDhuQuery({
    department,
    brand,
    order,
    line: line || lineOptions,
    style,
    start_date,
    end_date,
  });
  const { height, width } = useDimensions(areaChartWrapperRef, [isLoading]);

  return (
    <Segment
      heading="Line DHU"
      description="Factory performance Line-wise (DHU)"
      descriptionMaxWidth={180}
      isLoading={isLoading || isFetching}
    >
      <ChartWrapper wrapperRef={areaChartWrapperRef}>
        <BarChart
          data={data ?? []}
          height={height}
          width={width}
          yLabel="DHU"
        />
      </ChartWrapper>
    </Segment>
  );
};

export default LineDHU;
