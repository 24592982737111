import { useRef } from 'react';
import ChartWrapper from '../../../../shared/ChartWrapper';
import VerticalBarChart from '../../../../shared/VerticalBarChart';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import Segment from '../../../../shared/Segment';
import { PaginationWrapper } from '../../../../shared/Table/styles';
import ManualPagination from '../../../../shared/ManualPagination';
import useFakePagination from '../../../../hooks/useFakePagination';
import { useGetDashboardDefectsQuery } from '../../../../redux/auditDashboard/api';

const Defects = () => {
  const chartWrapperRef = useRef<HTMLDivElement>(null);
  const { department, brand, order, style, start_date, end_date, line } =
    useTypedSelector(store => store.filters.selected);
  const lineOptions = useTypedSelector(store =>
    store.filters.line_options
      .filter(item => item.department === department)
      .map(item => item.id)
      .join(',')
  );
  const { data, isLoading, isFetching } = useGetDashboardDefectsQuery({
    department,
    brand,
    order,
    line: line || lineOptions,
    style,
    start_date,
    end_date,
  });
  const { page, action } = useFakePagination(data, 10);

  return (
    <Segment
      heading="Defects"
      description="All defects and their counts"
      descriptionMaxWidth={300}
      shouldChildrenHavePadding={false}
      isLoading={isLoading || isFetching}
    >
      <ChartWrapper wrapperRef={chartWrapperRef}>
        <VerticalBarChart
          barFill="var(--cerise)"
          data={
            page.data?.map(item => ({
              name: item.name,
              value: item.count,
            })) ?? []
          }
          hasBackground={false}
        />
      </ChartWrapper>
      <PaginationWrapper className="flex-justify-end">
        <ManualPagination
          kind="manual"
          totalCount={data?.length ?? 0}
          pageLength={10}
          currentPage={page.current ?? 0}
          onChangePage={c => (c === 'next' ? action.next() : action.previous())}
        />
      </PaginationWrapper>
    </Segment>
  );
};

export default Defects;
