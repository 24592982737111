import { z } from 'zod';

export const AuditReportsOverallDataSchema = z.object({
  inspection_count: z.number(),
  inspection_pass_count: z.number(),
  inspection_ftt_count: z.number(),
  inspection_not_ftt: z.number(),
  defective_piece_count: z.number(),
  defect_count: z.number(),
  dhu: z.number(),
});

export type AuditReportsOverallDataType = z.infer<
  typeof AuditReportsOverallDataSchema
>;
