import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  overflow: auto;
  width: 100vw;
`;

export const BodyWrapper = styled.div`
  height: 100%;
  width: 95vw;
  opacity: 1;
  background: var(--white);
  z-index: 12;
  box-shadow: 0px 32px 64px -12px rgba(12, 16, 33, 0.5);
  overflow: auto;
`;

export const Header = styled.header`
  box-shadow: 0px 4px 12px 0px rgba(44, 53, 86, 0.05);
  border-bottom: 1px solid var(--athens-gray);
  padding: 14px 24px;
  position: sticky;
  top: 0;
  background: var(--white);
  z-index: 12;
`;

export const Heading = styled.h2`
  font-size: 24px;
  font-family: Lora;
  font-weight: 600;
  color: var(--rhino);
`;

export const SubHeading = styled.h5`
  font-size: 12px;
  font-weight: 400;
  color: var(--rhino);
`;

export const SubHeadingSeparator = styled.span`
  color: var(--mischka);
`;

interface IBodyProps {
  $shouldHavePadding: boolean;
}

export const Body = styled.main<IBodyProps>`
  padding: ${({ $shouldHavePadding }) => ($shouldHavePadding ? '24px' : 0)};
  background: var(--white);
  overflow: auto;
`;
