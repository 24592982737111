import { useRef } from 'react';
import useDimensions from '../../../../../../hooks/useDimensions';
import AreaChart from '../../../../../../shared/AreaChart';
import Segment from '../../../../../../shared/Segment';
import ChartWrapper from '../../../../../../shared/ChartWrapper';
import { useGetHourlyDhuByLineIdQuery } from '../../../../../../redux/dashboard/api';

interface IHourlyDHUProps {
  lineId: number;
}

const HourlyDHU = ({ lineId }: IHourlyDHUProps) => {
  const areaChartWrapperRef = useRef<HTMLDivElement>(null);
  const { data, isLoading, isFetching } = useGetHourlyDhuByLineIdQuery(lineId);
  const { height, width } = useDimensions(areaChartWrapperRef, [isLoading]);

  return (
    <Segment
      heading="Hourly DHU"
      description="Defects per hundred unit(DHU) for each line"
      descriptionMaxWidth={180}
      isLoading={isLoading || isFetching}
    >
      <ChartWrapper wrapperRef={areaChartWrapperRef}>
        <AreaChart
          data={data ?? []}
          height={height}
          width={width}
          xAxisDataKey="hour"
          xAxisLabel="Hours"
          yAxisLabel="DHU"
          axisStroke="var(--fiord)"
          areaData={[
            {
              dataKey: 'dhu',
              stroke: 'var(--seance)',
              strokeWidth: 2,
              gradientOpacity: 0.25,
            },
          ]}
        />
      </ChartWrapper>
    </Segment>
  );
};

export default HourlyDHU;
