import Grid from '../../../shared/Grid';
import GridItem from '../../../shared/Grid/GridItem';
import Section from '../../../shared/Section';
import AreasOfConcern from './AreasOfConcern';
import AuditSummaryByChecker from './AuditSummaryByChecker';
import AuditSummaryByStyle from './AuditSummaryByStyle';
import Defects from './Defects';
import DefectsSummary from './DefectsSummary';
import OverallData from './OverallData';

const AuditReports = () => (
  <>
    <Grid
      minHeight="20vh"
      gridTemplateColumns="1fr"
      gridTemplateRows="1fr"
      gap={24}
    >
      <GridItem gridColumn="1/2" gridRow="1/2">
        <OverallData />
      </GridItem>
    </Grid>

    <Section heading="Audit Summary">
      <Grid
        minHeight="40vh"
        gridTemplateColumns="1fr"
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <AuditSummaryByStyle />
        </GridItem>
      </Grid>

      <Grid
        minHeight="40vh"
        gridTemplateColumns="1fr"
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <AuditSummaryByChecker />
        </GridItem>
      </Grid>
    </Section>

    <Section heading="Defect Analysis">
      <Grid
        minHeight="40vh"
        gridTemplateColumns="1fr"
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <DefectsSummary />
        </GridItem>
      </Grid>

      <Grid
        minHeight="60vh"
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="1fr"
        gap={24}
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <Defects />
        </GridItem>
        <GridItem gridColumn="2/3" gridRow="1/2">
          <AreasOfConcern />
        </GridItem>
      </Grid>
    </Section>
  </>
);

export default AuditReports;
