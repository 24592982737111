import { BsInfoCircle } from 'react-icons/bs';
import * as Styles from './styles';

export interface IInfoTooltipProps {
  heading: string;
  maxWidth?: number;
  subHeading?: string;
  iconSize?: number;
  iconFill?: string;
}

const InfoTooltip = ({
  maxWidth,
  heading,
  subHeading,
  iconSize = 16,
  iconFill = 'var(--rhino)',
}: IInfoTooltipProps) => (
  <Styles.StyledTooltip
    maxWidth={maxWidth}
    content={
      <>
        <Styles.Heading>{heading}</Styles.Heading>
        {subHeading && <Styles.SubHeading>{subHeading}</Styles.SubHeading>}
      </>
    }
    arrowSize={8}
  >
    <div className="center cursor-pointer">
      <BsInfoCircle size={iconSize} fill={iconFill} />
    </div>
  </Styles.StyledTooltip>
);

export default InfoTooltip;
