import { trackAction } from './index';

const QappActions = {
  Shown: 'shown',
  TabChange: 'tab_change',
  ApplyFilter: 'apply_filter',
  ClearFilter: 'clear_filter',
  OpenTvDashboard: 'open_tv_dashboard',
};

interface ITrackingInfo {
  tab_name?: string;
  current_page?: string;
  filter_name?: string;
  filter_value?: string | number | any;
  department?: string;
  view?: 'master' | 'line';
  line_id?: number;
}

class QappTracker {
  constructor() {
    throw new Error('QappTracker is a static class');
  }

  static track(
    action: keyof typeof QappActions,
    trackingInfo: ITrackingInfo = {}
  ) {
    trackAction('qapp', {
      action: QappActions[action],
      ...trackingInfo,
    });
  }
}

export default QappTracker;
