import Cookies from 'js-cookie';
import { ENV } from '../env';

export function getTokenCookieKey() {
  if (Cookies.get(ENV.TRAKTEZ_TOKEN)) {
    return ENV.TRAKTEZ_TOKEN;
  }

  return ENV.TOKEN;
}
