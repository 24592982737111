import { IconPropsType } from './types';

interface IHelpIconProps extends IconPropsType {}

const HelpIcon = ({
  stroke = 'var(--rhino)',
  size = 24,
  strokeWidth = 1.25,
  ...props
}: IHelpIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.22766 7.5C6.77678 6.33481 8.25843 5.5 10.0001 5.5C12.2092 5.5 14.0001 6.84315 14.0001 8.5C14.0001 9.89944 12.7224 11.0751 10.9943 11.4066C10.4519 11.5106 10.0001 11.9477 10.0001 12.5M10 15.5H10.01M19 10.5C19 15.4706 14.9706 19.5 10 19.5C5.02944 19.5 1 15.4706 1 10.5C1 5.52944 5.02944 1.5 10 1.5C14.9706 1.5 19 5.52944 19 10.5Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HelpIcon;
