import { css } from 'styled-components';

export const FOCUS_STYLES = css`
  border-color: var(--all-ports);
  box-shadow: 0px 0px 0px 4px var(--french-pass);
  outline: none;
`;

export const SEARCH_STYLES = css`
  font-weight: 600;
  font-style: normal;
  color: var(--all-ports);
  background-color: var(--butter-milk);
`;
