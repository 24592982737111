import { IconPropsType } from './types';

interface IFinishingIconProps extends IconPropsType {}

const FinishingIcon = ({
  size = 24,
  stroke = 'var(--rhino)',
  strokeWidth = 1.25,
  viewType = 'default',
  ...props
}: IFinishingIconProps) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={40} height={40} rx={20} fill="#EAE6FF" />
    <path
      d="M13.8204 25V29M13.8204 25H26.3063M13.8204 25H11M26.3063 25V29M26.3063 25H29M23.5 19.5144V15.9979C23.5 15.5636 23.9333 15.2625 24.3404 15.4141C24.6664 15.5355 25.0286 15.3664 25.1449 15.0386L25.6917 13.4981C25.8659 13.0073 25.6341 12.4649 25.159 12.2515L22.9962 11.2798C22.6206 11.1111 22.1848 11.1957 21.8703 11.4616C21.8486 11.4799 21.827 11.498 21.8055 11.5157C20.5823 12.5244 19.6797 12.5202 18.3733 11.5157C18.3322 11.4841 18.2907 11.4515 18.2488 11.4179C17.9431 11.173 17.5293 11.1 17.1698 11.2558L14.1665 12.5571C14.0738 12.5973 14.0298 12.7039 14.0673 12.7978L14.9896 15.108C15.1055 15.3982 15.4358 15.5381 15.7248 15.4194C16.0945 15.2676 16.5 15.5395 16.5 15.9392V19.5144C16.5 20.2572 16.4706 21 17.5294 21H22.4706C23.6 21 23.5 20.2572 23.5 19.5144Z"
      stroke="#5243AA"
      strokeWidth={1.25}
      strokeLinecap="round"
    />
  </svg>
);

export default FinishingIcon;
