/* eslint-disable no-nested-ternary */
import { useTypedSelector } from '../../redux/useTypedSelector';
import EmptyChartView from '../EmptyChartView';
import ProgressBar from '../ProgressBar';
import * as Styles from './styles';

interface IVerticalBarChartProps {
  data: Array<any>;
  barFill: 'var(--astro-purple)' | 'var(--cerise)' | 'var(--web-orange)';
  barDataKey?: string;
  axisDataKey?: string;
  barSize?: number;
  hasBackground?: boolean;
}

const VerticalBarChart = ({
  data,
  barFill,
  hasBackground,
  barDataKey = 'value',
  axisDataKey = 'name',
  barSize = 8,
}: IVerticalBarChartProps) => {
  const viewMode = useTypedSelector(store => store.ui.viewMode);

  if (data.length === 0) {
    return <EmptyChartView />;
  }

  const maxValue = Math.max(...data.map(item => Number(item[barDataKey])));

  return (
    <div className="full-dimension">
      <Styles.Table className="full-height">
        <tbody>
          {data.map(item => (
            <Styles.Tr key={`${item[axisDataKey]}-${item[barDataKey]}`}>
              <Styles.Th>{item[axisDataKey]}</Styles.Th>
              <Styles.Td>
                <ProgressBar
                  color={barFill}
                  value={Number(item[barDataKey])}
                  shouldShowValue={viewMode === 'web'}
                  labelPosition="end"
                  barSize={barSize}
                  hasBackground={hasBackground}
                  referencePoint={maxValue}
                />
              </Styles.Td>
            </Styles.Tr>
          ))}
        </tbody>
      </Styles.Table>
    </div>
  );
};

export default VerticalBarChart;
