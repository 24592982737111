/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IUserInterfaceState {
  viewMode: 'tv' | 'web';
}

const initialState: IUserInterfaceState = {
  viewMode: 'web',
} as const;

const userInterfaceSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setViewMode(state, action: PayloadAction<IUserInterfaceState['viewMode']>) {
      state.viewMode = action.payload;
    },
  },
});

export const { setViewMode } = userInterfaceSlice.actions;
export default userInterfaceSlice.reducer;
