import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--white);
  border: 1px solid var(--athens-gray);
  border-radius: 8px;
  padding: 24px;
  margin: 0 24px;
`;

export const SubText = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: var(--rhino);
`;
