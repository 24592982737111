import { IconPropsType } from './types';

interface IStarIconProps extends IconPropsType {}

const StarIcon = ({
  size = 24,
  stroke = 'var(--rhino)',
  fill = 'var(--rhino)',
  strokeWidth = 1.25,
  offset = 100,
  ...props
}: IStarIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.049 4.927c.3-.922 1.603-.922 1.902 0l1.07 3.292a1 1 0 0 0 .95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 0 0-.364 1.118l1.07 3.292c.3.922-.755 1.688-1.539 1.119l-2.8-2.035a1 1 0 0 0-1.176 0l-2.8 2.035c-.784.569-1.838-.197-1.539-1.119l1.07-3.292a1 1 0 0 0-.364-1.118l-2.8-2.034c-.784-.57-.381-1.81.588-1.81h3.461a1 1 0 0 0 .951-.69l1.07-3.292Z"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default StarIcon;
