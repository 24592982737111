import Segment from '../../../../../shared/Segment';
import KeyValueTable from '../../../../../shared/KeyValueTable';
import { ImageWrapper } from '../../../../../shared/ImageWrapper';
import type { AuditSessionDetailsType } from '../schemas';
import {
  DEFAULT_IMAGE_PLACEHOLDER,
  DEFAULT_VALUES,
} from '../../../../../constants';
import { formatNumberToLocale } from '../../../../../utils';

interface IProductDetailsProps {
  isLoading: boolean;
  data: AuditSessionDetailsType | undefined;
}

const ProductDetails = ({ data, isLoading }: IProductDetailsProps) => (
  <Segment
    heading="Product Details"
    isLoading={isLoading}
    shouldHeadingHaveBorder
  >
    <div className="d-flex gap-12">
      <ImageWrapper
        style={{ width: '100px', height: '150px' }}
        $borderRadius={4}
        $hasBoxShadow={false}
      >
        <img src={data?.style_image ?? DEFAULT_IMAGE_PLACEHOLDER} alt="" />
      </ImageWrapper>
      <KeyValueTable
        data={[
          {
            key: 'Brand',
            value: data?.brand ?? DEFAULT_VALUES.DOUBLE_DASH,
          },
          {
            key: 'Order',
            value: `${data?.order_id} - ${data?.collection_name}`,
          },
          {
            key: 'Style',
            value: data?.style_name ?? DEFAULT_VALUES.DOUBLE_DASH,
          },
          {
            key: 'Color',
            value: data?.color ?? DEFAULT_VALUES.DOUBLE_DASH,
          },
          {
            key: 'Total Quantity',
            value: data?.quantity
              ? formatNumberToLocale(data.quantity)
              : DEFAULT_VALUES.DOUBLE_DASH,
          },
          {
            key: 'AQL',
            value: data?.aql_level ?? DEFAULT_VALUES.DOUBLE_DASH,
          },
          {
            key: 'Sample Size',
            value: data?.sample_size
              ? formatNumberToLocale(data.sample_size)
              : DEFAULT_VALUES.DOUBLE_DASH,
          },
          {
            key: 'Offered Quantity',
            value: data?.sample_offered_quantity
              ? formatNumberToLocale(data.sample_offered_quantity)
              : DEFAULT_VALUES.DOUBLE_DASH,
          },
        ]}
      />
    </div>
  </Segment>
);

export default ProductDetails;
