import useFeatureFlags from '../../../../redux/featureFlags/useFeatureFlags';
import Grid from '../../../../shared/Grid';
import GridItem from '../../../../shared/Grid/GridItem';
import Metric from '../../../../shared/Metric';
import { OverAllDataType } from './schemas';

interface IOverallDataProps {
  data: OverAllDataType | undefined;
}

const OverallData = ({ data }: IOverallDataProps) => {
  const { should_show_efficiency } = useFeatureFlags();

  return (
    <Grid
      minHeight="auto"
      gridTemplateRows="1fr"
      gridTemplateColumns={
        should_show_efficiency ? 'repeat(6, 1fr)' : 'repeat(5, 1fr)'
      }
    >
      <GridItem gridColumn="1/2" gridRow="1/2">
        <Metric
          heading="Total Inspected"
          value={data?.inspected_count}
          valueColor="var(--white)"
          hasRightBorder
        />
      </GridItem>
      <GridItem gridColumn="2/3" gridRow="1/2">
        <Metric
          heading="FTT"
          value={data?.ftt}
          valueColor="var(--deep-sea)"
          valueSuffix={
            data?.ftt_percentage ? ` (${data?.ftt_percentage} %)` : ''
          }
          hasRightBorder
        />
      </GridItem>
      <GridItem gridColumn="3/4" gridRow="1/2">
        <Metric
          heading="In alter"
          value={data?.alter_count}
          valueColor="var(--chilean-fire)"
          hasRightBorder
        />
      </GridItem>
      <GridItem gridColumn="4/5" gridRow="1/2">
        <Metric
          heading="Total DHU"
          value={data?.dhu}
          valueSuffix="%"
          valueColor="var(--red-dirt)"
          hasRightBorder
        />
      </GridItem>
      {should_show_efficiency && (
        <GridItem gridColumn="5/6" gridRow="1/2">
          <Metric
            heading="Line Efficiency"
            value={data?.efficiency}
            valueSuffix="%"
            valueColor="var(--deep-sea)"
            hasRightBorder
          />
        </GridItem>
      )}
      <GridItem
        gridColumn={should_show_efficiency ? '6/7' : '5/6'}
        gridRow="1/2"
      >
        <Metric
          heading="Current Hourly Output"
          value={data?.actual_hourly_output}
          valueColor="var(--white)"
        />
      </GridItem>
    </Grid>
  );
};

export default OverallData;
