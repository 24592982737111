import { IconPropsType } from './types';

interface IPencilIconProps extends IconPropsType {}

const PencilIcon = ({
  stroke = 'var(--rhino)',
  size = 24,
  strokeWidth = 1.25,
  ...props
}: IPencilIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.873 5.9803L18.0157 9.11682M16.2064 4.64959C17.0742 3.78347 18.4812 3.78347 19.3491 4.64959C20.2169 5.51572 20.2169 6.91998 19.3491 7.78611L7.11111 20H4V16.832L16.2064 4.64959Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PencilIcon;
