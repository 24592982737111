import { DEFAULT_VALUES } from '../../../../constants';
import Grid from '../../../../shared/Grid';
import GridItem from '../../../../shared/Grid/GridItem';
import Metric from '../../../../shared/Metric';
import { useGetAuditActivityInfoQuery } from '../../../../redux/auditSessions/api';

const ActivityInfo = () => {
  const { data, isFetching } = useGetAuditActivityInfoQuery();

  return (
    <Grid
      minHeight="10vh"
      gridTemplateColumns="repeat(4, 1fr)"
      gridTemplateRows="1fr"
      gap={24}
    >
      <GridItem gridColumn="1/2" gridRow="1/2">
        <Metric
          heading="Active Stitching Sessions"
          value={
            data?.active_stitching_session_count ?? DEFAULT_VALUES.DOUBLE_DASH
          }
          isLoading={isFetching}
          valueColor="var(--rhino)"
          hasBottomBorder
          hasLeftBorder
          hasRightBorder
          hasTopBorder
          hasBorderRadius
          hasBackground
        />
      </GridItem>
      <GridItem gridColumn="2/3" gridRow="1/2">
        <Metric
          heading="Active Finishing Sessions"
          value={
            data?.active_finishing_session_count ?? DEFAULT_VALUES.DOUBLE_DASH
          }
          isLoading={isFetching}
          hasBottomBorder
          hasLeftBorder
          hasRightBorder
          hasTopBorder
          hasBorderRadius
          hasBackground
          valueColor="var(--rhino)"
        />
      </GridItem>
      <GridItem gridColumn="3/4" gridRow="1/2">
        <Metric
          heading="Stitching Audit Sessions"
          value={
            data?.active_stitching_audit_session_count ??
            DEFAULT_VALUES.DOUBLE_DASH
          }
          isLoading={isFetching}
          valueColor="var(--rhino)"
          hasBottomBorder
          hasLeftBorder
          hasRightBorder
          hasTopBorder
          hasBorderRadius
          hasBackground
        />
      </GridItem>
      <GridItem gridColumn="4/5" gridRow="1/2">
        <Metric
          heading="Finishing Audit Sessions"
          value={
            data?.active_finishing_audit_session_count ??
            DEFAULT_VALUES.DOUBLE_DASH
          }
          isLoading={isFetching}
          hasBottomBorder
          hasLeftBorder
          hasRightBorder
          hasTopBorder
          hasBorderRadius
          hasBackground
          valueColor="var(--rhino)"
        />
      </GridItem>
    </Grid>
  );
};

export default ActivityInfo;
