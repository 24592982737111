import { ReactNode, RefObject } from 'react';
import { styled } from 'styled-components';

const StyledChartWrapper = styled.div`
  height: 100%;
  width: 100%;
  /* border: 1px dashed red; */
`;

interface IChartWrapperProps {
  wrapperRef: RefObject<HTMLDivElement>;
  children: ReactNode;
}

const ChartWrapper = ({ wrapperRef, children }: IChartWrapperProps) => (
  <StyledChartWrapper ref={wrapperRef} className="v-center-flex-end">
    {children}
  </StyledChartWrapper>
);

export default ChartWrapper;
