import { IconPropsType } from './types';

interface ICheckIconProps extends IconPropsType {}

const PATH_DRAWN = Object.freeze({
  check: 'M5 13L9 17L19 7',
  'check-circle':
    'M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z',
  'check-badge':
    'M8.97526 12L10.9918 14.0165L15.0247 9.98351M7.80039 4.63683C8.52384 4.57909 9.21064 4.29461 9.76302 3.82388C11.0521 2.72537 12.9479 2.72537 14.237 3.82388C14.7894 4.29461 15.4762 4.57909 16.1996 4.63683C17.8879 4.77155 19.2285 6.11215 19.3632 7.80039C19.4209 8.52384 19.7054 9.21064 20.1761 9.76302C21.2746 11.0521 21.2746 12.9479 20.1761 14.237C19.7054 14.7894 19.4209 15.4762 19.3632 16.1996C19.2285 17.8879 17.8879 19.2285 16.1996 19.3632C15.4762 19.4209 14.7894 19.7054 14.237 20.1761C12.9479 21.2746 11.0521 21.2746 9.76302 20.1761C9.21064 19.7054 8.52384 19.4209 7.80039 19.3632C6.11215 19.2285 4.77155 17.8879 4.63683 16.1996C4.57909 15.4762 4.29461 14.7894 3.82388 14.237C2.72537 12.9479 2.72537 11.0521 3.82388 9.76302C4.29461 9.21064 4.57909 8.52384 4.63683 7.80039C4.77155 6.11215 6.11215 4.77155 7.80039 4.63683Z',
});

const CheckIcon = ({
  size = 24,
  stroke = 'var(--rhino)',
  strokeWidth = 1.25,
  viewType = 'check',
  ...props
}: ICheckIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={PATH_DRAWN[viewType as keyof typeof PATH_DRAWN]}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckIcon;
