import { IconPropsType } from './types';

interface IMenuIconProps extends IconPropsType {}

const MenuIcon = ({
  stroke = 'var(--rhino)',
  strokeWidth = 1.25,
  ...props
}: IMenuIconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 6H20M4 12H20M4 18H20"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MenuIcon;
