import { css, styled } from 'styled-components';

interface ITableProps {
  $width?: number;
}

export const Table = styled.table<ITableProps>`
  width: ${props => (props.$width ? `${props.$width}px` : '100%')};
  overflow-x: auto;
  border-collapse: collapse;
  flex: 1;
  display: table-row-group;
`;

export const ExpandedBodyWrapper = styled.div<IBodyCellProps>`
  border: 1px dashed red;
`;

export const Tr = styled.tr`
  border-bottom: 1px solid var(--athens-gray);

  &:last-child {
    border: none;
  }

  &:first-child {
    border-bottom: 1px solid var(--athens-gray);
  }
`;

export const Th = styled.th`
  &:first-child > div {
    padding: 8px 24px;
  }
  white-space: nowrap;
  background: var(--wash-me);
  border-top: 1px solid var(--athens-gray);
  border-bottom: 1px solid var(--athens-gray);
`;

export const Td = styled.td`
  &:first-child > div {
    padding: 12px 24px;
  }
  position: relative;
  height: 1px; // hack
`;

export const HeaderCell = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: var(--rhino);
  padding: 8px 16px;
  text-align: left;
  min-height: 40px;
`;

interface IBodyCellProps {
  $shouldHavePadding?: boolean;
}

export const BodyCell = styled.div<IBodyCellProps>`
  font-weight: 400;
  font-size: 12px;
  color: var(--rhino);
  text-align: left;
  height: 100%;
  line-height: 20px;

  ${props =>
    props.$shouldHavePadding &&
    css`
      padding: 12px 16px;
    `}
`;

export const PaginationWrapper = styled.div`
  padding: 12px 24px;
  border-top: 1px solid var(--athens-gray);
`;
