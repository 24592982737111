import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithZodValidation, overrideFetchBaseQuery } from '../utils';
import { convertArrayToObject } from '../../utils/arrays';
import { getFormattedFilters } from '../auditDashboard/utils';
import type { SimpleApiResponse } from '../../@types/api';
import type { IFiltersState } from '../filters';
import type { AuditActivityInfoType } from '../../components/Sessions/Audit/ActivityInfo/schemas';
import {
  AuditSessionType,
  AuditSessionsSchema,
  AuditSessionsType,
} from '../../components/Sessions/Audit/schemas';
import {
  AuditSessionDetailsType,
  AuditSessionDetailsSchema,
} from '../../components/Sessions/Audit/AuditDetails/schemas';
import {
  AuditInspectionsSchema,
  AuditInspectionsType,
} from '../../components/Sessions/Audit/AuditDetails/Inspections/schemas';

export const auditSessionsApi = createApi({
  reducerPath: 'auditSessions',
  baseQuery: baseQueryWithZodValidation(
    overrideFetchBaseQuery({
      base: 'audit',
      urlSuffix: '/session',
    })
  ),
  endpoints: builder => ({
    getAuditSessions: builder.query<
      {
        ids: Array<AuditSessionType['id']>;
        data: Record<string, AuditSessionType>;
      },
      Partial<IFiltersState['selected']>
    >({
      query: filters => `/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: AuditSessionsSchema,
      },
      transformResponse: (response: SimpleApiResponse<AuditSessionsType>) => {
        const ids = response.data.map(item => item.id);
        const data = convertArrayToObject(response.data, 'id');
        return {
          ids,
          data,
        };
      },
    }),
    getAuditActivityInfo: builder.query<AuditActivityInfoType, void>({
      query: () => `basic/`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<AuditActivityInfoType>) =>
        response.data,
    }),
    getInspectionByAuditSessionId: builder.query<AuditInspectionsType, number>({
      query: sessionId => `${sessionId}/inspection/`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: AuditInspectionsSchema,
      },
      transformResponse: (response: SimpleApiResponse<AuditInspectionsType>) =>
        response.data,
    }),
    getDetailsByAuditSessionId: builder.query<AuditSessionDetailsType, number>({
      query: sessionId => `${sessionId}/`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: AuditSessionDetailsSchema,
      },
      transformResponse: (
        response: SimpleApiResponse<AuditSessionDetailsType>
      ) => response.data,
    }),
  }),
});

export const {
  useGetAuditSessionsQuery,
  useGetAuditActivityInfoQuery,
  useGetDetailsByAuditSessionIdQuery,
  useGetInspectionByAuditSessionIdQuery,
} = auditSessionsApi;

export const { updateQueryData: updateAuditSessionQueryData } =
  auditSessionsApi.util;
