import z from 'zod';

const EnvSchema = z.object({
  QAPP_SERVER_URL: z.string().nonempty(),
  PMB_SERVER_URL: z.string().nonempty(),
  TOKEN: z.string().nonempty(),
  TRAKTEZ_TOKEN: z.string().nonempty(),
  IS_DEV_MODE: z.string().nonempty(),
  COOKIE_DOMAIN: z.string().nonempty(),
});

type EnvType = z.infer<typeof EnvSchema>;

const ENV: EnvType = {} as EnvType;

const envParsingResult = EnvSchema.safeParse(process.env);

if (envParsingResult.success) {
  Object.assign(ENV, envParsingResult.data);
} else {
  const errorStyles = [
    'color: red',
    'background: yellow',
    'font-size: 24px',
  ].join(';');

  envParsingResult.error.errors.forEach((error, index) => {
    console.log(
      `%c[ENV] Error #${index + 1}: ${error.path.toString()} => ${
        error.message
      }`,
      errorStyles
    );
  });

  throw new Error('ENV variables not found');
}

export { ENV };
