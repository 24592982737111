import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 24px;
`;

interface IStrideWrapperProps {
  $isActive: boolean;
}

export const StrideWrapper = styled.div<IStrideWrapperProps>`
  height: 4px;
  border-radius: 12px;
  background: var(--fiord);
  transition: box-shadow 0.75s ease-in-out;

  ${props =>
    props.$isActive &&
    css`
      box-shadow: 0px 0px 4px 7px rgba(162, 177, 216, 0.32);
    `}
`;

export const ProgressBar = styled(motion.div)`
  width: 0%;
  height: 100%;
  border-radius: 12px;
  background: var(--wild-watermelon);
`;
