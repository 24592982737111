import { Fragment, ReactNode } from 'react';
import {
  ColumnDef,
  Row,
  TableOptions,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { IconButton } from '@fashinza/fashinza-design-system';
import ManualPagination from '../ManualPagination';
import EmptyView from './EmptyView';
import * as Styles from './styles';

interface ITableProps<T> {
  data: Array<T>;
  columns: ColumnDef<T, any>[];
  totalCount: number;
  currentPage: number;
  pageLength: number;
  handlePagination: (cursor: 'previous' | 'next') => void;
  cellsWithNoPadding?: Array<string>;
  width?: number;
  tableOptions?: Partial<TableOptions<T>>;
  expandedRow?: (row: Row<T>) => ReactNode;
}

const Table = <T extends object>({
  data,
  columns,
  currentPage,
  pageLength,
  totalCount,
  handlePagination,
  cellsWithNoPadding,
  width,
  tableOptions = {},
  expandedRow,
}: ITableProps<T>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    ...tableOptions,
  });

  const isTableEmpty = table.getRowModel().rows.length === 0;

  return (
    <>
      <Styles.Table $width={width} className="pos-r">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Styles.Th
                  key={header.id}
                  style={{
                    width: `${header.getSize()}%`,
                  }}
                >
                  <Styles.HeaderCell
                    className="spread"
                    style={{ ...header.column.columnDef.meta?.headerStyles }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Styles.HeaderCell>
                </Styles.Th>
              ))}
            </tr>
          ))}
        </thead>
        {isTableEmpty ? (
          <div style={{ height: '30vh' }}>
            <EmptyView />
          </div>
        ) : (
          <tbody>
            {table.getRowModel().rows.map(row => (
              <Fragment key={row.id}>
                <Styles.Tr>
                  {row.getVisibleCells().map(cell => (
                    <Styles.Td key={cell.id}>
                      <Styles.BodyCell
                        $shouldHavePadding={
                          !cellsWithNoPadding?.some(cellId =>
                            cell.id.includes(cellId)
                          )
                        }
                        className="truncated-text align-center"
                        style={{ ...cell.column.columnDef.meta?.cellStyles }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Styles.BodyCell>
                    </Styles.Td>
                  ))}
                </Styles.Tr>
                {row.getIsExpanded() && expandedRow ? expandedRow(row) : null}
              </Fragment>
            ))}
          </tbody>
        )}
      </Styles.Table>
      {!isTableEmpty && (
        <Styles.PaginationWrapper className="flex-justify-end">
          <ManualPagination
            kind="manual"
            totalCount={totalCount}
            pageLength={pageLength}
            currentPage={currentPage}
            onChangePage={cursor => handlePagination(cursor)}
          />
        </Styles.PaginationWrapper>
      )}
    </>
  );
};

export default Table;
