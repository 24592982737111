import styled from 'styled-components';
import Illustration from './Illustration';

const Text = styled.h3`
  color: var(--athens-gray);
  font-family: Lora;
  font-size: 24px;
  font-weight: 600;
`;

const NoStyles = () => (
  <div className="full-dimension v-center gap-32">
    <Illustration />
    <Text>No Styles Selected</Text>
  </div>
);

export default NoStyles;
