import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithZodValidation, overrideFetchBaseQuery } from '../utils';
import {
  getFormattedFilters,
  getLineDefectApiUrl,
  getLineAreaOfConcernApiUrl,
} from './utils';
import type { LineDhuItemsType } from '../../components/Dashboard/Ordinary/LineDHU/schemas';
import type { HourlyLineEfficienciesType } from '../../components/Dashboard/Ordinary/HourlyLineEfficiency/schemas';
import type { HourlyDhuItemsType } from '../../components/Dashboard/Ordinary/HourlyDHU/schemas';
import type { OverallAnalyticsType } from '../../components/Dashboard/Ordinary/OverallAnalytics/schemas';
import type { HourlyCountsItemsType } from '../../components/Dashboard/Ordinary/HourlyCounts/schemas';
import {
  LinePerformanceItemsSchema,
  MidLineItemsType,
  type LinePerformanceItemsType,
  MidLineItemsSchema,
} from '../../components/Dashboard/Ordinary/LinePerformance/schemas';
import { NUMBERS_REGEX } from '../../utils/regex';
import type { AtrociousApiResponse, SimpleApiResponse } from '../../@types/api';
import type { TotalType } from '../../components/Dashboard/Ordinary/Totals/schemas';
import type { IFiltersState } from '../filters';
import type { ITopDefectsTableItem } from '../../components/Dashboard/Ordinary/TopDefects';
import {
  LineOverallDataSchema,
  type LineOverallDataType,
} from '../../components/Dashboard/Ordinary/LinePerformance/Details/schemas';
import {
  AreasOfConcernSchema,
  AreasOfConcernType,
} from '../../components/Reports/Ordinary/AreasOfConcern/schemas';
import {
  DefectsSchema,
  DefectsType,
} from '../../components/Reports/Ordinary/Defects/schemas';
import { HourlyDhuItemsSchema } from '../../components/Dashboard/Ordinary/LinePerformance/Details/HourlyDHU/schemas';
import { HourlyLineEfficienciesSchema } from '../../components/Dashboard/Ordinary/LinePerformance/Details/HourlyLineEfficiency/schemas';

export const dashboardApi = createApi({
  reducerPath: 'dashboard',
  baseQuery: baseQueryWithZodValidation(
    overrideFetchBaseQuery({
      urlSuffix: 'dashboard',
    })
  ),
  endpoints: builder => ({
    getOverallAnalytics: builder.query<
      OverallAnalyticsType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => ({
        url: `overall-analytic/?${getFormattedFilters(filters)}`,
      }),
      keepUnusedDataFor: 0,
      transformResponse: (
        response: SimpleApiResponse<AtrociousApiResponse<OverallAnalyticsType>>
      ) => response.data[0],
    }),
    getLineDhu: builder.query<
      LineDhuItemsType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `line-dhu/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<LineDhuItemsType>) =>
        response.data.map(item => ({
          ...item,
          value: Number(item.value.toFixed(2)),
        })),
    }),
    getTotals: builder.query<TotalType, Partial<IFiltersState['selected']>>({
      query: filters => `total/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (
        response: SimpleApiResponse<AtrociousApiResponse<TotalType>>
      ) => ({
        ...response.data[0],
        dhu: Number(response.data[0].dhu.toFixed(2)),
        efficiency: Number(response.data[0].efficiency.toFixed(2)),
      }),
    }),
    getHourlyDhu: builder.query<
      HourlyDhuItemsType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `hourly-dhu/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<HourlyDhuItemsType>) =>
        response.data.map(item => ({
          ...item,
          dhu: Number(item.dhu.toFixed(2)),
          hour: item.hour.replace(NUMBERS_REGEX, ''),
        })),
    }),
    getHourlyLineEfficiency: builder.query<
      HourlyLineEfficienciesType,
      Partial<IFiltersState['selected']>
    >({
      query: filters =>
        `hourly-line-efficiency/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (
        response: SimpleApiResponse<HourlyLineEfficienciesType>
      ) =>
        response.data.map(item => ({
          ...item,
          efficiency: Number(item.efficiency.toFixed(2)),
          hour: item.hour.replace(NUMBERS_REGEX, ''),
        })),
    }),
    getHourlyCounts: builder.query<
      HourlyCountsItemsType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `hourly-count/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<HourlyCountsItemsType>) =>
        response.data.map((item, index) => ({
          ...item,
          id: index,
          dhu: Number(item.dhu.toFixed(2)),
          efficiency: Number(item.efficiency.toFixed(2)),
          alter_count: Number(item.alter_count.toFixed(2)),
          reject_count: Number(item.reject_count.toFixed(2)),
          pass_count: Number(item.pass_count.toFixed(2)),
        })),
    }),
    getTopDefects: builder.query<
      Array<ITopDefectsTableItem>,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `top-defect/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      transformResponse: (
        response: SimpleApiResponse<Array<ITopDefectsTableItem>>
      ) => response.data,
    }),

    // *─── Line Apis ───────────────────────────────────────────────

    getLinePerformance: builder.query<
      LinePerformanceItemsType,
      Partial<IFiltersState['selected']>
    >({
      query: filters => `line-performance/?${getFormattedFilters(filters)}`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: LinePerformanceItemsSchema,
      },
      transformResponse: (
        response: SimpleApiResponse<LinePerformanceItemsType>
      ) =>
        response.data.map((item, index) => ({
          ...item,
          id: index,
          dhu: Number(item.dhu.toFixed(2)),
          efficiency: Number(item.efficiency.toFixed(2)),
          alter_count: Number(item.alter_count.toFixed(2)),
          reject_count: Number(item.reject_count.toFixed(2)),
          pass_count: Number(item.pass_count.toFixed(2)),
          ftt: Number(item.ftt.toFixed(2)),
          ftt_percentage: Math.floor(item.ftt_percentage),
          total_checked: Number(item.total_checked.toFixed(2)),
        })),
    }),

    getMidlineDataById: builder.query<MidLineItemsType, number>({
      query: lineId => `/line-performance/${lineId}/midline/`,
      keepUnusedDataFor: 0,
      extraOptions: {
        zodSchema: MidLineItemsSchema,
      },
      transformResponse: (response: SimpleApiResponse<MidLineItemsType>) =>
        response.data.map(item => ({
          ...item,
          efficiency: item.efficiency ? Number(item.efficiency.toFixed(2)) : 0,
          dhu: item.dhu ? Number(item.dhu.toFixed(2)) : 0,
          ftt_percentage: item.ftt_percentage
            ? Number(item.ftt_percentage.toFixed(2))
            : 0,
        })),
    }),

    getLineOverallData: builder.query<LineOverallDataType, number>({
      query: lineId => `/line-performance/${lineId}/overview`,
      extraOptions: {
        zodSchema: LineOverallDataSchema,
      },
      keepUnusedDataFor: 0,
      transformResponse: (
        response: SimpleApiResponse<LineOverallDataType>
      ) => ({
        ...response.data,
        efficiency: response.data.efficiency
          ? Number(response.data.efficiency.toFixed(2))
          : 0,
      }),
    }),

    getLineAreaOfConcernDataById: builder.query<
      AreasOfConcernType,
      {
        department: string;
        endlineId: number;
        midlineId?: number;
      }
    >({
      query: ({ department, endlineId, midlineId }) =>
        getLineAreaOfConcernApiUrl({ department, endlineId, midlineId }),
      extraOptions: {
        zodSchema: AreasOfConcernSchema,
      },
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<AreasOfConcernType>) =>
        response.data,
    }),

    getLineDefectDataById: builder.query<
      DefectsType,
      {
        department: string;
        endlineId: number;
        midlineId?: number;
      }
    >({
      query: ({ department, endlineId, midlineId }) =>
        getLineDefectApiUrl({ department, endlineId, midlineId }),
      extraOptions: {
        zodSchema: DefectsSchema,
      },
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<DefectsType>) =>
        response.data,
    }),

    getHourlyDhuByLineId: builder.query<HourlyDhuItemsType, number>({
      query: lineId => `/hourly-dhu/${lineId}/`,
      extraOptions: {
        zodSchema: HourlyDhuItemsSchema,
      },
      keepUnusedDataFor: 0,
      transformResponse: (response: SimpleApiResponse<HourlyDhuItemsType>) =>
        response.data,
    }),

    getHourlyEfficiencyByLineId: builder.query<
      HourlyLineEfficienciesType,
      number
    >({
      query: lineId => `hourly-line-efficiency/${lineId}/`,
      extraOptions: {
        zodSchema: HourlyLineEfficienciesSchema,
      },
      keepUnusedDataFor: 0,
      transformResponse: (
        response: SimpleApiResponse<HourlyLineEfficienciesType>
      ) => response.data,
    }),
  }),
});

export const {
  useGetOverallAnalyticsQuery,
  useGetLineDhuQuery,
  useGetHourlyCountsQuery,
  useGetHourlyDhuQuery,
  useGetHourlyLineEfficiencyQuery,
  useGetTopDefectsQuery,
  useGetTotalsQuery,
  useGetLinePerformanceQuery,
  useGetLineOverallDataQuery,
  useGetMidlineDataByIdQuery,
  useGetLineDefectDataByIdQuery,
  useGetLineAreaOfConcernDataByIdQuery,
  useGetHourlyDhuByLineIdQuery,
  useGetHourlyEfficiencyByLineIdQuery,
} = dashboardApi;

export const { resetApiState: resetDashboardState } = dashboardApi.util;
