const TrakTezLogo = () => (
  <svg
    width={140}
    height={40}
    viewBox="0 0 140 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2592_223690)">
      <path
        d="M41.8539 16.1052C41.7882 15.6291 41.713 15.1615 41.6263 14.7061C41.4682 13.8769 41.2739 13.0778 41.0492 12.3125C40.2348 9.53761 38.9891 7.19097 37.43 5.2528C37.3243 5.12227 37.2186 4.99269 37.1081 4.86404C36.5071 4.15788 35.8661 3.50901 35.1871 2.91648C34.0375 1.91453 32.7841 1.07128 31.4536 0.382025C19.6675 -5.7264 1.90125 0.245864 -1.44077 14.4901C-1.51791 14.8225 -1.58934 15.1587 -1.6503 15.4977C-1.66934 15.6019 -1.68839 15.708 -1.70458 15.8151C-1.72935 15.9615 -1.75316 16.1071 -1.77411 16.2555C-1.78744 16.3559 -1.80268 16.4564 -1.81411 16.5569C-1.84268 16.7729 -1.8684 16.9917 -1.88935 17.2114C-1.89697 17.2987 -1.90649 17.3879 -1.91221 17.4753C-1.93697 17.7523 -1.95602 18.0331 -1.9684 18.3157C-1.97602 18.4528 -1.97983 18.5908 -1.98364 18.7317C-1.98364 18.7336 -1.98364 18.7336 -1.98364 18.7355C-1.98554 18.8021 -1.98745 18.8688 -1.98935 18.9345C-1.99126 19.081 -1.99316 19.2266 -1.99316 19.3731C-1.99316 21.5807 -1.67506 23.9387 -0.98837 26.4365C-0.969322 26.5163 -0.950274 26.5942 -0.930273 26.6722C-0.547404 28.2056 -0.000719786 29.6386 0.690731 30.9636C0.741209 31.0622 0.792639 31.1608 0.846926 31.2594C0.931691 31.419 1.01836 31.5768 1.11074 31.7317C2.68889 34.4671 4.88039 36.7105 7.43666 38.396C8.17383 38.8815 8.94052 39.32 9.73388 39.7116C10.4749 40.0797 11.2396 40.4046 12.0206 40.6835C23.3486 44.7608 38.19 39.6562 41.7073 21.3122C41.7092 21.3047 41.7111 21.2971 41.7111 21.2896C42.0425 19.5825 42.0863 17.8284 41.8539 16.1052ZM30.5364 7.90745C30.5307 7.91778 30.5241 7.92811 30.5193 7.93844C30.5231 7.92341 30.5279 7.91214 30.5364 7.90745Z"
        fill="#C93191"
      />
      <path
        d="M35.1876 2.91496C34.2561 3.86057 33.078 4.62212 31.857 5.12263C29.9665 5.89827 27.9416 6.25511 25.9311 6.65983C25.0539 6.83449 24.1749 7.02042 23.3158 7.25424C23.0396 7.3303 22.7653 7.41106 22.491 7.49745C22.1719 7.59793 21.8586 7.70591 21.5491 7.82423C20.9214 8.05711 20.3062 8.32286 19.7033 8.61302C17.148 9.83564 14.7965 11.4865 12.4202 13.0997C11.1725 13.9496 9.91726 14.7891 8.62388 15.5534C7.49813 16.2164 6.01618 16.8183 4.3847 17.2897C4.25041 17.3263 4.12279 17.3658 3.9885 17.4005C2.06272 17.9236 -0.0344878 18.2625 -1.96979 18.3067C-1.90788 16.9761 -1.72597 15.7037 -1.44215 14.4904C-0.0478215 8.55386 3.8504 4.05401 8.77532 1.26977C10.6182 0.226502 12.604 -0.577312 14.6584 -1.12759C20.2881 -2.63286 26.4178 -2.22813 31.4541 0.382382C32.7847 1.07069 34.0371 1.91301 35.1876 2.91496Z"
        fill="#F7A01D"
      />
      <path
        d="M41.6266 14.7049C37.3198 16.413 32.2949 15.8806 27.7614 17.3455C16.741 22.1965 10.2485 27.2391 -0.829993 26.9847C-2.02241 22.6923 -2.43671 18.9334 -1.44049 14.4889C15.2858 16.6478 13.7867 1.64104 31.4539 0.380859C36.8598 3.11721 40.4808 8.44435 41.6266 14.7049Z"
        fill="#EE5A63"
      />
      <path
        d="M41.7114 21.2905C41.7114 21.298 41.7095 21.3056 41.7076 21.3131C41.5114 21.33 41.3009 21.345 41.1705 21.3563C38.758 21.6014 36.2627 21.6032 34.0121 22.4972C29.0177 24.4767 26.7424 30.0536 23.0785 33.9412C20.2289 36.9649 16.0744 39.5501 12.02 40.6835C11.2381 40.4046 10.4743 40.0797 9.73328 39.7116C8.93992 39.3209 8.17419 38.8824 7.43607 38.396C4.8798 36.7104 2.6883 34.4671 1.11015 31.7317C1.06634 31.6481 1.02158 31.5683 0.97491 31.4885C0.931099 31.4124 0.888241 31.3345 0.846335 31.2584C0.792047 31.1598 0.740617 31.0612 0.690139 30.9626C0.443465 30.4809 0.230125 29.9832 0.0167847 29.478C5.43981 29.3831 10.7771 27.9652 15.5011 25.3303C19.2669 23.2343 22.5918 20.4125 26.1186 17.9504C28.092 16.57 30.1273 15.3042 32.3369 14.3398C34.996 13.182 38.178 12.5002 41.0486 12.3105C41.399 13.4965 41.6705 14.7595 41.8533 16.1033C42.0867 17.8283 42.0429 19.5824 41.7114 21.2905Z"
        fill="#ED3177"
      />
      <path
        d="M9.8134 8.11867C9.8134 8.40789 9.57721 8.64077 9.28386 8.64077C8.99052 8.64077 8.75146 8.40789 8.75146 8.11867C8.75146 7.82945 8.99052 7.59375 9.28386 7.59375C9.57721 7.59375 9.8134 7.82851 9.8134 8.11867Z"
        fill="#F8F9DF"
      />
      <path
        d="M7.00827 10.18C7.00827 10.3659 6.85588 10.5162 6.66731 10.5162C6.48159 10.5162 6.32825 10.3659 6.32825 10.18C6.32825 9.99595 6.48063 9.8457 6.66731 9.8457C6.85493 9.8457 7.00827 9.99689 7.00827 10.18Z"
        fill="#F8F9DF"
      />
      <path
        d="M30.9436 32.0026C30.9436 32.2918 30.7074 32.5275 30.414 32.5275C30.1207 32.5275 29.8817 32.2918 29.8817 32.0026C29.8817 31.7133 30.1207 31.4805 30.414 31.4805C30.7074 31.4805 30.9436 31.7133 30.9436 32.0026Z"
        fill="#ED3177"
      />
      <path
        d="M33.3681 29.9398C33.3681 30.1257 33.2157 30.2741 33.0272 30.2741C32.8386 30.2741 32.6881 30.1257 32.6881 29.9398C32.6881 29.7538 32.8386 29.6055 33.0272 29.6055C33.2157 29.6055 33.3681 29.7538 33.3681 29.9398Z"
        fill="#ED3177"
      />
      <path
        d="M16.1787 30H19.9467C20.2172 30 20.4762 29.8904 20.6646 29.6961L26.0052 24.1886C26.3981 23.7835 27.0481 23.7835 27.441 24.1886L28.2821 25.056C28.9075 25.7009 30 25.2582 30 24.3599V20.3363C30 20.0764 29.8988 19.8267 29.7179 19.6401L24.2978 14.0507C23.905 13.6455 23.2549 13.6455 22.862 14.0507L21.045 15.9245C20.6688 16.3124 20.6688 16.9289 21.045 17.3168L22.9049 19.2349C23.281 19.6228 23.281 20.2393 22.9049 20.6272L15.4608 28.3039C14.8458 28.9381 15.2952 30 16.1787 30Z"
        fill="white"
      />
      <path
        d="M23.8213 10H20.0533C19.7828 10 19.5238 10.1096 19.3354 10.3039L13.9948 15.8114C13.6019 16.2165 12.9519 16.2165 12.559 15.8114L11.7179 14.944C11.0925 14.2991 10 14.7418 10 15.6401V19.6637C10 19.9236 10.1012 20.1733 10.2821 20.3599L15.7022 25.9493C16.095 26.3545 16.7451 26.3545 17.138 25.9493L18.955 24.0755C19.3312 23.6876 19.3312 23.0711 18.955 22.6832L17.0951 20.7651C16.719 20.3772 16.719 19.7607 17.0951 19.3728L24.5392 11.6961C25.1542 11.0619 24.7048 10 23.8213 10Z"
        fill="white"
      />
    </g>
    <path
      d="M52.9987 29C51.4892 29 50.3227 28.6195 49.4993 27.8585C48.676 27.0806 48.2643 25.939 48.2643 24.4339V12.283H51.4806V24.3578C51.4806 25.0004 51.6435 25.4993 51.9695 25.8545C52.3125 26.2096 52.7843 26.3872 53.3846 26.3872C54.1051 26.3872 54.7055 26.2012 55.1858 25.8291L56.0863 28.0868C55.7089 28.3912 55.2458 28.6195 54.6969 28.7717C54.148 28.9239 53.5819 29 52.9987 29ZM46 17.9146V15.3778H55.16V17.9146H46Z"
      fill="white"
    />
    <path
      d="M58.7321 28.8224V15.2764H61.7941V19.0053L61.4338 17.9146C61.8455 17.0013 62.4888 16.308 63.3636 15.8344C64.2556 15.3609 65.362 15.1242 66.6828 15.1242V18.1429C66.5456 18.109 66.417 18.0921 66.2969 18.0921C66.1768 18.0752 66.0567 18.0668 65.9367 18.0668C64.7188 18.0668 63.7496 18.4219 63.0291 19.1322C62.3087 19.8255 61.9484 20.8656 61.9484 22.2523V28.8224H58.7321Z"
      fill="white"
    />
    <path
      d="M76.8966 28.8224V26.0828L76.7165 25.4993V20.7049C76.7165 19.7748 76.4335 19.0561 75.8674 18.5487C75.3013 18.0245 74.4436 17.7624 73.2944 17.7624C72.5224 17.7624 71.7591 17.8807 71.0043 18.1175C70.2667 18.3542 69.6406 18.684 69.126 19.1068L67.8652 16.7984C68.6028 16.2403 69.4777 15.826 70.4897 15.5554C71.519 15.2679 72.5825 15.1242 73.6803 15.1242C75.6701 15.1242 77.2054 15.5977 78.2861 16.5447C79.3839 17.4749 79.9328 18.9208 79.9328 20.8825V28.8224H76.8966ZM72.5739 29C71.5447 29 70.6441 28.8309 69.8722 28.4927C69.1003 28.1375 68.4999 27.6555 68.0711 27.0467C67.6594 26.421 67.4535 25.7192 67.4535 24.9413C67.4535 24.1802 67.6337 23.4953 67.9939 22.8865C68.3713 22.2777 68.9802 21.7957 69.8207 21.4406C70.6613 21.0854 71.7763 20.9079 73.1657 20.9079H77.1539V23.0134H73.3973C72.2994 23.0134 71.5618 23.1909 71.1845 23.5461C70.8071 23.8843 70.6184 24.3071 70.6184 24.8144C70.6184 25.3894 70.85 25.846 71.3131 26.1842C71.7763 26.5225 72.4195 26.6916 73.2429 26.6916C74.032 26.6916 74.7353 26.514 75.3528 26.1589C75.9875 25.8037 76.442 25.2795 76.7165 24.5861L77.2568 26.4887C76.9481 27.2835 76.3906 27.9008 75.5844 28.3405C74.7953 28.7802 73.7918 29 72.5739 29Z"
      fill="white"
    />
    <path
      d="M86.685 25.8037L86.7622 21.8211L93.941 15.2764H97.8006L91.7025 21.3138L90.0043 22.7089L86.685 25.8037ZM84.0605 28.8224V10H87.2768V28.8224H84.0605ZM94.4299 28.8224L89.4125 22.7089L91.4452 20.1722L98.3409 28.8224H94.4299Z"
      fill="white"
    />
    <path
      d="M106.303 28.5221C105.119 28.5221 104.21 28.2008 103.575 27.5581C102.958 26.9155 102.649 26.0361 102.649 24.92V12.2871H103.935V24.7931C103.935 25.6387 104.15 26.2898 104.579 26.7464C105.008 27.203 105.625 27.4313 106.431 27.4313C107.272 27.4313 107.967 27.1861 108.515 26.6957L109.056 27.5835C108.713 27.9048 108.292 28.1416 107.795 28.2938C107.315 28.446 106.817 28.5221 106.303 28.5221ZM100.179 16.2698V15.179H108.207V16.2698H100.179Z"
      fill="white"
    />
    <path
      d="M117.621 28.5221C116.266 28.5221 115.065 28.2346 114.019 27.6596C112.99 27.0846 112.175 26.2898 111.574 25.2751C110.991 24.2604 110.7 23.102 110.7 21.7998C110.7 20.4807 110.974 19.3223 111.523 18.3245C112.089 17.3098 112.861 16.515 113.839 15.94C114.816 15.365 115.914 15.0775 117.132 15.0775C118.35 15.0775 119.439 15.3565 120.4 15.9146C121.378 16.4727 122.141 17.2591 122.69 18.2738C123.256 19.2715 123.539 20.43 123.539 21.7491C123.539 21.7998 123.53 21.859 123.513 21.9266C123.513 21.9774 123.513 22.0366 123.513 22.1042H111.652V21.1149H122.819L122.304 21.5969C122.321 20.5653 122.098 19.6436 121.635 18.8318C121.189 18.0032 120.58 17.3605 119.808 16.9039C119.036 16.4304 118.144 16.1937 117.132 16.1937C116.137 16.1937 115.245 16.4304 114.456 16.9039C113.684 17.3605 113.075 18.0032 112.629 18.8318C112.183 19.6436 111.96 20.5737 111.96 21.6222V21.8505C111.96 22.9329 112.201 23.8968 112.681 24.7424C113.178 25.571 113.856 26.2221 114.714 26.6957C115.571 27.1523 116.549 27.3806 117.647 27.3806C118.504 27.3806 119.302 27.2284 120.04 26.924C120.794 26.6196 121.429 26.1545 121.944 25.5288L122.69 26.3659C122.107 27.0762 121.369 27.6173 120.477 27.9894C119.602 28.3445 118.65 28.5221 117.621 28.5221Z"
      fill="white"
    />
    <path
      d="M125.798 28.4206V27.5581L134.984 15.8385L135.318 16.2698H125.927V15.179H136.322V16.0414L127.11 27.7611L126.724 27.3298H136.476V28.4206H125.798Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_2592_223690">
        <rect width={40.0004} height={40} rx={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TrakTezLogo;
