import Metric from '../../../../shared/Metric';
import Grid from '../../../../shared/Grid';
import GridItem from '../../../../shared/Grid/GridItem';
import { useGetPlanningMetricsQuery } from '../../../../redux/idiotBox/apis/lineView';
import { POLLING_INTERVAL_IN_MILLISECONDS } from '../../../../constants/polling';

const PlanningMetrics = () => {
  const { data, isLoading } = useGetPlanningMetricsQuery(undefined, {
    pollingInterval: POLLING_INTERVAL_IN_MILLISECONDS,
  });

  return (
    <div className="full-height v-justify-center">
      <Grid
        minHeight="40vh"
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="repeat(3, 1fr)"
        gap={24}
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <Metric
            heading="Planned Quantity"
            value={data?.planned_operators}
            valueColor="var(--white)"
            hasBottomBorder
          />
        </GridItem>
        <GridItem gridColumn="1/2" gridRow="2/3">
          <Metric
            heading="Actual Operators"
            value={data?.actual_operators}
            valueColor="var(--white)"
            hasBottomBorder
          />
        </GridItem>
        <GridItem gridColumn="1/2" gridRow="3/4">
          <Metric
            heading="Working Hours"
            value={data?.working_duration}
            valueColor="var(--white)"
          />
        </GridItem>
        <GridItem gridColumn="2/3" gridRow="2/3">
          <Metric
            heading="SAM"
            value={data?.sam}
            valueColor="var(--white)"
            hasBottomBorder
          />
        </GridItem>
        <GridItem gridColumn="2/3" gridRow="3/4">
          <Metric
            heading="Day Output"
            value={data?.day_output_expected}
            valueColor="var(--white)"
          />
        </GridItem>
      </Grid>
    </div>
  );
};

export default PlanningMetrics;
