import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { dashboardApi } from './dashboard/api';
import { reportsApi } from './reports/api';
import { sessionsApi } from './sessions/api';
import filtersReducer from './filters';
import uiReducer from './ui';
import userReducer from './user';
import { idiotBoxApi } from './idiotBox/api';
import { featureFlagsApi } from './featureFlags/api';
import { auditDashboardApi } from './auditDashboard/api';
import { auditReportsApi } from './auditReports/api';
import { auditSessionsApi } from './auditSessions/api';

export const history = createBrowserHistory();

const middleware = [
  routerMiddleware(history),
  reportsApi.middleware,
  dashboardApi.middleware,
  sessionsApi.middleware,
  idiotBoxApi.middleware,
  featureFlagsApi.middleware,
  auditDashboardApi.middleware,
  auditReportsApi.middleware,
  auditSessionsApi.middleware,
];

const rootReducer = combineReducers({
  user: userReducer,
  router: connectRouter(history),
  filters: filtersReducer,
  ui: uiReducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [sessionsApi.reducerPath]: sessionsApi.reducer,
  [idiotBoxApi.reducerPath]: idiotBoxApi.reducer,
  [featureFlagsApi.reducerPath]: featureFlagsApi.reducer,
  [auditDashboardApi.reducerPath]: auditDashboardApi.reducer,
  [auditReportsApi.reducerPath]: auditReportsApi.reducer,
  [auditSessionsApi.reducerPath]: auditSessionsApi.reducer,
});

export type RootStateType = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
  devTools: true,
});
