import { useRef } from 'react';
import useDimensions from '../../../../hooks/useDimensions';
import BarChart from '../../../../shared/BarChart';
import Segment from '../../../../shared/Segment';
import ChartWrapper from '../../../../shared/ChartWrapper';
import { useGetLineDhuQuery } from '../../../../redux/dashboard/api';
import { useTypedSelector } from '../../../../redux/useTypedSelector';

const LineDHU = () => {
  const areaChartWrapperRef = useRef<HTMLDivElement>(null);
  const { department, start_date, end_date } = useTypedSelector(
    store => store.filters.selected
  );
  const { data, isLoading, isFetching } = useGetLineDhuQuery({
    department,
    start_date,
    end_date,
  });
  const { height, width } = useDimensions(areaChartWrapperRef, [isLoading]);

  const viewMode = useTypedSelector(store => store.ui.viewMode);

  return (
    <Segment
      heading="Line DHU"
      description="Defects per hundred unit (DHU) for each hour"
      descriptionMaxWidth={180}
      isLoading={isLoading || isFetching}
    >
      <ChartWrapper wrapperRef={areaChartWrapperRef}>
        <BarChart
          data={data ?? []}
          axisStroke={viewMode === 'tv' ? 'var(--white)' : 'var(--fiord)'}
          height={height}
          width={width}
          yLabel="Defects"
        />
      </ChartWrapper>
    </Segment>
  );
};

export default LineDHU;
