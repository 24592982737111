import styled, { css } from 'styled-components';

export enum ReviewOperationsEnum {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export const CellData = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: var(--rhino);

  .tooltip-wrapper {
    width: inherit;
  }
`;

interface ISmallIconWrapperProps {
  background: string;
  size: number;
}

export const SmallIconWrapper = styled.div<ISmallIconWrapperProps>`
  height: ${props => `calc(4px + ${props.size}px)`};
  width: ${props => `calc(4px + ${props.size}px)`};
  border-radius: 100%;
  background: ${props => props.background};
`;

export const Paragraph = styled.div`
  width: 200px;
  max-height: 400px;
  overflow: auto;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: var(--rhino);
  white-space: break-spaces;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--rhino);
  text-align: left;
`;

export const TableWrapper = styled.div`
  .quality-table-header {
    border-radius: inherit;
  }
`;

export const LoaderWrapper = styled.div`
  bottom: 0;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  pointer-events: none;
  height: 100%;
  width: 100%;
`;

export const OrderId = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--all-ports);
  &:hover {
    text-decoration: underline;
  }
`;

export const SubText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: var(--lynch);
`;

export const BoldBlueText = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--all-ports);
  &:hover {
    text-decoration: underline;
  }
`;
