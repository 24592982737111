import { Spinner } from '@fashinza/fashinza-design-system';
import { useLocation, useParams } from 'react-router-dom';
import HourlyDHU from './HourlyDHU';
import HourlyLineEfficiency from './HourlyLineEfficiency';
import LineDHU from './LineDHU';
import OverallAnalytics from './OverallAnalytics';
import Grid from '../../../shared/Grid';
import GridItem from '../../../shared/Grid/GridItem';
import TvHeader from '../../../shared/TvHeader';
import { useGetMasterBaseAnalyticsQuery } from '../../../redux/idiotBox/apis/masterView';
import { POLLING_INTERVAL_IN_MILLISECONDS } from '../../../constants/polling';
import { capitalizeFirstLetter } from '../../../utils';
import useFeatureFlags from '../../../redux/featureFlags/useFeatureFlags';
import * as Styles from '../styles';

const MasterView = () => {
  const { data: overallData, isLoading } = useGetMasterBaseAnalyticsQuery(
    undefined,
    {
      pollingInterval: POLLING_INTERVAL_IN_MILLISECONDS,
    }
  );

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const department = capitalizeFirstLetter(params.get('department') ?? '');

  const { should_show_efficiency, isFetching: isLoadingFeatureFlags } =
    useFeatureFlags();

  return (
    <>
      <TvHeader
        currentHour={overallData?.current_hour ?? 0}
        indicators={[
          {
            value: `${department} Master`,
          },
        ]}
      />
      <Styles.Content $height="90vh">
        <Grid
          minHeight="100%"
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="1fr 1fr"
          gap={24}
        >
          <GridItem gridColumn="1/2" gridRow="1/2">
            <OverallAnalytics data={overallData} isLoading={isLoading} />
          </GridItem>
          <GridItem gridColumn="2/3" gridRow="1/2">
            <LineDHU />
          </GridItem>
          {isLoadingFeatureFlags ? (
            <GridItem gridColumn="1/3" gridRow="2/3">
              <div className="full-dimension center">
                <Spinner height={50} width={50} />
              </div>
            </GridItem>
          ) : (
            <>
              <GridItem
                gridColumn={should_show_efficiency ? '1/2' : '1/3'}
                gridRow="2/3"
              >
                <HourlyDHU />
              </GridItem>
              {should_show_efficiency && (
                <GridItem gridColumn="2/3" gridRow="2/3">
                  <HourlyLineEfficiency />
                </GridItem>
              )}
            </>
          )}
        </Grid>
      </Styles.Content>
    </>
  );
};

export default MasterView;
