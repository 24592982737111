import { Skeleton } from '@fashinza/fashinza-design-system';
import { DEFAULT_VALUES } from '../../constants';
import { useTypedSelector } from '../../redux/useTypedSelector';
import InfoTooltip from '../InfoTooltip';
import * as Styles from './styles';

interface IMetricProps {
  heading: string;
  value: number | string | undefined;
  description?: string;
  valueSuffix?: string;
  valueColor?:
    | 'var(--rhino)'
    | 'var(--deep-sea)'
    | 'var(--red-dirt)'
    | 'var(--chilean-fire)'
    | 'var(--white)';
  hasTopBorder?: boolean;
  hasBottomBorder?: boolean;
  hasLeftBorder?: boolean;
  hasRightBorder?: boolean;
  hasBorderRadius?: boolean;
  hasBackground?: boolean;
  isLoading?: boolean;
}

const Metric = ({
  heading,
  description,
  value,
  valueSuffix,
  isLoading,
  valueColor = 'var(--rhino)',
  hasTopBorder = false,
  hasBottomBorder = false,
  hasLeftBorder = false,
  hasRightBorder = false,
  hasBorderRadius = false,
  hasBackground = false,
}: IMetricProps) => {
  const viewMode = useTypedSelector(store => store.ui.viewMode);

  return (
    <Styles.Wrapper
      className="v-d-flex gap-4 full-height"
      $borderColor={viewMode === 'tv' ? 'var(--fiord)' : 'var(--athens-gray)'}
      $hasBottomBorder={hasBottomBorder}
      $hasTopBorder={hasTopBorder}
      $hasLeftBorder={hasLeftBorder}
      $hasRightBorder={hasRightBorder}
      $hasBorderRadius={hasBorderRadius}
      $hasBackground={hasBackground}
    >
      <Styles.Heading className="align-center">
        {heading}
        {description && (
          <InfoTooltip
            heading={description}
            iconSize={15}
            iconFill="var(--lynch)"
          />
        )}
      </Styles.Heading>
      {isLoading ? (
        <Skeleton height={46} width="100%" />
      ) : (
        <Styles.Value $color={valueColor} $viewMode={viewMode}>
          {value ?? DEFAULT_VALUES.NOT_APPLICABLE}
          {valueSuffix}
        </Styles.Value>
      )}
    </Styles.Wrapper>
  );
};

export default Metric;
