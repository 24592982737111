import { format } from 'date-fns';
import { createColumnHelper } from '@tanstack/react-table';
import { InspectionsType } from './schemas';
import useFakePagination from '../../../../../hooks/useFakePagination';
import Segment from '../../../../../shared/Segment';
import Table from '../../../../../shared/Table';
import { capitalizeFirstLetter } from '../../../../../utils';
import Attachments from './Attachments';

const columnHelper = createColumnHelper<InspectionsType[number]>();

const columns = [
  columnHelper.accessor('id', {
    id: 'id',
    header: 'Item Number',
    cell: info => info.getValue(),
    size: 5,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Result',
    cell: info => capitalizeFirstLetter(info.getValue()),
    size: 10,
  }),
  columnHelper.accessor('images', {
    id: 'images',
    header: 'Attachment',
    cell: info => <Attachments files={info.getValue() ?? []} />,
    size: 10,
  }),
  columnHelper.accessor('defect_or_reason', {
    id: 'defect_or_reason',
    header: 'Defects / Reason for rejection',
    cell: info => info.getValue(),
    size: 25,
  }),
  columnHelper.accessor('defect_section', {
    id: 'defect_section',
    header: 'Sections',
    cell: info => info.getValue(),
    size: 15,
  }),
  columnHelper.accessor('created_on', {
    id: 'created_on',
    header: 'Inspection Time',
    cell: info => format(new Date(info.getValue()), 'pp'),
    size: 25,
  }),
];

interface IInspectionsProps {
  data?: InspectionsType;
  isLoading: boolean;
}

const Inspections = ({ data, isLoading }: IInspectionsProps) => {
  const formattedData = data?.map((item, index) => ({
    ...item,
    item_number: index + 1,
  }));

  const { page, action } = useFakePagination(formattedData, 5);

  return (
    <Segment
      heading="Inspection details"
      shouldChildrenHavePadding={false}
      isLoading={isLoading}
    >
      <Table
        data={page.data ?? []}
        columns={columns}
        currentPage={page.current ?? 0}
        handlePagination={c =>
          c === 'next' ? action.next() : action.previous()
        }
        pageLength={5}
        totalCount={data?.length ?? 0}
        cellsWithNoPadding={['images']}
      />
    </Segment>
  );
};

export default Inspections;
