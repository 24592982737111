import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import Sidebar from '../../../shared/Sidebar';
import SessionsTable from '../Table';
import ActivityInfo from './ActivityInfo';
import { useTypedSelector } from '../../../redux/useTypedSelector';
import { capitalizeFirstLetter } from '../../../utils';
import AuditDetails from './AuditDetails';
import { useGetAuditSessionsQuery } from '../../../redux/auditSessions/api';

const AuditSessions = () => {
  const { session, department, start_date, end_date } = useTypedSelector(
    store => store.filters.selected
  );

  const {
    data: sessions,
    isLoading,
    isFetching,
  } = useGetAuditSessionsQuery({
    session,
    department,
    start_date,
    end_date,
  });

  const [selectedSessionId, setSelectedSessionId] = useState(-1);

  function openDetailsView(sessionId: number) {
    setSelectedSessionId(() => sessionId);
  }

  function closeDetailsView() {
    setSelectedSessionId(-1);
  }

  const detailsData = sessions?.data[selectedSessionId];

  return (
    <AnimatePresence>
      <ActivityInfo />
      <SessionsTable
        mode="audit"
        height={720}
        onClickViewDetails={openDetailsView}
        data={sessions?.data ?? {}}
        ids={sessions?.ids ?? []}
        isLoading={isLoading}
        isFetching={isFetching}
      />
      <Sidebar
        isOpen={selectedSessionId > -1 && Boolean(detailsData)}
        heading={detailsData ? `${detailsData.collection_name} Details` : ''}
        subHeadings={
          detailsData
            ? [
                detailsData.line_name,
                capitalizeFirstLetter(detailsData.department),
              ]
            : []
        }
        onClose={closeDetailsView}
      >
        <AuditDetails sessionId={selectedSessionId} />
      </Sidebar>
    </AnimatePresence>
  );
};

export default AuditSessions;
