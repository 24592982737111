import React, { useRef } from 'react';
import ChartWrapper from '../../../../../../shared/ChartWrapper';
import VerticalBarChart from '../../../../../../shared/VerticalBarChart';
import { useGetLineDefectDataByIdQuery } from '../../../../../../redux/dashboard/api';
import Segment from '../../../../../../shared/Segment';
import { PaginationWrapper } from '../../../../../../shared/Table/styles';
import ManualPagination from '../../../../../../shared/ManualPagination';
import useFakePagination from '../../../../../../hooks/useFakePagination';

interface IDefectsProps {
  department: string;
  endlineId: number;
  midlineId?: number;
}

const Defects = ({ department, endlineId, midlineId }: IDefectsProps) => {
  const chartWrapperRef = useRef<HTMLDivElement>(null);

  const { data, isFetching, isLoading } = useGetLineDefectDataByIdQuery({
    department,
    endlineId,
    midlineId,
  });

  const { page, action } = useFakePagination(data, 10);

  return (
    <Segment
      heading="Defects"
      description="All defects and their counts"
      descriptionMaxWidth={300}
      shouldChildrenHavePadding={false}
      isLoading={isLoading || isFetching}
    >
      <ChartWrapper wrapperRef={chartWrapperRef}>
        <VerticalBarChart
          barFill="var(--cerise)"
          data={
            page.data?.map(item => ({
              name: item.defect_type,
              value: item.count,
            })) ?? []
          }
        />
      </ChartWrapper>
      <PaginationWrapper className="flex-justify-end">
        <ManualPagination
          kind="manual"
          totalCount={data?.length ?? 0}
          pageLength={10}
          currentPage={page.current ?? 0}
          onChangePage={c => (c === 'next' ? action.next() : action.previous())}
        />
      </PaginationWrapper>
    </Segment>
  );
};

export default Defects;
