import ArrowIcon from './ArrowIcon';
import * as Styles from './styles';

interface ICommonProps {
  totalCount: number;
  onChangePage(cursor: 'previous' | 'next'): void;
  totalCountSuffix?: string;
}

interface IAutomaticCalculation extends ICommonProps {
  kind: 'automatic';
  initialCount: number;
  endCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

interface IManualCalculation extends ICommonProps {
  kind: 'manual';
  pageLength: number;
  currentPage: number;
  shouldShowCurrentPage?: boolean;
}

type ManualPaginationPropsType = IAutomaticCalculation | IManualCalculation;

const ManualPagination = ({
  totalCount,
  onChangePage,
  totalCountSuffix,
  ...restProps
}: ManualPaginationPropsType) => {
  const hasPreviousPage =
    restProps.kind === 'automatic'
      ? restProps.hasPrevious
      : restProps.currentPage !== 1;

  const hasNextPage =
    restProps.kind === 'automatic'
      ? restProps.hasNext
      : restProps.currentPage !==
        Math.max(Math.ceil(totalCount / restProps.pageLength), 1);

  const minItemCount =
    restProps.kind === 'automatic'
      ? restProps.initialCount
      : Math.min(
          (restProps.currentPage - 1) * restProps.pageLength + 1,
          totalCount
        );

  const maxItemCount =
    restProps.kind === 'automatic'
      ? restProps.endCount
      : Math.min(minItemCount + restProps.pageLength - 1, totalCount);

  const CurrentDetails = () =>
    restProps.kind === 'manual' && restProps.shouldShowCurrentPage ? (
      <>#{restProps.currentPage}</>
    ) : (
      <>
        {minItemCount}-{maxItemCount}
      </>
    );

  function onPrevious() {
    if (hasPreviousPage) {
      onChangePage('previous');
    }
  }

  function onNext() {
    if (hasNextPage) {
      onChangePage('next');
    }
  }

  return (
    <div className="align-center">
      <Styles.PageButton
        disabled={!hasPreviousPage}
        className="center cursor-pointer"
        onClick={onPrevious}
      >
        <ArrowIcon
          viewType="left"
          size={16}
          strokeWidth={1.8}
          stroke={hasPreviousPage ? 'var(--rhino)' : 'var(--lynch)'}
        />
      </Styles.PageButton>
      <Styles.PageButton
        disabled={!hasNextPage}
        className="center cursor-pointer"
        onClick={onNext}
      >
        <ArrowIcon
          viewType="right"
          size={16}
          strokeWidth={1.8}
          stroke={hasNextPage ? 'var(--rhino)' : 'var(--lynch)'}
        />
      </Styles.PageButton>
      <Styles.CurrentDetails>
        <CurrentDetails />
      </Styles.CurrentDetails>
      <Styles.TotalDetails>
        &nbsp;out of {totalCount} {totalCountSuffix}
      </Styles.TotalDetails>
    </div>
  );
};

export default ManualPagination;
