import { ObjectType } from '../../@types/defined';
import { removeFalsyKeys } from '../../utils/objects';
import { IFiltersState } from '../filters';

export function getFormattedFilters(
  filtersObject: Partial<IFiltersState['selected']>
): string {
  const cleanFiltersObject = removeFalsyKeys(filtersObject);

  const formattedFilters: ObjectType = {};

  Object.keys(cleanFiltersObject).forEach((key: string) => {
    if (
      key === 'start_date' ||
      (key === 'end_date' &&
        cleanFiltersObject.start_date &&
        cleanFiltersObject.end_date)
    ) {
      formattedFilters.date_range = `${cleanFiltersObject.start_date},${cleanFiltersObject.end_date}`;
    } else if (key === 'department') {
      formattedFilters[key] = cleanFiltersObject[key].replace('_audit', '');
    } else {
      formattedFilters[key] = cleanFiltersObject[key];
    }
  });

  const urlSearchParams = new URLSearchParams(formattedFilters);
  return urlSearchParams.toString();
}
