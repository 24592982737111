import { SVGProps } from 'react';
import { IconBaseProps } from 'react-icons/lib';

export type IconPropsType = SVGProps<SVGSVGElement> &
  IconBaseProps & { viewType?: string };

interface IArrowIconProps extends IconPropsType {}

const PATH_DRAWN = Object.freeze({
  left: 'M19.5 12H4.5M4.5 12L11.25 18.75M4.5 12L11.25 5.25',
  up: 'M12 19.5V4.5M12 4.5L5.25 11.25M12 4.5L18.75 11.25',
  down: 'M12 4.5V19.5M12 19.5L18.75 12.75M12 19.5L5.25 12.75',
  right: 'M4.5 12H19.5M19.5 12L12.75 5.25M19.5 12L12.75 18.75',
});

const ArrowIcon = ({
  size = 24,
  stroke = 'var(--rhino)',
  strokeWidth = 1.25,
  viewType = 'left',
  ...props
}: IArrowIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={PATH_DRAWN[viewType as keyof typeof PATH_DRAWN]}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowIcon;
