import { ObjectType } from '../../@types/defined';
import { removeFalsyKeys } from '../../utils/objects';
import { IFiltersState } from '../filters';

export function getFormattedFilters(
  filtersObject: Partial<IFiltersState['selected']>
): string {
  const cleanFiltersObject = removeFalsyKeys(filtersObject);

  const formattedFilters: ObjectType = {};

  Object.keys(cleanFiltersObject).forEach((key: string) => {
    if (
      key === 'start_date' ||
      (key === 'end_date' &&
        cleanFiltersObject.start_date &&
        cleanFiltersObject.end_date)
    ) {
      formattedFilters.date_range = `${cleanFiltersObject.start_date},${cleanFiltersObject.end_date}`;
    } else {
      formattedFilters[key] = cleanFiltersObject[key];
    }
  });

  const urlSearchParams = new URLSearchParams(formattedFilters);
  return urlSearchParams.toString();
}

export function getLineDefectApiUrl({
  department,
  endlineId,
  midlineId,
}: {
  department: string;
  endlineId: number;
  midlineId?: number;
}) {
  if (midlineId) {
    return `/top-defect/${endlineId}/midline/${midlineId}/?department=${department}`;
  }

  return `/top-defect/${endlineId}/endline/?department=${department}`;
}

export function getLineAreaOfConcernApiUrl({
  department,
  endlineId,
  midlineId,
}: {
  department: string;
  endlineId: number;
  midlineId?: number;
}) {
  if (midlineId) {
    return `/top-defect-section/${endlineId}/midline/${midlineId}/?department=${department}`;
  }

  return `/top-defect-section/${endlineId}/endline/?department=${department}`;
}
