import Icon from './Icon';
import MasterView from '../MasterView';
import Segment from '../../../shared/Segment';
import { FactoryTvLineListItemsType, FactoryTvListItemsType } from '../schemas';
import LineView from '../LineView';

interface IStitchingProps {
  masterLines: FactoryTvListItemsType | undefined;
  lines: FactoryTvLineListItemsType;

  isLoading: boolean;
}

const Stitching = ({ lines, masterLines, isLoading }: IStitchingProps) => (
  <Segment
    isHeadingLarge
    heading="Stitching"
    icon={<Icon />}
    shouldChildrenHavePadding={false}
    isLoading={isLoading}
    className="gap-32"
  >
    {masterLines?.map(masterData => (
      <MasterView
        key={masterData.id}
        department="Stitching"
        data={masterData}
      />
    ))}
    {lines.length > 0 && <LineView lineName="Stitching" lines={lines} />}
  </Segment>
);

export default Stitching;
