import QappTracker from '../../analytics/tracker';
import { openInNewTab } from '../../utils';
import { FactoryTvListItemsType, FactoryTvLineListItemsType } from './schemas';

export function openLineTvView(data: FactoryTvLineListItemsType[number]) {
  let department;
  let view;

  switch (data.worker_department) {
    case 'finishing': {
      view = 'line';
      department = 'finishing';
      break;
    }
    case 'stitching': {
      view = 'line';
      department = 'stitching';
      break;
    }

    default: {
      // do some error handling
    }
  }

  openInNewTab(
    `/factory-qapp/tv?department=${department}&view=${view}&line_id=${data.id}`
  );
  QappTracker.track('OpenTvDashboard', {
    department,
    view: 'line',
    line_id: data.line_tv_id,
  });
}

export function openMasterTvView(data: FactoryTvListItemsType[number]) {
  let department;
  let view;

  switch (data.worker_department) {
    case 'line_stitching_tv': {
      view = 'line';
      department = 'finishing';
      break;
    }
    case 'master_stitching_tv': {
      view = 'master';
      department = 'stitching';
      break;
    }
    case 'line_finishing_tv': {
      view = 'line';
      department = 'finishing';
      break;
    }
    case 'master_finishing_tv': {
      view = 'master';
      department = 'finishing';
      break;
    }

    default: {
      // do some error handling
    }
  }

  openInNewTab(`/factory-qapp/tv?department=${department}&view=${view}`);
  QappTracker.track('OpenTvDashboard', {
    department,
    view: 'master',
  });
}
