import GridItem from '../../../../../shared/Grid/GridItem';
import Metric from '../../../../../shared/Metric';
import Grid from '../../../../../shared/Grid';
import { numberWithCommas } from '../../../../../utils/numbers';
import { DEFAULT_VALUES } from '../../../../../constants';
import type { LineOverallDataType } from './schemas';
import useFeatureFlags from '../../../../../redux/featureFlags/useFeatureFlags';

interface IOverallDataCardsProps {
  data: LineOverallDataType | undefined;
  isLoading: boolean;
}

const OverallDataCards = ({ data, isLoading }: IOverallDataCardsProps) => {
  const { should_show_efficiency } = useFeatureFlags();

  return (
    <Grid
      gridTemplateRows="1fr"
      gap={24}
      gridTemplateColumns={
        should_show_efficiency ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)'
      }
    >
      {should_show_efficiency && (
        <GridItem gridColumn="1/2" gridRow="1/2">
          <Metric
            heading="Total Efficiency"
            value={numberWithCommas(data?.efficiency)}
            valueColor="var(--deep-sea)"
            hasBorderRadius
            hasTopBorder
            hasLeftBorder
            hasBottomBorder
            hasRightBorder
            isLoading={isLoading}
          />
        </GridItem>
      )}

      <GridItem
        gridColumn={should_show_efficiency ? '2/3' : '1/2'}
        gridRow="1/2"
      >
        <Metric
          heading="Midline Checkers"
          value={
            data?.midline ? data.midline.length : DEFAULT_VALUES.DOUBLE_DASH
          }
          hasBorderRadius
          hasTopBorder
          hasLeftBorder
          hasBottomBorder
          hasRightBorder
          isLoading={isLoading}
        />
      </GridItem>
      <GridItem
        gridColumn={should_show_efficiency ? '3/4' : '2/3'}
        gridRow="1/2"
      >
        <Metric
          heading="Total DHU"
          value={numberWithCommas(data?.dhu)}
          valueSuffix="%"
          hasBorderRadius
          hasTopBorder
          hasLeftBorder
          hasBottomBorder
          hasRightBorder
          isLoading={isLoading}
        />
      </GridItem>
      <GridItem
        gridColumn={should_show_efficiency ? '4/5' : '3/4'}
        gridRow="1/2"
      >
        <Metric
          heading="FTT"
          valueSuffix="%"
          value={numberWithCommas(data?.ftt)}
          hasBorderRadius
          hasTopBorder
          hasLeftBorder
          hasBottomBorder
          hasRightBorder
          isLoading={isLoading}
        />
      </GridItem>
    </Grid>
  );
};

export default OverallDataCards;
