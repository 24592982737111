import { z } from 'zod';

export const HourlyLineEfficiencyItemSchema = z.object({
  hour: z.string(),
  efficiency: z.number(),
});

export type HourlyLineEfficiencyType = z.infer<
  typeof HourlyLineEfficiencyItemSchema
>;

export const HourlyLineEfficienciesSchema = z.array(
  HourlyLineEfficiencyItemSchema
);

export type HourlyLineEfficienciesType = z.infer<
  typeof HourlyLineEfficienciesSchema
>;
