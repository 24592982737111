import Segment from '../../../../shared/Segment';
import TableWithVerticalBarChart from '../../../../shared/TableWithVerticalBarChart';
import { useGetHourlyFttDataQuery } from '../../../../redux/idiotBox/apis/lineView';
import { POLLING_INTERVAL_IN_MILLISECONDS } from '../../../../constants/polling';
import FakeTablePagination, {
  useFakeTablePagination,
} from '../../../../shared/FakeTablePagination';

const HourlyFttData = () => {
  const { data, isLoading } = useGetHourlyFttDataQuery(undefined, {
    pollingInterval: POLLING_INTERVAL_IN_MILLISECONDS,
  });

  const { page, total } = useFakeTablePagination({ data: data ?? [], size: 8 });

  return (
    <Segment
      heading="Hourly Data"
      isLoading={isLoading}
      headerContent={
        <FakeTablePagination total={total} current={page.current ?? 0} />
      }
    >
      <TableWithVerticalBarChart
        barFill="var(--astro-purple)"
        barSize={16}
        data={
          page.data?.map(item => ({
            ...item,
            ftt: `${item.ftt} (${item.ftt_percentage} %)`,
          })) ?? []
        }
        chartHeader="Hours"
        axisDataKey="hour"
        barDataKey="inspected_count"
        yAxisWidth={75}
        headers={[
          {
            accessor: 'inspected_count',
            text: 'Count',
          },
          {
            accessor: 'ftt',
            text: 'FTT Rate',
          },
        ]}
      />
    </Segment>
  );
};

export default HourlyFttData;
