import { IconPropsType } from './types';

interface ICreditCardIconProps extends IconPropsType {}

const PATH_DRAWN = Object.freeze({
  default:
    'M3 10H21M7 15H8M12 15H13M6 19H18C19.6569 19 21 17.6569 21 16V8C21 6.34315 19.6569 5 18 5H6C4.34315 5 3 6.34315 3 8V16C3 17.6569 4.34315 19 6 19Z',
  alternate:
    'M3 9.2H21M5.76923 14.1H11.3077M5.76923 16.2H8.53846M5.07692 19H18.9231C19.4739 19 20.0022 18.7788 20.3917 18.3849C20.7812 17.9911 21 17.457 21 16.9V7.1C21 6.54305 20.7812 6.0089 20.3917 5.61508C20.0022 5.22125 19.4739 5 18.9231 5H5.07692C4.52609 5 3.99782 5.22125 3.60832 5.61508C3.21882 6.0089 3 6.54305 3 7.1V16.9C3 17.457 3.21882 17.9911 3.60832 18.3849C3.99782 18.7788 4.52609 19 5.07692 19Z',
});

const CreditCardIcon = ({
  size = 24,
  stroke = 'var(--rhino)',
  strokeWidth = 1.25,
  viewType = 'default',
  ...props
}: ICreditCardIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={PATH_DRAWN[viewType as keyof typeof PATH_DRAWN]}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CreditCardIcon;
