import { ObjectType } from '../@types/defined';
import { isEmpty } from './assertion';

export function getObjectKeys<T extends ObjectType>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}

export function removeFalsyKeys(object: ObjectType) {
  let result: ObjectType = {};
  getObjectKeys(object).forEach(eachKey => {
    const value = object[eachKey];
    if (!isEmpty(value)) {
      result = {
        ...result,
        [eachKey]: value,
      };
    }
  });
  return result;
}

export function omit<T extends ObjectType, TKey extends keyof T>(
  object: T,
  keysToRemove: Array<TKey>
) {
  const result: ObjectType = {};
  const stringifiedKeys = keysToRemove.map(x => String(x));

  Object.keys(object).forEach(eachKey => {
    if (stringifiedKeys.includes(eachKey)) {
      return;
    }
    result[eachKey] = object[eachKey];
  });

  return result as Omit<T, TKey>;
}
