import { useRef } from 'react';
import useDimensions from '../../../../hooks/useDimensions';
import AreaChart from '../../../../shared/AreaChart';
import Segment from '../../../../shared/Segment';
import ChartWrapper from '../../../../shared/ChartWrapper';
import { useGetHourlyLineEfficiencyQuery } from '../../../../redux/dashboard/api';
import { useTypedSelector } from '../../../../redux/useTypedSelector';

const HourlyLineEfficiency = () => {
  const areaChartWrapperRef = useRef<HTMLDivElement>(null);
  const viewMode = useTypedSelector(store => store.ui.viewMode);
  const { department, start_date, end_date } = useTypedSelector(
    store => store.filters.selected
  );
  const { data, isLoading, isFetching } = useGetHourlyLineEfficiencyQuery({
    department,
    start_date,
    end_date,
  });
  const { height, width } = useDimensions(areaChartWrapperRef, [isLoading]);

  return (
    <Segment
      heading="Hourly Line Efficiency"
      description="Hourly line efficiency for all lines"
      descriptionMaxWidth={180}
      isLoading={isLoading || isFetching}
    >
      <ChartWrapper wrapperRef={areaChartWrapperRef}>
        <AreaChart
          data={data ?? []}
          height={height}
          width={width}
          xAxisDataKey="hour"
          xAxisLabel="Hours"
          yAxisLabel="Efficiency"
          axisStroke={viewMode === 'tv' ? 'var(--white)' : 'var(--fiord)'}
          areaData={[
            {
              dataKey: 'efficiency',
              stroke: viewMode === 'tv' ? 'var(--white)' : 'var(--cerise)',
              strokeWidth: 2,
              gradientOpacity: 0.25,
            },
          ]}
        />
      </ChartWrapper>
    </Segment>
  );
};

export default HourlyLineEfficiency;
