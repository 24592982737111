export enum RestMethodsEnum {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

export enum ApiResponseEnum {
  Success,
  Failure,
  NetworkFailure,
}

interface INetworkSuccess<T> {
  type: ApiResponseEnum.Success;
  data: T;
  msg?: string;
}

interface INetworkFailure {
  type: ApiResponseEnum.Failure;
  error: string;
  source: 'response' | 'network';
}

export type APIResponseType<T> =
  | {
      status: true;
      data: T;
      msg: string;
    }
  | {
      status: false;
      msg: string;
    };

export interface IListRequest {
  page: number;
  qlQuery?: string;
}

export interface IListResponse<T> {
  results: Array<T>;
  count: number;
}

export type ServerFunctionType<T> = (
  argument: IListRequest
) => Promise<INetworkSuccess<IListResponse<T>> | INetworkFailure>;

export type DetailServerFunctionType<T> = (
  itemId: number
) => Promise<INetworkSuccess<T> | INetworkFailure>;

export type UpdateDetailServerFunctionType<T> = (
  itemId: number,
  data: Partial<T>
) => Promise<INetworkSuccess<T> | INetworkFailure>;

export type NonPaginatedListServerFunctionType<T> = () => Promise<
  INetworkSuccess<Array<T>> | INetworkFailure
>;

export type ApiResponseHandlerType<T> = INetworkSuccess<T> | INetworkFailure;

interface IRawApiSuccess<TSuccessData, TSuccessMessage> {
  type: ApiResponseEnum.Success;
  data: TSuccessData;
  msg?: TSuccessMessage;
  response: Response;
}

interface IRawApiFailure<TErrorData, TErrorMessage> {
  type: ApiResponseEnum.Failure;
  data: TErrorData;
  msg: TErrorMessage;
  source: 'response';
  response: Response;
}

interface IRawNetworkFailure {
  type: ApiResponseEnum.NetworkFailure;
  error: string;
  source: 'network';
}

export type RawApiResponseHandlerType<
  TSuccessData,
  TSuccessMessage,
  TErrorData,
  TErrorMessage
> =
  | IRawApiSuccess<TSuccessData, TSuccessMessage>
  | IRawApiFailure<TErrorData, TErrorMessage>
  | IRawNetworkFailure;

export type SimpleApiResponse<T> = {
  status: true;
  data: T;
  msg: string;
};

export type AtrociousApiResponse<T> = [T];
