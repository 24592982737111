export function getFiltersFromUrl() {
  const params = new URLSearchParams(window.location.search);
  let filters = '?';
  const department = params.get('department');
  const lineId = params.get('line_id');
  if (department) {
    // if (department.includes('stitching')) {
    //   filters += `department=stitching`;
    // } else if (department.includes('finishing')) {
    //   filters += `department=finishing`;
    // }
    filters += `department=${department}`;
  }
  if (lineId) {
    filters += `&line_id=${lineId}`;
  }
  return filters;
}
