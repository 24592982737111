import { IconPropsType } from './types';

interface IRfqsIconProps extends IconPropsType {}

const RfqsIcon = ({
  stroke = 'var(--rhino)',
  strokeWidth = 1.25,
  ...props
}: IRfqsIconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0245 7.90909V3H4.06995V17.1818L6.29722 14.4545H9.02449M12.2972 10.0909H6.84267M6.84267 6.81818H12.2972M13.7154 17.1818L15.0245 18.2727L17.2063 15.4364M15.5699 12.2727C13.16 12.2727 11.2063 14.2264 11.2063 16.6364C11.2063 19.0463 13.16 21 15.5699 21C17.9799 21 19.9336 19.0463 19.9336 16.6364C19.9336 14.2264 17.9799 12.2727 15.5699 12.2727Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RfqsIcon;
