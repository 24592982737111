import { BaseSyntheticEvent } from 'react';
import { ObjectType } from '../@types/defined';
import { isEmptyObject } from './assertion';

export function getObjectKeys<T extends ObjectType>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}

export function openInNewTab(url: string) {
  window.open(url, '_blank')?.focus();
}

export function openInSameTab(url: string) {
  window.open(url, '_self')?.focus();
}

export function formatNumberToLocale(numberToFormat: number, locale?: 'en-IN') {
  return new Intl.NumberFormat(locale).format(numberToFormat);
}

export function pluralize(str: string, counter: number, suffix = 's'): string {
  return counter === 1 ? str : `${str}${suffix}`;
}

export const getNameInitials = (name = '') => {
  name = name ? name.trim() : '';
  const parts = name.split(' ');
  let initials = '';

  if (name.length === 0) {
    initials = 'AU';
  } else {
    parts.length > 1
      ? (initials =
          parts[0][0].toUpperCase() + parts[parts.length - 1][0].toUpperCase())
      : (initials = parts[0][0].toUpperCase());
  }
  return initials;
};

const DEFAULT_UUID_LENGTH = 24;
const UUID_POSSIBLE_CHARACTERS =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export const ALPHABETS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export function generateUuid(
  length = DEFAULT_UUID_LENGTH,
  possibleCharacters = UUID_POSSIBLE_CHARACTERS
): string {
  return Array(length)
    .fill(1)
    .map(
      () =>
        possibleCharacters[
          Math.floor(Math.random() * possibleCharacters.length)
        ]
    )
    .join('');
}

export function createArrayOfSize(elementCount: number) {
  return Array.from(Array(elementCount).keys());
}

export const detectKeyPress = (e: any) => {
  const returnobj: ObjectType = {};
  if ('key' in e) {
    switch (e.key) {
      case 'Escape':
      case 'Esc': {
        returnobj['escape'] = true;
        break;
      }
      case 'ArrowLeft': {
        returnobj['leftarrow'] = true;
        break;
      }
      case 'ArrowUp': {
        returnobj['uparrow'] = true;
        break;
      }
      case 'ArrowRight': {
        returnobj['rightarrow'] = true;
        break;
      }
      case 'ArrowDown': {
        returnobj['downarrow'] = true;
        break;
      }
      case 'Enter': {
        returnobj['enter'] = true;
        break;
      }
      case 'Backspace': {
        returnobj['Backspace'] = true;
        break;
      }
      default:
        break;
    }
  } else {
    switch (e.keyCode) {
      case 27:
      case '27': {
        returnobj['escape'] = true;
        break;
      }
      case 37:
      case '37': {
        returnobj['leftarrow'] = true;
        break;
      }
      case 38:
      case '38': {
        returnobj['uparrow'] = true;
        break;
      }
      case 39:
      case '39': {
        returnobj['rightarrow'] = true;
        break;
      }
      case 40:
      case '40': {
        returnobj['downarrow'] = true;
        break;
      }
      case 13:
      case '13': {
        returnobj['enter'] = true;
        break;
      }
      default:
        break;
    }
  }
  return returnobj;
};

export const titleCase = (string = '') => {
  const newString = string.toLowerCase().split(' ');
  if (newString.length) {
    return newString
      .map(function (word) {
        if (!word) return '';
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(' ');
  }
  return '';
};

export const removeUnderscoresAndCapitalize = (str: string | number) =>
  titleCase(String(str)).replaceAll('_', ' ');

export function swallowEvent(event: BaseSyntheticEvent) {
  event.preventDefault();
  event.stopPropagation();
}

export function getInitialsNames(name: string) {
  const [firstName, lastName] = name.split(' ');
  const calculatedName =
    firstName && lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`
      : firstName.charAt(0);

  return calculatedName.length > 0 ? calculatedName : 'F';
}

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getRelativeTopCoords = (event: any) => {
  let top = event ? 0 : 'auto';
  try {
    const parentCoords =
      event.currentTarget.offsetParent.getBoundingClientRect();
    const childCoords = event.currentTarget.getBoundingClientRect();
    top = childCoords.top - (parentCoords?.top ?? 0) + childCoords.height;
  } catch (err) {}
  return { top, left: ' auto' };
};

export const createElementWithEvent = ({
  value,
  dataname,
  onChange,
  event,
  dataProps,
}: any) => {
  const elem = document.createElement('input');
  if (!isEmptyObject(dataProps)) {
    for (const item in dataProps) {
      if (dataProps.hasOwnProperty(item)) {
        elem.setAttribute(item, dataProps[item]);
      }
    }
  }
  elem.setAttribute('data-name', dataname);
  elem.setAttribute('value', value);
  elem.addEventListener(event, onChange);
  elem.dispatchEvent(new Event(event));
  return elem;
};

export function percentage(target: number, percent: number) {
  return (target * percent) / 100;
}
