import { styled } from 'styled-components';
import { GrClose } from 'react-icons/gr';
import { AnimatePresence, motion } from 'framer-motion';
import QappTracker from '../../analytics/tracker';

const Wrapper = styled.div`
  gap: 8px 16px;
`;

const Pill = styled(motion.div)`
  padding: 2px 8px;
  border-radius: 12px;
  background: var(--athens-gray);

  span {
    font-size: 12px;
    font-weight: 600;
    max-width: 150px;
  }
`;

interface IFilterPillsProps {
  items: Array<{
    key: string;
    value: string | null | undefined;
    onClick?: VoidFunction;
  }>;
}

const FilterPills = ({ items }: IFilterPillsProps) => (
  <Wrapper className="d-flex flex-wrap">
    {items
      .filter(item => item.value !== '')
      .filter(item => item.value !== null)
      .sort(item => (item.onClick ? 0 : -1))
      .map(item => (
        <Pill
          key={item.key}
          className="align-center gap-6"
          initial={{ transform: 'scale(0)' }}
          animate={{ transform: 'scale(1)', transition: { duration: 0.4 } }}
          // exit={{ transform: 'scale(0)', transition: { duration: 1 } }}
        >
          <span className="truncated-text">
            {item.key} : {item.value}
          </span>
          {item.onClick && (
            <GrClose
              size={12}
              onClick={() => {
                item?.onClick?.();
                QappTracker.track('ClearFilter', {
                  filter_name: item.key,
                  current_page: 'reports',
                });
              }}
              className="cursor-pointer"
            />
          )}
        </Pill>
      ))}
  </Wrapper>
);

export default FilterPills;
