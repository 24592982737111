import { ReactNode } from 'react';
import * as Styles from './styles';

interface IKeyValueTableProps {
  data: Array<{
    key: string | number;
    value: string | number | ReactNode;
  }>;
  shouldHavePadding?: boolean;
}

const KeyValueTable = ({
  data,
  shouldHavePadding = false,
}: IKeyValueTableProps) => (
  <Styles.Table className="full-flex" $shouldHavePadding={shouldHavePadding}>
    {data.map(pair => (
      <tr key={pair.key}>
        <Styles.Th>{pair.key} :</Styles.Th>
        <Styles.Td>{pair.value}</Styles.Td>
      </tr>
    ))}
  </Styles.Table>
);

export default KeyValueTable;
