const Icon = () => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.447 9.944V35.87H29.42V19.42c0-.752-.603-1.36-1.35-1.36H16.166c-.747 0-1.35.608-1.35 1.36v5.31c0 .752-.603 1.36-1.35 1.36H7.722c-.747 0-1.35-.608-1.35-1.36V9.944c0-.753.603-1.36 1.35-1.36h33.375c.746 0 1.35.607 1.35 1.36Zm1.536 25.926H4.017c-.746 0-1.35.61-1.35 1.36v6.743c0 .75.604 1.36 1.35 1.36h39.966c.746 0 1.35-.61 1.35-1.36V37.23c0-.752-.604-1.36-1.35-1.36Z"
      fill="#fff"
    />
    <path
      d="M10.58 26.09v5.903M30.355 2.666v5.918m6.49-3.458v3.458m5.602 1.36V35.87H29.42V19.42c0-.752-.603-1.36-1.35-1.36H16.166c-.747 0-1.35.608-1.35 1.36v5.31c0 .752-.603 1.36-1.35 1.36H7.722c-.747 0-1.35-.608-1.35-1.36V9.944c0-.753.603-1.36 1.35-1.36h33.375c.746 0 1.35.607 1.35 1.36ZM4.017 35.87h39.966c.746 0 1.35.61 1.35 1.36v6.743c0 .75-.604 1.36-1.35 1.36H4.017c-.746 0-1.35-.61-1.35-1.36V37.23c0-.752.604-1.36 1.35-1.36Z"
      stroke="#2C3556"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.386 22.17a2.422 2.422 0 0 0 2.413-2.43 2.422 2.422 0 0 0-2.413-2.431 2.422 2.422 0 0 0-2.412 2.43 2.42 2.42 0 0 0 2.412 2.43Z"
      stroke="#AF2660"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
