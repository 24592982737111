import { Button } from '@fashinza/fashinza-design-system';
import { format } from 'date-fns';
import {
  capitalizeFirstLetter,
  removeUnderscoresAndCapitalize,
} from '../../../utils';
import { DEFAULT_VALUES } from '../../../constants';
import ICONOGRAPHY from '../../../shared/iconography';
import TooltipWrapper from '../../../shared/VirtualizedTable/EditableCell/TooltipWrapper';
import { SessionType } from '../Ordinary/schemas';
import { AuditSessionType } from '../Audit/schemas';
import * as Styles from './styles';

const { stitching: StitchingIcon, finishing: FinishingIcon } = ICONOGRAPHY;

interface ICellContentProps {
  data: SessionType | AuditSessionType;
  identifier: string;
  onClickViewDetails?: (sessionId: number) => void;
}

const CellContent = ({
  data,
  identifier,
  onClickViewDetails,
}: ICellContentProps) => {
  switch (identifier) {
    case 'id': {
      return (
        <Styles.CellData className="d-flex full-height">
          <div className="v-d-flex">
            <Styles.BoldBlueText className="full-width">
              {data.id}
            </Styles.BoldBlueText>
            <Styles.SubText>
              {format(new Date(data.created_on), 'dd MMM yyyy')}
            </Styles.SubText>
          </div>
          <div className="end-align-center full-flex">
            <Button
              onClick={() => onClickViewDetails?.(data.id)}
              variant="outline"
              colorScheme="neutral"
              sizeVariant="small"
            >
              View Details
            </Button>
          </div>
        </Styles.CellData>
      );
    }

    case 'style_name': {
      return (
        <Styles.CellData className="full-height align-center">
          <TooltipWrapper
            valueToShow={data.style_name ?? DEFAULT_VALUES.NOT_APPLICABLE}
            textType="main-text"
          />
        </Styles.CellData>
      );
    }

    case 'department': {
      return (
        <Styles.CellData className="full-height v-justify-center">
          <Styles.Text>{capitalizeFirstLetter(data.department)}</Styles.Text>
          <Styles.SubText>
            {capitalizeFirstLetter(data.position)}
          </Styles.SubText>
        </Styles.CellData>
      );
    }

    case 'duration': {
      if ('duration' in data) {
        return (
          <Styles.CellData className="full-height align-center">
            <Styles.Text>{data.duration}</Styles.Text>
          </Styles.CellData>
        );
      }

      return (
        <Styles.CellData className="gap-8 full-height align-center">
          {DEFAULT_VALUES.NOT_APPLICABLE}
        </Styles.CellData>
      );
    }

    case 'order_id': {
      return (
        <Styles.CellData className="full-height align-center">
          <TooltipWrapper
            valueToShow={
              <Styles.OrderId>
                {data.order_id} - {data.collection_name}
              </Styles.OrderId>
            }
            textType="main-text"
          />
        </Styles.CellData>
      );
    }

    case 'checker_name': {
      if ('checker_name' in data) {
        return (
          <Styles.CellData className="gap-8 full-height align-center">
            <FinishingIcon />
            <TooltipWrapper
              valueToShow={<Styles.Text>{data.checker_name}</Styles.Text>}
              textType="main-text"
            />
          </Styles.CellData>
        );
      }

      return (
        <Styles.CellData className="gap-8 full-height align-center">
          {DEFAULT_VALUES.NOT_APPLICABLE}
        </Styles.CellData>
      );
    }

    case 'auditor_name': {
      if ('auditor_name' in data) {
        return (
          <Styles.CellData className="gap-8 full-height align-center">
            <StitchingIcon />
            <TooltipWrapper
              valueToShow={<Styles.Text>{data.auditor_name}</Styles.Text>}
              textType="main-text"
            />
          </Styles.CellData>
        );
      }

      return (
        <Styles.CellData className="gap-8 full-height align-center">
          {DEFAULT_VALUES.NOT_APPLICABLE}
        </Styles.CellData>
      );
    }

    case 'status': {
      if ('status' in data) {
        return (
          <Styles.CellData className="gap-8 full-height align-center">
            <TooltipWrapper
              valueToShow={
                <Styles.Text>
                  {removeUnderscoresAndCapitalize(data.status)}
                </Styles.Text>
              }
              textType="main-text"
            />
          </Styles.CellData>
        );
      }

      return (
        <Styles.CellData className="gap-8 full-height align-center">
          {DEFAULT_VALUES.NOT_APPLICABLE}
        </Styles.CellData>
      );
    }

    default: {
      if (
        data[identifier as keyof typeof data] &&
        typeof data[identifier as keyof typeof data] === 'object'
      ) {
        throw new Error(`[Sessions Table]: Unknown column ${identifier}`);
      }

      return (
        <Styles.CellData className="full-height align-center">
          <TooltipWrapper
            valueToShow={
              data[identifier as keyof typeof data] ??
              DEFAULT_VALUES.DOUBLE_DASH
            }
            textType="main-text"
          />
        </Styles.CellData>
      );
    }
  }
};

export default CellContent;
