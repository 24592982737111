/* eslint-disable react/jsx-no-useless-fragment */
import { ColumnDef, Row } from '@tanstack/react-table';
import { LinePerformanceItemsType } from '../schemas';
import { useGetMidlineDataByIdQuery } from '../../../../../redux/dashboard/api';
import * as Styles from './styles';
import ProgressBar from '../../../../../shared/ProgressBar';
import { BodyCell } from '../../../../../shared/Table/styles';
import { DEFAULT_VALUES } from '../../../../../constants';

interface IExpandedRowProps {
  lineId: number;
  columns: Array<ColumnDef<LinePerformanceItemsType[number], any>>;
}

const ExpandedRow = ({ lineId, columns }: IExpandedRowProps) => {
  const { data, isFetching } = useGetMidlineDataByIdQuery(lineId);

  //   if (isFetching) {
  //     return (
  //       <div className="center">

  //           <Spinner />

  //       </div>
  //     );
  //   }

  //   return (
  //     <tr>
  //       <td width="100%">
  //         <Skeleton height={44} border={0} width={100} />
  //       </td>
  //     </tr>
  //   );

  return (
    <>
      {data?.map(item => (
        <tr key={item.user_m_id}>
          {columns.map(column => {
            switch (column.id) {
              case 'ftt': {
                return (
                  <Styles.Td key={`${column.id}-${item.user_m_id}`}>
                    <BodyCell
                      className="truncated-text align-center"
                      $shouldHavePadding
                    >
                      {item.ftt} ({item.ftt_percentage} %)
                    </BodyCell>
                  </Styles.Td>
                );
              }

              case 'efficiency': {
                return (
                  <Styles.Td key={`${column.id}-${item.user_m_id}`}>
                    <BodyCell
                      className="truncated-text align-center"
                      $shouldHavePadding
                    >
                      {item.efficiency ?? DEFAULT_VALUES.NOT_APPLICABLE} %
                    </BodyCell>
                  </Styles.Td>
                );
              }

              case 'rejected': {
                return (
                  <Styles.Td key={`${column.id}-${item.user_m_id}`}>
                    <BodyCell
                      className="truncated-text align-center"
                      $shouldHavePadding
                    >
                      {item.reject_count}
                    </BodyCell>
                  </Styles.Td>
                );
              }

              case 'altered': {
                return (
                  <Styles.Td key={`${column.id}-${item.user_m_id}`}>
                    <BodyCell
                      className="truncated-text align-center"
                      $shouldHavePadding
                    >
                      {item.alter_count}
                    </BodyCell>
                  </Styles.Td>
                );
              }

              case 'passed': {
                return (
                  <Styles.Td key={`${column.id}-${item.user_m_id}`}>
                    <BodyCell
                      className="truncated-text align-center"
                      $shouldHavePadding
                    >
                      {item.pass_count}
                    </BodyCell>
                  </Styles.Td>
                );
              }

              case 'total_checked': {
                return (
                  <Styles.Td key={`${column.id}-${item.user_m_id}`}>
                    <BodyCell
                      className="truncated-text align-center"
                      $shouldHavePadding
                    >
                      <ProgressBar
                        value={item.total_checked}
                        color="var(--chetwode-blue)"
                        labelPosition="start"
                        shouldShowValue
                      />
                    </BodyCell>
                  </Styles.Td>
                );
              }

              case 'dhu': {
                return (
                  <Styles.Td key={`${column.id}-${item.user_m_id}`}>
                    <BodyCell
                      className="truncated-text align-center"
                      $shouldHavePadding
                    >
                      <ProgressBar
                        value={item.dhu}
                        color="var(--red-dirt)"
                        labelPosition="end"
                        valueSuffix="%"
                        shouldShowValue
                        gap={12}
                      />
                    </BodyCell>
                  </Styles.Td>
                );
              }

              case 'name': {
                return (
                  <Styles.Td key={`${column.id}-${item.user_m_id}`}>
                    <BodyCell
                      className="truncated-text align-center"
                      $shouldHavePadding
                    >
                      {item.checker_m_name}
                    </BodyCell>
                  </Styles.Td>
                );
              }

              case 'hourly':
              default: {
                return <Styles.Td key={`${column.id}-${item.user_m_id}`} />;
              }
            }
          })}
        </tr>
      ))}
    </>
  );
};

export default ExpandedRow;
