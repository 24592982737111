/* eslint-disable no-nested-ternary */
import { Fragment, useRef } from 'react';
import PieChart from '../../../../shared/PieChart';
import { IPieChartData } from '../../../../shared/PieChart/types';
import Segment from '../../../../shared/Segment';
import { Label, Dot } from '../../../../shared/PieChart/styles';
import useDimensions from '../../../../hooks/useDimensions';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import TinyMetric from '../../../../shared/TinyMetric';
import { useGetAuditOverallAnalyticsQuery } from '../../../../redux/auditDashboard/api';
import * as Styles from '../../Ordinary/OverallAnalytics/styles';

const OverallAnalytics = () => {
  const pieChartWrapperRef = useRef<HTMLDivElement>(null);

  const { department, start_date, end_date } = useTypedSelector(
    store => store.filters.selected
  );
  const { data, isLoading, isFetching, error } =
    useGetAuditOverallAnalyticsQuery({
      department,
      start_date,
      end_date,
    });

  const viewMode = useTypedSelector(store => store.ui.viewMode);

  const pieChartWrapperDimensions = useDimensions(pieChartWrapperRef, [
    isLoading,
  ]);

  const chartData: Array<IPieChartData> = [
    {
      color: 'var(--milano-red)',
      name: 'Rejected',
      subTexts: [`${data?.defective_piece_count} Rejected`],
      value: data?.defective_piece_count ?? 0,
    },
    {
      color: 'var(--deep-sea)',
      name: 'Passed',
      subTexts: [`${data?.inspection_pass_count} Passed`],
      value: data?.inspection_pass_count ?? 0,
    },
  ];

  return (
    <Segment
      heading="Overall Analytics"
      description="Summary of the production floor"
      descriptionMaxWidth={180}
      isLoading={isLoading}
      headerContent={
        <div className="center">
          {chartData.map(eachEntry => (
            <Fragment key={eachEntry.name}>
              <Dot fill={eachEntry.color} />
              <Label>{eachEntry.name}</Label>
            </Fragment>
          ))}
        </div>
      }
    >
      <div className="d-flex full-flex">
        <Styles.MetricContainer className="full-flex">
          <TinyMetric
            color="var(--text-color)"
            heading="Total Audits"
            isLoading={isFetching}
            value={data?.audit_count}
            viewMode={viewMode}
          />
          <TinyMetric
            color="var(--text-color)"
            heading="Total Insp."
            isLoading={isFetching}
            value={data?.inspection_count}
            viewMode={viewMode}
          />
          <TinyMetric
            color="var(--deep-sea)"
            heading="Passed"
            isLoading={isFetching}
            value={data?.inspection_pass_count}
            viewMode={viewMode}
          />
          <TinyMetric
            color="var(--milano-red)"
            heading="Defectives"
            isLoading={isFetching}
            value={data?.defective_piece_count}
            viewMode={viewMode}
          />
        </Styles.MetricContainer>

        <Styles.PieChartWrapper className="full-flex" ref={pieChartWrapperRef}>
          <PieChart
            config={{
              chartWidth: pieChartWrapperDimensions.width,
              chartHeight: pieChartWrapperDimensions.height,
              chartOuterRadius: 90,
            }}
            shouldShowLabels={false}
            data={chartData.map(eachData => ({
              ...eachData,
            }))}
          />
        </Styles.PieChartWrapper>
      </div>
    </Segment>
  );
};

export default OverallAnalytics;
