import { ZodSchema } from 'zod';
import Cookies from 'js-cookie';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { Toast } from '@fashinza/fashinza-design-system';
import { Faker } from '@faker-js/faker';
import { FakerFunction, generateMock } from '@anatine/zod-mock';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';
import { ENV } from '../../env';
import { getTokenCookieKey } from '../../utils/cookies';
import type { ObjectType } from '../../@types/defined';

type TBaseQuery = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  { zodSchema?: ZodSchema; mock?: boolean },
  FetchBaseQueryMeta
>;

export const baseQueryWithZodValidation: (
  baseQuery: TBaseQuery
) => TBaseQuery =
  (baseQuery: TBaseQuery) => async (args, api, extraOptions) => {
    if (ENV.IS_DEV_MODE && extraOptions?.mock && extraOptions?.zodSchema) {
      const mock = generateMock(extraOptions?.zodSchema);

      return {
        data: {
          data: mock,
          status: true,
          msg: '',
        },
      };
    }

    // Call the original baseQuery function with the provided arguments
    const returnValue = await baseQuery(args, api, extraOptions);

    // Retrieve the data schema from the extraOptions object
    const zodSchema = extraOptions?.zodSchema;

    const apiResponse = returnValue.data as ObjectType;

    // Check if both 'data' and 'zodSchema' are defined
    if (apiResponse && zodSchema) {
      const parseResult = zodSchema.safeParse(apiResponse.data);

      if (!parseResult.success) {
        console.group(api.endpoint);
        console.log(parseResult.error);
        console.groupEnd();
        Toast.ERROR(parseResult.error.message);
      }
    }

    return returnValue;
  };

export function overrideFetchBaseQuery({ urlSuffix = '', base = 'qapp/' }) {
  const baseUrl = `${ENV.QAPP_SERVER_URL}/rest-q/${base}${urlSuffix}`;

  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = Cookies.get(getTokenCookieKey() ?? '');

      if (token) {
        headers.set('Authorization', `Token ${token}`);
      }
      headers.set('content-type', `application/json`);
      return headers;
    },
    credentials: 'same-origin',
    mode: 'cors',
  });
}
