import styled, { css } from 'styled-components';

interface IImageWrapperProps {
  $borderRadius?: number;
  $hasBoxShadow?: boolean;
}

export const ImageWrapper = styled.div<IImageWrapperProps>`
  height: 60%;
  width: 100%;
  ${props =>
    props.$hasBoxShadow &&
    css`
      box-shadow: 0px 16px 32px 0px rgba(44, 53, 86, 0.1);
    `}

  img {
    object-fit: cover;
    border-radius: ${props => props.$borderRadius}px;
    height: 100%;
    width: 100%;
  }
`;

ImageWrapper.defaultProps = {
  $borderRadius: 12,
};
