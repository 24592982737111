import { useEffect, useRef } from 'react';
import OverallData from './OverallData';
import Strides from './Strides';
import PageTwo from './PageTwo';
import PageOne from './PageOne';
import PageThreeAndFour from './PageThreeAndFour';
import PageFive from './PageFive';
import Comments from './Comments';
import * as Styles from './styles';
import { OverAllDataType } from './OverallData/schemas';
import { POLLING_INTERVAL_IN_MILLISECONDS } from '../../../constants/polling';

interface IContentProps {
  pages: number;
  isLoading: boolean;
  data: OverAllDataType;
}

const Content = ({ data, isLoading, pages }: IContentProps) => {
  // const [page, setPage] = useState(1);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setPage(prevPage => (prevPage % pages) + 1);
  //   }, POLLING_INTERVAL_IN_MILLISECONDS);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [pages]);

  const pageRef = useRef(1);

  useEffect(() => {
    let animationFrameId: number;
    let lastTimestamp = 0;

    const updatePage = (timestamp: number) => {
      if (timestamp - lastTimestamp >= POLLING_INTERVAL_IN_MILLISECONDS) {
        pageRef.current = (pageRef.current % pages) + 1;
        lastTimestamp = timestamp;
      }
      animationFrameId = requestAnimationFrame(updatePage);
    };

    animationFrameId = requestAnimationFrame(updatePage);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [pages]);

  const page = pageRef.current;

  return (
    <>
      <Styles.Main className="v-d-flex gap-36 full-flex">
        <OverallData data={data} />
        {page === 1 && <PageOne />}
        {page === 2 && <PageTwo />}
        {(page === 3 || page === 4) && (
          <PageThreeAndFour page={page} styleData={data.sku_m} />
        )}
        {page === 5 && <PageFive />}
      </Styles.Main>

      <Comments isLoading={isLoading} data={data.sku_m?.buyer_comments} />

      <Strides currentPage={page} totalPages={pages} />
    </>
  );
};

export default Content;
