import { useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from '../Dashboard';
import Reports from '../Reports';
import Container from '../../shared/Container';
import DataViewer from '../DataViewer';
import Header from './Header';
import Sessions from '../Sessions';
import * as Styles from './styles';

const WorkStation = () => {
  const reportsPrintRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <Styles.H1 className="align-center">Quality Analytics</Styles.H1>
      <Container>
        <Header reportsPrintRef={reportsPrintRef} />
        <Switch>
          <Route path="/factory-qapp/dashboard" exact component={Dashboard} />
          <Route path="/factory-qapp/reports" exact component={Reports} />
          <Route path="/factory-qapp/sessions" exact component={Sessions} />
          <Route
            path="/factory-qapp/data-viewer"
            exact
            component={DataViewer}
          />
          <Redirect to="/factory-qapp/dashboard" />
        </Switch>
      </Container>
    </div>
  );
};

export default WorkStation;
