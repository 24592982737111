import { ObjectType } from '../@types/defined';

export function convertArrayToObject<T extends ObjectType>(
  arrayElements: Array<T>,
  idElement: keyof T
): Record<string, T> {
  return arrayElements.reduce(
    (accumulator, eachElement) => ({
      ...accumulator,
      [eachElement[idElement]]: eachElement,
    }),
    {} as Record<string, T>
  );
}
