import { Fragment, useEffect, DependencyList, CSSProperties, FC } from 'react';
import {
  AutoSizer as _AutoSizer,
  MultiGrid as _MultiGrid,
  Index,
  AutoSizerProps,
  MultiGridProps,
} from 'react-virtualized';
import { GridCoreProps } from 'react-virtualized/dist/es/Grid';
import FooterRow from '../FooterRow';
import { useMultiGrid } from './helperHooks';
import { useTableHeight } from '../helperHooks';
import { GRID_SETTINGS } from '../constants';
import { IGridSettings, IGridCellNode } from '../types';
import * as Styles from '../styles';

// * Typecasting because react-virtualized is not compatible with react v18
const MultiGrid = _MultiGrid as unknown as FC<MultiGridProps>;
const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;

interface IVirtualizedGridProps {
  className?: string;
  getGridCellNode(gridProps: IGridCellNode): JSX.Element;
  columnCount: number;
  recomputeDependency: DependencyList;
  listLength: number;
  getColumnWidth(index: number): number;
  getExpandedRowHeight?: (rowParams: Index) => number;
  heightDependencies: DependencyList;
  fixedRowCount?: number;
  fixedColumnCount?: number;
  gridSettings?: Partial<IGridSettings>;
  commonGridStyles?: CSSProperties;
  commonGridClassName?: string;
  onSectionRendered?: GridCoreProps['onSectionRendered'];
}

const VirtualizedGrid = ({
  className = '',
  getGridCellNode,
  columnCount,
  recomputeDependency,
  listLength,
  getColumnWidth,
  getExpandedRowHeight,
  heightDependencies,
  fixedRowCount = 1,
  fixedColumnCount = 2,
  gridSettings,
  commonGridStyles = {},
  commonGridClassName = '',
  onSectionRendered,
}: IVirtualizedGridProps) => {
  const modifiedGridSettings: IGridSettings = {
    ...GRID_SETTINGS,
    ...(gridSettings ?? {}),
  };

  const { multiGridRef, onScroll, classNameHash, scrollLeftValue } =
    useMultiGrid();
  const { listRef, tableHeight, cellsHeight } = useTableHeight(
    listLength,
    heightDependencies,
    modifiedGridSettings
  );

  useEffect(() => {
    multiGridRef.current?.recomputeGridSize();
  }, recomputeDependency);

  return (
    <Styles.ListWrapper
      ref={listRef}
      className={`full-view-max-height ${className} ${classNameHash}`}
      style={commonGridStyles}
    >
      <AutoSizer disableHeight>
        {({ width }) => (
          <>
            <MultiGrid
              ref={(element: _MultiGrid | null) => {
                multiGridRef.current = element;
              }}
              scrollLeft={scrollLeftValue}
              cellRenderer={gridProps => (
                <Fragment key={gridProps.key}>
                  {getGridCellNode({
                    columnIndex: gridProps.columnIndex,
                    rowIndex: gridProps.rowIndex,
                    style: gridProps.style,
                    viewMode: 'cell',
                  })}
                </Fragment>
              )}
              height={tableHeight}
              width={width}
              rowHeight={rowParams =>
                rowParams.index === 0
                  ? modifiedGridSettings.headerHeight
                  : modifiedGridSettings.cellDataHeight +
                    (getExpandedRowHeight?.(rowParams) ?? 0)
              }
              columnWidth={({ index }) => getColumnWidth(index)}
              rowCount={listLength + 1}
              columnCount={columnCount}
              fixedRowCount={fixedRowCount}
              fixedColumnCount={fixedColumnCount}
              enableFixedRowScroll
              enableFixedColumnScroll
              hideTopRightGridScrollbar
              hideBottomLeftGridScrollbar
              styleBottomRightGrid={{ willChange: 'auto', ...commonGridStyles }}
              styleTopLeftGrid={commonGridStyles}
              styleTopRightGrid={commonGridStyles}
              styleBottomLeftGrid={commonGridStyles}
              onScroll={({ scrollLeft }) => onScroll(scrollLeft)}
              onSectionRendered={onSectionRendered}
              classNameBottomLeftGrid={commonGridClassName}
              classNameBottomRightGrid={commonGridClassName}
              classNameTopLeftGrid={commonGridClassName}
              classNameTopRightGrid={commonGridClassName}
            />
            {modifiedGridSettings.shouldShowFooter && (
              <FooterRow
                positioning={{
                  top: cellsHeight,
                  height: modifiedGridSettings.footerHeight,
                  width,
                }}
                getGridCellNode={getGridCellNode}
                totalColumnCount={columnCount}
                fixedColumnCount={fixedColumnCount}
                rowIndex={listLength}
                getColumnWidth={getColumnWidth}
                onScroll={onScroll}
              />
            )}
          </>
        )}
      </AutoSizer>
    </Styles.ListWrapper>
  );
};

export default VirtualizedGrid;
