import { DEFAULT_VALUES } from '../../../../constants';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import Grid from '../../../../shared/Grid';
import GridItem from '../../../../shared/Grid/GridItem';
import Metric from '../../../../shared/Metric';
import Segment from '../../../../shared/Segment';
import { numberWithCommas } from '../../../../utils/numbers';
import { useGetAuditReportsOverAllDataQuery } from '../../../../redux/auditReports/api';

const OverallData = () => {
  const { department, brand, order, style, start_date, end_date, line } =
    useTypedSelector(store => store.filters.selected);
  const lineOptions = useTypedSelector(store =>
    store.filters.line_options
      .filter(item => item.department === department)
      .map(item => item.id)
      .join(',')
  );
  const { data, isLoading, isFetching } = useGetAuditReportsOverAllDataQuery({
    department,
    brand,
    order,
    line: line || lineOptions,
    style,
    start_date,
    end_date,
  });

  return (
    <Segment
      heading="Overall Data"
      isLoading={isLoading}
      shouldChildrenHavePadding={false}
    >
      <Grid
        minHeight="100%"
        gridTemplateRows="1fr 1fr"
        gridTemplateColumns="repeat(4, 1fr)"
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <Metric
            heading="Total Inspected"
            description="Total checked quantity"
            value={
              data?.inspection_count
                ? numberWithCommas(data.inspection_count)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>
        <GridItem gridColumn="2/3" gridRow="1/2">
          <Metric
            heading="Total passed"
            description="Total passed quantity (first time + after alteration)"
            value={
              data?.inspection_pass_count
                ? numberWithCommas(data.inspection_pass_count)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueColor="var(--deep-sea)"
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>
        <GridItem gridColumn="3/4" gridRow="1/2">
          <Metric
            heading="Passed in first time"
            description="Pieces approved in first check"
            value={
              data?.inspection_ftt_count
                ? numberWithCommas(data.inspection_ftt_count)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueColor="var(--deep-sea)"
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>
        <GridItem gridColumn="4/6" gridRow="1/2">
          <Metric
            heading="Passed after alter"
            description="Pieces approved after alteration"
            value={
              data?.inspection_not_ftt
                ? numberWithCommas(data.inspection_not_ftt)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueColor="var(--chilean-fire)"
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>

        <GridItem gridColumn="1/2" gridRow="2/3">
          <Metric
            heading="Defective Pieces"
            description="Garment pieces with defects"
            value={
              data?.defective_piece_count
                ? numberWithCommas(data.defective_piece_count)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueColor="var(--red-dirt)"
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>
        <GridItem gridColumn="2/3" gridRow="2/3">
          <Metric
            heading="Defect Count"
            description="Total defect count in all defective pieces"
            value={
              data?.defect_count
                ? numberWithCommas(data.defect_count)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueColor="var(--red-dirt)"
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>
        <GridItem gridColumn="3/5" gridRow="2/3">
          <Metric
            heading="DHU"
            description="Defect Per Hundred Units"
            value={
              data?.dhu
                ? numberWithCommas(data.dhu)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueColor="var(--red-dirt)"
            hasTopBorder
            isLoading={isFetching}
          />
        </GridItem>
      </Grid>
    </Segment>
  );
};

export default OverallData;
