import { z } from 'zod';

export const DefectsSummaryDataSchema = z.array(
  z.object({
    style: z.object({
      id: z.number(),
      style_name: z.string(),
      style_color: z.string(),
      style_image: z.string(),
    }),
    order: z.object({
      order_id: z.number(),
      collection_name: z.string(),
      buyer_name: z.string(),
    }),
    line_name: z.string(),
    department: z.string(),
    auditor_name: z.string(),
    status: z.string(),
    offered_quantity: z.number(),
    audit_quantity: z.number(),
    defect_count: z.number(),
    defect_percentage: z.number(),
  })
);

export type DefectsSummaryDataType = z.infer<typeof DefectsSummaryDataSchema>;
