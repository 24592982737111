import { IconPropsType } from './types';

interface IQualityAndTestingIconProps extends IconPropsType {}

const QualityAndTestingIcon = ({
  size = 24,
  stroke = 'var(--rhino)',
  strokeWidth = 1.25,
  ...props
}: IQualityAndTestingIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.51724 5.42315H4V19.9898H18.3448V14.0552M20 11.8972C20 11.8972 18.3187 10.3711 17.2414 9.31768M17.2414 9.31768C18.4602 8.12584 18.4602 6.09573 17.2414 4.90389C16.0225 3.71205 14.0464 3.71205 12.8276 4.90389C11.6088 6.09573 11.6088 8.12584 12.8276 9.31768C14.0464 10.5095 16.0225 10.5095 17.2414 9.31768Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default QualityAndTestingIcon;
