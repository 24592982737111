/* eslint-disable no-nested-ternary */
import { useEffect, useRef } from 'react';
import { HiOutlineDocumentArrowDown } from 'react-icons/hi2';
import { FixedSizeList } from 'react-window';
import { Button, Spinner } from '@fashinza/fashinza-design-system';
import Segment from '../../../../shared/Segment';
import useDimensions from '../../../../hooks/useDimensions';
import type { ObjectType } from '../../../../@types/defined';
import {
  useGetOverAllTrendQuery,
  useLazyGetOverAllTrendSpreadSheetQuery,
} from '../../../../redux/reports/api';
import type { OverAllTrendAnalysisTableItemType } from './schemas';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import { openInNewTab } from '../../../../utils';
import EmptyChartView from '../../../../shared/EmptyChartView';
import * as Styles from './styles';
import useFeatureFlags from '../../../../redux/featureFlags/useFeatureFlags';

const Column = ({ index, style, data, shouldShowEfficiency }: ObjectType) => {
  const columnData = data.columns?.[index] as OverAllTrendAnalysisTableItemType;

  return (
    <Styles.Column style={style}>
      <Styles.Label>Hour {columnData.id}</Styles.Label>
      <Styles.Cell className="align-center">{columnData.dhu} %</Styles.Cell>
      <Styles.Cell className="align-center">
        {columnData.ftt_count} ({columnData.ftt_percentage} %)
      </Styles.Cell>
      <Styles.Cell className="align-center">
        {columnData.reject_count}
      </Styles.Cell>
      {shouldShowEfficiency && (
        <Styles.Cell className="align-center">
          {columnData.efficiency} %
        </Styles.Cell>
      )}
    </Styles.Column>
  );
};

const TabularOverAllTrendAnalysis = () => {
  const tableContentRef = useRef<null>(null);
  const { department, brand, order, style, start_date, end_date, line } =
    useTypedSelector(store => store.filters.selected);
  const lineOptions = useTypedSelector(store =>
    store.filters.line_options
      .filter(item => item.department === department)
      .map(item => item.id)
      .join(',')
  );

  const filters = {
    department,
    brand,
    order,
    line: line || lineOptions,
    style,
    start_date,
    end_date,
  };

  const { data, isFetching } = useGetOverAllTrendQuery(filters);
  const tableContentDimensions = useDimensions(tableContentRef, [isFetching]);

  const [spreadSheetDownloadTrigger, spreadSheetResult] =
    useLazyGetOverAllTrendSpreadSheetQuery();

  useEffect(() => {
    if (spreadSheetResult.isFetching) {
      return;
    }

    if (spreadSheetResult.isSuccess) {
      openInNewTab(spreadSheetResult.data.link);
    }
  }, [spreadSheetResult]);

  const { should_show_efficiency } = useFeatureFlags();

  const isTableEmpty = !data || data?.length === 0;

  return (
    <Segment
      heading="Overall Trend Analysis (Tabular)"
      description="Tabular representation of hourly trend of DHU, FTT, Reject & Efficiency"
      descriptionMaxWidth={180}
      shouldChildrenHavePadding={false}
      headerContent={
        <Button
          onClick={() => spreadSheetDownloadTrigger(filters)}
          colorScheme="secondary"
          variant="tertiary"
          isLoading={spreadSheetResult.isFetching}
          leftIcon={<HiOutlineDocumentArrowDown size={16} />}
        >
          Download
        </Button>
      }
    >
      <Styles.TableWrapper className="d-flex">
        <Styles.LabelContainer className="v-d-flex">
          <Styles.Label>Metric/Hour</Styles.Label>
          <Styles.Label>DHU %</Styles.Label>
          <Styles.Label>FTT</Styles.Label>
          <Styles.Label>Rejection</Styles.Label>
          {should_show_efficiency && <Styles.Label>Efficiency %</Styles.Label>}
        </Styles.LabelContainer>
        <Styles.ContentContainer ref={tableContentRef}>
          {isFetching ? (
            <div className="full-dimension center">
              <Spinner />
            </div>
          ) : isTableEmpty ? (
            <EmptyChartView />
          ) : (
            <FixedSizeList
              height={tableContentDimensions.height}
              width={tableContentDimensions.width}
              itemCount={data?.length ?? 0}
              itemSize={120}
              layout="horizontal"
              itemData={{
                columns: data,
                shouldShowEfficiency: should_show_efficiency,
              }}
            >
              {Column}
            </FixedSizeList>
          )}
        </Styles.ContentContainer>
      </Styles.TableWrapper>
    </Segment>
  );
};

export default TabularOverAllTrendAnalysis;
