import { IconPropsType } from './types';

interface IStitchingIconProps extends IconPropsType {}

const StitchingIcon = ({
  size = 24,
  stroke = 'var(--rhino)',
  strokeWidth = 1.25,
  viewType = 'default',
  ...props
}: IStitchingIconProps) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={40} height={40} rx={20} fill="#FFCAD4" />
    <rect width={40} height={40} rx={20} fill="#FFCAD4" />
    <path
      d="M21.7027 17.4928H15.3784M15.3784 17.4928H13.9189C13.9189 17.4928 12.9459 17.4928 12.9459 16.3696V14.1232C12.9459 13 13.9189 13 13.9189 13H27.5405C29 13 29 14.6848 29 14.6848C29 14.6848 29 23.1304 29 24.3152C29 25.5 28 27 26.5405 27H12.4595C12.4595 27 11 27 11 25.3152C11 23.6304 12.4595 23.6304 12.4595 23.6304H24.6216C25.5 23.6304 25.5946 23 25.5946 22.5072V18.5759M15.3784 17.4928V19.7393"
      stroke="#9D219D"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default StitchingIcon;
