import { IconPropsType } from './types';

interface IArrowTrendingUpProps extends IconPropsType {}

const ArrowTrendingUp = ({
  stroke = 'var(--watermelon)',
  strokeWidth = 1.25,
  ...props
}: IArrowTrendingUpProps) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 11.68L6.13055 7.54944L8.76553 10.185C9.52723 8.68322 10.7839 7.49031 12.3233 6.80778L14 6.06122M14 6.06122L10.3651 4.66602M14 6.06122L12.6048 9.69671"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowTrendingUp;
