import { Key, MouseEventHandler, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import * as Styles from './styles';

type OptionalItemProperties = {
  text?: string;
  icon?: ReactNode;
} & (
  | {
      icon: ReactNode;
    }
  | {
      text: string;
    }
);

type ItemType =
  | {
      onClick: MouseEventHandler<HTMLElement>;
      key?: Key;
      as?: keyof JSX.IntrinsicElements;
    } & OptionalItemProperties;

interface IButtonGroupProps {
  items: [ItemType, ItemType, ...Array<ItemType>];
}

const ButtonGroup = ({ items }: IButtonGroupProps) => {
  const location = useLocation();
  const activeTab = location.pathname.split('factory-qapp/')[1];

  return (
    <Styles.Wrapper className="d-flex">
      {items.map(item => (
        <Styles.Button
          key={item.key}
          as={item.as ?? 'button'}
          onClick={item.onClick}
          className="align-center cursor-pointer"
          $isActive={item.key === activeTab}
        >
          {item.icon}
          {item.text}
        </Styles.Button>
      ))}
    </Styles.Wrapper>
  );
};

export default ButtonGroup;
