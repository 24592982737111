import { z } from 'zod';

export const AuditDefectsAnalyticsSchema = z.object({
  defect_count: z.number(),
  minor_defect_count: z.number(),
  major_defect_count: z.number(),
  critical_defect_count: z.number(),
});

export type AuditDefectsAnalyticsType = z.infer<
  typeof AuditDefectsAnalyticsSchema
>;
