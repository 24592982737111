import { useRef } from 'react';
import useDimensions from '../../../../hooks/useDimensions';
import BarChart from '../../../../shared/BarChart';
import Segment from '../../../../shared/Segment';
import ChartWrapper from '../../../../shared/ChartWrapper';
import { useGetLineFttQuery } from '../../../../redux/reports/api';
import { useTypedSelector } from '../../../../redux/useTypedSelector';

const LineFTT = () => {
  const areaChartWrapperRef = useRef<HTMLDivElement>(null);
  const { department, brand, order, style, start_date, end_date, line } =
    useTypedSelector(store => store.filters.selected);
  const lineOptions = useTypedSelector(store =>
    store.filters.line_options
      .filter(item => item.department === department)
      .map(item => item.id)
      .join(',')
  );
  const { data, isLoading, isFetching } = useGetLineFttQuery({
    department,
    brand,
    order,
    line: line || lineOptions,
    style,
    start_date,
    end_date,
  });
  const { height, width } = useDimensions(areaChartWrapperRef, [isLoading]);

  return (
    <Segment
      heading="Line FTT"
      description="Factory performance Line-wise (FTT)"
      descriptionMaxWidth={180}
      isLoading={isLoading || isFetching}
    >
      <ChartWrapper wrapperRef={areaChartWrapperRef}>
        <BarChart
          data={data ?? []}
          height={height}
          width={width}
          yLabel="FTT"
        />
      </ChartWrapper>
    </Segment>
  );
};

export default LineFTT;
