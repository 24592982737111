/* eslint-disable no-nested-ternary */
import { Skeleton } from '@fashinza/fashinza-design-system';
import { DEFAULT_VALUES } from '../../constants';
import { IUserInterfaceState } from '../../redux/ui';
import { numberWithCommas } from '../../utils/numbers';
import * as Styles from './styles';

interface ITinyMetricProps {
  viewMode: IUserInterfaceState['viewMode'];
  color: string;
  heading: string;
  value: number | undefined;
  isLoading: boolean;
  className?: string;
}

const TinyMetric = ({
  heading,
  color,
  value,
  isLoading,
  viewMode,
  className = 'v-space-around',
}: ITinyMetricProps) => (
  <Styles.Metric className={className} $viewMode={viewMode}>
    <Styles.MetricText>{heading}</Styles.MetricText>
    <Styles.MetricNumber
      style={{
        color,
      }}
    >
      {isLoading ? (
        <Skeleton height={24} width="100%" />
      ) : value ? (
        numberWithCommas(value)
      ) : (
        DEFAULT_VALUES.DOUBLE_DASH
      )}
    </Styles.MetricNumber>
  </Styles.Metric>
);

export default TinyMetric;
