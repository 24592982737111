import { DEFAULT_VALUES } from '../../../../constants';
import { useGetAuditOverallAnalyticsQuery } from '../../../../redux/auditDashboard/api';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import Grid from '../../../../shared/Grid';
import GridItem from '../../../../shared/Grid/GridItem';
import Metric from '../../../../shared/Metric';
import Segment from '../../../../shared/Segment';
import { numberWithCommas } from '../../../../utils/numbers';

const Totals = () => {
  const { department, start_date, end_date } = useTypedSelector(
    store => store.filters.selected
  );
  const { data, isLoading, isFetching } = useGetAuditOverallAnalyticsQuery({
    department,
    start_date,
    end_date,
  });

  return (
    <Segment isLoading={isLoading} shouldChildrenHavePadding={false}>
      <Grid
        minHeight="100%"
        gridTemplateRows="repeat(2, 1fr)"
        gridTemplateColumns="1fr"
      >
        <GridItem gridColumn="1/2" gridRow="1/2">
          <Metric
            heading="Audits Passed"
            value={
              data?.audit_pass_count
                ? numberWithCommas(data.audit_pass_count)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueColor="var(--deep-sea)"
            hasBottomBorder={true}
            isLoading={isFetching}
          />
        </GridItem>
        <GridItem gridColumn="1/2" gridRow="2/3">
          <Metric
            heading="Audits Failed"
            value={
              data?.audit_fail_count
                ? numberWithCommas(data.audit_fail_count)
                : DEFAULT_VALUES.DOUBLE_DASH
            }
            valueColor="var(--red-dirt)"
            isLoading={isFetching}
          />
        </GridItem>
      </Grid>
    </Segment>
  );
};

export default Totals;
