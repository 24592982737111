/* eslint-disable react/jsx-no-useless-fragment */
import {
  Children,
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
} from 'react';

type SwitchProps<T> = {
  children: Array<ReactElement<CaseProps<T>>>;
  currentCase: T;
  className?: string;
  as?: keyof JSX.IntrinsicElements;
};

type CaseProps<T> = {
  value: keyof SwitchProps<T>['currentCase'];
  children: ReactNode;
};

const Case = <T,>({ value, children }: CaseProps<T>) => (
  <>
    {Children.map(children, child => {
      if (isValidElement(child)) {
        return cloneElement(child);
      }
      return null;
    })}
  </>
);

const Switch = <T,>({
  children,
  currentCase,
  as = 'div',
  className = '',
}: SwitchProps<T>) => {
  const matchingCase = Children.toArray(children).find(
    child =>
      isValidElement(child) &&
      (child as ReactElement<CaseProps<T>>).props.value === currentCase
  );

  const Component = as;

  return <Component className={className}>{matchingCase || null}</Component>;
};

export { Switch, Case };
