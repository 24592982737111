import { useEffect } from 'react';
import { format } from 'date-fns';
import { getQappFilters } from '../../../redux/filters/thunks';
import useAppDispatch from '../../../redux/useAppDispatch';
import { useTypedSelector } from '../../../redux/useTypedSelector';
import { setDateRange, setDepartment } from '../../../redux/filters';
import Dropdown from '../../../shared/Dropdown';
import QappTracker from '../../../analytics/tracker';
import DatePickerField from '../../../shared/DatePickerField';

const Filters = () => {
  const dispatch = useAppDispatch();

  const departmentFilterOptions = useTypedSelector(store =>
    store.filters.department.map(option => ({
      name: option.name,
      value: option.id,
    }))
  );
  const { department, start_date, end_date } = useTypedSelector(
    store => store.filters.selected
  );

  useEffect(() => {
    dispatch(getQappFilters());
  }, []);

  return (
    <div className="align-flex-end spread">
      <Dropdown
        label="Departments"
        onChange={selectedValue => {
          dispatch(setDepartment(selectedValue));
          QappTracker.track('ApplyFilter', {
            filter_name: 'Departments',
            current_page: 'dashboard',
            filter_value: selectedValue,
          });
        }}
        options={departmentFilterOptions}
        value={department}
        placeholder="Select Department"
      />
      <div className="full-flex flex-end">
        <DatePickerField
          value={new Date(start_date)}
          setValue={newDate => {
            dispatch(
              setDateRange({
                start_date: format(newDate ?? new Date(), 'yyyy-M-d'),
                end_date: format(newDate ?? new Date(), 'yyyy-M-d'),
              })
            );
            QappTracker.track('ApplyFilter', {
              filter_name: 'Date Range',
              current_page: 'dashboard',
              filter_value: `start_date=${format(
                newDate ?? new Date(),
                'yyyy-M-d'
              )},end_date=${format(newDate ?? new Date(), 'yyyy-M-d')}`,
            });
          }}
        />
      </div>

      {/* <DateRangePicker
        shouldShowLabel={false}
        endDate={new Date(end_date)}
        startDate={new Date(start_date)}
        onChangeDays={(newStartDate, newEndDate) => {
          dispatch(
            setDateRange({
              start_date: format(newStartDate ?? new Date(), 'yyyy-M-d'),
              end_date: format(newEndDate ?? new Date(), 'yyyy-M-d'),
            })
          );
          QappTracker.track('ApplyFilter', {
            filter_name: 'Date Range',
            current_page: 'dashboard',
            filter_value: `start_date=${format(
              newStartDate ?? new Date(),
              'yyyy-M-d'
            )},end_date=${format(newEndDate ?? new Date(), 'yyyy-M-d')}`,
          });
        }}
      /> */}
    </div>
  );
};

export default Filters;
