import InfiniteLooper from '../../../../shared/InfiniteLooper';
import * as Styles from './styles';

interface ICommentsProps {
  data: Array<string> | undefined;
  isLoading: boolean;
}

const Comments = ({ data = [], isLoading }: ICommentsProps) => (
  <Styles.Wrapper className="align-center center">
    {!isLoading && data.length === 0 ? (
      <Styles.Comment>
        No Buyer Comments added. You can now add Buyer Comments when
        creating/editing styles or assigning to lines
      </Styles.Comment>
    ) : (
      <InfiniteLooper speed={60} direction="left">
        <Styles.CommentsContainer className="d-flex gap-24">
          {data.map((comment, index) => (
            <Styles.Comment key={comment}>
              {index + 1}. {comment}
            </Styles.Comment>
          ))}
        </Styles.CommentsContainer>
      </InfiniteLooper>
    )}
  </Styles.Wrapper>
);

export default Comments;
