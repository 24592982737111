import { z } from 'zod';

export const LotWiseSummarySchema = z.object({
  id: z.number(),
  audit_sku_id: z.number(),
  created_on: z.string(),
  status: z.string(),
  inspection_count: z.number(),
  inspection_pass_count: z.number(),
  defect_count: z.number(),
  auditor_name: z.string(),
  style: z.object({
    id: z.number(),
    style_name: z.string(),
    style_color: z.string(),
    style_image: z.string(),
  }),
  order: z.object({
    order_id: z.number(),
    collection_name: z.string(),
    buyer_name: z.string(),
  }),
  line_id: z.number(),
  line_name: z.string(),
});

export const LotWiseSummariesSchema = z.array(LotWiseSummarySchema);

export type LotWiseSummariesDataType = z.infer<typeof LotWiseSummariesSchema>;

export type LotWiseSummaryDataType = z.infer<typeof LotWiseSummarySchema>;
