import { z } from 'zod';

export const AuditSummaryByCheckerDataSchema = z.array(
  z.object({
    auditor_name: z.string(),
    department: z.string(),
    line_names: z.string(),
    audit_count: z.number(),
    inspection_count: z.number(),
    inspection_pass_count: z.number(),
    inspection_pass_percentage: z.number(),
    inspection_fail_count: z.number(),
    inspection_fail_percentage: z.number(),
  })
);

export type AuditSummaryByCheckerDataType = z.infer<
  typeof AuditSummaryByCheckerDataSchema
>;
