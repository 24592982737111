/* eslint-disable react/no-unstable-nested-components */
import { useMemo, useRef, useState } from 'react';
import Segment from '../../../../shared/Segment';
import useDimensions from '../../../../hooks/useDimensions';
import useFakePagination from '../../../../hooks/useFakePagination';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import { useGetLotWiseAnalyticsQuery } from '../../../../redux/auditDashboard/api';
import useFeatureFlags from '../../../../redux/featureFlags/useFeatureFlags';
import Sidebar from '../../../../shared/Sidebar';
import Table from './Table';
import { PaginationWrapper } from '../../../../shared/Table/styles';
import ManualPagination from '../../../../shared/ManualPagination';
import AuditDetails from '../../../Sessions/Audit/AuditDetails';

const LotWiseSummary = () => {
  const tableContentRef = useRef<null>(null);
  const { department, brand, order, style, start_date, end_date, line } =
    useTypedSelector(store => store.filters.selected);
  const lineOptions = useTypedSelector(store =>
    store.filters.line_options
      .filter(item => item.department === department)
      .map(item => item.id)
      .join(',')
  );

  const filters = {
    department,
    brand,
    order,
    line: line || lineOptions,
    style,
    start_date,
    end_date,
  };

  const {
    data: lotWiseAnalytics,
    isLoading,
    isFetching,
  } = useGetLotWiseAnalyticsQuery(filters);
  const tableContentDimensions = useDimensions(tableContentRef, [isLoading]);

  const [selectedSessionId, setSelectedSessionId] = useState(-1);

  function openDetailsView(sessionId: number) {
    setSelectedSessionId(() => sessionId);
  }

  function closeDetailsView() {
    setSelectedSessionId(-1);
  }

  const { page, action } = useFakePagination(lotWiseAnalytics?.ids, 5);

  const isTableEmpty = lotWiseAnalytics?.ids.length === 0;

  const detailsData = lotWiseAnalytics?.data[selectedSessionId];

  return (
    <Segment
      heading="Lot-wise summary"
      // description="Tabular representation of hourly trend of DHU, FTT, Reject & Efficiency"
      descriptionMaxWidth={180}
      shouldChildrenHavePadding={false}
      isLoading={isLoading || isFetching}
      childrenWrapperRef={tableContentRef}
    >
      <Table
        onClickViewDetails={openDetailsView}
        data={lotWiseAnalytics?.data ?? {}}
        ids={page.data ?? []}
        isLoading={isLoading}
        isFetching={isFetching}
      />
      {!isTableEmpty && (
        <PaginationWrapper className="flex-justify-end">
          <ManualPagination
            kind="manual"
            totalCount={lotWiseAnalytics?.ids.length ?? 0}
            pageLength={5}
            currentPage={page.current ?? 0}
            onChangePage={c =>
              c === 'next' ? action.next() : action.previous()
            }
          />
        </PaginationWrapper>
      )}
      <Sidebar
        isOpen={selectedSessionId > -1 && Boolean(detailsData)}
        heading={
          detailsData ? `${detailsData.order.collection_name} Details` : ''
        }
        subHeadings={detailsData ? [detailsData.line_name, department] : []}
        onClose={closeDetailsView}
      >
        <AuditDetails sessionId={selectedSessionId} />
      </Sidebar>
    </Segment>
  );
};

export default LotWiseSummary;
