import styled from 'styled-components';
import { ObjectType } from '../../@types/defined';
import { removeUnderscoresAndCapitalize } from '../../utils';

const Wrapper = styled.div`
  min-width: 170px;
  background-color: var(--white);
  border: 1px solid var(--athens-gray);
  box-shadow: 0 8px 8px -4px rgba(12, 16, 33, 0.03),
    0 20px 24px -4px rgba(12, 16, 33, 0.08);
  border-radius: 8px;
  padding: 12px;
  pointer-events: none;
`;

const Heading = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 8px;
`;

const SubText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: var(--rhino);
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const renderTooltip = (props: any) => {
  if (props.active && props.payload && props.payload.length) {
    return (
      <Wrapper>
        {props.payload.map((item: ObjectType) => (
          <div className="d-flex gap-8">
            <Heading style={{ color: item.color }}>
              {removeUnderscoresAndCapitalize(item.name)}
            </Heading>
            <SubText>{item.payload[item.dataKey]}</SubText>
          </div>
        ))}
      </Wrapper>
    );
  }

  return null;
};
export default renderTooltip;
