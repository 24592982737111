import { useGetFeatureFlagsQuery } from './api';
import { DEFAULT_FEATURE_FLAGS } from './default';

const useFeatureFlags = () => {
  const { data = DEFAULT_FEATURE_FLAGS, isFetching } =
    useGetFeatureFlagsQuery();

  return { ...data, isFetching };
};

export default useFeatureFlags;
