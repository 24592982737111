import { createColumnHelper } from '@tanstack/react-table';
import Segment from '../../../../shared/Segment';
import Table from '../../../../shared/Table';
import { useGetTopDefectsQuery } from '../../../../redux/dashboard/api';
import useFakePagination from '../../../../hooks/useFakePagination';
import { useTypedSelector } from '../../../../redux/useTypedSelector';
import { capitalizeFirstLetter } from '../../../../utils';

export interface ITopDefectsTableItem {
  department: string;
  defect_type: string;
  count: number;
}

const columnHelper = createColumnHelper<ITopDefectsTableItem>();

const columns = [
  columnHelper.accessor('department', {
    id: 'department',
    header: 'Department',
    cell: info => (
      <div className="truncated-text" style={{ maxWidth: 100 }}>
        {capitalizeFirstLetter(info.getValue())}
      </div>
    ),
    maxSize: 20,
  }),
  columnHelper.accessor('defect_type', {
    id: 'defect_type',
    header: 'Defect Type',
    cell: info => (
      <div className="truncated-text" style={{ maxWidth: 100 }}>
        {info.getValue()}
      </div>
    ),
    maxSize: 70,
  }),
  columnHelper.accessor('count', {
    id: 'count',
    header: 'Count',
    cell: info => info.getValue(),
    maxSize: 10,
  }),
];

const TopDefects = () => {
  const { department, start_date, end_date } = useTypedSelector(
    store => store.filters.selected
  );
  const { data, isLoading, isFetching } = useGetTopDefectsQuery({
    department,
    start_date,
    end_date,
  });

  const { page, action } = useFakePagination(data, 5);

  return (
    <Segment
      heading="Top Defects"
      description="Top defects for the selected date"
      descriptionMaxWidth={180}
      shouldChildrenHavePadding={false}
      isLoading={isLoading || isFetching}
    >
      <Table
        data={page.data ?? []}
        columns={columns}
        currentPage={page.current ?? 0}
        handlePagination={c =>
          c === 'next' ? action.next() : action.previous()
        }
        pageLength={5}
        totalCount={data?.length ?? 0}
      />
    </Segment>
  );
};

export default TopDefects;
