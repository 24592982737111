import styled from 'styled-components';

export const PageButton = styled.button<{ disabled: boolean }>`
  height: 32px;
  width: 32px;
  box-shadow: 0 1px 2px rgba(12, 16, 33, 0.05);
  border: 1px solid var(--loblolly);
  border-radius: 8px;
  padding: 0;
  margin-right: 8px;

  &:hover {
    background-color: var(--athens-gray);
  }

  &:disabled {
    background-color: var(--wash-me);
    cursor: not-allowed;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const CurrentDetails = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--rhino);
  margin-left: 16px;
`;

export const TotalDetails = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--manatee);
`;
