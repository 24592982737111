import styled from 'styled-components';
import { IUserInterfaceState } from '../../redux/ui';

interface IWrapperProps {
  $viewMode: IUserInterfaceState['viewMode'];
}

export const Wrapper = styled.div<IWrapperProps>`
  background: var(--ebony);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;

  --subtle-text-color: ${props =>
    props.$viewMode === 'tv' ? 'var(--manatee)' : 'var(--fiord)'};

  --text-color: ${props =>
    props.$viewMode === 'tv' ? 'var(--white)' : 'var(--rhino)'};

  --manatee-or-rhino: ${props =>
    props.$viewMode === 'tv' ? 'var(--manatee)' : 'var(--rhino)'};
`;

interface IContentProps {
  $height: '90vh' | '80vh' | string;
}

export const Content = styled.div<IContentProps>`
  padding: 12px 24px 24px 24px;
  height: ${props => props.$height};
`;
