import { styled } from 'styled-components';

const StyledText = styled.h3`
  font-weight: 600;
  font-size: 16px;
  color: var(--hibiscus);
`;

interface IPurpleTextProps {
  children: string;
}

const PurpleText = ({ children }: IPurpleTextProps) => (
  <StyledText>{children}</StyledText>
);

export default PurpleText;
