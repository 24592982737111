import { IconPropsType } from './types';

interface IExternalLinkIconProps extends IconPropsType {}

const ExternalLinkIcon = ({
  stroke = 'var(--rhino)',
  size = 24,
  strokeWidth = 1.25,
  fill = 'none',
  ...props
}: IExternalLinkIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.333 4.999H5c-.92 0-1.667.746-1.667 1.666V15c0 .92.746 1.666 1.667 1.666h8.333c.92 0 1.667-.746 1.667-1.666v-3.334m-3.334-8.333h5m0 0v5m0-5-8.333 8.333"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ExternalLinkIcon;
