import CellContent from './CellContent';
import HeaderCell from './HeaderCell';
import GridCell from '../../../shared/VirtualizedTable/GridCell';
import { IGridCellNode } from '../../../shared/VirtualizedTable/types';
import { SESSION_FIELDS_MAPPING, getMultiGridSettings } from './constants';
import { SessionType } from '../Ordinary/schemas';
import { ModeOfOperationType } from '../../../@types';
import { AuditSessionType } from '../Audit/schemas';

interface ITableCellProps {
  mode: ModeOfOperationType;
  listIds: Array<number>;
  gridProps: IGridCellNode;
  lastItemRef: (node: any) => void;
  data: SessionType | AuditSessionType;
  onClickViewDetails: (sessionId: number) => void;
}

const TableCell = ({
  mode,
  listIds,
  gridProps,
  data,
  lastItemRef,
  onClickViewDetails,
}: ITableCellProps) => {
  const { columnIndex, rowIndex } = gridProps;

  const listFields = Object.keys(SESSION_FIELDS_MAPPING[mode]);

  const field = SESSION_FIELDS_MAPPING[mode][listFields[columnIndex]];

  if (rowIndex === 0) {
    return (
      <GridCell
        gridProps={gridProps}
        totalRowsCount={listIds.length}
        totalColumnsCount={listFields.length}
        fixedColumnCount={getMultiGridSettings(mode).fixedColumnCount}
        cellNode={<HeaderCell mode={mode} columnIndex={columnIndex} />}
      />
    );
  }

  if (data) {
    let cellNode = (
      <CellContent
        data={data}
        identifier={field.value}
        onClickViewDetails={onClickViewDetails}
      />
    );

    if (columnIndex === 0 && rowIndex === listIds.length - 3) {
      cellNode = (
        <div ref={lastItemRef}>
          <CellContent
            data={data}
            identifier={field.value}
            onClickViewDetails={onClickViewDetails}
          />
        </div>
      );
    }

    return (
      <GridCell
        gridProps={gridProps}
        totalRowsCount={listIds.length}
        totalColumnsCount={listFields.length}
        fixedColumnCount={getMultiGridSettings(mode).fixedColumnCount}
        cellNode={cellNode}
      />
    );
  }

  return null;
};

export default TableCell;
