/* eslint-disable no-param-reassign */
import { addDays, format } from 'date-fns';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getBrands, getOrders, getQappFilters, getStyles } from './thunks';
import { ApiDrivenFiltersType } from './schemas';

export interface IFiltersState extends ApiDrivenFiltersType {
  selected: {
    date: string;
    department: string;
    line: string;
    session: string;
    brand: string;
    order: string;
    style: string;
    start_date: string;
    end_date: string;
  };
}

const INITIAL_STATE: IFiltersState = {
  department: [] as ApiDrivenFiltersType['department'],
  line_options: [] as ApiDrivenFiltersType['line_options'],
  session_options: [] as ApiDrivenFiltersType['session_options'],
  brands: [] as ApiDrivenFiltersType['brands'],
  orders: [] as ApiDrivenFiltersType['orders'],
  styles: [] as ApiDrivenFiltersType['styles'],
  selected: {
    date: format(new Date(), 'yyyy-M-d'),
    department: 'stitching',
    // department: 'stitching', // TODO : undo
    line: '',
    session: '',
    brand: '',
    order: '',
    style: '',
    start_date: format(new Date(), 'yyyy-M-d'),
    end_date: format(addDays(new Date(), 1), 'yyyy-M-d'),
  },
} as const;

const filtersReducer = createSlice({
  name: 'filters',
  initialState: INITIAL_STATE,
  reducers: {
    setDate(state, action) {
      state.selected.date = action.payload;
    },
    setDepartment(state, action) {
      state.selected.department = action.payload;
    },
    setLine(state, action) {
      state.selected.line = action.payload ?? '';
    },
    setSession(state, action) {
      state.selected.session = action.payload ?? '';
    },
    setBrand(state, action) {
      state.selected.brand = action.payload ?? '';
    },
    setOrder(state, action) {
      state.selected.order = action.payload ?? '';
    },
    setStyle(state, action) {
      state.selected.style = action.payload ?? '';
    },
    resetFilter(state, action: PayloadAction<keyof IFiltersState['selected']>) {
      const key = action.payload;

      if (key === 'start_date' || key === 'end_date') {
        state.selected.start_date = '';
        state.selected.end_date = '';
        return;
      }

      state.selected[key] = '';
    },
    setDateRange(
      state,
      action: PayloadAction<{ start_date: string; end_date: string }>
    ) {
      state.selected.start_date = action.payload.start_date;
      state.selected.end_date = action.payload.end_date;
    },
  },
  extraReducers: builder => {
    // builder.addCase(getQappFilters.pending, (state, action) => {
    //   state.status = 'pending';
    // });
    builder.addCase(getQappFilters.fulfilled, (state, action) => {
      // state.status = 'fulfilled';
      // TODO : Remove hardcoded filters
      state.department = [
        ...action.payload.department,
        { id: 'stitching_audit', name: 'Stitching Audit' },
        { id: 'finishing_audit', name: 'Finishing Audit' },
      ];
      state.line_options = action.payload.line_options;
      state.session_options = action.payload.session_options;
    });
    // builder.addCase(getQappFilters.rejected, (state, action) => {
    //   state.status = 'rejected';
    // });
    // builder.addCase(getBrands.pending, (state, action) => {
    //   state.status = 'pending';
    // });
    builder.addCase(getBrands.fulfilled, (state, action) => {
      // state.status = 'fulfilled';
      state.brands = action.payload.filter(
        item => !['', '.', '-', '_'].includes(item.name)
      );
    });
    // builder.addCase(getBrands.rejected, (state, action) => {
    // state.status = 'rejected';
    // });
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.orders = action.payload;
    });
    builder.addCase(getStyles.fulfilled, (state, action) => {
      state.styles = action.payload.products;
    });
  },
});

export const {
  setDate,
  setDepartment,
  setLine,
  setSession,
  setBrand,
  setOrder,
  setDateRange,
  setStyle,
  resetFilter,
} = filtersReducer.actions;

export default filtersReducer.reducer;
