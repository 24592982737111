import { RefObject } from 'react';
import { replace } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
// import { BsPrinter } from 'react-icons/bs';
// import { useReactToPrint } from 'react-to-print';
import {
  Button,
  DevicesIcon,
  RefreshIcon,
  ViewIcon,
} from '@fashinza/fashinza-design-system';
import { HiOutlineDocumentChartBar, HiOutlineChartPie } from 'react-icons/hi2';
import { Case, Switch } from '../../shared/Switch';
import ButtonGroup from '../../shared/ButtonGroup';
import useAppDispatch from '../../redux/useAppDispatch';
import QappTracker from '../../analytics/tracker';
import { resetDashboardState } from '../../redux/dashboard/api';
import { useTypedSelector } from '../../redux/useTypedSelector';
import { resetAuditDashboardState } from '../../redux/auditDashboard/api';

interface IHeaderProps {
  reportsPrintRef: RefObject<HTMLDivElement>;
}

const Header = ({ reportsPrintRef }: IHeaderProps) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const activeTab = location.pathname.split('factory-qapp/')[1];

  const department = useTypedSelector(
    store => store.filters.selected.department
  );

  const isAuditDepartment = department.includes('audit');

  // const onPrintReports = useReactToPrint({
  //   content: () => reportsPrintRef.current,
  //   documentTitle: `Report`,
  //   pageStyle: 'padding: 24px',
  // });

  return (
    <header className="spread">
      <ButtonGroup
        items={[
          {
            onClick: () => {
              dispatch(replace('/factory-qapp/dashboard'));
              QappTracker.track('TabChange', { tab_name: 'dashboard' });
            },
            text: 'Dashboard',
            key: 'dashboard',
            icon: <ViewIcon size={20} />,
          },
          {
            onClick: () => {
              dispatch(replace('/factory-qapp/reports'));
              QappTracker.track('TabChange', { tab_name: 'reports' });
            },
            text: 'Reports',
            key: 'reports',
            icon: <HiOutlineDocumentChartBar stroke="var(--rhino)" size={20} />,
          },
          {
            onClick: () => {
              dispatch(replace('/factory-qapp/sessions'));
              QappTracker.track('TabChange', { tab_name: 'sessions' });
            },
            text: 'Sessions',
            key: 'sessions',
            icon: <HiOutlineChartPie stroke="var(--rhino)" size={20} />,
          },
          {
            onClick: () => {
              dispatch(replace('/factory-qapp/data-viewer'));
              QappTracker.track('TabChange', { tab_name: 'data-viewer' });
            },
            text: 'Data Viewer',
            key: 'data-viewer',
            icon: (
              <DevicesIcon viewType="desktop" strokeWidth={1.5} size={20} />
            ),
          },
        ]}
      />

      <Switch currentCase={activeTab} className="flex-end gap-12">
        <Case value="dashboard">
          <Button
            variant="outline"
            colorScheme="neutral"
            onClick={() => {
              if (isAuditDepartment) {
                dispatch(resetAuditDashboardState());
              } else {
                dispatch(resetDashboardState());
              }
            }}
            leftIcon={<RefreshIcon strokeWidth={1.5} />}
          >
            Refresh Page
          </Button>
        </Case>
        <Case value="reports">
          {/* <Button
            variant="outline"
            colorScheme="neutral"
            onClick={onPrintReports}
            leftIcon={<BsPrinter stroke="var(--rhino)" size={20} />}
          >
            Print
          </Button> */}
        </Case>
      </Switch>
    </header>
  );
};

export default Header;
