import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';
import { POLLING_INTERVAL_IN_MILLISECONDS } from '../../../../constants/polling';
import * as Styles from './styles';

interface IProgressBarProps {
  isActive: boolean;
}

const ProgressBar = ({ isActive }: IProgressBarProps) => {
  const progressBarAnimation = useAnimation();

  useEffect(() => {
    const startAnimation = async () => {
      if (isActive) {
        await progressBarAnimation.start({
          width: '100%',
          transition: {
            duration: POLLING_INTERVAL_IN_MILLISECONDS / 1000,
            ease: 'linear',
          },
        });
        progressBarAnimation.start({
          width: '0%',
          transition: { duration: 0 },
        });
      }
    };

    startAnimation();
  }, [isActive, progressBarAnimation]);

  return (
    <Styles.StrideWrapper className="full-flex" $isActive={isActive}>
      <Styles.ProgressBar animate={progressBarAnimation}></Styles.ProgressBar>
    </Styles.StrideWrapper>
  );
};

interface ISeekBar {
  currentPage: number;
  totalPages: number;
}

const Strides = ({ currentPage, totalPages }: ISeekBar) => (
  <Styles.Wrapper className="align-center gap-12">
    {Array.from({ length: totalPages }, (_, index) => index + 1).map(
      pageNumber => (
        <ProgressBar key={pageNumber} isActive={currentPage === pageNumber} />
      )
    )}
  </Styles.Wrapper>
);

export default Strides;
