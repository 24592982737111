import {
  BarChart as RechartsBarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { useTypedSelector } from '../../redux/useTypedSelector';
import EmptyChartView from '../EmptyChartView';
import renderTooltip from './renderTooltip';

const getColor = (value: number) => {
  if (value > 60) {
    return 'var(--milano-red)';
  }

  return 'var(--fuschia-blue)';
};

interface IBarChartProps {
  data: Array<any>;
  height: number;
  width: number;
  shouldShowXAxis?: boolean;
  yLabel?: string;
  axisStroke?: string;
  barCellWidth?: number | string;
  barRadius?: number;
  shouldShowTooltip?: boolean;
  shouldShowCartesianGrid?: boolean;
  fill?: string;
  margin?: { top?: number; right?: number; bottom?: number; left?: number };
}

const BarChart = ({
  data,
  height,
  width,
  yLabel,
  axisStroke,
  barCellWidth,
  fill,
  margin,
  shouldShowXAxis = true,
  barRadius = 4,
  shouldShowTooltip = true,
  shouldShowCartesianGrid = true,
}: IBarChartProps) => {
  const viewMode = useTypedSelector(store => store.ui.viewMode);

  if (data.length === 0) {
    return <EmptyChartView />;
  }

  return (
    <RechartsBarChart
      width={width}
      height={height}
      data={data}
      barSize={4}
      barCategoryGap={10}
      margin={margin}
    >
      {shouldShowCartesianGrid && <CartesianGrid vertical={false} />}
      {shouldShowXAxis && (
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          stroke={axisStroke}
          style={{
            fontSize: '12px',
            fontWeight: 500,
          }}
        />
      )}
      {yLabel && (
        <YAxis
          axisLine={false}
          tickLine={false}
          stroke={axisStroke}
          style={{
            fontSize: '12px',
            fontWeight: 500,
          }}
          label={{
            value: yLabel,
            angle: -90,
            position: 'insideLeft',
            stroke: axisStroke,
          }}
        />
      )}

      {shouldShowTooltip && (
        <Tooltip
          content={renderTooltip}
          cursor={{
            fill: viewMode === 'tv' ? 'var(--rhino)' : 'var(--athens-gray)',
          }}
        />
      )}
      <Bar
        dataKey="value"
        radius={[barRadius, barRadius, 0, 0]}
        width={barCellWidth || viewMode === 'tv' ? 12 : 6}
      >
        {data.map(entry => (
          <Cell
            key={`cell-${entry.name}-${entry.value}`}
            fill={entry.fill || fill || getColor(entry.value)}
          />
        ))}
      </Bar>
    </RechartsBarChart>
  );
};

export default BarChart;
